import React from 'react'
import {
  Provider,
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
} from 'react-redux'
import PrayasSchemeStore from '../../../store/scheme/prayas/prayas'

const MyContext = React.createContext(null)

// Export your custom hooks if you wish to use them in other files.
export const PrayasStore = createStoreHook(MyContext)
export const PrayasDispatch = createDispatchHook(MyContext)
export const PrayasSelector = createSelectorHook(MyContext)

export function PrayasProvider({ children }) {
  return (
    <Provider context={MyContext} store={PrayasSchemeStore}>
      {children}
    </Provider>
  )
}
