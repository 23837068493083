import { getRequest, postRequest } from '../../../utils/axiosClient/axios'
import { getToken } from './startupForm'
import { logout } from './user'
export const getSelectedApplication = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  console.log('trying api')
  const response = await postRequest({
    endpoint: '/scheme/getCallApplicationList',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
export const getProjectInfo = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/getprojectdata',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
export const getMileInfo = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/getMilestoneData',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
export const uploadPrjReport = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/uploadReport',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}

//api request to delete prjoject report
export const deletePrjReport = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/deleteReport',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}

export const submitReport = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/updateStatus',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
export const uploadExpense = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/uploadExpense',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}
export const getExpenseForms = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/getExpenseForms',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
export const getExpenseFields = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/getExpenseData',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
export const updateExpenseFormReject = async (
  { data, dispatch, controller },
  multipartformEnabled
) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/updateExpenseFormReject',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
      'Content-Type': multipartformEnabled
        ? 'multipart/form-data'
        : 'application/json',
    },
  })
  return response
}
export const updateExpenseFormAccept = async (
  { data, dispatch, controller },
  multipartformEnabled
) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/updateExpenseFormAccept',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
      'Content-Type': multipartformEnabled
        ? 'multipart/form-data'
        : 'application/json',
    },
  })
  return response
}
export const saveExpenseForm = async (
  { data, dispatch, controller },
  multipartformEnabled
) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  console.log(data)
  const response = await postRequest({
    endpoint: '/scheme/saveExpenseForm',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
export const submitForDispersion = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/submitfordispersion',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
export const submitForYes = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/financeYes',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
export const submitForNo = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/financeNo',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}

//api request function to submit comments for milestone reports
export const reportComment = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }
  const response = await postRequest({
    endpoint: '/scheme/submitReportComment',
    data: data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
