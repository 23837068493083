import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useRef } from "react";
import { useEffect, useState } from "react";

import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

import { useParams } from "react-router-dom";
import {
  checkApplication,
  getAppicationData,
  saveApplicationData,
  updatePhaseData,
} from "../../../../../../data/network/api/scheme";
import { uploadFile } from "../../../../../../data/network/api/startupForm";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../providers/GlobalProvider";
import { DialogBody } from "../../../../../../utils/customStyledComponents";
import { Icons, Pdf } from "../../../../../../utils/mediaExports";
import { SchemePhaseTabContainer } from "../ikp/Applications";
import ActionDialog from "./applicationAction/ActionModal";
import CompletionOfForm from "./forms/CompletionOfForm";
import ObjectiveAndPlan from "./forms/ObjectiveAndPlan";
import { saveComment } from "../../../../../../data/network/api/scheme";
import moment from "moment/moment";
import {
  ApplicaitonStatusColor,
  ApplicationStatus,
  ApplicationStatusTextColor,
} from "../../../../../../utils/constants";
import {
  applicanteeSideRole,
  applicationSideRole,
} from "../../../../customComponents/CustomTabView";
import paths from "../../../../../../utils/links";
import { updateBigPathNav } from "../../../../../../reducers/schemes/big/pathNav";
import { BigDispatch } from "../../../../../../providers/scheme/big/BigProvider";
import BigSchemeStore from "../../../../../../store/scheme/big/big";
import { useSnackbar } from "notistack";
import { updateBigApplication } from "../../../../../../reducers/schemes/big/applications";
import GlobalStore from "../../../../../../store/globalStore";
import { updateNavigation } from "../../../../../../reducers/navigationReducer";
import { getFileName } from "../../../../../../utils/helperHandler";
import { downloadFile } from "../../../../../../data/network/api/download";

const ApplicantApplicationSide = ({ from, call, setActive, setIsLateral }) => {
  const { user } = globalSelector((state) => state.auth);
  const controller = new AbortController();
  const [activeForm, setActiveForm] = useState(null);
  const dispatch = globalDispatch();
  const bigDispatch = BigDispatch();
  const params = useParams();

  const [application, setApplication] = useState(null);
  const [applicationData, setApplicationData] = useState(null);

  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [uploading, setUploading] = useState(false);

  const isProgramAssociate = applicanteeSideRole.includes(user.role.roleId);
  const isFounder = applicationSideRole.includes(user.role.roleId);

  //function to move to next phase automatically when moved to next round
  const [movingNextRound, setMovingNextRound] = useState(false);
  useEffect(() => {
    if (movingNextRound) {
      setActionDialogOpen(false);
      setActive(1);
      setMovingNextRound(false);
    }
  }, [movingNextRound]);

  const { enqueueSnackbar } = useSnackbar();
  //function to get application details and application phase data
  const getApplication = async () => {
    let bodyData = {};
    if (isProgramAssociate) {
      bodyData = {
        fromIkp: true,
        applicationId: params.applicationId,
      };
    } else if (isFounder) {
      bodyData = { callId: call._id };
    }

    try {
      const response = await checkApplication(bodyData, dispatch, controller);
      if (response && response.status == 200) {
        setApplication(response.msg?.application);
        // setIsLateral(response?.msg?.application?.others?.lateralEntry || false);
        bigDispatch(updateBigApplication({
          id: "application",
          value: response?.msg?.application
        }));
        setApplicationData(response.msg?.applicationData);
        let progress = {};
        for (let i = 1; i <= 8; i++) {
          progress["phase" + i] =
            response?.msg?.applicationData?.["phase" + i]?.status;
        }
        progress.milestone = response?.msg?.applicationData?.milestone?.status;
        bigDispatch(
          updateBigApplication({
            id: "progress",
            value: progress,
          })
        );

        if (user.role.roleId != "founder") {
          try {
            let pageStackData = JSON.parse(
              JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
            );
            Object.keys(pageStackData).map((id) => {
              pageStackData[id].isActive = false;
            });
            pageStackData.companyName.title =
              response.msg?.application?.companyName;
            pageStackData.companyName.show = true;
            pageStackData.companyName.isActive = true;
            pageStackData.companyName.path = `/schemes/big/${call?._id}/${response?.msg?.application?._id}`;
            dispatch(
              updateNavigation({
                type: "pageNavStack",
                value: { ...pageStackData },
              })
            );
          } catch (e) {}
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const handleFile = async (e, dates) => {
    if (e.target.files.length > 0) {
      const startDate = new Date(dates?.startDate);
      const endDate = new Date(dates?.endDate);
      const currentDate = new Date();
      // if (startDate <= currentDate && endDate <= currentDate) {
      const file = e.target.files[0];
      if (file.type != "application/pdf") {
        snackbarHandler("Invalid file type! Upload PDF", "error");
        return;
      }
      setUploading(true);
      const fileData = new FormData();
      fileData.append("file", file);
      const urlRes = await uploadFile(fileData, dispatch, controller);
      if (urlRes && urlRes.status == 200) {
        const response = await updatePhaseData(
          {
            data: urlRes.msg,
            applicationId: application._id,
            phase: "phase1",
            subPhase: "phase3",
          },
          dispatch,
          controller
        );
        if (response && response.status == 200) {
          snackbarHandler("PDF Uploaded", "success");
          handleForm(null, null, true);
        } else {
          snackbarHandler(response?.msg || "Something went wrong!", "error");
        }
      } else {
        snackbarHandler("Something went wrong! Try again", "error");
      }
      setUploading(false);
      // }
    }
  };

  useEffect(() => {
    setActiveForm(null);
    getApplication();

    return () => {
      try {
        let pageStackData = JSON.parse(
          JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
        );
        pageStackData.callName.isActive = true;
        pageStackData.companyName.title = "";
        pageStackData.companyName.show = false;
        pageStackData.companyName.isActive = false;
  
        dispatch(
          updateNavigation({
            type: "pageNavStack",
            value: { ...pageStackData },
          })
        );
      } catch (e) {}
    };
  }, []);

  const handleForm = (value, dates = null, refresh = false) => {
    if (dates) {
      const startDate = new Date(dates.startDate);
      const endDate = new Date(dates.endDate);
      const currentDate = new Date().toISOString();

      // if (startDate > currentDate) {
      //   alert(`Try on ${startDate.toLocaleDateString()}`);
      //   return;
      // } else if (endDate < currentDate) {
      //   alert(`This form and phase has been expired on ${endDate}`);
      //   return;
      // }
    }
    if (refresh) {
      getApplication();
    }
    setActiveForm(value);
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
  };

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  if (activeForm) {
    switch (activeForm) {
      case 1:
        return (
          <CompletionOfForm
            application={application}
            handleForm={handleForm}
            applicationData={applicationData?.phase1?.subPhases?.phase1}
            call={call}
            from={from}
          />
        );

      case 2:
        return (
          <ObjectiveAndPlan
            application={application}
            handleForm={handleForm}
            applicationData={applicationData?.phase1?.subPhases?.phase2}
            call={call}
            from={from}
          />
        );
    }
  }

  return (
    <SchemePhaseTabContainer>
      <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
        <div
          className={`statusTextApplication `}
          style={{
            background:
              ApplicaitonStatusColor[applicationData?.["phase1"]?.status],
            color:
              ApplicationStatusTextColor[applicationData?.["phase1"]?.status],
          }}
        >
          {ApplicationStatus[applicationData?.["phase1"]?.status] || "Pending"}
        </div>

        {from == "associate" ? (
          <div className="assignedText" style={{ fontSize: "1.2rem" }}>
            Assigned to:{" "}
            {application?.reviewer?.map((person, i) => {
              return (
                <span className="rName">
                  {person.name}{" "}
                  {application?.reviewer?.length != i + 1 ? ", " : ""}
                </span>
              );
            })}
            {application?.reviewer?.length == 0 ? "-" : ""}
          </div>
        ) : (
          <></>
        )}
      </div>

      {application ? (
        <div className="container">
          <TableContainer className="tableContainer applicantTable">
            <Table sx={{ minWidth: 650 }} aria-label="simple table ">
              <TableHead>
                <TableRow>
                  <TableCell width={"50px"} align="center">
                    Sl No
                  </TableCell>
                  <TableCell>Task</TableCell>
                  <TableCell>Application Open from</TableCell>
                  <TableCell>Apply Before</TableCell>
                  <TableCell>Progress</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <BuildApplicationPhases
                  handleForm={handleForm}
                  phase={application?.call?.phases?.application}
                  applicationData={applicationData?.phase1?.subPhases}
                  applicationDataId={applicationData?._id}
                  handleFile={handleFile}
                  applicationId={application._id}
                  uploading={uploading}
                  schemeCategory={call.scheme.schemeId}
                  presentationTemplateUrl={
                    call?.phases?.application?.phase3?.templateUrl
                  }
                  getApplication={getApplication}
                  from={from}
                />
              </TableBody>
            </Table>
          </TableContainer>
          {from == "associate" ? (
            <div className="actions">
              <div className="title">Action: </div>
              <div className="input">
                <Select
                  size="small"
                  value={selectedAction}
                  onChange={handleActionManager}
                >
                  <MenuItem value={-1}>
                    <FormLabel>Select</FormLabel>
                  </MenuItem>
                  {application?.status == "invited" ||
                  application?.status == "applied" ? (
                    <MenuItem value={"nextRound"}>
                      <FormLabel>Move to next round</FormLabel>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  <MenuItem value={"assign"}>
                    <FormLabel>Assign Reviewer</FormLabel>
                  </MenuItem>

                  {application?.status == "invited" ||
                  application?.status == "applied" ? (
                    <MenuItem value={"reject"}>
                      <FormLabel style={{ color: "red" }}>Reject</FormLabel>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                </Select>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="no-application">No Application found!</div>
      )}

      <Dialog open={actionDialogOpen} onClose={handleActionDialog}>
        <ActionDialog
          handleActionDialog={handleActionDialog}
          activeAction={selectedAction}
          application={application}
          getApplicationDataHandlerForManager={getApplication}
          phase={"phase1"}
          setMovingNextRound={setMovingNextRound}
          applicationDataVersion={applicationData?.__v}
        />
      </Dialog>
    </SchemePhaseTabContainer>
  );
};

const BuildApplicationPhases = ({
  handleForm,
  phase,
  applicationData,
  handleFile,
  from,
  uploading,
  applicationId,
  presentationTemplateUrl,
  getApplication,
}) => {
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [loading, setLoading] = useState(true);

  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const dialogRef = useRef();
  const [fileName, setFileName] = useState("");
  const [comments, setComments] = useState([]);
  const [isCommentsChanged, setCommentsChanged] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    const name = String(applicationData?.phase3?.data).split("/");
    let fileName = name[name.length - 1];
    fileName = fileName.substring(0, fileName.length - 15);
    setFileName(getFileName(applicationData?.phase3?.data));

    if (applicationData?.phase3?.comments?.length > 0) {
      setComments(applicationData?.phase3?.comments);
      setLoading(false);
      return;
    }
    const commentsArray = Array(numPages).fill("");
    setComments([...commentsArray]);
    setLoading(false);
  }

  const submitComments = async () => {
    if (!isCommentsChanged) {
      return true;
    }
    try {
      setLoading(true);
      const response = await saveComment(
        {
          values: comments,
          phase: "phase1",
          subPhase: "phase3",
          applicationId: applicationId,
        },
        dispatch,
        controller
      );
      if (response && response.status == 200) {
        getApplication();
        return true;
      } else {
        snackbarHandler(response?.msg || "Something went wrong!", "error");
      }
    } catch (err) {
      snackbarHandler("Something went wrong!", "error");
    } finally {
      setLoading(false);
    }
    return false;
  };

  const handlePdfView = (url) => {
    setPdfDialogOpen(true);
  };

  const closePdfView = async (e, reason) => {
    if (reason == "backdropClick") {
      return;
    }

    if (from == "associate") {
      const isCommentsSaved = await submitComments();
      if (isCommentsSaved) {
        getAppicationData();
        setComments([]);
        setPdfDialogOpen(false);
      } else {
        snackbarHandler("Error saving comments! Try again..", "error");
      }
    } else {
      setComments([]);
      setPdfDialogOpen(false);
    }
    setPageNumber(1);
    setNumPages(null);
  };

  const handlePrev = () => {
    if (pageNumber == 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber == numPages) return;
    setPageNumber(pageNumber + 1);
  };

  const changeComment = (index, value) => {
    setCommentsChanged(true);
    let array = [...comments];
    array[index] = value;
    setComments(array);
  };

  useEffect(() => {
    setNumPages(null);
    setPageNumber(1);
    setFileName("");
  }, [applicationData?.phase3?.data]);

  const initiateDownload = async (url) => {
    downloadFile(url);
  };

  return (
    <>
      <TableRow>
        <TableCell align="center" className="text">
          1
        </TableCell>
        <TableCell>
          <div>
            <div className="text">Completion of Form</div>
            {/* <div className="subtextAction">View form</div> */}
          </div>
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase1?.startDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase1?.endDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>{applicationData?.phase1?.progress}%</TableCell>
        <TableCell>
          <span className="statusText">{applicationData?.phase1?.status}</span>
        </TableCell>
        <TableCell
          className="actionText"
          onClick={() => handleForm(1, phase?.phase1)}
        >
          {from == "associate"
            ? "View"
            : applicationData?.phase1?.progress > 0
            ? "Edit Form"
            : "Fill Form"}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" className="text">
          2
        </TableCell>
        <TableCell>
          <div>
            <div className="text">Objective & Work Plan</div>
            {/* <div className="subtextAction">View form</div> */}
          </div>
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase2?.startDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase2?.endDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>{applicationData?.phase2?.progress}</TableCell>
        <TableCell>
          <span className="statusText">{applicationData?.phase2?.status}</span>
        </TableCell>
        <TableCell
          className="actionText"
          onClick={() => handleForm(2, phase?.phase2)}
        >
          {from == "associate"
            ? "View"
            : applicationData?.phase1?.progress > 0
            ? "Edit Form"
            : "Fill Form"}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" className="text">
          3
        </TableCell>
        <TableCell>
          <div>
            <div className="text">
              <span style={{ color: "red" }}>*</span> Presentation Upload
            </div>
            {/* <div className="subtextAction">View uploads</div> */}
          </div>
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase3?.startDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase3?.endDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>{applicationData?.phase3?.progress}%</TableCell>
        <TableCell>
          <span className="statusText">{applicationData?.phase3?.status}</span>
        </TableCell>
        <TableCell>
          {uploading ? (
            <CircularProgress />
          ) : (
            <div>
              <label className="text">
                {from == "associate" ? (
                  <></>
                ) : (
                  <input
                    onChange={(e) => handleFile(e, phase?.phase1)}
                    type="file"
                    hidden
                  />
                )}

                {from == "associate" ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handlePdfView(applicationData?.phase3?.data)
                    }
                  >
                    View
                  </div>
                ) : applicationData?.phase3?.progress > 0 ? (
                  <span
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                    }}
                  >
                    ReUpload{" "}
                    <FormLabel style={{ fontSize: "0.7rem" }}>
                      (Only PDF)
                    </FormLabel>
                  </span>
                ) : (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                      cursor: "pointer"
                    }}
                  >
                    Upload{" "}
                    <FormLabel style={{ fontSize: "0.7rem" }}>
                      (Only PDF)
                    </FormLabel>
                  </span>
                )}
              </label>

              {from == "associate" ? (
                <></>
              ) : (
                <div
                  className="subtextAction"
                  onClick={() =>
                    handlePdfView(applicationData?.phase3?.data?.url)
                  }
                >
                  View
                </div>
              )}

              {from != "associate" ? (
                <div className="subtextAction">
                  <div className="download"
                    onClick={() => initiateDownload(presentationTemplateUrl)}
                  >
                    Download Template
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </TableCell>
      </TableRow>

      <Dialog
        open={pdfDialogOpen}
        onClose={closePdfView}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1600px", // Set your width here
              maxHeight: "90vh",
              overflow: "auto",
            },
          },
        }}
        ref={dialogRef}
      >
        <DialogBody>
          <div className="closeButton closePdfViewer" onClick={closePdfView}>
            {Icons.close}
          </div>
          {/* {loading ? <CircularProgress /> : <></>} */}
          <DialogTitle className="dialogTitle">
            <span className="bold">Presentation: </span>{" "}
            <span className="light">{fileName}</span>
          </DialogTitle>
          <DialogContent className="content pdfViewerComments">
            <div className="arrowLeft arrow" onClick={handlePrev}>
              {Icons.arrowFormward}
            </div>
            <div className="pdfView">
              <Document
                file={applicationData?.phase3?.data}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  className="pdfPage"
                  renderAnnotationLayer={null}
                  renderTextLayer={false}
                />

                <FormLabel className="slideLabel">
                  Slide: {pageNumber}/{numPages}
                </FormLabel>
              </Document>
            </div>

            <div className="commentView">
              <div className="commentTitle">
                <FormLabel>Comment for Slide {pageNumber}</FormLabel>
              </div>

              <div className="commentTextfield">
                <textarea
                  placeholder={from == "associate" ? "Add your comments.." : ""}
                  value={comments[pageNumber - 1]}
                  readOnly={from == "associate" ? false : true}
                  onChange={(e) =>
                    changeComment(pageNumber - 1, e.target.value)
                  }
                ></textarea>
                {from == "associate" ? (
                  <FormLabel>
                    Comments will get saved when popup is closed!
                  </FormLabel>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="arrowRight arrow" onClick={handleNext}>
              {Icons.arrowFormward}
            </div>

            <FormLabel
              className="download"
              onClick={() => {
                initiateDownload(applicationData?.phase3?.data);
              }}
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}
            >
              {Icons.download}
            </FormLabel>
          </DialogContent>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ApplicantApplicationSide;
