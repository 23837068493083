import { combineReducers, configureStore } from '@reduxjs/toolkit'
import NavigationReducer from '../reducers/navigationReducer'
import DocumentReducer from '../reducers/DocumentReducer'
import startupsList from '../reducers/startups/startupsList'
import StartupFormDataReducer from '../reducers/startupform/formReducer'
import authReducer from '../reducers/auth/authReducer'
import ApplicationReducer from '../reducers/schemes/applicaitonReducer'

const combinedReducer = combineReducers({
  auth: authReducer,
  startupList: startupsList,
  navigation: NavigationReducer,
  startup: StartupFormDataReducer,
  document: DocumentReducer,
  application: ApplicationReducer,
})

const rootReducer = (state, action) => {
  //if logout is performed then reset all states
  if (action.type == 'navigation/updateAuth') {
    if (action.payload.isAuthenticated == false) {
      state = undefined
    }
  }
  return combinedReducer(state, action)
}

const GlobalStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
})

export default GlobalStore
