import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import {
  getApplicationSubphaseData,
  getFormData,
  saveApplicationData,
  saveComment,
  saveFormInputs,
} from "../../../../../../../data/network/api/scheme";
import { uploadFile } from "../../../../../../../data/network/api/startupForm";
import BuildFormField from "../../../../widgets/FormBuilder";
import { Icons } from "../../../../../../../utils/mediaExports";
import CustomButton from "../../../../../../customComponents/CustomButton";
import PassportPhotoUpload from "../../../../../../customComponents/PassportPhotoUpload";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MuiTelInput } from "mui-tel-input";
import { BuildFileUploader } from "../../../../../../widgetManager/componentBuilder";
import validator from "validator";
import { inrAddCommas } from "../../../../../../../utils/helperHandler";
import OwlLoader from "../../../../../../customComponents/OwlLoader";

// const fields = {
//   title: {
//     title: "1. Title of the proposed project",
//     inputs: {
//       title: {
//         id: "title",
//         title: "Title",
//         sl: 1,
//         label: "Title of the proposed project :",
//         inputType: "text",
//         default: "",
//       },
//       photo: {
//         id: "photo",
//         label: "Upload your latest photo",
//         default: [],
//         inputType: "file",
//         mt: true,
//         fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
//       },
//     },
//   },
//   personalDetails: {
//     title: "2.1. Personal Details",
//     inputs: {
//       name: {
//         id: "name",
//         title: "Innovator Details",
//         sl: 2,
//         label: "2.1 Personal Details\na) Name of the Innovator",
//         inputType: "text",
//         default: "",
//       },
//       fatherName: {
//         id: "fatherName",
//         label: "b) Father's name/Husband's name",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       dob: {
//         id: "dob",
//         label: "c) Date of Birth",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       email: {
//         id: "email",
//         label: "d) Email Id",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       mobile: {
//         id: "mobile",
//         label: "e) Mobile Number",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       landline: {
//         id: "landline",
//         label: "f) Landline Number",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       gender: {
//         id: "gender",
//         label: "g) Gender",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       qualification: {
//         id: "qualification",
//         label: "h) Educational Qualification",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       category: {
//         id: "category",
//         label: "i) Category (General/SC/ST/OBC/Others)",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       pan: {
//         id: "pan",
//         label: "i) PAN",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       aadhar: {
//         id: "aadhar",
//         label: "k) Aadhar",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       address: {
//         id: "address",
//         label:
//           "l) Address : (Please enclose residence certificate issued by Sub-Divisional Magistrate(SDM)/District Magistrate(DM) or a copy of ration card or any other document regarding proof of Residence)\nPostal Address :",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       permanentAddress: {
//         id: "permanentAddress",
//         label: "Permanent Address : ",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//     },
//   },
//   professionalDetails: {
//     title: "2.2. Professional Details",
//     inputs: {
//       profession: {
//         id: "profession",
//         inputType: "text",
//         title: "Professional Details",
//         sl: 2.2,
//         default: "",
//         label: "a) Profession of the Innovator",
//       },
//       organization: {
//         id: "organization",
//         inputType: "text",
//         default: "",
//         label:
//           "b) Name & Address of the Institution/Organization (applicable in case of Innovator in Employment / Students with any R& D organisation / Academic Institution:-",
//         mt: true,
//       },
//       noc: {
//         id: "noc",
//         inputType: "file",
//         default: [],
//         label:
//           "c) For Innovator in Employment / Students with any R& D organisation / Academic Institution  No Objection Certificate  Upload NOC from Head of Institute /Organization is mandatorily required)",
//         mt: true,
//         fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
//       },
//       annualIncome: {
//         id: "annualIncome",
//         inputType: "text",
//         default: "",
//         label:
//           "d) Annual Income of the applicant (applicable in case of Innovator in Employment)",
//         mt: true,
//       },
//       itr: {
//         id: "itr",
//         inputType: "file",
//         default: [],
//         label: "Attach a copy of the latest Income Tax Returns filed",
//         mt: true,
//         fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
//       },
//       ownVenture: {
//         id: "ownVenture",
//         inputType: "text",
//         default: "",
//         label:
//           "e) Have you started your own venture (start up) ? Yes / No  ----  If yes, please mention the following : Name of your start up, Your Role in start up (eg. CEO, COO, Product Head etc), Date of Incorporation, Revenue generated till date, – Provide Cap structure of the startup -",
//         mt: true,
//       },
//       founders: {
//         id: "founders",
//         inputType: "table",
//         default: [],
//         label: "",
//         theadings: [
//           "Name (Founder/Co-founder)",
//           "Nationality",
//           "Commitment (Part time/Full time",
//           "% Shareholding",
//         ],
//       },
//     },
//   },
//   teamMembers: {
//     title: "2.3. Team Members",
//     inputs: {
//       teamMembers: {
//         id: "teamMembers",
//         inputType: "table",
//         title: "Team Member",
//         sl: 2.3,
//         theadings: [
//           "Name",
//           "Aadhar No.",
//           "PAN Card no.",
//           "Gender",
//           "Contact",
//           "Email-id",
//           "Qualification",
//         ],
//         default: [],
//         label: "2.3 Team Member Details",
//       },
//     },
//   },
//   description: {
//     title: "3. Brief Description",
//     inputs: {
//       description: {
//         id: "description",
//         default: "",
//         inputType: "text",
//         title: "Description",
//         sl: 3,
//         label: "Brief description of the idea highlighting innovative element.",
//       },
//     },
//   },
//   otherDetails: {
//     title: "4. Other Details",
//     inputs: {
//       statusOfWork: {
//         id: "statusOfWork",
//         title: "Status of Work",
//         sl: 4,
//         label:
//           "4. a) Status of work already carried out (if any) such as. Please tick ✓ as applicable. ",
//         default: "",
//         inputType: "radio",
//         options: [
//           "Idea level",
//           "College Project",
//           "Lab level – Proof of Concept",
//           "Paper Presentation",
//           "Working Prototype ready",
//         ],
//       },
//       workingPrinciple: {
//         id: "workingPrinciple",
//         label:
//           "b) Science and working principle behind the idea (Not more than 250 words).",
//         default: "",
//         inputType: "text",
//         mt: true,
//       },
//       outcome: {
//         id: "outcome",
//         label:
//           "c) Final outcome/deliverable of the project (Not more than 150 words)",
//         default: "",
//         inputType: "text",
//         mt: true,
//       },
//       beneficiary: {
//         id: "beneficiary",
//         label:
//           "d) Who would be the beneficiary of this innovation and why? (Not more than 200 words)",
//         default: "",
//         inputType: "text",
//         mt: true,
//       },
//     },
//   },
//   costs: {
//     title: "5. Proposed costs and time frame",
//     inputs: {
//       items: {
//         id: "items",
//         inputType: "staticTable",
//         columnInputType: "text",
//         default: {},
//         label: "5. Proposed costs and time frame",
//         columns: ["Items", "Project Cost(Own Share)", "PRAYAS support sought"],
//         rows: [
//           "Outsourcing Charges for R&D/Design Engg/Consultancy/Testing Expert cost",
//           "Raw material/ Consumables/Spares",
//           "Fabrication /Synthesis charges of working model or process",
//           "Business Travel and Event participation Fees (Ceiling 10% of approved project cost)",
//           "Patent filing Cost – (PCT- Ceiling 10% of approved project cost)",
//           "Contingency - (Ceiling 10% of approved project cost)",
//         ],
//         columnIds: ["items", "ownShare", "prayas"],
//       },
//       projectPeriod: {
//         id: "projectPeriod",
//         inputType: "text",
//         default: "",
//         label: "Project period in months (Not more than 12 months)",
//         mt: true,
//       },
//     },
//   },
//   financialSupport: {
//     title: "6. Financial Support from other sources",
//     inputs: {
//       financialSupport: {
//         id: "financialSupport",
//         sl: 6,
//         title: "Financial Support",
//         label:
//           "Have you received financial support / award for your present work from any other sources? (if so, please furnish details)",
//         default: "",
//         inputType: "text",
//       },
//     },
//   },
//   otherCenter: {
//     title: "7. Other PRAYAS Centre",
//     inputs: {
//       otherCenter: {
//         id: "otherCenter",
//         sl: 7,
//         title: "Other PRAYAS Centre",
//         label:
//           "Have you also applied / in process of applying to any other PRAYAS Centre (s) for NIDHI-PRAYAS  and/or NIDHI-EIR grant  --    Yes/ No \nIf Yes – Please mention the name of the Centre / TBI and the program. ",
//         default: "",
//         inputType: "text",
//       },
//     },
//   },
//   received: {
//     title: "8. PRAYAS Recieved/approved",
//     inputs: {
//       received: {
//         id: "received",
//         sl: 8,
//         title: "PRAYAS Received/Approved",
//         label:
//           "Have you been approved / received the NIDHI-PRAYAS --	Yes/ No \nIf Yes – Please mention the name of the Centre / TBI  and Date of approval/Funds received",
//         default: "",
//         inputType: "text",
//       },
//     },
//   },
//   criteria: {
//     title: "9. Essential Criteria",
//     inputs: {
//       criteria: {
//         id: "criteria",
//         label: "9. Essential Criteria",
//         default: ["", "", "", "", "", ""],
//         inputType: "booleanTable",
//         rows: [
//           "Applicant Confirms that he/she has not been a recipient of any NIDHI-PRAYAS  or similar  support previously for the same innovative concept as proposed in this form. NIDHI-PRAYAS can be used only once.",
//           "Applicant is planning to pursue NIDHI- PRAYAS full time with no other concurrent commitments",
//           "Applicant confirms that he/she is fully committed to work towards the prototype development for which the support is being sought and should not treat this as a stop gap arrangement to support any other pursuits",
//           "Applicant has or is planning to register for the pre incubation or incubation program at the TBI for the entire duration of PRAYAS support",
//           "Applicant confirms that if selected for NIDHI-PRAYAS, then they (applicant and their team members) will avail support for their project from only one PRAYAS Centre. If the applicant is found seeking NIDHI-PRAYAS support for the same idea from two different PCs, then the PC reserves right to discontinue the PRAYAS support and appropriate action will be taken on the applicant.",
//           "Applicant confirms that they will not avail the funding support in parallel for both the schemes i.e NIDHI-PRAYAS and NIDHI - EIR from the same or different centers.",
//         ],
//       },
//     },
//   },
//   declaration: {
//     title: "10. Declaration",
//     inputs: {
//       place: {
//         inputType: "text",
//         sl: 10,
//         title: "Declaration",
//         label:
//           "I declare that all the statements made in this application are true, complete and correct to the best of my knowledge and belief.  If any information is found false or incorrect, my candidature will stand cancelled and all my claims will be forfeited. I have not received any financial assistance for the present proposal from any other agency.\n\nPlace :",
//         default: "",
//         id: "place",
//       },
//       date: {
//         inputType: "text",
//         label: "Date : ",
//         default: "",
//         id: "date",
//         mt: true,
//       },
//       signature: {
//         id: "signature",
//         label: "Signature : ",
//         default: [],
//         inputType: "file",
//         mt: true,
//         fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
//       },
//     },
//   },
// };

const initialData = {
  title: {
    title: "",
    photo: [],
  },
  personalDetails: {
    name: "",
    fatherName: "",
    dob: "",
    email: "",
    mobile: "+91",
    landline: "",
    gender: "",
    qualification: "",
    category: "",
    pan: "",
    panFile: null,
    aadhaarFile: null,
    aadhar: "",
    addressFile: null,
    address: {
      address: "",
      city: "",
      state: "",
      pincode: "",
    },
    permanentAddress: {
      address: "",
      city: "",
      state: "",
      pincode: "",
    },
  },
  professionalDetails: {
    profession: "",
    organization: "",
    noc: null,
    annualIncome: "",
    itr: null,
    founders: [],
    ownVenture: {
      isExists: "no",
      details: "",
    },
  },
  teamMembers: {
    teamMembers: [],
  },
  description: {
    description: "",
  },
  otherDetails: {
    statusOfWork: "",
    workingPrinciple: "",
    outcome: "",
    beneficiary: "",
  },
  costs: {
    items: {
      item1: {
        ownShare: "0",
        prayas: "0",
      },
      item2: {
        ownShare: "0",
        prayas: "0",
      },
      item3: {
        ownShare: "0",
        prayas: "0",
      },
      item4: {
        ownShare: "0",
        prayas: "0",
      },
      item5: {
        ownShare: "0",
        prayas: "0",
      },
      item6: {
        ownShare: "0",
        prayas: "0",
      },
    },
    projectPeriod: "",
  },
  financialSupport: {
    financialSupport: {
      isSupport: "no",
      details: "",
    },
  },
  otherCenter: {
    otherCenter: {
      isOtherCenter: "no",
      details: "",
    },
  },
  received: {
    received: {
      isReceived: "no",
      details: "",
    },
  },
  criteria: {
    criteria: ["yes", "yes", "yes", "yes", "yes", "yes"],
  },
  declaration: {
    place: "",
    date: new Date().toString(),
    signature: null,
  },
};

const mandatoryFields = {
  title: {
    title: {
      mandatory: true,
      error: "",
    },
    photo: {
      mandatory: true,
      error: "",
    },
  },
  personalDetails: {
    name: {
      mandatory: true,
      error: "",
    },
    fatherName: {
      mandatory: false,
      error: "",
    },
    dob: {
      mandatory: true,
      error: "",
    },
    email: {
      mandatory: true,
      error: "",
    },
    mobile: {
      mandatory: true,
      error: "",
    },
    landline: {
      mandatory: false,
      error: "",
    },
    gender: {
      mandatory: true,
      error: "",
    },
    qualification: {
      mandatory: false,
      error: "",
    },
    category: {
      mandatory: false,
      error: "",
    },
    pan: {
      mandatory: true,
      error: "",
    },
    panFile: {
      mandatory: true,
      error: "",
    },
    aadhaarFile: {
      mandatory: true,
      error: "",
    },
    aadhar: {
      mandatory: true,
      error: "",
    },
    addressFile: {
      mandatory: true,
      error: "",
    },
    address: {
      address: {
        mandatory: false,
        error: "",
      },
      city: {
        mandatory: false,
        error: "",
      },
      state: {
        mandatory: false,
        error: "",
      },
      pincode: {
        mandatory: false,
        error: "",
      },
    },
    permanentAddress: {
      address: {
        mandatory: true,
        error: "",
      },
      city: {
        mandatory: true,
        error: "",
      },
      state: {
        mandatory: true,
        error: "",
      },
      pincode: {
        mandatory: true,
        error: "",
      },
    },
  },
  professionalDetails: {
    profession: {
      mandatory: true,
      error: "",
    },
    organization: {
      mandatory: false,
      error: "",
    },
    noc: {
      mandatory: false,
      error: "",
    },
    annualIncome: {
      mandatory: true,
      error: "",
    },
    itr: {
      mandatory: true,
      error: "",
    },
    founders: {
      mandatory: false,
      error: "",
    },
    ownVenture: {
      isExists: {
        mandatory: true,
        error: "",
      },
      details: {
        mandatory: false,
        error: "",
      },
    },
  },
  teamMembers: {
    teamMembers: {
      mandatory: false,
      error: "",
    },
  },
  description: {
    description: {
      mandatory: true,
      error: "",
    },
  },
  otherDetails: {
    statusOfWork: {
      mandatory: true,
      error: "",
    },
    workingPrinciple: {
      mandatory: true,
      error: "",
    },
    outcome: {
      mandatory: true,
      error: "",
    },
    beneficiary: {
      mandatory: true,
      error: "",
    },
  },
  costs: {
    items: {
      item1: {
        ownShare: { mandatory: true, error: "" },
        prayas: { mandatory: true, error: "" },
      },
      item2: {
        ownShare: { mandatory: true, error: "" },
        prayas: { mandatory: true, error: "" },
      },
      item3: {
        ownShare: { mandatory: true, error: "" },
        prayas: { mandatory: true, error: "" },
      },
      item4: {
        ownShare: { mandatory: true, error: "" },
        prayas: { mandatory: true, error: "" },
      },
      item5: {
        ownShare: { mandatory: true, error: "" },
        prayas: { mandatory: true, error: "" },
      },
      item6: {
        ownShare: { mandatory: true, error: "" },
        prayas: { mandatory: true, error: "" },
      },
    },
    projectPeriod: {
      mandatory: true,
      error: "",
    },
  },
  financialSupport: {
    financialSupport: {
      isSupport: {
        mandatory: true,
        error: "",
      },
      details: {
        mandatory: true,
        error: "",
      },
    },
  },
  otherCenter: {
    otherCenter: {
      isOtherCenter: {
        mandatory: false,
        error: "",
      },
      details: {
        mandatory: false,
        error: "",
      },
    },
  },
  received: {
    received: {
      isReceived: {
        mandatory: false,
        error: "",
      },
      details: {
        mandatory: false,
        error: "",
      },
    },
  },
  criteria: {
    criteria: {
      mandatory: false,
      error: "",
    },
  },
  declaration: {
    place: {
      mandatory: true,
      error: "",
    },
    date: {
      mandatory: true,
      error: "",
    },
    signature: {
      mandatory: true,
      error: "",
    },
  },
};

const tableFields = {
  professionalDetails: {
    founders: {
      name: "",
      nationality: "",
      commitment: "",
      shareholding: "",
    },
  },
  teamMembers: {
    teamMembers: {
      name: "",
      aadhaar: "",
      pan: "",
      gender: "",
      contact: "",
      email: "",
      qualification: "",
    },
  },
  costs: {
    costs: {
      items: "Items",
      ownShare: "Project Cost(Own Share)",
      prayas: "PRAYAS support sought",
    },
    costsRows: [
      "Outsourcing Charges for R&D/Design Engg/Consultancy/Testing Expert cost",
      "Raw material/ Consumables/Spares",
      "Fabrication /Synthesis charges of working model or process",
      "Business Travel and Event participation Fees (Ceiling 10% of approved project cost)",
      "Patent filing Cost – (PCT- Ceiling 10% of approved project cost)",
      "Contingency - (Ceiling 10% of approved project cost)",
    ],
  },
  criteriaRows: [
    "Applicant Confirms that he/she has not been a recipient of any NIDHI-PRAYAS  or similar  support previously for the same innovative concept as proposed in this form. NIDHI-PRAYAS can be used only once.",
    "Applicant is planning to pursue NIDHI- PRAYAS full time with no other concurrent commitments",
    "Applicant confirms that he/she is fully committed to work towards the prototype development for which the support is being sought and should not treat this as a stop gap arrangement to support any other pursuits",
    "Applicant has or is planning to register for the pre incubation or incubation program at the TBI for the entire duration of PRAYAS support",
    "Applicant confirms that if selected for NIDHI-PRAYAS, then they (applicant and their team members) will avail support for their project from only one PRAYAS Centre. If the applicant is found seeking NIDHI-PRAYAS support for the same idea from two different PCs, then the PC reserves right to discontinue the PRAYAS support and appropriate action will be taken on the applicant.",
    "Applicant confirms that they will not avail the funding support in parallel for both the schemes i.e NIDHI-PRAYAS and NIDHI - EIR from the same or different centers.",
  ],
};

const CompletionOfForm = ({
  handleForm,
  application,
  applicationData,
  call,
  from,
}) => {
  const [data, setData] = useState(JSON.parse(JSON.stringify(initialData)));
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [fileLoading, setFileLoading] = useState({
    proposalConceptFiles: false,
    tableFile: false,
    documents: false,
  });
  const [openComments, setOpenComments] = useState(false);
  const [commentsStatus, setCommentsStatus] = useState({});
  const [commentsStatusState, setCommentsStatusState] = useState({});
  const [formVersion, setFormVersion] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const { user } = globalSelector((state) => state.auth);

  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFormDataHandler();
  }, []);

  const getFormDataHandler = async () => {
    setFormLoading(true);
    const response = await getFormData(
      {
        formName: "innovatorForm",
        applicationId: application._id,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      let dataObj = {};
      let commentObj = {};
      Object.keys(initialData).map((field, i) => {
        dataObj[field] = response.msg[field]?.data;
        commentObj[field] = {
          comment: response.msg[field]?.comment,
          status: response.msg[field]?.status,
        };
      });
      setData(dataObj);
      setCommentsStatus({ ...commentObj });
      setCommentsStatusState({ ...commentObj });
      setFormVersion(response?.msg?.__v);
      setFormLoading(false);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const changeComment = (id, value) => {
    setCommentsStatusState({
      ...commentsStatusState,
      [id]: { ...commentsStatusState[id], comment: value },
    });
  };

  const handleApproveCheckbox = (id, e) => {
    setCommentsStatusState({
      ...commentsStatusState,
      [id]: {
        ...commentsStatusState[id],
        status: e.target.checked ? "approved" : "pending",
      },
    });
  };

  const handleChange = (field, id, value) => {
    setData({ ...data, [field]: { ...data[field], [id]: value } });
  };

  const handleCheckbox = (field, id, value, isChecked) => {
    setData({
      ...data,
      [field]: {
        ...data[field],
        [id]: { ...data[field][id], [value]: isChecked },
      },
    });
  };

  const removeFile = (field, id, index) => {
    let array = [...data[field][id]];
    array.splice(index, 1);
    handleChange(field, id, array);
  };

  const saveCommentHandler = async (id, name) => {
    const response = await saveComment(
      {
        applicationId: application._id,
        fieldId: id,
        values: commentsStatusState[id],
        formName: "innovatorForm",
        formVersion: formVersion,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(`Comment for ${name} submitted!`, "success");
      let dataObj = {};
      let commentObj = {};
      Object.keys(initialData).map((field, i) => {
        dataObj[field] = response.msg[field]?.data;
        commentObj[field] = {
          comment: response.msg[field]?.comment,
          status: response.msg[field]?.status,
        };
      });
      setData(dataObj);
      setCommentsStatus({ ...commentObj });
      setCommentsStatusState({ ...commentObj });
      setFormVersion(response?.msg?.__v);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  // const addRow = (id) => {
  //   let obj = {};
  //   let parent = "";
  //   if (id == "founders") {
  //     parent = "professionalDetails";
  //     obj = {
  //       name: "",
  //       nationality: "",
  //       commitment: "",
  //       shareholding: "",
  //     };
  //   } else if (id == "teamMembers") {
  //     parent = "teamMembers";
  //     obj = {
  //       name: "",
  //       aadhar: "",
  //       pan: "",
  //       gender: "",
  //       contact: "",
  //       email: "",
  //       qualification: "",
  //     };
  //   }

  //   setData({
  //     ...data,
  //     [parent]: { ...data[parent], [id]: [...data[parent][id], { ...obj }] },
  //   });
  // };

  // const deleteRow = (id, index) => {
  //   let parent = "";
  //   if (id == "founders") {
  //     parent = "professionalDetails";
  //   } else if (id == "teamMembers") {
  //     parent = "teamMembers";
  //   }
  //   let array = [...data[parent][id]];
  //   array.splice(index, 1);
  //   setData({
  //     ...data,
  //     [parent]: { ...data[parent], [id]: [...array] },
  //   });
  // };

  // const changeRowTfValue = (index, id, field, value) => {
  //   let parent = "";
  //   if (id == "founders") {
  //     parent = "professionalDetails";
  //   } else if (id == "teamMembers") {
  //     parent = "teamMembers";
  //   }
  //   let array = [...data[parent][id]];
  //   let obj = { ...array[index] };
  //   obj[field] = value;
  //   array.splice(index, 1, { ...obj });
  //   setData({
  //     ...data,
  //     [parent]: { ...data[parent], [id]: [...array] },
  //   });
  // };

  //new form handlers
  const [isCopyPostalAddress, setPostalAddressCopy] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState([]);
  const [error, setError] = useState(
    JSON.parse(JSON.stringify(mandatoryFields))
  );
  const [isFounder, setIsFounder] = useState(user.role.roleId == "founder");

  //handler to change value of textfields in the form
  const handleTextChange = (category, id, value, subCategory = null) => {
    if (subCategory) {
      setData({
        ...data,
        [category]: {
          ...data[category],
          [id]: { ...data[category][id], [subCategory]: value },
        },
      });
      return;
    }
    setData({ ...data, [category]: { ...data[category], [id]: value } });
  };

  //handler to copy address from postal to permanent
  const handleCopyAddress = (e) => {
    if (e.target.checked) {
      setPostalAddressCopy(true);
      const permanentAddress = JSON.parse(
        JSON.stringify(data.personalDetails.permanentAddress)
      );
      setData({
        ...data,
        personalDetails: {
          ...data["personalDetails"],
          address: { ...permanentAddress },
        },
      });
      return;
    }
    setPostalAddressCopy(false);
  };

  //handlers realted to table in form (add rows, delete rows, change text box values in table cell)
  //handler to push a row in table with related data fields
  const addRowToTable = (category, id) => {
    const tableFieldsData = { ...tableFields[category][id] };
    let array = [...data[category][id]];
    array.push({ ...tableFieldsData });
    setData({ ...data, [category]: { ...data[category], [id]: array } });
  };

  //handler to delete a row from the table
  const deleteRowTable = (category, id, index) => {
    let array = [...data[category][id]];
    array.splice(index, 1);
    setData({ ...data, [category]: { ...data[category], [id]: [...array] } });
  };

  //handler to change value of textbox in table cell
  const handleChangeTextTable = (category, subCategory, id, value, index) => {
    let array = [...data[category][subCategory]];
    array[index][id] = value;
    setData({
      ...data,
      [category]: { ...data[category], [subCategory]: [...array] },
    });
  };

  //handler to change static table values
  const changeStaticTableValue = (id, rowId, field, value) => {
    let parent = "";
    if (id == "items") {
      parent = "costs";
    }
    setData({
      ...data,
      [parent]: {
        ...data[parent],
        [id]: {
          ...data[parent][id],
          [rowId]: { ...data[parent][id][rowId], [field]: value },
        },
      },
    });
  };

  //handler to change values of boolean table
  const changeBooleanTableValue = (id, index, value) => {
    let parent = "";
    if (id == "criteria") {
      parent = "criteria";
    }

    let array = [...data[parent][id]];
    array[index] = value;
    setData({ ...data, [parent]: { ...data[parent], [id]: array } });
  };

  //end of handlers realted to table in form (add rows, delete rows, change text box values in table cell)

  //start of handlers related to files in form
  const handleUpload = async (file, field, id) => {
    setFileLoading({ ...fileLoading, [id]: true });
    const fileData = new FormData();
    fileData.append("file", file);
    const response = await uploadFile(fileData, dispatch, controller);
    if (response && response.status == 200) {
      setData({
        ...data,
        [field]: {
          ...data[field],
          [id]: [...data[field][id], { name: file.name, url: response.msg }],
        },
      });
    } else {
      snackbarHandler(`Failed to upload ${file?.name || ""}!`, "error");
    }
    setFileLoading({ ...fileLoading, [id]: false });
  };

  const handleSingleUpload = async (file, field, id) => {
    setFileLoading({ ...fileLoading, [id]: true });
    const fileData = new FormData();
    fileData.append("file", file);
    const response = await uploadFile(fileData, dispatch, controller);
    if (response && response.status == 200) {
      setData({
        ...data,
        [field]: {
          ...data[field],
          [id]: { name: file.name, url: response.msg },
        },
      });
    } else {
      snackbarHandler(`Failed to upload ${file?.name || ""}!`, "error");
    }
    setFileLoading({ ...fileLoading, [id]: false });
  };

  const handleFiles = async (category, id, file) => {
    if (file.length > 0) {
      setIsFileLoading([...isFileLoading, id]);
      await handleUpload(file[0], category, id);
      let array = [...isFileLoading];
      array.pop(id);
      setIsFileLoading(array);
    }
  };

  const handleFile = async (e, dataField) => {
    if (!(e.target.files.length > 0)) {
      snackbarHandler("Failed to select file!", "error");
      return;
    }
    setIsFileLoading([...isFileLoading, dataField.fieldName]);
    await handleSingleUpload(
      e.target.files[0],
      dataField.category,
      dataField.fieldName
    );
    let array = [...isFileLoading];
    array.pop(dataField.fieldName);
    setIsFileLoading(array);
  };

  const deleteFile = async (category, id) => {
    setData({ ...data, [category]: { ...data[category], [id]: [] } });
  };

  const deleteSingleFile = async (dataField) => {
    setData({
      ...data,
      [dataField.category]: {
        ...data[dataField.category],
        [dataField.fieldName]: null,
      },
    });
  };
  //end of handlers related tof file in form

  //handler to validate data
  const validate = () => {
    let errorData = JSON.parse(JSON.stringify(mandatoryFields));
    let isSuccess = true;
    Object.keys(data).map((category, i) => {
      Object.keys(data[category]).map((subCategory, j) => {
        if (
          [
            "panFile",
            "aadhaarFile",
            "addressFile",
            "noc",
            "itr",
            "signature",
          ].includes(subCategory)
        ) {
          const isMandatory = mandatoryFields[category][subCategory].mandatory;
          if (isMandatory) {
            if (!data[category][subCategory]?.url) {
              errorData[category][
                subCategory
              ].error = `${subCategory} is required!`;
              isSuccess = false;
            }
          }

          return;
        }

        if (subCategory == "photo") {
          const isMandatory = mandatoryFields[category][subCategory].mandatory;
          if (isMandatory) {
            if (data[category][subCategory]?.length != 1) {
              errorData[category][
                subCategory
              ].error = `${subCategory} is required!`;
              isSuccess = false;
            }
          }
          return;
        }

        if (["founders", "teamMembers"].includes(subCategory)) {
          return;
        }

        if (typeof data[category][subCategory] == "object") {
          if (
            ![
              "permanentAddress",
              "address",
              "isExists",
              "details",
              "items",
            ].includes(subCategory)
          ) {
            return;
          }
          Object.keys(data[category][subCategory]).map((field, k) => {
            if (subCategory == "items") {
              Object.keys(data[category][subCategory][field]).map(
                (itemField) => {
                  console.log(data[category][subCategory][field]);
                  const isMandatory =
                    mandatoryFields[category][subCategory][field][itemField]
                      .mandatory;
                  if (
                    isMandatory &&
                    (!data[category][subCategory][field][itemField] ||
                      data[category][subCategory][field][itemField] == "")
                  ) {
                    errorData[category][subCategory][field][
                      itemField
                    ].error = `${itemField} is required!`;
                    isSuccess = false;
                  }
                }
              );
              return;
            }
            const isMandatory =
              mandatoryFields[category][subCategory][field].mandatory;
            if (
              isMandatory &&
              (!data[category][subCategory][field] ||
                data[category][subCategory][field] == "")
            ) {
              errorData[category][subCategory][
                field
              ].error = `${subCategory} is required!`;
              isSuccess = false;
            }
            return;
          });
        }

        if (subCategory == "email") {
          if (!validator.isEmail(data[category][subCategory].trim())) {
            errorData[category][
              subCategory
            ].error = `${subCategory} is invalid!`;
            isSuccess = false;
          }
        }

        if (subCategory == "mobile") {
          if (!data[category][subCategory]) {
            errorData[category][
              subCategory
            ].error = `${subCategory} is invalid!`;
            isSuccess = false;
            return;
          }
          let number = String(data[category][subCategory]).split(" ");
          number.splice(0, 1);
          number = number.join("");
          if (!validator.isMobilePhone(number)) {
            errorData[category][
              subCategory
            ].error = `${subCategory} is invalid!`;
            isSuccess = false;
            return;
          }
        }

        if (typeof data[category][subCategory] == "string") {
          const isMandatory = mandatoryFields[category][subCategory].mandatory;
          if (isMandatory) {
            if (
              !data[category][subCategory] ||
              data[category][subCategory] == ""
            ) {
              errorData[category][
                subCategory
              ].error = `${subCategory} is required!`;
              isSuccess = false;
            }
          }
          return;
        }
      });
    });
    setError(errorData);
    if (isSuccess) {
      return true;
    }
    snackbarHandler("Errors in form!", "error");
    return false;
  };

  //handler to submit data to the server with api request
  const submitData = async (type) => {
    if (type == "submit" && !validate()) {
      return;
    }
    setLoading(true);
    const response = await saveFormInputs(
      {
        formData: data,
        applicationId: application._id,
        formName: "innovatorForm",
        formVersion: formVersion,
        type: type,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      if (type == "submit") {
        snackbarHandler("Form Submitted..", "success");
        handleForm(null, null, true);
      } else {
        snackbarHandler("Form Saved..", "success");
        getFormDataHandler();
      }
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
    setLoading(false);
  };

  return (
    <PhaseContainer>
      <div className="header">
        <div className="title">Innovator Application form</div>
        <div className="closeIcon" onClick={() => handleForm(null)}>
          {Icons.close}
        </div>
      </div>
      <hr />

      {formLoading ? (
        <OwlLoader />
      ) : (
        <div className="row contentPanel">
          <div className="fields">
            <div className="accordionContainer">
              <Accordion>
                <AccordionSummary
                  expandIcon={Icons.expand}
                  aria-controls="panel1a-content"
                >
                  <Typography className="accordionHeader">
                    i. Personal / Professional Details
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordionDetails">
                  <div className="fieldsContainer">
                    <div className="fieldsHeader">
                      <div className="text">1. Title</div>
                      {/* <div className="status">Pending</div> */}
                    </div>
                    <div className="fieldsContents">
                      <div className="field">
                        <TextField
                          label="Title of the Proposed Project"
                          size="small"
                          value={data.title.title}
                          onChange={(e) =>
                            handleTextChange("title", "title", e.target.value)
                          }
                          error={!!error.title.title.error}
                          helperText={error.title.title.error}
                          disabled={!isFounder}
                        />
                      </div>

                      <div className="field">
                        <InputLabel>Upload your latest photo</InputLabel>
                        <PassportPhotoUpload
                          handleFile={handleFiles}
                          options={{ category: "title", id: "photo" }}
                          value={data.title.photo}
                          deleteHandler={deleteFile}
                          loading={isFileLoading.includes("photo")}
                          error={!!error.title.photo.error}
                          helperText={error.title.photo.error}
                          disabled={!isFounder}
                        />
                      </div>

                      <div className="field"></div>
                    </div>
                  </div>

                  <div className="fieldsContainer">
                    <div className="fieldsHeader">
                      <div className="text">2. Innovator Details</div>
                      {/* <div className="status">Pending</div> */}
                    </div>
                    <div className="fieldsContents">
                      <div className="fieldsContainer">
                        <div className="fieldsHeader">2.1 Personal Details</div>

                        <div className="fieldsContents">
                          <div className="row">
                            <div className="field">
                              <TextField
                                label="Name of the Innovator"
                                size={"small"}
                                value={data.personalDetails.name}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "name",
                                    e.target.value
                                  )
                                }
                                error={!!error.personalDetails.name.error}
                                helperText={error.personalDetails.name.error}
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <TextField
                                label="Parents name / Spouse name"
                                size={"small"}
                                value={data.personalDetails.fatherName}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "fatherName",
                                    e.target.value
                                  )
                                }
                                error={!!error.personalDetails.fatherName.error}
                                helperText={
                                  error.personalDetails.fatherName.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Date of Birth"
                                  inputFormat="DD/MM/YYYY"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      error={!!error.personalDetails.dob.error}
                                      helperText={
                                        error.personalDetails.dob.error
                                      }
                                    />
                                  )}
                                  onChange={(newValue) =>
                                    handleTextChange(
                                      "personalDetails",
                                      "dob",
                                      newValue.toString()
                                    )
                                  }
                                  value={data.personalDetails.dob}
                                  disabled={!isFounder}
                                />
                              </LocalizationProvider>
                            </div>

                            <div className="field">
                              <TextField
                                label="Email ID"
                                size={"small"}
                                value={data.personalDetails.email}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "email",
                                    e.target.value
                                  )
                                }
                                error={!!error.personalDetails.email.error}
                                helperText={error.personalDetails.email.error}
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <MuiTelInput
                                value={data.personalDetails.mobile}
                                onChange={(newValue) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "mobile",
                                    newValue
                                  )
                                }
                                size="small"
                                label="Mobile number"
                                error={!!error.personalDetails.mobile.error}
                                helperText={error.personalDetails.mobile.error}
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <TextField
                                label="Landline number"
                                size={"small"}
                                value={data.personalDetails.landline}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "landline",
                                    e.target.value
                                  )
                                }
                                error={!!error.personalDetails.landline.error}
                                helperText={
                                  error.personalDetails.landline.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <FormControl fullWidth>
                                <InputLabel
                                  size="small"
                                  id="demo-simple-select-label"
                                >
                                  Gender
                                </InputLabel>
                                <Select
                                  label="Gender"
                                  size="small"
                                  value={data.personalDetails.gender}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "personalDetails",
                                      "gender",
                                      e.target.value
                                    )
                                  }
                                  error={!!error.personalDetails.gender.error}
                                  disabled={!isFounder}
                                >
                                  <MenuItem value={"female"}>Female</MenuItem>
                                  <MenuItem value={"male"}>Male</MenuItem>
                                  <MenuItem value={"others"}>Others</MenuItem>
                                </Select>
                              </FormControl>
                              {!!error.personalDetails.gender.error ? (
                                <FormHelperText
                                  style={
                                    !!error.personalDetails.gender.error
                                      ? { color: "var(--errorTextColor)" }
                                      : null
                                  }
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {!!error.personalDetails.gender.error ? (
                                    error.personalDetails.gender.error
                                  ) : (
                                    <>&nbsp;</>
                                  )}
                                </FormHelperText>
                              ) : null}
                            </div>

                            <div className="field">
                              <TextField
                                label="Educational Qualification"
                                size={"small"}
                                value={data.personalDetails.qualification}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "qualification",
                                    e.target.value
                                  )
                                }
                                error={
                                  !!error.personalDetails.qualification.error
                                }
                                helperText={
                                  error.personalDetails.qualification.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field mb1">
                              <TextField
                                label="Category (General/OBC/SC/ST/Others)"
                                size={"small"}
                                value={data.personalDetails.category}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "category",
                                    e.target.value
                                  )
                                }
                                error={!!error.personalDetails.category.error}
                                helperText={
                                  error.personalDetails.category.error
                                }
                                disabled={!isFounder}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="field">
                              <TextField
                                label="PAN"
                                size={"small"}
                                value={data.personalDetails.pan}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "pan",
                                    e.target.value
                                  )
                                }
                                error={!!error.personalDetails.pan.error}
                                helperText={error.personalDetails.pan.error}
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <BuildFileUploader
                                options={{
                                  title: !!data.personalDetails.panFile
                                    ? "PAN File"
                                    : "",
                                }}
                                dataField={{
                                  fieldName: "panFile",
                                  category: "personalDetails",
                                }}
                                label={"Upload PAN"}
                                handleFile={handleFile}
                                file={data.personalDetails.panFile}
                                removeFile={deleteSingleFile}
                                error={
                                  !!error?.["personalDetails"]?.["panFile"]
                                    ?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.["panFile"]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              {/* <TextField  size="small"/> */}
                            </div>
                          </div>

                          <div className="row">
                            <div className="field">
                              <TextField
                                label="AADHAAR"
                                size={"small"}
                                value={data.personalDetails.aadhar}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "aadhar",
                                    e.target.value
                                  )
                                }
                                error={!!error.personalDetails.aadhar.error}
                                helperText={error.personalDetails.aadhar.error}
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <BuildFileUploader
                                options={{
                                  title: !!data.personalDetails.aadhaarFile
                                    ? "AADHAAR File"
                                    : "",
                                }}
                                dataField={{
                                  fieldName: "aadhaarFile",
                                  category: "personalDetails",
                                }}
                                label={"Upload AADHAAR"}
                                handleFile={handleFile}
                                file={data.personalDetails.aadhaarFile}
                                removeFile={deleteSingleFile}
                                error={
                                  !!error?.["personalDetails"]?.["aadhaarFile"]
                                    ?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.["aadhaarFile"]
                                    ?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field"></div>
                          </div>

                          <div className="row fieldFullWidth">
                            <div className="field">
                              <div className="infoText">
                                (<b>PERMANENT ADDRESS</b> : Please enclose
                                residence certificate issued by Sub-Divisional
                                Magistrate(DM) or a copy of ration card or any
                                other document regarding proof of residence)
                              </div>
                            </div>
                          </div>

                          <div className="row fieldFullWidth">
                            <div className="field">
                              <TextField
                                label="Permanent Address"
                                size="small"
                                value={
                                  data.personalDetails.permanentAddress.address
                                }
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "permanentAddress",
                                    e.target.value,
                                    "address"
                                  )
                                }
                                error={
                                  !!error?.["personalDetails"]?.[
                                    "permanentAddress"
                                  ]?.["address"]?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.[
                                    "permanentAddress"
                                  ]?.["address"]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>
                          </div>

                          <div className="row mt1">
                            <div className="field">
                              <TextField
                                label="City"
                                size="small"
                                value={
                                  data.personalDetails.permanentAddress.city
                                }
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "permanentAddress",
                                    e.target.value,
                                    "city"
                                  )
                                }
                                error={
                                  !!error?.["personalDetails"]?.[
                                    "permanentAddress"
                                  ]?.["city"]?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.[
                                    "permanentAddress"
                                  ]?.["city"]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <TextField
                                label="State"
                                size="small"
                                value={
                                  data.personalDetails.permanentAddress.state
                                }
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "permanentAddress",
                                    e.target.value,
                                    "state"
                                  )
                                }
                                error={
                                  !!error?.["personalDetails"]?.[
                                    "permanentAddress"
                                  ]?.["state"]?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.[
                                    "permanentAddress"
                                  ]?.["state"]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <TextField
                                label="Pincode"
                                size="small"
                                value={
                                  data.personalDetails.permanentAddress.pincode
                                }
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "permanentAddress",
                                    e.target.value,
                                    "pincode"
                                  )
                                }
                                error={
                                  !!error?.["personalDetails"]?.[
                                    "permanentAddress"
                                  ]?.["pincode"]?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.[
                                    "permanentAddress"
                                  ]?.["pincode"]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="field">
                              <BuildFileUploader
                                options={{
                                  title: !!data.personalDetails.addressFile
                                    ? "Address Proof"
                                    : "",
                                }}
                                dataField={{
                                  fieldName: "addressFile",
                                  category: "personalDetails",
                                }}
                                label={"Upload Address Proof"}
                                handleFile={handleFile}
                                file={data.personalDetails.addressFile}
                                removeFile={deleteSingleFile}
                                error={
                                  !!error?.["personalDetails"]?.["addressFile"]
                                    ?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.["addressFile"]
                                    ?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>
                          </div>

                          <div className="row fieldFullWidth">
                            <div className="field">
                              <div className="infoText">
                                (<b>POSTAL ADDRESS</b> : Please enclose
                                residence certificate issued by Sub-Divisional
                                Magistrate(DM) or a copy of ration card or any
                                other document regarding proof of residence)
                              </div>
                            </div>
                          </div>

                          {isFounder ? (
                            <div className="row">
                              <div className="field">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isCopyPostalAddress}
                                        onChange={handleCopyAddress}
                                      />
                                    }
                                    label="Copy from Permanent Address"
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          ) : null}

                          <div className="row fieldFullWidth">
                            <div className="field">
                              <TextField
                                label="Postal Address"
                                size="small"
                                value={data.personalDetails.address.address}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "address",
                                    e.target.value,
                                    "address"
                                  )
                                }
                                error={
                                  !!error?.["personalDetails"]?.["address"]?.[
                                    "address"
                                  ]?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.["address"]?.[
                                    "address"
                                  ]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>
                          </div>

                          <div className="row mt1">
                            <div className="field">
                              <TextField
                                label="City"
                                size="small"
                                value={data.personalDetails.address.city}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "address",
                                    e.target.value,
                                    "city"
                                  )
                                }
                                error={
                                  !!error?.["personalDetails"]?.["address"]?.[
                                    "city"
                                  ]?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.["address"]?.[
                                    "city"
                                  ]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <TextField
                                label="State"
                                size="small"
                                value={data.personalDetails.address.state}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "address",
                                    e.target.value,
                                    "state"
                                  )
                                }
                                error={
                                  !!error?.["personalDetails"]?.["address"]?.[
                                    "state"
                                  ]?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.["address"]?.[
                                    "state"
                                  ]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field">
                              <TextField
                                label="Pincode"
                                size="small"
                                value={data.personalDetails.address.pincode}
                                onChange={(e) =>
                                  handleTextChange(
                                    "personalDetails",
                                    "address",
                                    e.target.value,
                                    "pincode"
                                  )
                                }
                                error={
                                  !!error?.["personalDetails"]?.["address"]?.[
                                    "pincode"
                                  ]?.error
                                }
                                helperText={
                                  error?.["personalDetails"]?.["address"]?.[
                                    "pincode"
                                  ]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="fieldsContainer mt1">
                        <div className="fieldsHeader">
                          2.2 Professional Details
                        </div>

                        <div className="fieldsContents">
                          <div className="row">
                            <div className="field">
                              <TextField
                                label="Profession of the Innovator"
                                size={"small"}
                                value={data.professionalDetails.profession}
                                onChange={(e) =>
                                  handleTextChange(
                                    "professionalDetails",
                                    "profession",
                                    e.target.value
                                  )
                                }
                                error={
                                  !!error?.["professionalDetails"]?.[
                                    "profession"
                                  ]?.error
                                }
                                helperText={
                                  error?.["professionalDetails"]?.["profession"]
                                    ?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>
                            <div className="field"></div>
                            <div className="field"></div>
                          </div>

                          <div className="row fieldFullWidth mt1 mb-0_8">
                            <div className="field">
                              <div className="infoText">
                                =&gt;&nbsp;Name & Address of the Institution /
                                Organisation (Applicable in case of Innovator in
                                Employment / Students with any R&D organisation
                                / Academic Institution)
                              </div>
                            </div>
                          </div>

                          <div className="row fieldFullWidth">
                            <div className="field">
                              <TextField
                                label="Name & Address of the Institution / Organisation"
                                size={"small"}
                                value={data.professionalDetails.organization}
                                onChange={(e) =>
                                  handleTextChange(
                                    "professionalDetails",
                                    "organization",
                                    e.target.value
                                  )
                                }
                                error={
                                  !!error?.["professionalDetails"]?.[
                                    "organization"
                                  ]?.error
                                }
                                helperText={
                                  error?.["professionalDetails"]?.[
                                    "organization"
                                  ]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>
                          </div>

                          <div className="row fieldFullWidth mt1 mb-0_8">
                            <div className="field">
                              <div className="infoText">
                                =&gt;&nbsp;For Innovator in Employment /
                                Students with any R&D organisation / Academic
                                Institution (NOC from Head of Institute /
                                Organisation is mandatorily required)
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="field">
                              <BuildFileUploader
                                options={{
                                  title: !!data.professionalDetails.noc
                                    ? "NOC File"
                                    : "",
                                }}
                                dataField={{
                                  fieldName: "noc",
                                  category: "professionalDetails",
                                }}
                                label={"Upload NOC"}
                                handleFile={handleFile}
                                file={data.professionalDetails.noc}
                                removeFile={deleteSingleFile}
                                error={
                                  !!error?.["professionalDetails"]?.["noc"]
                                    ?.error
                                }
                                helperText={
                                  error?.["professionalDetails"]?.["noc"]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>
                            <div className="field"></div>
                            <div className="field"></div>
                          </div>

                          <div className="row">
                            <div className="field">
                              <FormControl>
                                <InputLabel>Annual Income</InputLabel>
                                <OutlinedInput
                                  size="small"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      ₹
                                    </InputAdornment>
                                  }
                                  label="Annual Income"
                                  value={
                                    !data.professionalDetails.annualIncome
                                      ? ""
                                      : inrAddCommas(
                                          data.professionalDetails.annualIncome
                                        )
                                  }
                                  error={
                                    !!error?.["professionalDetails"]?.[
                                      "annualIncome"
                                    ]?.error
                                  }
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    value = value.replaceAll(",", "");
                                    value = value;
                                    handleTextChange(
                                      "professionalDetails",
                                      "annualIncome",
                                      value
                                    );
                                  }}
                                  disabled={!isFounder}
                                />
                              </FormControl>
                              <FormHelperText style={{ color: "red" }}>
                                {
                                  error?.["professionalDetails"]?.[
                                    "annualIncome"
                                  ]?.error
                                }
                              </FormHelperText>
                            </div>

                            <div className="field">
                              <BuildFileUploader
                                options={{
                                  title: !!data.professionalDetails.itr
                                    ? "ITR File"
                                    : "",
                                }}
                                dataField={{
                                  fieldName: "itr",
                                  category: "professionalDetails",
                                }}
                                label={
                                  "Upload Copy of latest Income Tax Returns filed"
                                }
                                handleFile={handleFile}
                                file={data.professionalDetails.itr}
                                removeFile={deleteSingleFile}
                                error={
                                  !!error?.["professionalDetails"]?.["itr"]
                                    ?.error
                                }
                                helperText={
                                  error?.["professionalDetails"]?.["itr"]?.error
                                }
                                disabled={!isFounder}
                              />
                            </div>

                            <div className="field"></div>
                          </div>

                          <div className="row fieldFullWidth">
                            <div className="field">
                              <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">
                                  =&gt;&nbsp; Have you started your own venture
                                  (Startup) ? Yes / No --- If yes, Please
                                  mention the following: Name of your startup,
                                  Your role in startup (eg: CEO, COO, Product
                                  Head etc), Date of Incorporation, Revenue
                                  generated till date, - Provide Cap structure
                                  of the startup -{" "}
                                </FormLabel>
                                <RadioGroup
                                  value={
                                    data.professionalDetails.ownVenture.isExists
                                  }
                                  onChange={(e) =>
                                    handleTextChange(
                                      "professionalDetails",
                                      "ownVenture",
                                      e.target.value,
                                      "isExists"
                                    )
                                  }
                                  row
                                  disabled={!isFounder}
                                >
                                  <FormControlLabel
                                    value={"yes"}
                                    control={<Radio size="small" />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={"no"}
                                    control={<Radio size="small" />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>

                              {data.professionalDetails.ownVenture.isExists ==
                              "yes" ? (
                                <TextField
                                  multiline
                                  size="small"
                                  rows={2}
                                  placeholder="Details"
                                  value={
                                    data.professionalDetails.ownVenture.details
                                  }
                                  onChange={(e) =>
                                    handleTextChange(
                                      "professionalDetails",
                                      "ownVenture",
                                      e.target.value,
                                      "details"
                                    )
                                  }
                                  error={
                                    !!error?.["professionalDetails"]?.[
                                      "ownVenture"
                                    ]?.details.error
                                  }
                                  helperText={
                                    error?.["professionalDetails"]?.[
                                      "ownVenture"
                                    ]?.details.error
                                  }
                                  disabled={!isFounder}
                                />
                              ) : null}
                            </div>
                          </div>

                          <div className="row fieldFullWidth">
                            <div className="field">
                              <>
                                <FormLabel className={`label`}>
                                  <div className="statusContainer"></div>
                                </FormLabel>

                                <TableContainer>
                                  <Table sx={{ minWidth: 650 }} size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{ width: "40px" }}>
                                          Sl No
                                        </TableCell>
                                        <TableCell>
                                          Name (Founder/Co-founder)
                                        </TableCell>
                                        <TableCell>Nationality</TableCell>
                                        <TableCell>
                                          Commitment (Part-time/Full-time)
                                        </TableCell>
                                        <TableCell>% Shareholding</TableCell>
                                        <TableCell>Action</TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {data.professionalDetails.founders.map(
                                        (value, i) => {
                                          return (
                                            <TableRow key={i}>
                                              <TableCell>{i + 1}</TableCell>
                                              {Object.keys(
                                                tableFields.professionalDetails
                                                  .founders
                                              ).map((field, index) => (
                                                <TableCell key={index}>
                                                  <TextField
                                                    disabled={!isFounder}
                                                    size="small"
                                                    fullWidth
                                                    value={
                                                      data.professionalDetails
                                                        .founders?.[i]?.[field]
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeTextTable(
                                                        "professionalDetails",
                                                        "founders",
                                                        field,
                                                        e.target.value,
                                                        i
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                              ))}
                                              <TableCell>
                                                {user?.role?.roleId !=
                                                "founder" ? (
                                                  "-"
                                                ) : (
                                                  <div
                                                    className="actions"
                                                    onClick={() =>
                                                      deleteRowTable(
                                                        "professionalDetails",
                                                        "founders",
                                                        i
                                                      )
                                                    }
                                                  >
                                                    {Icons.delete}
                                                  </div>
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      )}

                                      {user?.role?.roleId != "founder" ? (
                                        <></>
                                      ) : (
                                        <TableRow>
                                          <TableCell align="center" colSpan={6}>
                                            <Button
                                              className="muiOutlinedBtn"
                                              variant="outlined"
                                              onClick={() =>
                                                addRowToTable(
                                                  "professionalDetails",
                                                  "founders"
                                                )
                                              }
                                            >
                                              Add Row
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="fieldsContainer mt1">
                        <div className="fieldsHeader">
                          2.3 Team Member Details
                        </div>

                        <div className="fieldsContents">
                          <div className="row fieldFullWidth">
                            <div className="field">
                              <>
                                <FormLabel className={`label`}>
                                  <div className="statusContainer"></div>
                                </FormLabel>

                                <TableContainer>
                                  <Table sx={{ minWidth: 650 }} size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{ width: "40px" }}>
                                          Sl No
                                        </TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>AADHAAR No.</TableCell>
                                        <TableCell>PAN No.</TableCell>
                                        <TableCell>Gender</TableCell>
                                        <TableCell>Contact</TableCell>
                                        <TableCell>Email ID</TableCell>
                                        <TableCell>Qualification</TableCell>
                                        <TableCell>Action</TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {data.teamMembers.teamMembers.map(
                                        (value, i) => {
                                          return (
                                            <TableRow key={i}>
                                              <TableCell>{i + 1}</TableCell>
                                              {Object.keys(
                                                tableFields.teamMembers
                                                  .teamMembers
                                              ).map((field, index) => (
                                                <TableCell key={index}>
                                                  <TextField
                                                    size="small"
                                                    disabled={!isFounder}
                                                    fullWidth
                                                    value={
                                                      data.teamMembers
                                                        .teamMembers[i][field]
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeTextTable(
                                                        "teamMembers",
                                                        "teamMembers",
                                                        field,
                                                        e.target.value,
                                                        i
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                              ))}
                                              <TableCell>
                                                {user?.role?.roleId !=
                                                "founder" ? (
                                                  "-"
                                                ) : (
                                                  <div
                                                    className="actions"
                                                    onClick={() =>
                                                      deleteRowTable(
                                                        "teamMembers",
                                                        "teamMembers",
                                                        i
                                                      )
                                                    }
                                                  >
                                                    {Icons.delete}
                                                  </div>
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      )}

                                      {user?.role?.roleId != "founder" ? (
                                        <></>
                                      ) : (
                                        <TableRow>
                                          <TableCell align="center" colSpan={9}>
                                            <Button
                                              className="muiOutlinedBtn"
                                              variant="outlined"
                                              onClick={() =>
                                                addRowToTable(
                                                  "teamMembers",
                                                  "teamMembers"
                                                )
                                              }
                                            >
                                              Add Row
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="accordionContainer">
              <Accordion>
                <AccordionSummary
                  expandIcon={Icons.expand}
                  aria-controls="panel1a-content"
                >
                  <Typography className="accordionHeader">
                    ii. Project Details
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordionDetails">
                  <div className="fieldsContainer">
                    <div className="fieldsHeader">
                      <div className="text">3. Description</div>
                      {/* <div className="status">Pending</div> */}
                    </div>

                    <div className="fieldsContents">
                      <div className="fieldsContainer">
                        <div className="row fieldFullWidth">
                          <div className="field">
                            <TextField
                              size="small"
                              label="Brief description of the idea highlighting innovative element"
                              value={data.description.description}
                              onChange={(e) =>
                                handleTextChange(
                                  "description",
                                  "description",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["description"]?.["description"]?.error
                              }
                              helperText={
                                error?.["description"]?.["description"]?.error
                              }
                              disabled={!isFounder}
                              multiline={true}
                              rows={5}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">4. Status of work</div>
                      {/* <div className="status">Pending</div> */}
                    </div>

                    <div className="fieldsContents">
                      <div className="fieldsContainer">
                        <div className="row fieldFullWidth">
                          <div className="field">
                            <FormControl>
                              <FormLabel id="demo-controlled-radio-buttons-group">
                                a) Status of work already carried out (if any)
                                such as. Please tick ✓ as applicable
                              </FormLabel>
                              <RadioGroup
                                value={data.otherDetails.statusOfWork}
                                onChange={(e) =>
                                  handleTextChange(
                                    "otherDetails",
                                    "statusOfWork",
                                    e.target.value
                                  )
                                }
                                row
                                disabled={!isFounder}
                              >
                                <FormControlLabel
                                  value="idea"
                                  control={
                                    <Radio size="small" disabled={!isFounder} />
                                  }
                                  label="Idea level"
                                />
                                <FormControlLabel
                                  value="college"
                                  control={
                                    <Radio size="small" disabled={!isFounder} />
                                  }
                                  label="College Project"
                                />
                                <FormControlLabel
                                  value="lab"
                                  control={
                                    <Radio size="small" disabled={!isFounder} />
                                  }
                                  label="Lab level - Proof of Concept"
                                />
                                <FormControlLabel
                                  value="paper"
                                  control={
                                    <Radio size="small" disabled={!isFounder} />
                                  }
                                  label="Paper Presentation"
                                />
                                <FormControlLabel
                                  value="prototype"
                                  control={
                                    <Radio size="small" disabled={!isFounder} />
                                  }
                                  label="Working prototype ready"
                                />
                              </RadioGroup>
                            </FormControl>

                            <FormHelperText
                              style={
                                !!error.personalDetails.gender.error
                                  ? { color: "var(--errorTextColor)" }
                                  : null
                              }
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {!!error.otherDetails.statusOfWork.error ? (
                                error.otherDetails.statusOfWork.error
                              ) : (
                                <>&nbsp;</>
                              )}
                            </FormHelperText>
                          </div>
                        </div>

                        <div className="row fieldFullWidth">
                          <div className="field">
                            <TextField
                              multiline
                              rows={5}
                              size="small"
                              label="b) Science and working principle behind the idea (Not more than 250 words)."
                              value={data.otherDetails.workingPrinciple}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherDetails",
                                  "workingPrinciple",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["otherDetails"]?.["workingPrinciple"]
                                  ?.error
                              }
                              helperText={
                                error?.["otherDetails"]?.["workingPrinciple"]
                                  ?.error
                              }
                              disabled={!isFounder}
                            />
                          </div>
                        </div>

                        <div className="row fieldFullWidth mt1">
                          <div className="field">
                            <TextField
                              multiline
                              rows={5}
                              size="small"
                              label="c) Final outcome / deliverable of the project (Not more than 250 words)."
                              value={data.otherDetails.outcome}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherDetails",
                                  "outcome",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["otherDetails"]?.["outcome"]?.error
                              }
                              helperText={
                                error?.["otherDetails"]?.["outcome"]?.error
                              }
                              disabled={!isFounder}
                            />
                          </div>
                        </div>

                        <div className="row fieldFullWidth mt1">
                          <div className="field">
                            <TextField
                              multiline
                              size="small"
                              label="d) Who would be beneficiary of this innovation and why ? (Not more than 200 words)."
                              value={data.otherDetails.beneficiary}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherDetails",
                                  "beneficiary",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["otherDetails"]?.["beneficiary"]
                                  ?.error
                              }
                              helperText={
                                error?.["otherDetails"]?.["beneficiary"]?.error
                              }
                              disabled={!isFounder}
                              rows={5}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">
                        5. Proposed costs and time frame
                      </div>
                      {/* <div className="status">Pending</div> */}
                    </div>

                    <div className="fieldsContents">
                      <div className="fieldsContainer">
                        <div className="row fieldFullWidth">
                          <div className="field">
                            <>
                              <FormLabel className={`label`}></FormLabel>

                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell width={"40px"}>
                                        Sl No
                                      </TableCell>
                                      {Object.keys(tableFields.costs.costs).map(
                                        (column, i) => {
                                          return (
                                            <TableCell key={i}>
                                              {tableFields.costs.costs[column]}
                                            </TableCell>
                                          );
                                        }
                                      )}
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {tableFields.costs.costsRows.map(
                                      (row, i) => {
                                        return (
                                          <TableRow key={i}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{row}</TableCell>
                                            {Object.keys(
                                              tableFields.costs.costs
                                            ).map((column, j) => {
                                              if (j == 0) return <></>;
                                              return (
                                                <TableCell key={j}>
                                                  <FormControl>
                                                    <OutlinedInput
                                                      size="small"
                                                      startAdornment={
                                                        <InputAdornment position="start">
                                                          ₹
                                                        </InputAdornment>
                                                      }
                                                      value={
                                                        data.costs.items[
                                                          "item" + (i + 1)
                                                        ][column] != "0"
                                                          ? inrAddCommas(
                                                              data.costs.items[
                                                                "item" + (i + 1)
                                                              ][column]
                                                            )
                                                          : "0"
                                                      }
                                                      onChange={(e) => {
                                                        let value =
                                                          e.target.value;
                                                        if (
                                                          !value ||
                                                          value == ""
                                                        )
                                                          value = "0";
                                                        value =
                                                          value.replaceAll(
                                                            ",",
                                                            ""
                                                          );
                                                        value = value;
                                                        changeStaticTableValue(
                                                          "items",
                                                          "item" +
                                                            (i + 1).toString(),
                                                          column,
                                                          value
                                                        );
                                                      }}
                                                      disabled={!isFounder}
                                                      error={
                                                        !!error?.["costs"]?.[
                                                          "items"
                                                        ]?.["item" + (i + 1)][
                                                          column
                                                        ].error
                                                      }
                                                    />
                                                  </FormControl>
                                                  <FormHelperText
                                                    style={{ color: "red" }}
                                                  >
                                                    {
                                                      error?.["costs"]?.[
                                                        "items"
                                                      ]?.["item" + (i + 1)][
                                                        column
                                                      ].error
                                                    }
                                                  </FormHelperText>
                                                </TableCell>
                                              );
                                            })}
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          </div>
                        </div>

                        <div className="row mt1">
                          <div className="field">
                            <TextField
                              size="small"
                              label="Project period in months (Not more than 12 months)"
                              value={data.costs.projectPeriod}
                              type="number"
                              onChange={(e) =>
                                handleTextChange(
                                  "costs",
                                  "projectPeriod",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["costs"]?.["projectPeriod"]?.error
                              }
                              helperText={
                                error?.["costs"]?.["projectPeriod"]?.error
                              }
                              disabled={!isFounder}
                            />
                          </div>
                          <div className="field"></div>
                          <div className="field"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="accordionContainer">
              <Accordion>
                <AccordionSummary
                  expandIcon={Icons.expand}
                  aria-controls="panel1a-content"
                >
                  <Typography className="accordionHeader">
                    iii. Declarations
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordionDetails">
                  <div className="fieldsContainer">
                    <div className="fieldsHeader">
                      <div className="text">6. Financial Support</div>
                      {/* <div className="status">Pending</div> */}
                    </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              a) Have you received financial support / award for
                              your present work from any other sources? (if so,
                              please furnish details)
                            </FormLabel>
                            <RadioGroup
                              disabled={!isFounder}
                              value={
                                data.financialSupport.financialSupport.isSupport
                              }
                              onChange={(e) =>
                                handleTextChange(
                                  "financialSupport",
                                  "financialSupport",
                                  e.target.value,
                                  "isSupport"
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                value={"yes"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value={"no"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>

                          {data.financialSupport.financialSupport.isSupport ==
                          "yes" ? (
                            <TextField
                              size="small"
                              fullWidth
                              multiline
                              rows={2}
                              placeholder="Details"
                              value={
                                data.financialSupport.financialSupport.details
                              }
                              onChange={(e) =>
                                handleTextChange(
                                  "financialSupport",
                                  "financialSupport",
                                  e.target.value,
                                  "details"
                                )
                              }
                              disabled={!isFounder}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">7. Other PRAYAS Centre</div>
                      {/* <div className="status">Pending</div> */}
                    </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              a) Have you received financial support / award for
                              your present work from any other sources? (if so,
                              please furnish details)
                            </FormLabel>
                            <RadioGroup
                              value={data.otherCenter.otherCenter.isOtherCenter}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherCenter",
                                  "otherCenter",
                                  e.target.value,
                                  "isOtherCenter"
                                )
                              }
                              row
                              disabled={!isFounder}
                            >
                              <FormControlLabel
                                value={"yes"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value={"no"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>

                          {data.otherCenter.otherCenter.isOtherCenter ==
                          "yes" ? (
                            <TextField
                              disabled={!isFounder}
                              size="small"
                              fullWidth
                              multiline
                              rows={2}
                              placeholder="Details"
                              value={data.otherCenter.otherCenter.details}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherCenter",
                                  "otherCenter",
                                  e.target.value,
                                  "details"
                                )
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">8. PRAYAS Received / Approved</div>
                      {/* <div className="status">Pending</div> */}
                    </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              a) Have you been approved / received the
                              NIDHI-PRAYAS -- Yes/ No, If Yes – Please mention
                              the name of the Centre / TBI and Date of
                              approval/Funds received
                            </FormLabel>
                            <RadioGroup
                              value={data.received.received.isReceived}
                              onChange={(e) =>
                                handleTextChange(
                                  "received",
                                  "received",
                                  e.target.value,
                                  "isReceived"
                                )
                              }
                              row
                              disabled={!isFounder}
                            >
                              <FormControlLabel
                                value={"yes"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value={"no"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>

                          {data.received.received.isReceived == "yes" ? (
                            <TextField
                              size="small"
                              fullWidth
                              multiline
                              rows={2}
                              placeholder="Details"
                              value={data.received.received.details}
                              onChange={(e) =>
                                handleTextChange(
                                  "received",
                                  "received",
                                  e.target.value,
                                  "details"
                                )
                              }
                              disabled={!isFounder}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">9. Essential Criteria</div>
                      {/* <div className="status">Pending</div> */}
                    </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <>
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  {Object.keys(tableFields.criteriaRows).map(
                                    (row, i) => {
                                      return (
                                        <TableRow>
                                          <TableCell width={"80%"}>
                                            {tableFields.criteriaRows[row]}
                                          </TableCell>
                                          <TableCell
                                            width={"20%"}
                                            align="center"
                                          >
                                            <RadioGroup
                                              row
                                              value={data.criteria.criteria[i]}
                                              onChange={(e) => {
                                                changeBooleanTableValue(
                                                  "criteria",
                                                  i,
                                                  e.target.value
                                                );
                                              }}
                                              disabled={!isFounder}
                                            >
                                              <FormControlLabel
                                                value={"yes"}
                                                control={
                                                  <Radio
                                                    size="small"
                                                    disabled={!isFounder}
                                                  />
                                                }
                                                label={"Yes"}
                                                disabled={
                                                  user.role.roleId != "founder"
                                                }
                                              />
                                              <FormControlLabel
                                                value={"no"}
                                                control={
                                                  <Radio
                                                    size="small"
                                                    disabled={!isFounder}
                                                  />
                                                }
                                                label={"No"}
                                                disabled={
                                                  user.role.roleId != "founder"
                                                }
                                              />
                                            </RadioGroup>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">10. Declaration</div>
                      {/* <div className="status">Pending</div> */}
                    </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <div className="infoText">
                            I declare that all the statements made in this
                            application are true, complete and correct to the
                            best of my knowledge and belief. If any information
                            is found false or incorrect, my candidature will
                            stand cancelled and all my claims will be forfeited.
                            I have not received any financial assistance for the
                            present proposal from any other agency.
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="field">
                          <TextField
                            size="small"
                            label="Place"
                            value={data.declaration.place}
                            onChange={(e) =>
                              handleTextChange(
                                "declaration",
                                "place",
                                e.target.value
                              )
                            }
                            disabled={!isFounder}
                            error={!!error?.["declaration"]?.["place"]?.error}
                            helperText={
                              error?.["declaration"]?.["place"]?.error
                            }
                          />
                        </div>

                        <div className="field">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Date"
                              inputFormat="DD/MM/YYYY"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  error={
                                    !!error?.["declaration"]?.["date"]?.error
                                  }
                                  helperText={
                                    error?.["declaration"]?.["date"]?.error
                                  }
                                />
                              )}
                              onChange={(newValue) =>
                                handleTextChange(
                                  "declaration",
                                  "date",
                                  newValue.toString()
                                )
                              }
                              value={
                                !!data.declaration.date
                                  ? new Date().toString()
                                  : data.declaration.date
                              }
                              disabled={!isFounder}
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="field">
                          <BuildFileUploader
                            options={{
                              title: !!data.declaration.signature
                                ? "Signature File"
                                : "",
                            }}
                            dataField={{
                              fieldName: "signature",
                              category: "declaration",
                            }}
                            label={"Upload Signature"}
                            handleFile={handleFile}
                            file={data.declaration.signature}
                            removeFile={deleteSingleFile}
                            error={
                              !!error?.["declaration"]?.["signature"]?.error
                            }
                            helperText={
                              error?.["declaration"]?.["signature"]?.error
                            }
                            disabled={!isFounder}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="mb50"></div>

            {from == "associate" ? (
              <></>
            ) : !loading ? (
              <div className="buttons">
                <CustomButton
                  title="Submit Form"
                  onClickHandler={() => submitData("submit")}
                />
                <CustomButton
                  title="Save as Draft"
                  onClickHandler={() => submitData("draft")}
                />
              </div>
            ) : (
              <div className="buttons">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>

      )}

    </PhaseContainer>
  );
};

export default CompletionOfForm;

export const PhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1rem;

  .contentPanel {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
      color: var(--text1);
      font-size: 1.2rem;
      font-weight: 400;
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .statusContainer {
    margin: 0.5rem 0;
  }

  .status {
    background: yellow;
    width: max-content;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    text-transform: capitalize;
  }

  .approved {
    background: #58d68d;
    color: #fff;
    text-transform: capitalize;
  }

  hr {
    margin: 1rem 0;
    color: var(--text3);
    width: 100%;
    opacity: 0.3;
  }

  .row {
    display: flex;
  }

  .formgroup {
    color: var(--text3);
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .accordionContainer {
      height: max-content;
      .accordionHeader {
        color: var(--text1);
      }

      .accordionDetails {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .fieldsContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-wrap: wrap;

        .fieldsHeader {
          display: flex;
          align-items: center;
          gap: 1rem;

          .text {
            font-size: 18px;
            color: var(--text3);
          }
        }

        .fieldsContents {
          display: grid;
          row-gap: 1rem;

          .row {
            // width: 100%;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            // grid-template-columns: auto auto auto;
            display: grid;
            grid-gap: 2rem;
          }

          .fieldFullWidth {
            grid-template-columns: 1fr;
          }

          .field {
            display: flex;
            flex-direction: column;
            min-width: 300px;
            height: max-content;

            .infoText {
              color: var(--text3);
              line-height: 1.5;
            }
          }
        }
      }
    }

    .field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.5rem;

      .inputField {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0;
      }

      .label {
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
        white-space: pre-wrap;
      }

      .fileUploader {
        width: 100%;
        padding: 1rem;
        border: 1px solid var(--text3);
        border-style: dashed;
        border-radius: 4px;
        min-height: 5rem;
        cursor: pointer;

        .files {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .file {
            margin-right: 1rem;
          }
        }

        .component {
          box-sizing: border-box;
          min-width: 100%;
          min-height: 5rem;
        }
      }
    }

    .addRowButton {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;

      button {
        color: var(--primary) !important;
      }
    }

    .buttons {
      display: flex;
      z-index: 99999999;
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;
      position: fixed;
      bottom: -40px;
      padding: 20px 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      width: 100%;
    }
  }

  .tableContainer {
    background: var(--light);

    table {
      border-collapse: separate;
      border-spacing: 10px;
      table-layout: fixed;
      thead {
        tr {
          th {
            border: none;
            padding: 0 !important;
            color: var(--text3);
            font-size: 1.1rem;
            text-align: center;
          }

          .tc-50 {
            width: 50px !important;
          }

          .tc-200 {
            min-width: 200px !important;
          }
        }
      }

      tbody {
        tr {
          height: 180px;
          td {
            border: none;
            color: var(--text1);
            background: #fff;
            height: 100%;

            textarea {
              width: 100%;
              height: 100%;
              height: 8rem;
              line-height: 1.4;
              color: var(--text3);
              resize: none;
              border: none;
              outline: none;
              font-size: 1rem;
            }

            .actions {
              display: flex;
              justify-content: center;
              flex-direction: column;
              gap: 1rem;
              align-items: center;

              .action {
                cursor: pointer;
                transition: 0.1s;

                &:hover {
                  color: var(--text3);
                }
              }
            }
          }

          .va-center {
            text-align: center;
          }
        }
      }
    }
  }

  .objectiveTable {
    table {
      thead {
        tr {
          // height: 6rem;
          th {
            height: auto !important;
            overflow: hidden;
            text-overflow: clip;
          }
        }
      }
    }
  }
`;

export const CommentsContainer = styled.div`
  width: ${(props) => (props.openComments ? "25rem" : "2rem")};
  transition: 0.2s width;
  display: flex;
  height: 100%;
  .arrow {
    background: var(--primary);
    height: 100%;
    min-width: 2rem;
    max-width: 2rem;
    cursor: pointer;
    border-top-left-radius: ${(props) => (props.index == 0 ? "15px" : "none")};
    border-bottom-left-radius: ${(props) =>
      props.index == 11 ? "15px" : "none"};
    svg {
      font-size: 1.7rem;
      color: #fff;
      transform: ${(props) =>
        props.openComments ? "rotate(0deg)" : "rotate(180deg)"};
      display: ${(props) => (props.index == 0 ? "flex" : "none")};
      margin-top: 0.5rem;
      justify-content: center;
      width: 100%;
    }
  }
  .commentsSection {
    width: 100%;
    height: 100%;
    border: 1px solid #d0d3d4;
    border-left: none;
    border-top: ${(props) => (props.index == 0 ? "" : "none")};
    padding: 1rem;
    display: ${(props) => (props.openComments ? "flex" : "none")};
    flex-direction: column;
    textarea {
      margin-top: 0.5rem;
      resize: none;
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      color: var(--text1);
      height: 100%;
      border: 1px solid #d0d3d4;
      outline: none;
      border-radius: 8px;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .muiBtn {
        // padding: 0rem 1.5rem;
        height: 2rem;
        color: var(--primary) !important;
      }
      .radioButtons {
        font-size: 0.9rem;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        label {
          width: max-content;
        }
      }
    }
  }
`;

