import styled from "styled-components";

const CustomTab = ({ startupTabs, activeTab, changeActiveTab }) => {
  return (
    <Container>
      {Object.keys(startupTabs).map((startupTab, i) => (
        <div
          className={`tab-item ${activeTab == i ? "active" : ""}`}
          onClick={() => changeActiveTab(i)}
          key={i}
        >
          {startupTabs[startupTab]}
        </div>
      ))}
    </Container>
  );
};

export default CustomTab;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  
  .tab-item {
    color: var(--text1);
    letter-spacing: 1px;
    font-size: 1rem;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid #d0d3d4;
    transition: 0.1s;
    position: relative;
    margin-bottom: 0.5rem;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0rem;
      width: 0%;
      height: 100%;
      border-bottom: 2px solid var(--primary);
      transition: 0.3s;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  .active {
    pointer-events: none;
    text-shadow: 0px 0px 0.8px var(--text1);

    &::after {
      width: 100%;
      border-bottom: 3px solid var(--primary);
      transition: 0.05s;
    }
  }
`;
