import { updateAuth } from '../../../reducers/auth/authReducer'
import { updateStartupList } from '../../../reducers/startups/startupsList'
import { postRequest } from '../../../utils/axiosClient/axios'
import paths from '../../../utils/links'
import { getToken } from './startupForm'

//api request handler to create user
export const createUser = async (data, dispatch, controller) => {
  const response = await postRequest({
    endpoint: '/auth/createUser',
    data,
    dispatch,
    controller,
  })
  return response
}

//api request handler to login user
export const signInUser = async (data, controller) => {
  const response = await postRequest({
    endpoint: '/auth/signIn',
    data,
    controller,
  })
  return response
}

//api request handler to send otp again to the user
export const resendOtp = async (data, dispatch, controller) => {
  const response = await postRequest({
    endpoint: '/auth/resendOtp',
    data,
    dispatch,
    controller,
  })
  return response
}

//api request handler to verify email and set password
export const verifyEmail = async (data, dispatch, controller) => {
  const response = await postRequest({
    endpoint: '/auth/verifyEmail',
    data,
    dispatch,
    controller,
  })
  return response
}

//api request to get user from token
export const getUser = async (dispatch, controller) => {
  const response = await postRequest({
    endpoint: '/auth/getUser',
    headers: { 'access-token': getToken(dispatch, controller) },
    dispatch,
    controller,
  })
  return response
}

//api request to change password of the user
export const changePassword = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    data: data,
    endpoint: '/auth/changePassword',
    headers: { 'access-token': token },
    dispatch,
    controller,
  })
  return response
}

//handler to logout user
export const logout = async (dispatch) => {
  localStorage.removeItem('access-token')
  sessionStorage.removeItem('active-sub-link')
  dispatch(updateAuth({ isAuthenticated: false, user: null }))
}

export const saveUser = async (data, dispatch, controller) => {
  const response = await postRequest({
    endpoint: '/user/addUser',
    data,
    dispatch,
    controller,
  })
  return response
}

export const fetchUsers = async (data, dispatch, controller) => {
  const response = await postRequest({
    endpoint: '/user/fetchUsers',
    data,
    dispatch,
    controller,
  })
  return response
}
