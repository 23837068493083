import Box from "@mui/material/Box";
import {
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Select,
  MenuItem,
  Dialog,
} from "@mui/material";
import "./applications.css";
import SchemeCardInfo from "./application_card";
import { FormLabel, Table } from "react-bootstrap";
import PageNavigation from "../../../customComponents/PageNavigation";
import { useEffect, useState } from "react";
import { postRequest } from "../../../../utils/axiosClient/axios";
import { useNavigate, useParams } from "react-router-dom";
import { globalDispatch } from "../../../../providers/GlobalProvider";
import { getSelectedApplication } from "../../../../data/network/api/milestone";
import moment from "moment";
import { CheckBox } from "@mui/icons-material";
import ApplicationActionModal from "./ApplicationActionModal";
const ApplicationsPage = () => {
  const params = useParams();
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [selectedApplicants, setSelectedApplications] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(-1);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("pageNavStackContainer").style.display = "none";
    getApplicationList();
  }, [params.callId]);

  const getApplicationList = async () => {
    const appList = await getSelectedApplication(
      {
        callId: params.callId,
      },
      dispatch,
      controller
    );
    let pushData = [];
    if (appList && appList.status == 200) {
      appList.msg?.map((x, i) => {
        pushData.push({
          comp_name: x.companyName,
          industry_type: x?.project?.startUpType,
          total_amount: x?.project?.totalBudget,
          milestone_status: "-",
          start_date: moment(x?.project?.startDate).format("DD/MM/YYYY"),
          end_date: moment(x?.project?.endDate).format("DD/MM/YYYY"),
          applicationId: x._id,
          projectId: x?.project?._id,
        });
      });
      setSelectedApplications(pushData);
    }
  };

  const handleChangeCheckbox = async (e, id) => {
    const isPush = e.target.checked;
    let array = [...selectedProjects];
    if (isPush) {
      array.push(id);
    } else {
      const index = array.indexOf(id);
      array.splice(index, 1);
    }
    setSelectedProjects(array);
  };

  const handleActionDialog = () => {
    setSelectedAction(-1);
    setActionDialogOpen(false);
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    if (e.target.value != -1) {
      setActionDialogOpen(true);
    }
  };

  return (
    <div>
      <SchemeCardInfo />
      <TableContainer
        style={{ width: "100%", marginTop: "30px" }}
        component={Paper}
      >
        <Table
          style={{ width: "100%" }}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {/* <TableCell style={{ width: "0px" }}></TableCell> */}
              <TableCell align="center" style={{ width: "50px" }}>
                Sl No.
              </TableCell>
              <TableCell align="left">Company Name</TableCell>
              {/* <TableCell align="center">Industry Type</TableCell> */}
              <TableCell align="left">Approved Budget</TableCell>
              {/* <TableCell align="center">Milestones Status</TableCell> */}
              <TableCell align="left">Start Date</TableCell>
              <TableCell align="left">End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedApplicants.map((row, i) => {
              return (
                <TableRow style={{ cursor: "pointer" }}>
                  {/* <TableCell>
                    <Checkbox
                      color="primary"
                      checked={selectedProjects.includes(row?.projectId)}
                      onChange={(e) => handleChangeCheckbox(e, row?.projectId)}
                    />
                  </TableCell> */}
                  <TableCell
                    align="center"
                    onClick={() => {
                      navigate(`/schemes/milestones/${row.applicationId}`);
                    }}
                  >
                    {i + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => {
                      navigate(`/schemes/milestones/${row.applicationId}`);
                    }}
                  >
                    {row.comp_name}
                  </TableCell>
                  {/* <TableCell align="center">{row.industry_type}</TableCell> */}
                  <TableCell
                    align="left"
                    onClick={() => {
                      navigate(`/schemes/milestones/${row.applicationId}`);
                    }}
                  >
                    ₹{row.total_amount}
                  </TableCell>
                  {/* <TableCell align="center">{row.milestone_status}</TableCell> */}
                  <TableCell
                    align="left"
                    onClick={() => {
                      navigate(`/schemes/milestones/${row.applicationId}`);
                    }}
                  >
                    {row.start_date}
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => {
                      navigate(`/schemes/milestones/${row.applicationId}`);
                    }}
                  >
                    {row.end_date}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <div
        className="actionSection"
        style={{ marginTop: "2rem", float: "right" }}
      >
        <div className="actions">
          <div className="title">Action: </div>
          <div className="input">
            <Select
              size="small"
              value={selectedAction}
              onChange={handleActionManager}
            >
              <MenuItem value={-1}>
                <FormLabel>Select</FormLabel>
              </MenuItem>
              <MenuItem value={1}>
                <FormLabel>Assign Accountant</FormLabel>
              </MenuItem>
            </Select>
          </div>
        </div>

        <Dialog open={actionDialogOpen} onClose={handleActionDialog}>
          <ApplicationActionModal
            handleActionDialog={handleActionDialog}
            activeAction={selectedAction}
          />
        </Dialog>
      </div> */}
    </div>
  );
};
export default ApplicationsPage;
