import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  applications: {
    title: 'Applications',
    notifications: 0,
  },
  eligibility: {
    title: 'Eligibility Check',
    notifications: 0,
  },
  review: {
    title: 'Online Review',
    notifications: 0,
  },
  birac: {
    title: 'BIRAC Selection',
    notifications: 0,
  },
  tep: {
    title: 'TEP',
    notifications: 0,
  },
  award: {
    title: 'Award',
    notifications: 0,
  },
  dueDiligence: {
    title: 'Due Diligence',
    notifications: 0,
  },
  agreementSigning: {
    title: 'Agreement',
    notifications: 0,
  },
  milestones: {
    title: 'Milestones',
    notifications: 0,
  },
}

const BigTabsApplicanteeDataSlice = createSlice({
  name: 'big_applicantee_tabs',
  initialState: { ...INITIAL_STATE },
  reducers: {
    updateBigNotification1: (state, actions) => {
      state[actions.payload.id].notifications = actions.payload.value
    },
  },
})

export const { updateBigNotification1 } = BigTabsApplicanteeDataSlice.actions

export default BigTabsApplicanteeDataSlice.reducer
