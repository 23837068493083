import {
  OutletContainer,
  SchemeTabContainer,
  SchemeTopPanel,
} from "../../../../utils/customStyledComponents";
import paths from "../../../../utils/links";
import SchemeViewToggle from "../../customComponents/SchemeViewToggle";
import { useEffect, useState } from "react";
import CustomSchemeTabs from "../../customComponents/CustomSchemeTab";
import {
  setSidebarActive,
  handleTopBarTitle,
} from "../../../../utils/helperHandler";
import { getSchemeDetails } from "../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../providers/GlobalProvider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import OwlLoader from "../../../customComponents/OwlLoader";
import {
  TitanDispatch,
  TitanSelector,
} from "../../../../providers/scheme/titan/TitanProvider";
import TitanTabView from "./components/TitanTabView";
import { applicationPageStack } from "../../../../utils/constants";
import { updateNavigation } from "../../../../reducers/navigationReducer";
import GlobalStore from "../../../../store/globalStore";
import TitanProgressView from "./TitanProgressView";
import { ViewDocContainer } from "../big/Big";
import { Icons } from "../../../../utils/mediaExports";
import ClickableLinksIntoString from "../../../customComponents/ClickableLinksIntoString";

const Titan = ({ from }) => {
  //get titan scheme id from url
  const { id, applicationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [activeView, setActiveView] = useState(0);
  const [loading, setLoading] = useState(true);
  const [call, setCall] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const { user } = globalSelector((state) => state.auth);

  const [isFounder, setFounder] = useState(false);

  const dispatch = globalDispatch();
  const titanDispatch = TitanDispatch();
  const controller = new AbortController();

  useEffect(() => {
    setCall(null);
    setLoading(true);
    setActiveView(0);
    setActiveTab(0);
    handleTopBarTitle(dispatch, paths.titan);
    setSidebarActive(paths.titan.id);

    getCallDetailsHandler();
    if (user.role.roleId == "founder") {
      setFounder(true);
    }

    document.getElementById("pageNavStackContainer").style.display = "block";
  }, [id]);

  useEffect(() => {
    let pageNavStack = JSON.parse(JSON.stringify(applicationPageStack));
    pageNavStack.scheme.block = true;
    pageNavStack.scheme.show = true;
    pageNavStack.schemeName.show = true;
    pageNavStack.schemeName.title = "titan";
    pageNavStack.schemeName.path = `/schemes/titan`;

    dispatch(
      updateNavigation({
        type: "pageNavStack",
        value: { ...pageNavStack },
      })
    );
  }, []);

  useEffect(() => {
    // const subValue = sessionStorage.getItem("active-sub-link");
    // const string = String("titan").concat(subValue.toString());
    // if (subValue) {
    //   try {
    //     for (let i = 0; i <= 4; i++) {
    //       try {
    //         document.getElementById("titan" + i).classList.remove("active");
    //       } catch (e) {}
    //     }
    //     sessionStorage.setItem("active-sub-link", subValue);
    //     document.getElementById(string).classList.toggle("active");
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
  }, [searchParams]);

  //function to get the details of the call extracting callId from the url
  const getCallDetailsHandler = async () => {
    const response = await getSchemeDetails(
      { callId: id },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      setCall(response.msg);
      handleTopBarTitle(
        dispatch,
        paths.titan,
        paths.titan.title + " - " + response.msg?.callName,
        paths.titan.link + `/${response.msg._id}`
      );

      let pageStackData = JSON.parse(
        JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
      );
      Object.keys(pageStackData).map((id, i) => {
        if (i <= 2) {
          return;
        }
        pageStackData[id].isActive = false;
        pageStackData[id].show = false;
      });
      pageStackData.callName.title = response.msg?.callName;
      pageStackData.callName.show = true;
      pageStackData.callName.isActive = true;
      pageStackData.callName.path = `/schemes/titan/${response.msg._id}`;

      dispatch(
        updateNavigation({
          type: "pageNavStack",
          value: { ...pageStackData },
        })
      );
    } else if (response) {
      alert(response.msg);
    } else {
      alert("Something went wrong! Try again");
    }
    setLoading(false);
  };

  // return <div>Titan</div>
  if (loading) {
    return <OwlLoader />;
  }

  if (!call) {
    return <OwlLoader />;
  }

  if (!user) {
    return <div>User not found!</div>;
  }

  if (call._id != id) {
    return <></>;
  }

  return (
    <OutletContainer>
      {/* <PageNavigation scheme={call?.scheme?.abbr} /> */}
      {applicationId ? (
        <></>
      ) : activeTab == 0 || !isFounder ? (
        <SchemeTopPanel>
          <div className="leftPanel">
            <div className="title">About</div>
            <div className="content">
              <pre className="contentAbout">
                <ClickableLinksIntoString
                  textWithLinks={call?.scheme?.description}
                />
              </pre>
            </div>
            {/* <pre className="contentAbout">To know more about the program and eligibility criteria:  */}
            {/* <a target="_blank" href="https://ikpeden.com/nidhi-titan/">https://ikpeden.com/nidhi-titan/</a> */}
            {/* </pre> */}
          </div>

          {user.role.roleId == "program_manager" ||
          user.role.roleId == "super_admin" ? (
            <div className="rightPanel">
              <SchemeViewToggle active={activeView} setActive={setActiveView} />
            </div>
          ) : (
            <></>
          )}
        </SchemeTopPanel>
      ) : (
        <></>
      )}

      {/* {applicationId || isFounder ? <ViewDocument /> : null} */}

      <SchemeTabContainer>
        {activeView == 0 ? (
          <>
            <CustomSchemeTabs
              active={activeTab}
              setActive={setActiveTab}
              isFounder={isFounder}
              afterApplicationSelect={false}
              from={from}
              scheme={"titan"}
            />
            <TitanTabView
              from={from}
              active={activeTab}
              call={call}
              setActive={setActiveTab}
            />
          </>
        ) : (
          <>
            <TitanProgressView setActiveView={setActiveView} />
          </>
        )}
      </SchemeTabContainer>
    </OutletContainer>
  );
};

const ViewDocument = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { application } = TitanSelector((state) => state.application);

  return (
    <ViewDocContainer
      onClick={() =>
        navigate(`/documents/${params?.applicationId || application?._id}`)
      }
    >
      <div className="text">View Documents</div>
      <div className="icon">{Icons.download}</div>
    </ViewDocContainer>
  );
};

export default Titan;
