import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import styledComponent from "styled-components";
import CustomNoRowsOverlay from "./CustomNoRowSvg";
import OwlLoader from "./OwlLoader";

const DataTable = ({
  columns,
  tableData,
  totalRows,
  setPageNumber,
  setPageLimit,
  pageNumber,
  pageLoading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    setPageLimit(rowsPerPage);
    setPageNumber(page);
  }, [page, rowsPerPage]);

  return (
    <TableWrapper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(columns).map((heading) => {
                return (
                  <TableCell
                    sx={{ width: columns[heading]?.width }}
                    key={heading}
                  >
                    {columns[heading].text}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {pageLoading ? (
            <WrapperDiv>
              <OwlLoader />
            </WrapperDiv>
          ) : tableData && tableData?.length > 0 ? (
            <TableBody style={{ height: "400px", overflow: "auto" }}>
              {tableData?.map((ele, index) => (
                <TableRow className="companyRow" key={index}>
                  <TableCell align="center">{ele?.companyName}</TableCell>
                  <TableCell>{ele?.location}</TableCell>
                  <TableCell>{ele?.phoneNumber}</TableCell>
                  <TableCell>{ele?.emailID}</TableCell>
                  <TableCell>{ele?.founders}</TableCell>
                  <TableCell>{ele?.companyDescription}</TableCell>
                  <TableCell>{ele?.productDescription}</TableCell>
                  <TableCell>{ele?.program}</TableCell>
                  <TableCell>{ele?.domain}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <WrapperDiv>
              <CustomNoRowsOverlay />
            </WrapperDiv>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={columns?.length}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                count={totalRows || 0}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default DataTable;

const TableWrapper = styledComponent.div`
    margin-top: 1rem;
    .chip {
      margin: 0.2rem 0.2rem 0.2rem 0rem;
    }
  
    .chipActive {
      background: var(--hoverColor);
    }
  
 
  `;
const WrapperDiv = styledComponent.div`
  height: 100%;
  width: 100%;
  margin: 20px;
  position: relative;
  margin-left: 300%;
`;
