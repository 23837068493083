import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { useEffect, useRef, useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  Radio,
  Box,
  Tab,
  Button,
  FormHelperText,
  FormLabel,
  Alert,
  Dialog,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";

import { useParams } from "react-router-dom";
import {
  camelCaseWithoutSpaceConverter,
  camelCaseWithSpaceConverter,
  handleTopBarTitle,
} from "../../../utils/helperHandler";
const { postRequest, getRequest } = require("../../../utils/axiosClient/axios");
import styled from "styled-components";
import { Icons } from "../../../utils/mediaExports";
import {
  globalDispatch,
  globalSelector,
} from "../../../providers/GlobalProvider";
import {
  getApplicationDataInvestment,
  saveInvestementData,
  updateInvestmentStatus,
  uploadInvestmentFile,
} from "../../../data/network/api/investment";
import { useSnackbar } from "notistack";
import OwlLoader from "../../customComponents/OwlLoader";
import {
  ApplicaitonStatusColor,
  ApplicationStatus,
  ApplicationStatusTextColor,
} from "../../../utils/constants";
import paths from "../../../utils/links";
import { updateDocument } from "../../../reducers/DocumentReducer";
import CustomButton from "../../customComponents/CustomButton";
import ActionDialog from "../../../components/schemes/ikp/big/phases/applicant/applicationAction/ActionModal.jsx";

//use try catch block
//add alerts

const PERMS = {
  superAdmin: ["view", "write", "submit"],
  founder: ["view", "upload", "submit"],
  program_manager: ["view", "write", "submit"],
  test: ["view", "upload", "write", "submit"],
};

const ViewAccess = [
  "founder",
  "program_manager",
  "super_admin",
  "program_associate",
];
const DocumentEditAcess = ["founder"];
const CommentEditAccess = [
  "program_manager",
  "super_admin",
  "program_associate",
];
const StatusChangeAccess = ["program_manager", "super_admin"];

const PHASES = [
  "preInvestment",
  "investment",
  "postInvestment",
  "exitInvestment",
];

//every field has a entity or active tab , rootFieldElement, parenFieldElement, childFieldElement

const inputTextBox = (e, column) => {
  if (e.key.length == 1 || e.keyCode == 8) {
    if (column.value.length == 0 && e.keyCode == 8) {
      column.value = "";
      e.target.value = "";
    } else if (e.keyCode == 8) {
      let str = column.value.split("").splice(0, column.value.length - 1);
      column.value = str.join("");
    } else {
      column.value += e.key;
    }
  }
};

const FormBuilder = ({ info }) => {
  const { user } = globalSelector((state) => state.auth);
  const role = user.role.roleId;

  if (!ViewAccess.includes(role)) {
    return <h1>You have no access to this page!</h1>;
  }

  const { id } = useParams();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState({ loading: true, error: "loading" });
  const uploadButton = useRef(null);
  const [permissions, setPermissions] = useState([]);
  const [activeTab, setActiveTab] = useState("preInvestment");
  const [projectState, setProjectState] = useState({
    error: null,
    success: null,
  });
  const [application, setApplication] = useState(null);

  const handleDocument = (url) => {
    dispatch(
      updateDocument({
        url: url?.value,
        open: true,
      })
    );
  };

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  const BuildFileUploader = ({
    options,
    error,
    helperText,
    view,
    rootFieldName,
    parentFieldName,
    parentEL,
  }) => {
    let file = fields[activeTab][rootFieldName][parentFieldName].document;

    let url = file.value.length > 1 ? file : false;

    return (
      <FileComponent>
        <FormLabel>{options.title}</FormLabel>

        {!url ? (
          // <Button
          //   component="label"
          //   variant="contained"
          //   style={{ width: "100%" }}
          //   disableRipple={url.fileURL}
          //   className="uploadFile"
          //   disabled={!DocumentEditAcess.includes(role)}
          // >
          <>
            {Icons.uploadFile}&nbsp;No File Chosen
            <input
              type={"file"}
              value=""
              onChange={(e) =>
                getFileURL(e, rootFieldName, parentFieldName, parentEL)
              }
              // accept="*/*"
              // key={rootFieldName}
            />
          </>
        ) : (
          // </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>
              <div className="url" onClick={() => handleDocument(url)}>
                {url.fileName}
              </div>
            </span>
            {DocumentEditAcess.includes(role) ? (
              <span
                onClick={(e) => {
                  removeFileUploaded(e, rootFieldName, parentFieldName);
                  e.preventDefault();
                }}
                className="delete"
              >
                {Icons.delete}
              </span>
            ) : (
              <></>
            )}
          </div>
        )}

        <FormHelperText
          style={error ? { color: "var(--errorTextColor)" } : null}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;{helperText ? helperText : <>&nbsp;</>}
        </FormHelperText>
      </FileComponent>
    );
  };

  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(-1);
  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
  };

  const getFileURL = async (e, rootFieldName, parentFieldName) => {
    console.log("fired");
    try {
      const file = e.target.files && e.target.files[0];
      if (!file) return;

      // e.target.value = null;

      let formData = new FormData();
      formData.append("applicationId", application._id);
      formData.append("fieldName", parentFieldName);
      formData.append("file", file);

      let url = await uploadInvestmentFile({
        data: formData,
        dispatch: dispatch,
        controller: controller,
      });

      if (url && url.status === 200) {
        fields[activeTab][rootFieldName][parentFieldName].document = {
          fileName: file.name,
          value: url.msg,
          parentFieldName: parentFieldName,
          rootFieldName: rootFieldName,
          inputType: "file",
          title: "Upload File",
        };
        fields[activeTab][rootFieldName][parentFieldName].dateOfUpload.value =
          new Date().toDateString();
        setFields({ ...fields });
        setProjectState({
          error: null,
          success: "Got file url now upload to save the file",
        });
      } else {
        snackbarHandler(
          url?.msg?.msg || url?.msg || "Failed to upload file!",
          "error"
        );
        setProjectState({ error: url.msg, success: null });
      }
    } catch (e) {
      console.log(e);
      setProjectState({ error: "Some error occured", success: null });
    }
  };

  const handlePhase = async (e) => {
    const index = PHASES.findIndex((el) => el === application.status);
    let status;
    if (index >= 0 && PHASES[index + 1]) {
      status = PHASES[index + 1];
      setFields({ ...fields, currentStatus: status });
    }

    if (!status) return;

    try {
      const response = await updateInvestmentStatus({
        data: {
          status: status,
          applicationId: application._id,
          applicationVersion: application?.__v,
          applicationDataVersion: fields?.__v,
        },
        controller,
        dispatch,
      });
      if (response && response.status == 200) {
        snackbarHandler(
          response?.msg?.msg || response?.msg || "Application status updated",
          "success"
        );
        setProjectState({ success: "Phase Changed", error: null });
        setApplication(response?.msg?.application);
        setActiveTab(response?.msg?.application?.status);
      } else {
        snackbarHandler(
          response?.msg?.msg || response?.msg || "Something went wrong!",
          "error"
        );
      }
    } catch (e) {
      setProjectState({ success: null, error: "Some error occred" });
      console.log(e);
    }
  };

  const dataChange = (e, rootFieldName, parentFieldName) => {
    if (!e.target.name || !e.target.value) return;

    let parentName = camelCaseWithoutSpaceConverter(e.target.name);
    if (fields[activeTab][rootFieldName][parentFieldName][parentName]) {
      if (parentName === "verifiedByIKP") {
        fields[activeTab][rootFieldName][parentFieldName][
          "dateOfVerification"
        ].value = new Date().toDateString();
        fields[activeTab][rootFieldName][parentFieldName][
          parentName
        ].parentFieldName = parentFieldName;
        fields[activeTab][rootFieldName][parentFieldName][
          parentName
        ].rootFieldName = rootFieldName;
        setFields({ ...fields });
      }
      fields[activeTab][rootFieldName][parentFieldName][parentName].value =
        e.target.value;
      fields[activeTab][rootFieldName][parentFieldName][
        parentName
      ].parentFieldName = parentFieldName;
      fields[activeTab][rootFieldName][parentFieldName][
        parentName
      ].rootFieldName = rootFieldName;
      setFields({ ...fields });
    }
  };

  const removeFileUploaded = (e, rootFieldName, parentFieldName) => {
    if (
      fields[activeTab][rootFieldName][parentFieldName].document &&
      fields[activeTab][rootFieldName][parentFieldName].document.value.length >
        1
    ) {
      fields[activeTab][rootFieldName][parentFieldName].document.value = "";
      fields[activeTab][rootFieldName][parentFieldName].document.fileName =
        null;
      //do this from loop
      fields[activeTab][rootFieldName][parentFieldName].dateOfUpload.value = "";
      fields[activeTab][rootFieldName][
        parentFieldName
      ].dateOfVerification.value = "";
      fields[activeTab][rootFieldName][parentFieldName].verifiedByIKP.value =
        "";
      fields[activeTab][rootFieldName][
        parentFieldName
      ].verifiedByReviewer.value = "";
      fields[activeTab][rootFieldName][
        parentFieldName
      ].commentsByReviewer.value = "";
      fields[activeTab][rootFieldName][parentFieldName].commentsByIKP.value =
        "";
      setFields({ ...fields });
    } else {
      console.log("error occured in fields format");
    }
  };

  const uploadData = async () => {
    try {
      const response = await saveInvestementData({
        data: {
          obj: fields,
          activeTab: activeTab,
          applicationId: id,
          applicationVersion: application?.__v,
          applicationDataVersion: fields?.__v,
        },
        controller,
        dispatch,
      });

      if (response && response.status == 200) {
        setProjectState({ success: "Data Uploaded", error: null });
        snackbarHandler("Data Submitted", "success");
        setApplication(response?.msg?.application);
        setFields(response?.msg?.applicationData);
      } else {
        setProjectState({ success: null, error: response.msg });
        snackbarHandler(
          response?.msg?.msg || response?.msg || "Something went wrong!",
          "error"
        );
      }
    } catch (e) {
      console.log(e);
      setProjectState({ success: null, error: "Some error occured" });
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const controller = new AbortController();
  const dispatch = globalDispatch();

  let getApplicationInfo = async () => {
    let response = await getApplicationDataInvestment({
      data: { applicationId: id },
      dispatch: dispatch,
      controller: controller,
    });
    if (response && response.status == 200) {
      setFields(response?.msg?.applicationData);
      setApplication(response?.msg?.application);
    } else {
      snackbarHandler(
        response?.msg?.msg || response?.msg || "Something went wrong!",
        "error"
      );
    }
    setLoading({ loading: false, error: null });
  };

  useEffect(() => {
    setTimeout(() => {
      setProjectState({ error: null, success: null });
    }, 9000);
  }, [projectState]);

  useEffect(() => {
    if (user.role.roleId === "founder" && activeTab != "preInvestment") {
      if (!permissions.includes("write")) {
        setPermissions([...permissions, "write"]);
      }
    } else {
      const index = permissions.findIndex((el) => el === "write");
      console.log(index);
      if (index >= 0) {
        setPermissions((prev) => prev.filter((el) => el != "write"));
      }
    }

    if (
      user.role.roleId === "program_manager" &&
      activeTab != "preInvestment"
    ) {
      const index = permissions.findIndex((el) => el == "write");
      if (index >= 0) {
        setPermissions((prev) => prev.filter((el) => el != "write"));
      }
    } else {
      if (!permissions.includes("write")) {
        setPermissions([...permissions, "write"]);
      }
    }

    if (user.role.roleId === "super_admin") {
      setPermissions(PERMS.superAdmin);
    }
  }, [activeTab]);

  useEffect(() => {
    handleTopBarTitle(dispatch, paths.investment);
    getApplicationInfo();

    if (user.role.roleId === "founder") {
      setPermissions(PERMS.founder);
    } else if (user.role.roleId === "program_manager") {
      setPermissions(PERMS.program_manager);
    } else {
      setPermissions(PERMS.founder);
    }
  }, []);

  if (loading?.loading) {
    return <OwlLoader />;
  }

  return (
    <FileComponent>
      <TableContainer className="TableContainer">
        <div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              onChange={(e) => {
                console.log(e.target.id);
                setActiveTab(e.target.id);
              }}
              aria-label="basic tabs example"
            >
              {/* <Tab label="Title Of Sheet" value="titleOfSheet" id="titleOfSheet" /> */}
              <Tab
                label="Pre Investment"
                value="preInvestment"
                id="preInvestment"
              />
              <Tab label="Investment" value="investment" id="investment" />
              <Tab
                label="Post Investment"
                value="postInvestment"
                id="postInvestment"
              />
              {application?.status === "exitInvestment" ? (
                <Tab
                  label="Exit Investment"
                  value="exitInvestment"
                  id="exitInvestment"
                />
              ) : null}
            </Tabs>
          </Box>
        </div>
        <div className="applicationStatus">
          <span>
            Current Status:{" "}
            <span
              className="status"
              style={{ color: ApplicationStatusTextColor[application?.status] }}
            >
              {ApplicationStatus[application?.status]}
            </span>
          </span>
        </div>
        {Object.keys(fields[activeTab]).map((key, i) => {
          let charCode = String.fromCharCode(i + 65);
          return (
            <>
              {activeTab === "preInvestment" ? (
                <div className="tableHeading">
                  <h3
                    className="tableHeading"
                    colSpan={2}
                    width="100%"
                    align="center"
                    sx={{
                      borderBottom: "none",
                      fontSize: "1.31rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {camelCaseWithSpaceConverter(key)}
                  </h3>
                </div>
              ) : null}
              <Table sx={{ padding: "20px", minWidth: "650px" }}>
                <TableHead>
                  {activeTab == "preInvestment" ? (
                    <TableRow>
                      <TableCell align="left">Sl No.</TableCell>
                      <TableCell align="center" width="15%">
                        Certificates
                      </TableCell>
                      <TableCell align="center" width="15%">
                        Uploaded Document
                      </TableCell>
                      <TableCell align="center" width="8%">
                        Date Of Upload
                      </TableCell>
                      <TableCell align="center" width="8%">
                        Verified
                      </TableCell>
                      <TableCell align="center" width="8%">
                        Date Of Verification
                      </TableCell>
                      <TableCell align="center">Comments By IKP</TableCell>
                      <TableCell align="center" width="8%">
                        Verified by Reviewer
                      </TableCell>
                      <TableCell align="center">Comments By Reviewer</TableCell>
                    </TableRow>
                  ) : activeTab === "titleOfSheet" ? (
                    <TableRow>
                      <TableCell align="left" width="8%">
                        Sl No.
                      </TableCell>
                      <TableCell align="center" width="25%">
                        Certificates
                      </TableCell>
                      <TableCell align="center" width="100%">
                        Value
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell align="left" width="8%">
                        Sl No.
                      </TableCell>
                      <TableCell align="center" width="25%">
                        Certificates
                      </TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Remark</TableCell>
                    </TableRow>
                  )}
                </TableHead>

                <TableBody>
                  {Object.keys(fields[activeTab][key]).map((parentEL, j) => {
                    let disabledRadio = true;
                    return (
                      <TableRow>
                        <TableCell align="left" size="small">
                          {key != "investment" &&
                          key != "postInvestment" &&
                          key != "exitInvestment"
                            ? charCode + "." + (j + 1)
                            : j + 1}
                        </TableCell>
                        {/* <TableCell align='center'>{camelCaseWithSpaceConverter(parentEL)}</TableCell> */}
                        {Object.keys(fields[activeTab][key][parentEL]).map(
                          (childEL, m) => {
                            let column =
                              fields[activeTab][key][parentEL][childEL];
                            if (
                              column.inputType === "file" &&
                              column.value &&
                              column.value.length > 1
                            ) {
                              disabledRadio = false;
                            }
                            return (
                              <TableCell align="center">
                                {column.inputType === "title" ? (
                                  <span>{column.title}</span>
                                ) : column.inputType === "file" ? (
                                  column.value && column.value.length > 1 ? (
                                    <>
                                      <BuildFileUploader
                                        file={column.file}
                                        options={{ title: "" }}
                                        view={false}
                                        rootFieldName={key}
                                        parentFieldName={parentEL}
                                        key={column.title}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        component="label"
                                        variant="contained"
                                        style={{ width: "100%" }}
                                        // disableRipple={url.fileURL}
                                        className="uploadFile"
                                        disabled={
                                          !DocumentEditAcess.includes(role)
                                        }
                                      >
                                        <>
                                          {Icons.uploadFile}&nbsp;No File Chosen
                                          <input
                                            type={"file"}
                                            onChange={(e) =>
                                              getFileURL(
                                                e,
                                                key,
                                                parentEL
                                              )
                                            }
                                            hidden
                                            // accept="*/*"
                                            // key={rootFieldName}
                                          />
                                        </>
                                       
                                      </Button>
                                    </>
                                  )
                                ) : column.inputType === "textbox" ? (
                                  <textarea
                                    id={key}
                                    onKeyDown={(e) => inputTextBox(e, column)}
                                    name={column.title}
                                    onChange={(e) =>
                                      dataChange(e, key, parentEL)
                                    }
                                    disabled={!CommentEditAccess.includes(role)}
                                    style={{
                                      resize: "none",
                                      height: !column.height
                                        ? "100px"
                                        : column.height,
                                      width:
                                        activeTab != "preInvestment"
                                          ? "300px"
                                          : "100%",
                                      padding: "0.2rem 0.4rem",
                                    }}
                                    value={column.value}
                                  >
                                    {" "}
                                  </textarea>
                                ) : column.inputType === "checkbox" &&
                                  CommentEditAccess.includes(role) ? (
                                  <RadioGroup
                                    onChange={(e) =>
                                      dataChange(e, key, parentEL)
                                    }
                                    defaultValue={
                                      column.value.length > 1
                                        ? column.value
                                        : "notApplicable"
                                    }
                                  >
                                    <FormControlLabel
                                      disabled={disabledRadio}
                                      name={column.title}
                                      value="approved"
                                      control={<Radio id={key} />}
                                      label="Approved"
                                    />
                                    <FormControlLabel
                                      disabled={disabledRadio}
                                      name={column.title}
                                      value="rejected"
                                      control={<Radio id={key} />}
                                      label="Rejected"
                                    />
                                    <FormControlLabel
                                      disabled={disabledRadio}
                                      name={column.title}
                                      value="notApplicable"
                                      control={<Radio id={key} />}
                                      label="N/A"
                                    />
                                  </RadioGroup>
                                ) : column.inputType === "checkbox" &&
                                  column.value === "approved" ? (
                                  <TiTick color="green" size={30 + "px"} />
                                ) : column.inputType === "checkbox" &&
                                  column.value === "rejected" ? (
                                  <ImCross color="red" size={20 + "px"} />
                                ) : (column.inputType === "checkbox" &&
                                    column.value === "") ||
                                  column.value === "notApplicable" ? (
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "black",
                                    }}
                                  >
                                    N/A
                                  </span>
                                ) : column.inputType === "date" ? (
                                  <span>
                                    {column.value.length <= 0
                                      ? "-"
                                      : new Date(column.value).getDate() +
                                        "/" +
                                        (new Date(column.value).getMonth() +
                                          1) +
                                        "/" +
                                        new Date(column.value).getFullYear()}
                                  </span>
                                ) : column.inputType == "text" ? (
                                  <span>
                                    {column.value.length > 0
                                      ? column.value
                                      : "-"}
                                  </span>
                                ) : column.inputType === "dropbox" ? (
                                  <FormControl
                                    sx={{
                                      width: "320px",
                                      minWidth: "220px",
                                      border: "none",
                                      outline: "none",
                                    }}
                                    variant="outlined"
                                    disabled={!DocumentEditAcess.includes(role)}
                                  >
                                    {/* <InputLabel id={key}>{ camelCaseWithSpaceConverter(parentEL) }</InputLabel> */}
                                    <Select
                                      labelId="demo-simple-select-label"
                                      notched
                                      // label={parentEL}
                                      id={key}
                                      variant="outlined"
                                      size="small"
                                      value={column.value}
                                      onChange={(e) =>
                                        dataChange(e, key, parentEL)
                                      }
                                      name={column.title}
                                      // labelWidth={150}
                                    >
                                      {column.values.map((el, i) => {
                                        return (
                                          <MenuItem
                                            key={i}
                                            name={column.title}
                                            value={el}
                                          >
                                            {el}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                ) : null}
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </>
          );
        })}
        {application?.status == activeTab && ViewAccess.includes(role) ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton onClickHandler={uploadData} title={"Upload"} />
          </div>
        ) : (
          <></>
        )}

        {StatusChangeAccess.includes(role) &&
        application?.status == activeTab &&
        application?.status != "exitInvestment" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <CustomButton
              title="Move to next phase"
              onClickHandler={handlePhase}
            />
          </div>
        ) : (
          <></>
        )}

        {CommentEditAccess.includes(role) ? (
          <div className="actions" style={{ float: "right" }}>
            <div className="title">Action: </div>
            <div className="input">
              <Select
                size="small"
                value={selectedAction}
                onChange={handleActionManager}
              >
                <MenuItem value={-1}>
                  <FormLabel>Select</FormLabel>
                </MenuItem>
                <MenuItem value={"assign"}>
                  <FormLabel>Assign Reviewer</FormLabel>
                </MenuItem>
              </Select>
            </div>
          </div>
        ) : (
          <></>
        )}
      </TableContainer>

      <Dialog open={actionDialogOpen} onClose={handleActionDialog}>
        <ActionDialog
          handleActionDialog={handleActionDialog}
          activeAction={selectedAction}
          application={application}
          getApplicationDataHandlerForManager={getApplicationInfo}
          scheme="investment"
        />
      </Dialog>
    </FileComponent>
  );
};

export default FormBuilder;

const FileComponent = styled.div`
  .fileUpload {
    padding: 0.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: #01d2b2;
    border-radius: 5px;
    border: none;
    outline: none;
    display: inline-block;
    min-width: 100px;
    margin: 0.7rem;
  }
  table {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  .viewFile {
    background-color: grey;
  }
  .uploadBtn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }
  .uploadFile {
    margin-top: 1.5rem !important;
    padding: 0.5rem !important;
  }
  .tableHeading {
    text-align: center;
    width: 100%;
    padding-top: 1rem;
  }
  .tableHeading h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
  }
  .applicationStatus {
    float: right;
    padding: 0.6rem;
    padding-top: 1.2rem;
  }
  .applicationStatus .status {
    background-color: #01d2b2;
    text-transform: uppercase;
  }
  .delete {
    display: flex;
    align-items: center;
    transition: 0.3s color;
    &:hover {
      color: red;
    }
  }
`;
