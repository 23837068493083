import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  applications: [],
}

const TitanReviewApplicationsSlice = createSlice({
  name: 'titan_review_applications',
  initialState: { ...INITIAL_STATE },
  reducers: {
    updateTitanReviewApplications: (state, actions) => {
      state[actions.payload.id] = actions.payload.value
    },
  },
})

export const { updateTitanReviewApplications } =
  TitanReviewApplicationsSlice.actions

export default TitanReviewApplicationsSlice.reducer
