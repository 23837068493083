import styled from "styled-components";
import { Icons } from "../../utils/mediaExports";
import { CircularProgress, FormHelperText } from "@mui/material";
import { globalDispatch } from "../../providers/GlobalProvider";
import { updateDocument } from "../../reducers/DocumentReducer";

const PassportPhotoUpload = ({
  handleFile,
  options,
  value,
  deleteHandler,
  loading,
  error,
  helperText,
  disabled
}) => {
  const dispatch = globalDispatch();

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <div className="image">
        {value?.length > 0 ? (
          <div className="imageContainer">
            <img src={value[0]?.url} alt={value[0]?.name} onClick={() => dispatch(
                  updateDocument({
                    open: true,
                    url: value[0]?.url,
                  })
                )} />
            {
              !disabled ? <div
              className="closeIcon"
              onClick={() => deleteHandler(options.category, options.id)}
            >
              Delete
            </div> : null
            }
          </div>
        ) : (
          <label className="input">
            <input
              type={"file"}
              onChange={(e) =>
                handleFile(options.category, options.id, e.target.files)
              }
              hidden
            />
            {Icons.plus}
          </label>
        )}
      </div>
      <FormHelperText style={error ? { color: "var(--errorTextColor)" } : null}>
        &nbsp;&nbsp;&nbsp;&nbsp;{helperText ? helperText : <>&nbsp;</>}
      </FormHelperText>
    </Container>
  );
};

export default PassportPhotoUpload;

const Container = styled.div`
  .image {
    border: 1px solid var(--text3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: dashed;
    width: 150px;
    height: 150px;
    position: relative;
  }

  .input {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;

    svg {
      font-size: 5rem;
      color: var(--text3);
    }
  }

  .imageContainer {
    width: 150px;
    height: 150px;
    overflow: hidden;

    img {
      z-index: 4;
      width: 100%;
      height: 100%;
      overflow: hidden;
      cursor: pointer;
    }

    .closeIcon {
      z-index: 5;
      position: absolute;
      bottom: 0px;
      display: flex;
      justify-content: center;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
      padding: 5px;
      color: #e74c3c;
      cursor: pointer;
      transition: 0.2s background;

      svg {
        font-size: 25px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
`;
