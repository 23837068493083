import { Alert, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getUser, signInUser } from "../../data/network/api/user";
import { updateAuth } from "../../reducers/auth/authReducer";
import { Validator } from "../../utils/helperHandler";
import paths from "../../utils/links";
import { Gif, Image, Logo } from "../../utils/mediaExports";
import LoadingScreen from "../../components/customComponents/LoadingScreen";
import CustomLoader from "../../components/customComponents/CustomLoader";
import { globalDispatch } from "../../providers/GlobalProvider";
import { BASE_URL } from "../../utils/axiosClient/axios";

const initialState = {
	email: "",
	password: "",
};

//try login using token if present
export const autoLoginAttempt = async (dispatch, navigate) => {
	const token = localStorage.getItem("access-token");
	if (!token) return;

	const response = await getUser(token, dispatch);
	if (response.status == 200) {
		dispatch(
			updateAuth({
				isAuthenticated: true,
			})
		);
		navigate(paths.home.path);
	}
};

const LoginPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [pageLoading, setPageLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		sessionStorage.removeItem("active-link");
		const token = searchParams.get("token");
		if (token) {
			localStorage.setItem("access-token", token);
			navigate(paths.home.path);
		}
		setPageLoading(false);
	}, []);

	if (pageLoading) {
		return <LoadingScreen />;
	}

	return (
		<AuthContainer>
			<AuthPanel className="leftPanel">
				<img className="loginBg" src={Image.loginBg} alt="LoginBg" />
				<img
					className="pageLogo"
					src={Logo.athenaLogo}
					onClick={() => navigate("/")}
				/>
				<div className="content">
					<div className="logo">
						<img src={Logo.ikpLogo} />
					</div>

					<div className="textwriter">
						<div className="row">
							<div className="text text1">IKP Presents</div>
						</div>
						<div className="row">
							<div className="logo">
								<img src={Gif.flying} />
							</div>
							<div className="text text2">Athena</div>
						</div>
					</div>
				</div>
			</AuthPanel>

			<LoginPanel />
		</AuthContainer>
	);
};

export default LoginPage;

export const LoginPanel = ({
	fromScheme = null,
	callId = null,
	regPath = null,
	heading = null,
}) => {
	const [user, setUser] = useState(null);
	const dispatch = globalDispatch();
	const controller = new AbortController();
	const navigate = useNavigate();
	const [data, setData] = useState({ ...initialState });
	const [error, setError] = useState({ ...initialState });
	const [serverResponse, setServerResponse] = useState(null);
	const [loading, setLoading] = useState(false);

	const updateFields = (e) => {
		setData({ ...data, [e.target.id]: e.target.value });
	};

	const handleSignIn = async () => {
		let error = { ...initialState };
		setServerResponse(null);
		let isValid = true;
		if (data.email == "") {
			isValid = false;
			error.email = "Email is required!";
		}
		if (data.password == "") {
			isValid = false;
			error.password = "Password is required!";
		}
		if (data.email != "" && !Validator.validateEmail(data.email)) {
			isValid = false;
			error.email = "Email is invalid!";
		}
		setError(error);
		if (!isValid) return;
		setLoading(true);
		const response = await signInUser(data, controller);
		console.log(response);
		if (response.status != 200) {
			setServerResponse({
				type: "error",
				msg: response?.msg || "Something went wrong!",
			});
		} else {
			const token = response.msg;
			localStorage.setItem("access-token", token);
			if (!fromScheme) {
				navigate(paths.home.path);
			} else {
				navigate("/schemes/prayas/" + callId);
			}
		}
		setLoading(false);
	};

	const handleSocialLogin = (source) => {
		window.location.assign(`${BASE_URL}/auth/${source}`);
	};

	return (
		<AuthPanel
			className="rightPanel"
			style={loading ? { pointerEvents: "none" } : null}
		>
			<div className="content">
				<div className="loginForm">
					<div className="title">
						{heading ? heading : "Sign In To IKP Athena"}
					</div>

					<div className="fields">
						<div className="field">
							<div className="label">Email</div>
							<TextField
								id="email"
								fullWidth
								size="small"
								placeholder="Email"
								onChange={updateFields}
								value={data.email}
								error={!!error.email}
								helperText={!!error.email ? error.email : " "}
							/>
						</div>

						<div className="field">
							<div className="label">Password</div>
							<TextField
								fullWidth
								size="small"
								placeholder="Password"
								id="password"
								type={"password"}
								onChange={updateFields}
								value={data.password}
								error={!!error.password}
								helperText={!!error.password ? error.password : " "}
							/>
						</div>

						<div className="field" style={{ marginTop: "-1.3rem" }}>
							{/* <div className="forgotPassword">Forgot Password?</div> */}
						</div>

						<div className="field mt2">
							<>
								{loading ? (
									<CustomLoader />
								) : (
									<Button
										className="button"
										disableElevation
										variant="contained"
										fullWidth
										onClick={handleSignIn}
									>
										Login
									</Button>
								)}
							</>

							{serverResponse ? (
								<div className="mt1">
									<Alert color={serverResponse.type}>
										{serverResponse.msg}
									</Alert>
								</div>
							) : null}
						</div>

						{fromScheme ? null : (
							<div className="field mt2 center">
								{/* <div className="mediumFont">Or Use</div> */}
								<div className="signInfo">
									(For IKP Knowledgepark Employees)
								</div>
								<div className="socialLinks">
									<img
										className="signInSocialImg"
										id="googleSignIn"
										src={Image.google}
										onClick={() => handleSocialLogin("google")}
									/>
									{/* <img
                  className="signInSocialImg"
                  style={{ cursor: "not-allowed" }}
                  src={Image.facebook}
                  onClick={() => handleSocialLogin("facebook")}
                />
                <img
                  className="signInSocialImg"
                  style={{ cursor: "not-allowed" }}
                  src={Image.linkedin}
                  onClick={() => handleSocialLogin("linkedin")}
                /> */}
								</div>
							</div>
						)}

						<div className="field mt2">
							<Button
								className="button colorprimary"
								disableElevation
								variant="outlined"
								fullWidth
								onClick={() =>
									navigate(fromScheme ? regPath : paths.signUp.path)
								}
							>
								New Here? Signup now
							</Button>
						</div>
					</div>
				</div>
				<div id="signUpDiv"></div>
			</div>
		</AuthPanel>
	);
};

export const AuthContainer = styled.div`
	min-height: 100vh;
	width: 100vw;
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr;

	.leftPanel {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.pageLogo {
			position: absolute;
			top: 10px;
			left: 10px;
			width: 8vw;
			cursor: pointer;

			img {
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			gap: 5rem;

			.logo {
				width: 100%;
				display: flex;
				align-items: center;

				img {
					width: 25vw;
				}
			}
		}

		.textwriter {
			display: flex;
			flex-direction: column;

			gap: 1rem;
			justify-content: center;
			align-items: center;

			.row {
				display: flex;
				gap: 0.5rem;
				height: max-content;
				align-items: center;

				.logo {
					width: max-content;
					display: flex;
					align-items: center;
					img {
						width: 5vw;
					}
				}

				.text {
					height: max-content;
					font-size: 3vw;
					display: flex;
					align-items: center;
					color: #fff;
					height: 5vh;
					overflow: hidden; /* Ensures the content is not revealed until the animation */
					// border-right: 2px solid var(--primary); /* The typwriter cursor */
					white-space: nowrap; /* Keeps the content on a single line */
					margin: 0 auto; /* Gives that scrolling effect as the typing happens */
					letter-spacing: 0.15em; /* Adjust as needed */

					@keyframes typing {
						from {
							width: 0;
						}
						to {
							width: 100%;
						}
					}

					@keyframes blink-caret {
						from,
						to {
							border-color: transparent;
						}
						50% {
							border-color: var(--primary);
						}
					}
				}

				.text1 {
					// animation: typing 2s steps(40, end),
					// blink-caret 0.75s step-end infinite;
				}

				.text2 {
					// animation: typing 2s steps(40, end),
					// blink-caret 0.75s step-end infinite;
				}
			}
		}
	}

	.rightPanel {
		margin: 0 5rem;

		.topBar {
			width: 100%;
			.links {
				display: flex;
				justify-content: flex-end;
				flex-wrap: wrap;
				gap: 2rem;
				color: var(--text2);
				font-size: 0.9rem;

				span {
					cursor: not-allowed;
					transition: 0.3s color;

					&:hover {
						color: var(--primary);
					}
				}
			}
		}

		.loginForm {
			margin-top: 3rem;
			min-width: 25rem;

			.title {
				font-size: 2.5rem;
				font-weight: bold;
				color: rgba(0, 0, 0, 0.85);
			}

			.fields {
				margin-top: 3rem;
				display: flex;
				flex-direction: column;

				.field {
					display: flex;
					flex-direction: column;
					z-index: 0;

					.signInfo {
						color: var(--text3);
						letter-spacing: 2px;
						font-size: 12px;
					}

					.label {
						color: var(--link-active);
						font-weight: 600;
						margin-bottom: 0.2rem;
					}

					.forgotPassword {
						margin-top: 0.2rem;
						font-size: 0.9rem;
						color: var(--primary);
						display: flex;
						justify-content: flex-end;
						cursor: not-allowed;
						transition: 0.2s color;

						&:hover {
							color: var(--text2);
						}
					}

					.socialLinks {
						display: flex;
						gap: 2rem;

						.signInSocialImg {
							width: 1rem;
							background: #fff;
							padding: 1rem;
							cursor: pointer;
							box-shadow: 2px 8px 32px rgb(1 206 175 / 32%);
							transition: 0.1s;
							border-radius: 50%;

							&:hover {
								box-shadow: 0px 2px 3px rgb(1 206 175 / 32%);
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 1200px) {
		grid-template-columns: 1fr;

		.leftPanel {
			display: none;
		}

		.rightPanel {
			margin: 0;

			.content {
				margin: 1rem auto;
				padding: 2rem;

				.topBar {
					.links {
						gap: 0.5rem;
					}
				}
			}
		}
	}

	@media (max-width: 500px) {
		.rightPanel {
			.loginForm {
				min-width: unset;
				width: 100%;
			}
		}
	}
`;

export const AuthPanel = styled.div`
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	.emailVerifiedMsg {
		padding-top: 5rem;
		color: green;
		max-width: 400px;
		display: flex;
		flex-direction: column;

		.msg {
			font-size: 2rem;
			text-align: center;
			letter-spacing: 2px;
			line-height: 1.5;
		}

		.actionField {
			margin-top: 1rem;
			display: flex;
			justify-content: center;
		}
	}

	.loginBg {
		z-index: -1;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.content {
		margin-top: 1.5rem;
		max-width: 35rem;

		.mb1 {
			margin-bottom: 1rem;
		}

		.mt1 {
			margin-top: 1rem;
		}

		.mt2 {
			margin-top: 2rem;
		}

		.text {
			color: var(--text2);
			letter-spacing: 1.5px;
			line-height: 1.5rem;
		}

		.col-2 {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 1rem;
			margin: 0.8rem 0;
			font-size: 0.8rem;
			color: var(--text2);

			.click {
				color: var(--primary);
				font-weight: 700;
				transition: 0.1s color;
				cursor: pointer;

				&:hover {
					color: var(--text2);
				}
			}
		}

		.button {
			height: 3rem;
		}

		.colorprimary {
			color: var(--primary) !important;
		}

		.center {
			display: flex;
			align-items: center;
			color: var(--text1);
			gap: 1rem;
		}

		.mediumFont {
			font-size: 0.9rem;
		}
	}
`;
