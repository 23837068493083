import { Error } from "@mui/icons-material";
import { Box, Dialog, Tab, Tabs } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  fetchIndustries,
  formEdit,
  formSubmit,
  getStartupData,
  uploadFile,
} from "../../data/network/api/startupForm";
import { fetchUsers } from "../../data/network/api/user";
import { globalDispatch, globalSelector } from "../../providers/GlobalProvider";
import { updateData } from "../../reducers/startupform/formReducer";
import GlobalStore from "../../store/globalStore";
import { File } from "../../utils/config";
import { TabContainer as SubTabContainer } from "../../utils/customStyledComponents";
import {
  Form,
  FormContainer,
  OutletContainer,
} from "../../utils/customStyledComponents";
import { handleTopBarTitle, setSidebarActive } from "../../utils/helperHandler";
import { BuildComponent } from "../widgetManager/componentBuilder";
import CoreDetailsView from "./CoreDetailsView";
import { formJson } from "./forms/form.json";
import IncubatorAddModal from "./modals/IncubatorAddModal";
import InvestorAddModal from "./modals/InvestorAddModal";
import MentorAddModal from "./modals/MentorAddModal";
import VendorAddModal from "./modals/VendorAddModal";
import ScoreView from "./ScoreView";
import { fetchSchemes } from "../../data/network/api/scheme";

const tabIndex = {
  summary: 0,
  companyDetails: 1,
  productShowcase: 2,
  process: 3,
  people: 4,
  financials: 5,
  association: 6,
  jobOpenings: 7,
  planOfAction: 8,
  contact: 9,
  formSubmit: 10,
};

const TabContainer = () => {
  //check whether if the event is for viewing the startup or adding startup
  const { id } = useParams();
  let view = false;
  if (id) {
    view = true;
  }

  const user = globalSelector((state) => state.auth.user);

  const [tabValue, setTabValue] = useState(view ? "summary" : "summary");
  const [active, setActive] = useState(0);

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentEditRow, setCurrentEditRow] = useState(0);

  const [dialog, setDialog] = useState({
    id: null,
    open: false,
  });

  const formData = globalSelector((state) => state.startup);

  const [tabs, setTabs] = useState({});

  const dispatch = globalDispatch();
  const controller = new AbortController();

  const [coreDetails, setCoreDetails] = useState(null);

  const [documentUrl, setDocumentUrl] = useState("");

  const [industries, setIndustries] = useState([]);
  const [schemes, setSchemes] = useState([]);

  useEffect(() => {
    const tabsForUser = user?.others?.startupDetails;
    let tabsToShow = {};
    Object.keys(formJson).map((obj, i) => {
      if (user.role.roleId == "super_admin") {
        tabsToShow[obj] = formJson[obj];
      } else if (user.role.roleCategory == "ikp") {
        if (!tabsForUser) {
          if (i == 0 || i == 1) {
            tabsToShow[obj] = formJson[obj];
          }
        } else {
          if (tabsForUser.includes(obj)) {
            tabsToShow[obj] = formJson[obj];
          }
        }
      }
    });

    setTabs(tabsToShow);

    if (view) {
      clearAllValues();
      getStartupDetails();
      handleTopBarTitle(dispatch, {
        title: "Startup Details",
        link: `/startups/${id}`,
      });
    } else {
      clearAllValues();
      handleTopBarTitle(dispatch, {
        title: "Add Startup",
        link: `/startups/add`,
      });
    }
    industriesHandler();
    schemesHandler(),
    setSidebarActive("startups");
  }, []);

  //handler to fetch all schemes 
  const schemesHandler = async () => {
    const response = await fetchSchemes({}, dispatch, controller);
    if (response && response.status == 200) {
      setSchemes(response.msg?.associations);
    }
  }

  //handler to fetch industries (domain and sector)
  const industriesHandler = async () => {
    const response = await fetchIndustries({ data: {}, dispatch, controller });
    if (response && response.status == 200) {
      console.log(response?.msg);
      let array = [];
      response?.msg?.map((domain) => {
        return array.push(domain._id);
      });
      setIndustries(array);
    } else {
      snackbarHandler(
        response?.msg || "Failed to retrieve domain and sectors!",
        "error"
      );
    }
  };

  //handler to update values of the form fields
  const update = (category, subCategory, id, value) => {  
    dispatch(
      updateData({
        category: category,
        subCategory: subCategory,
        id: id,
        type: "value",
        value: value,
      })
    );
  };

  // handler to update both error fields and value fields
  const updateBoth = (category, subCategory, id, value, error) => {
    dispatch(
      updateData({
        category: category,
        subCategory: subCategory,
        id: id,
        type: "both",
        value: value,
        error: "",
      })
    );
  };

  // handler to get startup data through api call and set the values to the form fields
  const getStartupDetails = async () => {
    if (id) {
      const response = await getStartupData({ id: id }, dispatch, controller);
      if (response?.status == 200) {
        setCoreDetails(response?.msg?.core);
        Object.keys(formData).map((category, i) => {
          Object.keys(formData[category]).map((subCategory, j) => {
            if (
              [
                "productDetails",
                "ipDetails",
                "patents",
                "copyrights",
                "people",
                "grants",
                "investments",
                "awards",
                "auditedFinancialStatement",
                "mentors",
                "investors",
                "incubators",
                "vendors",
                "jobPostings",
                "planOfAction",
              ].includes(subCategory)
            ) {
              dispatch(
                updateData({
                  category: category,
                  subCategory: subCategory,
                  id: "array",
                  type: "array",
                  value: response.msg?.[category]?.[subCategory]?.array || [],
                })
              );
              return;
            }

            Object.keys(formData[category][subCategory]).map((fieldName, k) => {
              let data = "";
              let responseValue = "";
              let halt = false;

              data =
                formJson[category]?.tabs?.[subCategory]?.fields?.[fieldName]
                  ?.default;
              responseValue =
                response?.msg?.[category]?.[subCategory]?.[fieldName];

              if (fieldName == "productGallery") {
                data = [];
                responseValue.map((url, i) => {
                  data.push({ url: url });
                });
              } else if (fieldName == "hasParentCompany") {
                for (var fieldName in formJson[category].tabs[subCategory]
                  .fields) {
                  if (String(fieldName).substring(0, 6) == "parent") {
                    dispatch(
                      updateData({
                        category: category,
                        subCategory: subCategory,
                        id: fieldName,
                        type: "active",
                        value: responseValue == "yes" ? true : false,
                      })
                    );
                  }
                }

                dispatch(
                  updateData({
                    category: "companyDetails",
                    subCategory: "companyDetails",
                    id: "hasParentCompany",
                    type: "value",
                    value: responseValue,
                  })
                );
                return;
              } else if (fieldName == "shareholderPattern") {
                dispatch(
                  updateData({
                    category: category,
                    subCategory: subCategory,
                    id: fieldName,
                    type: "value",
                    value: responseValue,
                  })
                );
                return;
              } else {
                data = responseValue;
              }
              update(category, subCategory, fieldName, data);
            });
          });
        });
      }
    }
  };

  // handler for manatging the tabs
  const handleChangex = (_, newValue) => {
    setActive(0);
    setCurrentEditRow(0);
    setEdit(false);
    setTabValue(newValue);
  };

  // handler for value changes from the textfield of the form
  const handleChange = (id, value, category, subCategory, valuePath) => {
    //change values for multiple textbox for single id
    if (id == "ikpAssociation") {
      const associationData =
        formData["summary"]["summary"]["associationData"].value;
      let exists = false;
      associationData.map((assoc) => {
        if (assoc?.schemeId?.toLowerCase() == value?.[value?.length-1]?.toLowerCase()) {
          exists = true;
        }
      });
      if (!exists) {
        if (value?.[value?.length - 1]) {
          let obj = {
            schemeId: value?.[value?.length-1],
            callNumber: "",
            startDate: "",
            endDate: "",
          };
          let arr = [...formData["summary"]["summary"]["associationData"]?.value];
          arr.push(obj);
          dispatch(
            updateData({
              category: "summary",
              subCategory: "summary",
              id: "associationData",
              type: "value",
              value: arr,
            })
          );
        }
        
      }
    }

    if (
      category == "process" &&
      (id == "indian" || id == "foreign_nri" || id == "foreign_individual")
    ) {
      dispatch(
        updateData({
          category: category,
          subCategory: subCategory,
          id: id,
          type: "multipleText",
          value: value,
          field: "shareholderPattern",
        })
      );
      return;
    }

    // if (category == "summary" && id == "productDescription") {
    //   dispatch(
    //     updateData({
    //       category: category,
    //       subCategory: subCategory,
    //       id: id,
    //       type: "others",
    //       value: value,
    //     })
    //   );
    //   return;
    // }

    //set the values if found in autocomplete
    if (subCategory == "mentors" && id == "name") {
      if (value?.new) {
        setDialog({ id: "mentor", open: true });
        value = value?.inputValue;
      } else {
        const fields = [
          "_id",
          "name",
          "mentoredCompanies",
          "sector",
          "specialization",
        ];
        fields.map((field, i) => {
          return dispatch(
            updateData({
              category: category,
              subCategory: subCategory,
              id: field,
              type: "value",
              value: value?.[field],
            })
          );
        });
        return;
      }
    } else if (subCategory == "investors" && id == "name") {
      if (value?.new) {
        setDialog({ id: "investor", open: true });
        value = value?.inputValue;
      } else {
        const fields = [
          "_id",
          "name",
          "investedCompanies",
          "investedSectors",
          "proficiency",
          "ticketSize",
        ];
        fields.map((field, i) => {
          return dispatch(
            updateData({
              category: category,
              subCategory: subCategory,
              id: field,
              type: "value",
              value: value?.[field],
            })
          );
        });
        return;
      }
    } else if (subCategory == "incubators" && id == "name") {
      if (value?.new) {
        setDialog({ id: "incubator", open: true });
        value = value?.inputValue;
      } else {
        const fields = ["_id", "name", "address", "since", "focusAreas"];
        fields.map((field, i) => {
          return dispatch(
            updateData({
              category: category,
              subCategory: subCategory,
              id: field,
              type: "value",
              value: value?.[field],
            })
          );
        });
        return;
      }
    } else if (subCategory == "vendors" && id == "name") {
      if (value?.new) {
        setDialog({ id: "vendor", open: true });
        value = value?.inputValue;
      } else {
        const fields = ["_id", "name", "natureOfWork"];
        fields.map((field, i) => {
          return dispatch(
            updateData({
              category: category,
              subCategory: subCategory,
              id: field,
              type: "value",
              value: value?.[field],
            })
          );
        });
        return;
      }
    }

    //regular update of values
    dispatch(
      updateData({
        category: category,
        subCategory: subCategory,
        id: id,
        type: "value",
        value: value,
      })
    );

    //display of textbox based on selected radio
    if (id == "hasParentCompany") {
      for (var fieldName in formJson[category].tabs[subCategory].fields) {
        if (String(fieldName).substring(0, 6) == "parent") {
          dispatch(
            updateData({
              category: category,
              subCategory: subCategory,
              id: fieldName,
              type: "active",
              value: !formData[category][subCategory][fieldName].active,
            })
          );
        }
      }
    }
  };

  const handleSearch = async (id, value, category, subCategory) => {
    const data = { subCategory, value };
    const response = await getUser(data);
    dispatch(
      updateData({
        category: category,
        subCategory: subCategory,
        id: id,
        type: "options",
        value: response,
      })
    );
  };

  const getUser = async (data) => {
    const response = await fetchUsers(data, dispatch, controller);
    if (response && response?.status == 200) {
      return response.msg;
    } else {
      return [];
    }
  };

  // handler for the closing the dialog for association member creation
  const handleDialogClose = (e, reason) => {
    if (reason == "backdropClick") return;
    setDialog({
      id: null,
      open: false,
    });
  };

  // handle to add files for product gallery
  const handleImageGallery = async (e, id, category, subCategory) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!File.GALLERY_VALID_FILE_TYPES.includes(file.type)) {
          snackbarHandler("Invalid file type!", "error");
          return;
        }
        const fileType = String(file.type).split("/")[0];
        if (fileType == "image") {
          if (file.size > File.IMAGE_MAX_SIZE) {
            snackbarHandler(
              `Image should not exceed ${File.IMAGE_MAX_SIZE / 1024 / 1024}MB`,
              "error"
            );
            return;
          }
        } else if (fileType == "video") {
          if (file.size > File.VIDEO_MAX_SIZE) {
            snackbarHandler(
              `Video should not exceed ${File.VIDEO_MAX_SIZE / 1024 / 1024}MB`,
              "error"
            );
            return;
          }
        }
        const timestamp = new Date().getTime();
        let array = [
          ...GlobalStore.getState()?.["startup"]?.[category][subCategory][id]
            .value,
        ];
        array.push({
          url: "",
          id: timestamp,
          error: null,
          type: fileType,
          loading: true,
          name: file.name,
        });

        dispatch(
          updateData({
            category: category,
            subCategory: subCategory,
            id: id,
            type: "value",
            value: array,
          })
        );
        handleUpload(file, category, subCategory, id, timestamp, fileType);
      }
    }
  };

  // function to upload the file to the server
  const handleUpload = async (
    file,
    category,
    subCategory,
    id,
    timestamp,
    fileType
  ) => {
    const fileData = new FormData();
    fileData.append("file", file);
    const response = await uploadFile(fileData, dispatch, controller);
    let array = [
      ...GlobalStore.getState()["startup"][category][subCategory][id].value,
    ];
    const index = array.findIndex((obj) => obj.id == timestamp);
    if (response && response.status == 200) {
      if (index != -1) {
        array.splice(index, 1, {
          id: timestamp,
          url: response.msg,
          error: "",
          loading: false,
          type: fileType,
          name: file.name,
        });
        dispatch(
          updateData({
            category: category,
            subCategory: subCategory,
            id: id,
            type: "value",
            value: [...array],
          })
        );
      }
    } else {
      array.splice(index, 1, {
        id: timestamp,
        url: "",
        error: "Retry",
        loading: false,
        type: fileType,
        name: file.name,
      });
      dispatch(
        updateData({
          category: category,
          subCategory: subCategory,
          id: id,
          type: "value",
          value: [...array],
        })
      );
    }
  };

  // remove uploaded file from any place mainly used in product gallery
  const removeFileFromArray = (index, dataField) => {
    var array = [
      ...formData[dataField.category][dataField.subCategory][
        dataField.fieldName
      ].value,
    ];
    array.splice(index, 1);
    dispatch(
      updateData({
        category: dataField.category,
        subCategory: dataField.subCategory,
        id: dataField.fieldName,
        type: "value",
        value: array,
      })
    );
  };

  // handler to add a row
  const addToArray = (dataField, index = -1, isEdit = false) => {
    var obj = {};
    const fieldsToCheck = [
      "productName",
      "applicant",
      "patentTitle",
      "name",
      "grantName",
      "agency",
      "year",
      "jobTitle",
      "duration",
      "investmentKind",
    ];
    for (var fieldName in formData[dataField.category][dataField.subCategory]) {
      if (fieldName == "array") continue;
      if (fieldsToCheck.includes(fieldName)) {
        if (fieldName == "duration" || fieldName == "investmentKind") {
          if (
            formData[dataField.category][dataField.subCategory][fieldName]
              .value == -1
          ) {
            snackbarHandler(`${fieldName} is required!`);
            return;
          }
        } else if (
          formData[dataField.category][dataField.subCategory][fieldName]
            .value == ""
        ) {
          snackbarHandler(`${fieldName} is required!`);
          return;
        }
      }
      obj[fieldName] =
        formData[dataField.category][dataField.subCategory][fieldName].value;
      dispatch(
        updateData({
          category: dataField.category,
          subCategory: dataField.subCategory,
          id: fieldName,
          type: "value",
          value:
            formJson[dataField.category].tabs[dataField.subCategory].fields[
              fieldName
            ].default,
        })
      );
    }

    let array = [...formData[dataField.category][dataField.subCategory].array];
    if (isEdit) {
      array.splice(index, 1, obj);
      setEdit(false);
    } else {
      array.push(obj);
    }
    dispatch(
      updateData({
        category: dataField.category,
        subCategory: dataField.subCategory,
        type: "array",
        value: array,
      })
    );
  };

  // handler for deleting the row which is added
  const removeRow = (index, dataField) => {
    var array = [...formData[dataField.category][dataField.subCategory].array];
    array.splice(index, 1);
    dispatch(
      updateData({
        category: dataField.category,
        subCategory: dataField.subCategory,
        type: "array",
        value: array,
      })
    );
  };

  // handler for what to do when edit action is done on a row which is added
  const handleEditRow = (index, dataField) => {
    setEdit(true);
    setCurrentEditRow(index);
    let obj = formData[dataField.category][dataField.subCategory].array[index];

    for (var fieldName in formJson[dataField.category].tabs[
      dataField.subCategory
    ].fields) {
      if (fieldName == "viewer" || fieldName == "saveBtn") {
        continue;
      }
      dispatch(
        updateData({
          category: dataField.category,
          subCategory: dataField.subCategory,
          id: fieldName,
          type: "value",
          value: obj[fieldName],
        })
      );
    }
  };

  // get the file and start uploading to the server and files can be in multiple
  const handleFile = async (e, dataField) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (!File.PDF_VALID_TYPES.includes(file.type)) {
        snackbarHandler("Invalid file type!");
        return;
      }
      const fileType = String(file.type).split("/")[0];
      let data = {
        file,
        url: "",
        loading: true,
        error: "",
        type: fileType,
      };
      dispatch(
        updateData({
          category: dataField.category,
          subCategory: dataField.subCategory,
          id: dataField.fieldName,
          type: "value",
          value: { ...data },
        })
      );
      const fileData = new FormData();
      fileData.append("file", file);
      const response = await uploadFile(fileData, dispatch, controller);

      if (response && response.status == 200) {
        dispatch(
          updateData({
            category: dataField.category,
            subCategory: dataField.subCategory,
            id: dataField.fieldName,
            type: "value",
            value: {
              file,
              url: response.msg,
              loading: false,
              error: "",
              type: fileType,
            },
          })
        );
      } else {
        dispatch(
          updateData({
            category: dataField.category,
            subCategory: dataField.subCategory,
            id: dataField.fieldName,
            type: "value",
            value: {},
          })
        );
      }
    }
  };

  // handler for removing the file which is uploaded on clicking delete icon
  const removeFile = (dataField) => {
    dispatch(
      updateData({
        category: dataField.category,
        subCategory: dataField.subCategory,
        id: dataField.fieldName,
        type: "value",
        value: {},
      })
    );
  };

  // final submit handler for the whole startup form
  const submitHandler = async () => {
    setLoading(true);
    const isValidData = await validateData(GlobalStore.getState()["startup"]);
    if (!isValidData) {
      setLoading(false);
      return;
    }
    const data = await cleanData(GlobalStore.getState()["startup"]);
    if (view) {
      const response = await formEdit(
        { data: data, companyId: id },
        dispatch,
        controller
      );
      if (response && response?.status == 200) {
        getStartupDetails();
        snackbarHandler("Startup Updated", "success");
      } else if (response) {
        snackbarHandler(`Something went wrong! => ${response.msg}`, "error");
      }
    } else {
      const response = await formSubmit(data, dispatch, controller);
      if (response && response?.status == 200) {
        snackbarHandler("Startup Created", "success");
      } else if (response) {
        snackbarHandler(`Something went wrong! => ${response.msg}`, "error");
      }
    }
    setLoading(false);
  };

  //checking all the fields of the form whether the value entered is valid or not
  const validateData = async (data) => {
    let valid = true;
    const allFieldsCheck = ["summary", "companyDetails", "contact", "process"];
    dispatch(
      updateData({
        type: "errorTab",
        errorTabs: [],
      })
    );
    Object.keys(data).map((keyname, i) => {
      let isMainTabCategoryValid = true;
      if (!allFieldsCheck.includes(keyname)) return;
      Object.keys(data[keyname]).map((category, j) => {
        Object.keys(data[keyname][category]).map((fieldName, i) => {
          let isFieldValid = true;
          if (data[keyname][category][fieldName].mandatory) {
            if (fieldName == "hashtag") {
              if (data[keyname][category][fieldName].value.length == 0) {
                valid = false;
                isFieldValid = false;
                isMainTabCategoryValid = false;
                dispatch(
                  updateData({
                    category: keyname,
                    subCategory: category,
                    id: fieldName,
                    type: "error",
                    value: `${fieldName} is mandatory!`,
                  })
                );
              }
            } else if (keyname == "process") {
              if (fieldName != "shareholderPattern") {
                if (
                  Object.keys(data[keyname][category][fieldName].value)
                    .length == 0 ||
                  data[keyname][category][fieldName].value?.url == "" ||
                  data[keyname][category][fieldName].value == ""
                ) {
                  valid = false;
                  isFieldValid = false;
                  isMainTabCategoryValid = false;
                  dispatch(
                    updateData({
                      category: keyname,
                      subCategory: category,
                      id: fieldName,
                      type: "error",
                      value: `${fieldName} is mandatory!`,
                    })
                  );
                }
              } else {
                Object.keys(data[keyname][category][fieldName].value).map(
                  (field) => {
                    if (data[keyname][category][fieldName].value[field] == "") {
                      valid = false;
                      isFieldValid = false;
                      isMainTabCategoryValid = false;
                      dispatch(
                        updateData({
                          category: keyname,
                          subCategory: category,
                          id: fieldName,
                          type: "error",
                          value: `${fieldName} is mandatory!`,
                        })
                      );
                    }
                  }
                );
              }
            } else if (
              data[keyname][category][fieldName].value == "" ||
              data[keyname][category][fieldName].value == -1
            ) {
              valid = false;
              isFieldValid = false;
              isMainTabCategoryValid = false;
              dispatch(
                updateData({
                  category: keyname,
                  subCategory: category,
                  id: fieldName,
                  type: "error",
                  value: `${fieldName} is mandatory!`,
                })
              );
            }

            if (isFieldValid) {
              dispatch(
                updateData({
                  category: keyname,
                  subCategory: category,
                  id: fieldName,
                  type: "error",
                  value: ``,
                })
              );
            }
          }
        });
      });
      if (!isMainTabCategoryValid) {
        dispatch(
          updateData({
            type: "errorTab",
            errorTabs: [
              ...GlobalStore.getState()["startup"]["errorTabs"],
              keyname,
            ],
          })
        );
      }
    });
    return valid;
  };

  // clean the reducer data before submiting it to the server like removing unwanted properties from the data object
  const cleanData = async (data) => {
    let objData = {};
    Object.keys(data).map((category) => {
      objData[category] = {};
      Object.keys(data[category]).map((subCategory) => {
        objData[category][subCategory] = {};
        Object.keys(data[category][subCategory]).map((fieldName) => {
          // if (fieldName == "others") {
          //   objData[category][subCategory]["others"] = {};
          //   Object.keys(data[category][subCategory]["others"]).map((id) => {
          //     objData[category][subCategory]["others"][id] = data?.[category][subCategory]?.others?.[id]?.value;
          //   })
          //   return;
          // }

          if (fieldName == "array") {
            objData[category][subCategory][fieldName] =
              data?.[category][subCategory][fieldName];
            return;
          }
          objData[category][subCategory][fieldName] =
            data?.[category][subCategory][fieldName]?.value;
        });
      });
    });
    return objData;
  };

  //reset all the fields of the form to default value
  const clearAllValues = () => {
    dispatch(
      updateData({
        type: "errorTab",
        errorTabs: [],
      })
    );
    Object.keys(formJson).map((category, index) => {
      if (category == "formSubmit") return;
      Object.keys(formJson[category].tabs).map((subCategory, i) => {
        if (
          [
            "people",
            "productDetails",
            "ipDetails",
            "patents",
            "copyrights",
            "grants",
            "investments",
            "awards",
            "auditedFinancialStatement",
            "mentors",
            "investors",
            "incubators",
            "vendors",
            "jobPostings",
            "planOfAction",
          ].includes(subCategory)
        ) {
          dispatch(
            updateData({
              category,
              subCategory,
              type: "array",
              value: [],
            })
          );
        }

        Object.keys(formJson[category].tabs[subCategory].fields).map(
          (fieldName, i) => {
            if (
              category == "summary" &&
              ["phoneNumber", "website", "emails"].includes(fieldName)
            ) {
              return;
            }
            if (
              category == "companyDetails" &&
              ["officeAddress", "city", "state", "pincode"].includes(fieldName)
            ) {
              return;
            }

            if (
              fieldName == "viewer" ||
              fieldName == "saveBtn" ||
              fieldName == "_id"
            )
              return;

            if (fieldName == "shareholderPattern") {
              updateBoth(
                category,
                subCategory,
                fieldName,
                {
                  indian: "",
                  foreign_nri: "",
                  foreign_individual: "",
                },
                ""
              );
              return;
            }

            updateBoth(
              category,
              subCategory,
              fieldName,
              formJson[category].tabs[subCategory].fields[fieldName]?.default ||
                "",
              ""
            );
          }
        );
      });
    });
  };

  //handle association data
  const addRow = (title = "Title") => {
    let arr = [...formData["summary"]["summary"]["associationData"]?.value];
    let obj = {
      schemeId: title,
      callNumber: "",
      startDate: "",
      endDate: "",
    };
    arr.push(obj);

    const titleText = title.toLowerCase();
    let associations = [
      ...formData["summary"]["summary"]["ikpAssociation"].value,
    ];
    const lowerAssoc = associations.map((assoc) => {
      return assoc.toLowerCase();
    });

    if (!lowerAssoc.includes(titleText)) {
      associations.push(title.toUpperCase());

      dispatch(
        updateData({
          category: "summary",
          subCategory: "summary",
          id: "ikpAssociation",
          type: "value",
          value: associations,
        })
      );
    }

    dispatch(
      updateData({
        category: "summary",
        subCategory: "summary",
        id: "associationData",
        type: "value",
        value: arr,
      })
    );
  };

  //handle association data
  const remAssocRow = (index) => {
    let arr = [...formData["summary"]["summary"]["associationData"]?.value];
    arr.splice(index, 1);

    dispatch(
      updateData({
        category: "summary",
        subCategory: "summary",
        id: "associationData",
        type: "value",
        value: [...arr],
      })
    );
  };

  //handle association data
  const handleAssocChange = (index, id, value) => {
    let arr = [...formData["summary"]["summary"]["associationData"]?.value];
    let obj = { ...arr[index] };
    obj[id] = value;
    arr[index] = { ...obj };

    dispatch(
      updateData({
        category: "summary",
        subCategory: "summary",
        id: "associationData",
        type: "value",
        value: arr,
      })
    );
  };

  const { enqueueSnackbar } = useSnackbar();
  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  useEffect(() => {
    console.log(tabValue);
  }, [tabValue]);

  //build the form with json and passing the handlers to it
  return (
    <OutletContainer>
      {/* build tab section using json */}
      <Box sx={{ maxWidth: { xs: 500, sm: "100%", marginTop: "1rem" } }}>
        <Tabs
          onChange={handleChangex}
          value={tabValue == -1 ? "summary" : tabValue}
          scrollButtons
          allowScrollButtonsMobile
          variant="scrollable"
          className="tabs"
        >
          {/* building tabs with the help of json */}
          {Object.keys(tabs).map((category, index) => (
            <Tab
              value={category}
              style={{ display: category == "core" && !view ? "none" : "" }}
              key={index}
              // disabled={category == "formSubmit" && view}
              label={
                <TabItem>
                  <div className="number">{index + 1}</div>
                  <div className="text">{tabs[category].name}</div>
                  {formData.errorTabs.includes(category) ? (
                    <div className="error">
                      <Error />{" "}
                    </div>
                  ) : (
                    <></>
                  )}
                </TabItem>
              }
              className="tabItemHead"
            />
          ))}
        </Tabs>
      </Box>

      {/* building form for each tab using json */}
      <div>
        {Object.keys(formJson).map((category, index) =>
          tabIndex[tabValue] == index ? (
            category == "core" && view ? (
              <FormContainer key={index}>
                <SubTabContainer>
                  <CoreDetailsView data={coreDetails} />
                </SubTabContainer>
              </FormContainer>
            ) : category === "imm" ? (
              <FormContainer key={index}>
                <SubTabContainer>
                  <ScoreView />
                </SubTabContainer>
              </FormContainer>
            ) : (
              <FormContainer key={index}>
                {formJson[category].hasTabs ? (
                  <SubTabContainer>
                    {Object.keys(formJson[category].tabs).map((name, i) => (
                      <div
                        className={`link ${active == i ? "active" : null}`}
                        onClick={() => setActive(i)}
                        key={i}
                      >
                        {formJson[category].tabs[name].title}
                      </div>
                    ))}
                  </SubTabContainer>
                ) : null}

                <Form>
                  {Object.keys(formJson[category].tabs).map((subCategory, i) =>
                    active == i
                      ? Object.keys(
                          formJson[category].tabs[subCategory].fields
                        ).map((fieldName, i) => (
                          <>
                            <BuildComponent
                              key={i}
                              reducerData={formData?.[category]?.[subCategory]}
                              options={
                                formJson?.[category]?.tabs?.[subCategory]
                                  ?.fields?.[fieldName]
                              }
                              dataField={{
                                category,
                                subCategory,
                                fieldName,
                              }}
                              handleChange={handleChange}
                              handleSearch={handleSearch}
                              handleImageGallery={handleImageGallery}
                              removeFileFromArray={removeFileFromArray}
                              addToArray={addToArray}
                              removeRow={removeRow}
                              handleEditRow={handleEditRow}
                              isEdit={edit}
                              currentEditRow={currentEditRow}
                              handleFile={handleFile}
                              removeFile={removeFile}
                              submitHandler={submitHandler}
                              loading={loading}
                              view={view}
                              associationAddRow={addRow}
                              removeAssocRow={remAssocRow}
                              handleAssocChange={handleAssocChange}
                              setDocumentUrl={setDocumentUrl}
                              industries={
                                fieldName == "domainAndSector" ? industries : []
                              }
                              schemes={fieldName == "associationData" ? schemes : []}
                            />
                          </>
                        ))
                      : null
                  )}
                </Form>
              </FormContainer>
            )
          ) : null
        )}
      </div>

      {/* dialog box for adding association members directly to database */}
      <Dialog
        PaperProps={{ style: { width: "50rem" } }}
        open={dialog.open}
        onClose={handleDialogClose}
      >
        <BuildDialog type={dialog.id} handleDialogClose={handleDialogClose} />
      </Dialog>
    </OutletContainer>
  );
};

export default TabContainer;

const BuildDialog = ({ type, handleDialogClose }) => {
  switch (type) {
    case "mentor":
      return <MentorAddModal handleDialogClose={handleDialogClose} />;
    case "investor":
      return <InvestorAddModal handleDialogClose={handleDialogClose} />;
    case "incubator":
      return <IncubatorAddModal handleDialogClose={handleDialogClose} />;
    case "vendor":
      return <VendorAddModal handleDialogClose={handleDialogClose} />;
  }
};

const TabItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .number {
    background: #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0.2rem;
    font-size: 0.8rem;
  }

  .error {
    color: red;
  }
`;
