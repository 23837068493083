import { Radio } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BigSelector } from "../../../providers/scheme/big/BigProvider";
import { PrayasSelector } from "../../../providers/scheme/prayas/PrayasProvider";
import BigSchemeStore from "../../../store/scheme/big/big";
import { Icons } from "../../../utils/mediaExports";
import { TitanSelector } from "../../../providers/scheme/titan/TitanProvider";
import { useParams } from "react-router-dom";

//from: it is to identify whether applicationid with schemeid and it is only for the ikp side view
//afterApplicationSelect: this is to identify whether it is from ikp side and the application has been selected so that we can display the application view of the applicant

const CustomSchemeTabs = ({
	from,
	active,
	setActive,
	isFounder,
	afterApplicationSelect,
	scheme,
}) => {
	let tabs = [];
	let progress = {};
	let isLateral;
	const params = useParams();

	if (scheme == "big") {
		tabs = BigSelector((state) => state.bigTabs1);
		progress = BigSelector((state) => state.application.progress);
		isLateral =
			afterApplicationSelect || isFounder
				? BigSelector(
						(state) => state.application?.application?.others?.lateralEntry
				  )
				: false;
		if (isLateral && active == 0) {
			setActive(7);
		}
	} else if (scheme == "prayas") {
		tabs = PrayasSelector((state) => state.prayasTabs1);
		progress = PrayasSelector((state) => state.application.progress);
		isLateral =
			afterApplicationSelect || isFounder
				? PrayasSelector(
						(state) => state.application?.application?.others?.lateralEntry
				  )
				: false;
		if (isLateral && active == 0) {
			setActive(5);
		}
	} else if (scheme == "titan") {
		tabs = TitanSelector((state) => state.titanTabs1);
		progress = TitanSelector((state) => state.application.progress);
		isLateral =
			afterApplicationSelect || isFounder
				? TitanSelector(
						(state) => state.application?.application?.others?.lateralEntry
				  )
				: false;
		// if (isLateral && active == 0) {
		//   setActive(5);
		// }
	}

	const handleChangeTab = (value) => {
		setActive(value);
	};

	if (from == "associate" && !afterApplicationSelect) {
		return <></>;
	}

	let previousStatus = "pending";

	return (
		<Container>
			<div className="tabContainer">
				{Object.keys(tabs).map((tab, i) => {
					const id = tabs[tab].title;
					let statusColor = "#fff";
					if (i == 0) {
						if (progress["phase" + (i + 1)] == "approved") {
							statusColor = "#33AD55";
						} else if (progress["phase" + (i + 1)] == "pending") {
							statusColor = "#EFE614";
						}
						if (progress["phase" + (i + 1)] == "rejected") {
							statusColor = "#FA8484";
						}
					} else if (id == "Milestones") {
						if (progress?.["milestone"] == "approved") {
							statusColor = "#33AD55";
						} else if (
							progress?.["milestone"] == "pending" &&
							previousStatus == "approved"
						) {
							statusColor = "#EFE614";
						}
					} else if (progress["phase" + (i + 1)] == "rejected") {
						statusColor = "#FA8484";
					} else if (
						previousStatus == "approved" &&
						progress["phase" + (i + 1)] == "pending"
					) {
						statusColor = "#EFE614";
					} else if (progress["phase" + (i + 1)] == "approved") {
						statusColor = "#33AD55";
					} else if (progress["phase" + (i + 1)] == "pending") {
						statusColor = "#fff";
					}
					previousStatus = progress["phase" + (i + 1)];

					return scheme === "titan" && params?.applicationId ? (
						<></>
					) : (
						<div
							key={i}
							className={`item ${
								isLateral
									? tabs[tab].title != "Agreement" &&
									  tabs[tab].title != "Milestones"
										? "block"
										: ""
									: ""
							}`}
							onClick={() => handleChangeTab(i)}
						>
							<span className={`tabItem ${active == i ? "active" : ""}`}>
								<div
									style={i == 0 ? null : { marginLeft: "1rem" }}
									className="text"
								>
									{i == 0
										? isFounder
											? "Application"
											: tabs[tab].title
										: tabs[tab].title}
								</div>

								{isFounder ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<div
											style={{
												margin: "0 auto",
												width: "30px",
												height: "30px",
												border: "1px solid gray",
												borderRadius: "50%",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<div
												style={{
													width: "20px",
													height: "20px",
													border: "1px solid gray",
													borderRadius: "50%",
													background: statusColor,
												}}
											></div>
										</div>
									</div>
								) : (
									<div className={`badge ${active == i ? "activeBadge" : ""}`}>
										{tabs[tab].notifications > 99
											? "99+"
											: tabs[tab].notifications}
									</div>
								)}
								<span className="arrowIcon">
									{i + 1 < Object.keys(tabs).length
										? Icons.arrowFormward
										: null}
								</span>
							</span>
						</div>
					);
				})}
			</div>
		</Container>
	);
};

export default CustomSchemeTabs;

const Container = styled.div`
	width: 100%;
	margin-top: 1rem;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;

	.tabContainer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		color: var(--text1);
		width: max-content;

		.item {
			display: flex;
			align-items: center;
			cursor: pointer;

			.tabItem {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				border-bottom: 2px solid var(--borderBottomColor);

				.text {
					font-size: 1.2rem;
					padding: 0.7rem 0;
				}

				.badge {
					background: #fff;
					font-size: 0.7rem;
					border-radius: 50%;
					width: 1.5rem;
					height: 1.5rem;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.activeBadge {
					background: var(--primary);
				}
			}

			.active {
				border-bottom: 2px solid var(--primary);
			}
		}

		.block {
			pointer-events: none;
			background: #ecf0f1;

			.tabItem {
				.text {
					color: var(--text3);
				}
			}
		}
	}
`;
