import { useEffect, useState } from "react";
import "./expenseView.css";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Icons } from "../../../../../utils/mediaExports";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../providers/GlobalProvider";
import { useParams } from "react-router-dom";
import {
  getExpenseFields,
  getExpenseForms,
  saveExpenseForm,
  updateExpenseFormAccept,
  updateExpenseFormReject,
} from "../../../../../data/network/api/milestone";
import { useSnackbar } from "notistack";
import CustomButton from "../../../../customComponents/CustomButton";
import ExpensesForm from "../expenses/expenseForm";
import { Dialog } from "@mui/material";
import GlobalStore from "../../../../../store/globalStore";
import { updateNavigation } from "../../../../../reducers/navigationReducer";

const expenseEditAccess = ["founder"];
const expenseViewAccess = [
  "founder",
  "program_manager",
  "finance_manager",
  "super_admin",
  "accountant",
];
const expenseActionEditAccess = ["accountant"];
const financeAction = ["finance_manager"];

const ExpenseViewer = () => {
  //   const user = globalSelector((state) => {
  //     state.auth.user;
  //   });
  const user = globalSelector((state) => state.auth.user);
  const role = user.role.roleId;

  const userType = user.role.roleId;
  const userId = user._id;
  const categoriesTemplate = {
    capital: { name: "Capital", count: "00" },
    consumables: { name: "Consumables", count: "00" },
    salaries: { name: "Salaries", count: "00" },
    outsourcing: { name: "Outsourcing", count: "00" },
    rent_facilities: { name: "Rent and Facilities", count: "00" },
    travel: { name: "Travel", count: "00" },
  };
  const params = useParams();
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [showAcceptButtons, setShowAcceptButtons] = useState(2);
  const [currentCategory, setCurrentCategory] = useState(0);
  const [categories, setCategories] = useState(categoriesTemplate);
  const [expenseFormdata, setExpenseFormData] = useState(null);
  const [milestoneStatus, setMistoneStatus] = useState(null);
  const [milestoneId, setMilestoneId] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const [expenseCount, setExpenseCount] = useState([
    {
      ids: [],
    },
    {
      ids: [],
    },
    {
      ids: [],
    },
    {
      ids: [],
    },
    {
      ids: [],
    },
    {
      ids: [],
    },
  ]);
  const [currenElePosition, setCurrentElePosition] = useState(0);
  const [isTds, setIsTds] = useState("no");

  const Fields = [
    // { field: "Serial Number", type: "number", id: "serial_number" },
    { field: "Name of Item", type: "text", id: "name_of_item" },
    { field: "Name of Vendor", type: "text", id: "name_of_vendor" },
    { field: "Invoice Number", type: "text", id: "invoice_number" },
    { field: "Invoice Date", type: "date", id: "invoice_date" },
    { field: "Payment Date", type: "date", id: "payment_date" },
    { field: "Transaction ID", type: "text", id: "cheque_number" },
    // { field: "Base Amount", type: "number", id: "base_amt" },
    // { field: "Gross Amount", type: "number", id: "gross_amt" },
    // { field: "Net Amount", type: "number", id: "net_amt" },
    { field: "Total Amount", type: "number", id: "total_amt" },
    { field: "TDS Amount", type: "number", id: "tds" },
    // { field: "GST", type: "number", id: "gst" },
  ];
  let categoryHeader = [];
  Object.keys(categories).map((x, i) => {
    if (i == 5) {
      categoryHeader.push(
        <>
          <div
            className={currentCategory == i ? "selCat" : "indCat"}
            onClick={() => {
              setCurrentCategory(i);

              if (expenseCount[i].ids.length > 0) {
                setCurrentElePosition(0);
                setIsTds("no");
                upDateAndSerial(expenseCount[i].ids[0].id);
              }
            }}
          >
            {categories[`${x}`].name}
            <div className="countcircle">{expenseCount[i].ids.length}</div>
          </div>
        </>
      );
      return;
    }
    categoryHeader.push(
      <>
        <div
          className={currentCategory == i ? "selCat" : "indCat"}
          onClick={() => {
            setCurrentCategory(i);
            if (expenseCount[i].ids.length > 0) {
              setCurrentElePosition(0);
              upDateAndSerial(expenseCount[i].ids[0].id);
            }
          }}
        >
          {categories[`${x}`].name}
          <div className="countcircle">{expenseCount[i]?.ids.length}</div>
        </div>
        <div className="categoryBarries"></div>
      </>
    );
  });
  //pdf viewer states and functions
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { enqueueSnackbar } = useSnackbar();
  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const pdfBackWard = () => {
    if (pageNumber == 1) {
      return;
    }
    setPageNumber(pageNumber - 1);
  };
  const pdfForward = () => {
    if (pageNumber == numPages) {
      return;
    }
    setPageNumber(pageNumber + 1);
  };
  useEffect(() => {
    initilizeView();
  }, []);

  const upDateAndSerial = async (appId) => {
    const expenseinfo = await getExpenseFields({
      data: { appId: appId, applicationId: params.applicationId },
      controller: controller,
      dispatch,
    });
    setCheckAll(false);
    if (expenseinfo && expenseinfo.status == 200) {
      if (expenseinfo.msg[0]?.net_amt?.data) {
        setIsTds("yes");
      }
      setExpenseFormData(expenseinfo.msg[0]);
    } else {
      snackbarHandler("Failed to load!", "error");
    }
  };

  const initilizeView = async () => {
    const prjInfo = await getExpenseForms({
      data: { repId: params.repId, applicationId: params.applicationId },
      controller: controller,
      dispatch,
    });
    if (prjInfo && prjInfo.status == 200) {
      setExpenseCount(prjInfo.msg.data);
      setMistoneStatus(prjInfo.msg.mileStoneStatus);
      setAdminId(prjInfo.msg.adminId);

      if (prjInfo?.msg?.data[0]?.ids.length > 0) {
        upDateAndSerial(prjInfo.msg.data[0].ids[0].id);
      }
    }
  };

  const saveExpenseFormInternal = async () => {
    let obj = { ...expenseFormdata, applicationId: params.applicationId };
    const expenseFormSave = await saveExpenseForm({
      data: obj,
      controller: controller,
      dispatch,
    });
    if (expenseFormSave && expenseFormSave.status == 200) {
      let changeCategoryTab = 0;
      switch (obj?.category?.data) {
        case "capital":
          changeCategoryTab = 0;
          break;
        case "consumables":
          changeCategoryTab = 1;
          break;
        case "salaries":
          changeCategoryTab = 2;
          break;
        case "outsourcing":
          changeCategoryTab = 3;
          break;
        case "travel":
          changeCategoryTab = 5;
          break;
        default:
          changeCategoryTab = 4;
      }
      snackbarHandler("Expense form saved..", "success");
      setCurrentCategory(changeCategoryTab);
    } else {
      snackbarHandler(
        expenseFormSave?.msg?.msg || expenseFormSave?.msg || "Unable to save!",
        "error"
      );
    }
    initilizeView();
  };

  const acceptForm = async () => {
    let obj = { ...expenseFormdata, applicationId: params.applicationId };
    const expenseFormSave = await updateExpenseFormAccept({
      data: obj,
      controller: controller,
      dispatch,
    });

    if (expenseFormSave && expenseFormSave.status == 200) {
      snackbarHandler("Expense form accepted", "success");
      initilizeView();
    } else {
      snackbarHandler(
        expenseFormSave?.msg?.msg ||
          expenseFormSave?.msg ||
          "Something went wrong!",
        "error"
      );
    }
  };
  const rejectForm = async () => {
    let obj = { ...expenseFormdata, applicationId: params.applicationId };
    console.log(obj);
    const expenseFormSave = await updateExpenseFormReject({
      data: obj,
      controller: controller,
      dispatch,
    });
    if (expenseFormSave && expenseFormSave.status == 200) {
      snackbarHandler("Expense form rejected!", "success");
      initilizeView();
    } else {
      snackbarHandler(
        expenseFormSave?.msg?.msg.toString() || "Something went wrong!",
        "error"
      );
    }
  };

  const [checkAll, setCheckAll] = useState(false);
  const checkAllHandler = () => {
    let obj = { ...expenseFormdata };
    if (
      expenseActionEditAccess.includes(role) &&
      (milestoneStatus == "initial" ||
        milestoneStatus == "current" ||
        milestoneStatus == "dispersion" ||
        milestoneStatus == "subimitted") &&
      expenseFormdata?.status != "accepted"
    ) {
      Fields.map((x, i) => {
        obj[`${x.id}`].status = "accepted";
      });
      obj["category"].status = "accepted";
    } else return;
    const isTrue = checkAll;
    checkAll ? setCheckAll(false) : setCheckAll(true);
    if (!isTrue) {
      setExpenseFormData(obj);
    } else {
      Fields.map((x, i) => {
        obj[`${x.id}`].status = "created";
      });
      obj["category"].status = "created";
      setExpenseFormData(obj);
    }
  };

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  useEffect(() => {
    try {
      let pageNavStack = JSON.parse(
        JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
      );
      pageNavStack.milestoneNumber.isActive = false;
      pageNavStack.expenseManagement.show = true;
      pageNavStack.expenseManagement.isActive = true;
      pageNavStack.expenseManagement.path = `/schemes/big/CrossCheckingForm/${params?.repId}`;

      dispatch(
        updateNavigation({
          type: "pageNavStack",
          value: { ...pageNavStack },
        })
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  if (!expenseViewAccess.includes(role)) {
    return <div className="noAccess">You have no access to this page!</div>;
  }

  useEffect(() => {
    if (isTds == "no" && expenseFormdata) {
      let obj = JSON.parse(JSON.stringify(expenseFormdata));
      obj["tds"] = {...obj["tds"], data: null, status: "created"};
      obj["transferred_amt"] = {...obj["transferred_amt"], data: null, status: "created"};
      setExpenseFormData(obj);
    }
  }, [isTds]);

  return (
    <div className="expenseViewBox">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="categoryTab">{categoryHeader}</div>
      </div>

      <hr></hr>

      <div className="expenseGridView">
        <div className="catSerial">
          <div className="serialHeader">Sl No.</div>
          {expenseCount[currentCategory].ids.map((x, i) => {
            return (
              <div
                className={`serialItems ${
                  currenElePosition == i ? "selectedSer" : ""
                } ${x.status}Sl`}
                id={`sl${i}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsTds("no");
                  upDateAndSerial(x.id);
                  setCurrentElePosition(i);
                }}
              >
                {i + 1}
                {x.status == "reviewed" ? (
                  <div
                    style={{
                      position: "absolute",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      background: "rgba(249, 143, 45, 1)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      right: "-3px",
                      top: "-3px",
                      zIndex: "10",
                    }}
                  >
                    {Icons.exclamation}
                  </div>
                ) : null}
              </div>
            );
          })}

          {expenseEditAccess.includes(role) &&
          milestoneStatus != "completed" ? (
            <div className="serialItems addBtn" onClick={() => setOpen(true)}>
              {Icons.plus}
            </div>
          ) : null}
        </div>
        {expenseCount[currentCategory].ids.length > 0 ? (
          <>
            <div className="expenseFormView">
              <div className="expenseFormViewformPart">
                <div style={{ padding: "10px 20px 10px 15px" }}>
                  {" "}
                  {expenseFormdata
                    ? Fields.map((x, i) => {
                        if (x.id == "tds") {
                          return (
                            <>
                              <div
                                className="expenseViewFormElement"
                                style={
                                  !expenseEditAccess.includes(role)
                                    ? {
                                        display:
                                          x.id == "tds" &&
                                          !expenseFormdata[`${x.id}`].data
                                            ? "none"
                                            : "",
                                      }
                                    : null
                                }
                              >
                                <label
                                  htmlFor="serial_number"
                                  className="expenseLabel"
                                >
                                  {"Do you have TDS ?"}
                                </label>

                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    width: "70%",
                                    gap: "1rem",
                                  }}
                                >
                                  <label
                                    htmlFor="tdsRadio"
                                    className="expenseFormLabels"
                                  >
                                    <input
                                      type="radio"
                                      id="isTds"
                                      name="isTds"
                                      value="yes"
                                      checked={isTds == "yes"}
                                      onChange={(e) => setIsTds(e.target.value)}
                                      disabled={
                                        !expenseEditAccess.includes(role) ||
                                        ((milestoneStatus == "initial" ||
                                          milestoneStatus == "current" ||
                                          milestoneStatus == "subimitted" ||
                                          milestoneStatus == "dispersion") &&
                                        expenseFormdata?.status != "accepted"
                                          ? false
                                          : true)
                                      }
                                    />
                                    <label for="yes">Yes</label>
                                  </label>

                                  <label
                                    htmlFor="tdsRadio"
                                    className="expenseFormLabels"
                                  >
                                    <input
                                      type="radio"
                                      id="isTds"
                                      name="isTds"
                                      value="no"
                                      checked={isTds == "no"}
                                      onChange={(e) => setIsTds(e.target.value)}
                                      disabled={
                                        !expenseEditAccess.includes(role) ||
                                        ((milestoneStatus == "initial" ||
                                          milestoneStatus == "current" ||
                                          milestoneStatus == "subimitted" ||
                                          milestoneStatus == "dispersion") &&
                                        expenseFormdata?.status != "accepted"
                                          ? false
                                          : true)
                                      }
                                    />
                                    <label for="yes">No</label>
                                  </label>
                                </div>
                              </div>

                              {isTds == "yes" ? (
                                <>
                                  <div className="expenseViewFormElement">
                                    <label
                                      htmlFor={Fields[i].id}
                                      className="expenseLabel"
                                      // style={{ width: "30%", height: "30px" }}
                                    >
                                      {Fields[i].field}
                                    </label>

                                    <div
                                      className=""
                                      style={{
                                        // width: "70%",
                                        // height: "30px",
                                        display: "flex",
                                        gap: "1rem",
                                        width: "60%",
                                      }}
                                    >
                                      <input
                                        type={Fields[i].type}
                                        className="expViewInput"
                                        // pattern={Fields[i].type == "number"}
                                        id={"tdsValue"}
                                        required
                                        // style={{ width: "70%", height: "30px" }}
                                        style={{ width: "100%" }}
                                        step="any"
                                        value={expenseFormdata[`${x.id}`].data}
                                        onChange={(e) => {
                                          const ele =
                                            document.getElementById(
                                              "total_amt"
                                            );
                                          const targetEle =
                                            document.getElementById(
                                              "transferred_amt"
                                            );
                                          const transferred_amt =
                                            ele.value - e.target.value;
                                          // console.log(transferred_amt, targetEle)
                                          targetEle.value = transferred_amt;

                                          let obj = JSON.parse(
                                            JSON.stringify(expenseFormdata)
                                          );
                                          obj[`${x.id}`] = {};
                                          obj[`${x.id}`].data = e.target.value;
                                          obj[`transferred_amt`] = {};
                                          obj[`transferred_amt`].data =
                                            transferred_amt;
                                          setExpenseFormData({ ...obj });
                                        }}
                                        readOnly={
                                          !expenseEditAccess.includes(role) ||
                                          ((milestoneStatus == "initial" ||
                                            milestoneStatus == "current" ||
                                            milestoneStatus == "subimitted" ||
                                            milestoneStatus == "dispersion") &&
                                          expenseFormdata?.status != "accepted"
                                            ? false
                                            : true)
                                        }
                                      />
                                    </div>

                                    <div
                                      className="triStateCheckBox"
                                      style={{
                                        background:
                                          expenseFormdata[`${x.id}`]?.status ==
                                          "rejected"
                                            ? "#FA8484"
                                            : "#fff",
                                      }}
                                      onClick={() => {
                                        if (
                                          expenseActionEditAccess.includes(
                                            role
                                          ) &&
                                          (milestoneStatus == "initial" ||
                                            milestoneStatus == "current" ||
                                            milestoneStatus == "subimitted" ||
                                            milestoneStatus == "dispersion") &&
                                          expenseFormdata?.status != "accepted"
                                        ) {
                                          let obj = { ...expenseFormdata };

                                          if (
                                            expenseFormdata[`${x.id}`].status ==
                                            "created"
                                          ) {
                                            obj[`${x.id}`].status = "accepted";
                                          } else if (
                                            expenseFormdata[`${x.id}`].status ==
                                            "accepted"
                                          ) {
                                            obj[`${x.id}`].status = "rejected";
                                          } else {
                                            obj[`${x.id}`].status = "created";
                                          }
                                          setExpenseFormData(obj);
                                        }
                                      }}
                                    >
                                      {/* {JSON.stringify(expenseFormdata[`${x.id}`]).toString()} */}
                                      {expenseFormdata[`${x.id}`]?.status ==
                                        "created" ||
                                      !expenseFormdata[`${x.id}`]?.status ? (
                                        <></>
                                      ) : expenseFormdata[`${x.id}`]?.status ==
                                        "accepted" ? (
                                        Icons.check
                                      ) : (
                                        Icons.close
                                      )}
                                    </div>
                                  </div>

                                  <div className="expenseViewFormElement">
                                    <label
                                      htmlFor={Fields[i].id}
                                      className="expenseLabel"
                                      // style={{ width: "30%", height: "30px" }}
                                    >
                                      {"Transferred Amount"}
                                    </label>

                                    <div
                                      className=""
                                      style={{
                                        // width: "70%",
                                        // height: "30px",
                                        display: "flex",
                                        gap: "1rem",
                                        width: "60%",
                                      }}
                                    >
                                      <input
                                        type={Fields[i].type}
                                        className="expViewInput"
                                        // pattern={Fields[i].type == "number"}
                                        id={"transferred_amt"}
                                        required
                                        // style={{ width: "70%", height: "30px" }}
                                        style={{ width: "100%" }}
                                        step="any"
                                        value={expenseFormdata[`transferred_amt`]?.data}
                                        onChange={(e) => {
                                          let obj = JSON.parse(
                                            JSON.stringify(expenseFormdata)
                                          );
                                          obj[`transferred_amt`] = {};
                                          obj[`transferred_amt`].data =
                                            e.target.value;
                                          setExpenseFormData({ ...obj });
                                        }}
                                        readOnly={
                                          !expenseEditAccess.includes(role) ||
                                          ((milestoneStatus == "initial" ||
                                            milestoneStatus == "current" ||
                                            milestoneStatus == "subimitted" ||
                                            milestoneStatus == "dispersion") &&
                                          expenseFormdata?.status != "accepted"
                                            ? false
                                            : true)
                                        }
                                      />
                                    </div>

                                    <div
                                      className="triStateCheckBox"
                                      style={{
                                        background:
                                          expenseFormdata[`transferred_amt`]?.status ==
                                          "rejected"
                                            ? "#FA8484"
                                            : "#fff",
                                      }}
                                      onClick={() => {
                                        if (
                                          expenseActionEditAccess.includes(
                                            role
                                          ) &&
                                          (milestoneStatus == "initial" ||
                                            milestoneStatus == "current" ||
                                            milestoneStatus == "subimitted" ||
                                            milestoneStatus == "dispersion") &&
                                          expenseFormdata?.status != "accepted"
                                        ) {
                                          let obj = { ...expenseFormdata };

                                          if (
                                            expenseFormdata[`transferred_amt`].status ==
                                            "created"
                                          ) {
                                            obj[`transferred_amt`].status = "accepted";
                                          } else if (
                                            expenseFormdata[`transferred_amt`].status ==
                                            "accepted"
                                          ) {
                                            obj[`transferred_amt`].status = "rejected";
                                          } else {
                                            obj[`transferred_amt`].status = "created";
                                          }
                                          setExpenseFormData(obj);
                                        }
                                      }}
                                    >
                                      {/* {JSON.stringify(expenseFormdata[`${x.id}`]).toString()} */}
                                      {expenseFormdata[`transferred_amt`]?.status ==
                                        "created" ||
                                      !expenseFormdata[`transferred_amt`]?.status ? (
                                        <></>
                                      ) : expenseFormdata[`transferred_amt`]?.status ==
                                        "accepted" ? (
                                        Icons.check
                                      ) : (
                                        Icons.close
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          );
                        }

                        return (
                          <>
                            {i == 0 ? (
                              <div className="expenseViewFormElement">
                                <label
                                  htmlFor={"category"}
                                  className="expenseLabel"
                                >
                                  Category
                                </label>
                                <select
                                  name="Category"
                                  id="category"
                                  className="expViewInput"
                                  required
                                  style={{ paddingLeft: "0px" }}
                                  value={expenseFormdata?.category?.data}
                                  onChange={(e) => {
                                    let obj = { ...expenseFormdata };
                                    obj[`category`].data = e.target.value;
                                    setExpenseFormData(obj);
                                  }}
                                  disabled={
                                    !expenseEditAccess.includes(role) ||
                                    ((milestoneStatus == "initial" ||
                                      milestoneStatus == "current" ||
                                      milestoneStatus == "subimitted" ||
                                      milestoneStatus == "dispersion") &&
                                    expenseFormdata?.status != "accepted"
                                      ? false
                                      : true)
                                  }
                                >
                                  <option value="capital">Capital</option>
                                  <option value="consumables">
                                    Consumables
                                  </option>
                                  <option value="salaries">Salaries</option>
                                  <option value="outsourcing">
                                    Outsourcing
                                  </option>
                                  <option value="rent_facilities">
                                    Rent and Facilities
                                  </option>
                                  <option value="travel">Travel</option>
                                </select>
                                <div
                                  className="triStateCheckBox"
                                  style={{
                                    background:
                                      expenseFormdata[`category`]?.status ==
                                      "rejected"
                                        ? "#FA8484"
                                        : "#fff",
                                  }}
                                  onClick={() => {
                                    if (
                                      expenseActionEditAccess.includes(role) &&
                                      (milestoneStatus == "initial" ||
                                        milestoneStatus == "current" ||
                                        milestoneStatus == "subimitted" ||
                                        milestoneStatus == "dispersion") &&
                                      expenseFormdata?.status != "accepted"
                                    ) {
                                      let obj = { ...expenseFormdata };
                                      if (
                                        expenseFormdata[`category`]?.status ==
                                        "created"
                                      ) {
                                        obj[`category`].status = "accepted";
                                      } else if (
                                        expenseFormdata[`category`]?.status ==
                                        "accepted"
                                      ) {
                                        obj[`category`].status = "rejected";
                                      } else {
                                        obj[`category`].status = "created";
                                      }
                                      setExpenseFormData(obj);
                                    }
                                  }}
                                >
                                  {expenseFormdata[`category`]?.status ==
                                  "created" ? (
                                    <></>
                                  ) : expenseFormdata[`category`]?.status ==
                                    "accepted" ? (
                                    Icons.check
                                  ) : (
                                    Icons.close
                                  )}
                                </div>
                              </div>
                            ) : null}

                            <div
                              className="expenseViewFormElement"
                              style={
                                !expenseEditAccess.includes(role)
                                  ? {
                                      display:
                                        x.id == "tds" &&
                                        !expenseFormdata[`${x.id}`].data
                                          ? "none"
                                          : "",
                                    }
                                  : null
                              }
                            >
                              <label
                                htmlFor="serial_number"
                                className="expenseLabel"
                              >
                                {x.field}
                              </label>
                              <input
                                type={x.type}
                                id={x.id}
                                className="expViewInput"
                                step={"any"}
                                value={
                                  expenseFormdata[`${x.id}`]
                                    ? x.type != "date"
                                      ? expenseFormdata[`${x.id}`].data
                                      : expenseFormdata[`${x.id}`].data.slice(
                                          0,
                                          10
                                        )
                                    : ""
                                }
                                onChange={(e) => {
                                  let obj = JSON.parse(
                                    JSON.stringify(expenseFormdata)
                                  );
                                  obj[`${x.id}`] = {};
                                  obj[`${x.id}`].data = e.target.value;
                                  setExpenseFormData({ ...obj });
                                }}
                                readOnly={
                                  !expenseEditAccess.includes(role) ||
                                  ((milestoneStatus == "initial" ||
                                    milestoneStatus == "current" ||
                                    milestoneStatus == "subimitted" ||
                                    milestoneStatus == "dispersion") &&
                                  expenseFormdata?.status != "accepted"
                                    ? false
                                    : true)
                                }
                              ></input>

                              <div
                                className="triStateCheckBox"
                                style={{
                                  background:
                                    expenseFormdata[`${x.id}`]?.status ==
                                    "rejected"
                                      ? "#FA8484"
                                      : "#fff",
                                }}
                                onClick={() => {
                                  if (
                                    expenseActionEditAccess.includes(role) &&
                                    (milestoneStatus == "initial" ||
                                      milestoneStatus == "current" ||
                                      milestoneStatus == "subimitted" ||
                                      milestoneStatus == "dispersion") &&
                                    expenseFormdata?.status != "accepted"
                                  ) {
                                    let obj = { ...expenseFormdata };

                                    if (
                                      expenseFormdata[`${x.id}`].status ==
                                      "created"
                                    ) {
                                      obj[`${x.id}`].status = "accepted";
                                    } else if (
                                      expenseFormdata[`${x.id}`].status ==
                                      "accepted"
                                    ) {
                                      obj[`${x.id}`].status = "rejected";
                                    } else {
                                      obj[`${x.id}`].status = "created";
                                    }
                                    setExpenseFormData(obj);
                                  }
                                }}
                              >
                                {/* {JSON.stringify(expenseFormdata[`${x.id}`]).toString()} */}
                                {expenseFormdata[`${x.id}`]?.status ==
                                  "created" ||
                                !expenseFormdata[`${x.id}`]?.status ? (
                                  <></>
                                ) : expenseFormdata[`${x.id}`]?.status ==
                                  "accepted" ? (
                                  Icons.check
                                ) : (
                                  Icons.close
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })
                    : null}
                  {expenseActionEditAccess.includes(role) ? (
                    <div
                      className="expenseViewFormElement"
                      style={{ marginTop: "2rem" }}
                    >
                      <div className=""></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flex: "1",
                          marginRight: "1rem",
                        }}
                      >
                        Check All
                      </div>
                      <div
                        className="triStateCheckBox"
                        onClick={() => {
                          checkAllHandler();
                        }}
                      >
                        {checkAll ? Icons.check : <></>}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="expenseFormViewElementPart">
                <textarea
                  className="remarksField"
                  placeholder="Remarks"
                  id="remark"
                  value={expenseFormdata ? expenseFormdata["remark"] : ""}
                  onChange={(e) => {
                    let obj = { ...expenseFormdata };
                    obj[`remark`] = e.target.value;
                    setExpenseFormData(obj);
                  }}
                  readOnly={
                    !expenseActionEditAccess.includes(role) ||
                    ((milestoneStatus == "initial" ||
                      milestoneStatus == "current" ||
                      milestoneStatus == "subimitted" ||
                      milestoneStatus == "dispersion") &&
                    expenseFormdata?.status != "accepted"
                      ? false
                      : true)
                  }
                ></textarea>

                {(milestoneStatus == "initial" ||
                  milestoneStatus == "current" ||
                  milestoneStatus == "subimitted" ||
                  milestoneStatus == "dispersion") &&
                expenseFormdata?.status != "accepted" &&
                expenseEditAccess.includes(role) ? (
                  <div className="submissionBox">
                    <CustomButton
                      title="Save"
                      onClickHandler={() => {
                        saveExpenseFormInternal();
                      }}
                    />
                  </div>
                ) : (milestoneStatus == "initial" ||
                    milestoneStatus == "current" ||
                    milestoneStatus == "subimitted" ||
                    milestoneStatus == "dispersion") &&
                  expenseFormdata?.status != "accepted" &&
                  expenseActionEditAccess.includes(role) ? (
                  <div className="submissionBox">
                    <CustomButton
                      title="Accept"
                      onClickHandler={() => {
                        acceptForm();
                      }}
                    />
                    <CustomButton
                      title="Reject"
                      onClickHandler={() => {
                        rejectForm();
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="expensePdfViewer">
              <Document
                file={expenseFormdata ? expenseFormdata?.file?.data : ""}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  renderAnnotationLayer={null}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                />
              </Document>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center ",
                  position: "sticky",
                  bottom: "10px",
                  width: "100%",
                  bottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "fit-content",
                    background: "#F0F3F4",
                    display: "flex",
                    alignItems: "center",
                    opacity: "0.6",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      padding: "3px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={pdfBackWard}
                  >
                    {Icons.arrowBackword}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1.2rem",
                    }}
                  >
                    {pageNumber} / {numPages}
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      padding: "3px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={pdfForward}
                  >
                    {Icons.arrowFormward}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="expenseFormView cenetrEmpty">
              No form to display
            </div>
            <div className="expensePdfViewer cenetrEmpty">
              No pdf to display
            </div>
          </>
        )}
      </div>

      <Dialog
        classes={"expensePopUpModal"}
        open={open}
        onClose={closeModal}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1400px", // Set your width here
              padding: "1rem",
            },
          },
        }}
      >
        <ExpensesForm
          onClose={closeModal}
          currentCategory={currentCategory}
          refresh={initilizeView}
        />
      </Dialog>
    </div>
  );
};
export default ExpenseViewer;
