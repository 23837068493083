import { Pattern } from "@mui/icons-material";
import { height } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { postRequest } from "../../../../../utils/axiosClient/axios";
import DragDropFile from "./expenseFileDragAndDrop";
import "./expense_form.css";
import CloseIcon from "@mui/icons-material/Close";
import { uploadExpense } from "../../../../../data/network/api/milestone";
import { globalDispatch } from "../../../../../providers/GlobalProvider";
import { useParams } from "react-router-dom";
import { DialogBody } from "../../../../../utils/customStyledComponents";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Icons } from "../../../../../utils/mediaExports";
import CustomButton from "../../../../customComponents/CustomButton";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import { useSnackbar } from "notistack";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ExpensesForm = (props) => {
  const params = useParams();
  const [categoryVal, setCategoryVal] = useState("capital");

  useEffect(() => {
    switch (props.currentCategory) {
      case 0:
        setCategoryVal("capital");
        break;
      case 1:
        setCategoryVal("consumables");
        break;
      case 2:
        setCategoryVal("salaries");
        break;
      case 3:
        setCategoryVal("outsourcing");
        break;
      case 4:
        setCategoryVal("rent_facilities");
        break;
      case 5:
        setCategoryVal("travel");
        break;
    }
  }, [props.currentCategory]);

  const [isTds, setIsTds] = useState("no");

  const Fields = [
    // { field: "Serial Number", type: "number", id: "serial_number" },
    { field: "Name of Item", type: "text", id: "name_of_item" },
    { field: "Name of Vendor", type: "text", id: "name_of_vendor" },
    { field: "Invoice Number", type: "text", id: "invoice_number" },
    { field: "Invoice Date", type: "date", id: "invoice_date" },
    { field: "Payment Date", type: "date", id: "payment_date" },
    { field: "Transaction ID", type: "text", id: "cheque_number" },
    // { field: "Base Amount", type: "number", id: "base_amt" },
    // { field: "Gross Amount", type: "number", id: "gross_amt" },
    // { field: "Net Amount", type: "number", id: "net_amt_value" },
    { field: "Total Amount", type: "number", id: "total_amt_value" },
    { field: "TDS Amount", type: "number", id: "tds" },
    // { field: "GST", type: "number", id: "gst" },
  ];
  let rows = [];
  for (let i = 0; i < Fields.length; i++) {
    let inputField = (
      <input
        type={Fields[i].type}
        className="expenseInputContainer"
        pattern={Fields[i].type == "number"}
        id={Fields[i].id}
        required
        style={{ width: "70%" }}
      />
    );

    if (Fields[i].type == "number") {
      console.log("printing number names", Fields[i].field);
      inputField = (
        <input
          type={Fields[i].type}
          className="expenseInputContainer"
          id={Fields[i].id}
          required
          style={{ width: "70%", height: "30px" }}
          step={"any"}
          onChange={(e) => {
            if (Fields[i].id == "total_amt_value") {
              document.getElementById("tds_value").value = null;
              document.getElementById("transferred_amt_value").value = e.target.value;
            }
          }}
        />
      );
    }
    if (i == 0) {
      rows.push(
        <div className="inputFields">
          <label
            htmlFor={"category"}
            className="expenseFormLabels"
            style={{ width: "30%", height: "30px" }}
          >
            Category
          </label>
          <select
            name="Category"
            id="category"
            className="expenseInputContainer"
            required
            style={{ width: "70%", height: "30px" }}
            value={categoryVal}
            onChange={(e) => setCategoryVal(e.target.value)}
          >
            <option value="capital">Capital</option>
            <option value="consumables">Consumables</option>
            <option value="salaries">Salaries</option>
            <option value="outsourcing">Outsourcing</option>
            <option value="rent_facilities">Rent and Facilities</option>
            <option value="travel">Travel</option>
          </select>
        </div>
      );
    }

    if (Fields[i].id == "tds") {
      rows.push(
        <>
          <div className="inputFields">
            <label
              htmlFor={Fields[i].id}
              className="expenseFormLabels"
              style={{ width: "30%", height: "30px" }}
            >
              Do you have TDS ?
            </label>

            <div className="valueField" style={{ width: "70%" }}>
              <label htmlFor="tdsRadio" className="expenseFormLabels">
                <input
                  type="radio"
                  id="isTds"
                  name="isTds"
                  value="yes"
                  checked={isTds == "yes"}
                  onChange={(e) => setIsTds(e.target.value)}
                />
                <label for="yes">Yes</label>
              </label>

              <label htmlFor="tdsRadio" className="expenseFormLabels">
                <input
                  type="radio"
                  id="isTds"
                  name="isTds"
                  value="no"
                  checked={isTds == "no"}
                  onChange={(e) => setIsTds(e.target.value)}
                />
                <label for="yes">No</label>
              </label>
            </div>
          </div>

          {isTds == "yes" ? (
            <>
              <div className="inputFields">
                <label
                  htmlFor={Fields[i].id}
                  className="expenseFormLabels"
                  style={{ width: "30%", height: "30px" }}
                >
                  {Fields[i].field}
                </label>

                <input
                  type={Fields[i].type}
                  className="expenseInputContainer"
                  // pattern={Fields[i].type == "number"}
                  id={"tds_value"}
                  required
                  style={{ width: "70%", height: "30px" }}
                  step="any"
                  onChange={(e) => {
                    const ele = document.getElementById("total_amt_value");
                    const targetEle = document.getElementById("transferred_amt_value");
                    const transferred_amt = ele.value - e.target.value;
                    targetEle.value = transferred_amt;
                  }}
                />
              </div>

              <div className="inputFields">
                <label
                  htmlFor={Fields[i].id}
                  className="expenseFormLabels"
                  style={{ width: "30%", height: "30px" }}
                >
                  Transferred Amount
                </label>

                <input
                  type={Fields[i].type}
                  className="expenseInputContainer"
                  // pattern={Fields[i].type == "number"}
                  id={"transferred_amt_value"}
                  required
                  style={{ width: "70%", height: "30px" }}
                  step="any"
                />
              </div>
            </>
          ) : null}
        </>
      );
    } else
      rows.push(
        <div className="inputFields">
          <label
            htmlFor={Fields[i].id}
            className="expenseFormLabels"
            style={{ width: "30%", height: "30px" }}
            
          >
            {Fields[i].field}
          </label>
          {inputField}
        </div>
      );
  }
  const [Formfile, setFormfile] = useState(null);
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(0);

  function handleFileChange(newValue) {
    setFormfile(newValue);
  }
  const { enqueueSnackbar } = useSnackbar();

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const controller = new AbortController();
    if (!Formfile) {
      snackbarHandler("File is required!", "error");
      return;
    }
    const formData = new FormData();
    formData.append("file", Formfile);
    for (let i = 0; i < e.target.length - 1; i++) {
      formData.append(e.target[i].id, e.target[i].value);
    }

    formData.append("repId", params.repId);
    formData.append("applicationId", params.applicationId);
    formData.append("milestoneId", "");
    const formuploaddata = await uploadExpense({
      data: formData,
      dispatch,
      controller,
    });

    if (formuploaddata && formuploaddata.status == 200) {
      snackbarHandler("Expense form added", "success");
      props.onClose();
      props?.refresh();
    } else if (formuploaddata && formuploaddata.status == 410) {
      snackbarHandler("Invoice Number exists!", "error");
    } else {
      snackbarHandler(
        formuploaddata?.msg?.msg ||
          formuploaddata?.msg ||
          "Something went wrong!",
        "error"
      );
    }
  };

  const confirmClose = () => {
    setConfirmDialog(true);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handlePrev = () => {
    if (pageNumber == 1) return;
    setPageNumber(pageNumber - 1);
  };
  const handleNext = () => {
    if (pageNumber == numPages) return;
    setPageNumber(pageNumber + 1);
  };

  return (
    <DialogBody>
      <div className="closeButton" onClick={confirmClose}>
        {Icons.close}
      </div>
      <DialogTitle
        style={{ padding: "0", marginBottom: "1rem", fontSize: "1.7rem" }}
      >
        Add Expense
      </DialogTitle>
      <form className="overAllExpenseForm" onSubmit={submitForm}>
        <div className="expenseFormFieldContainer">{rows}</div>
        {Formfile ? (
          <div
            style={{
              border: "1px solid black",
              borderRadius: "10px",
              width: "100%",
              height: "70vh",
              overflow: "auto",
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                position: "sticky",
                top: "10px",
                right: "10px",
                zIndex: "99999",
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "10px",
              }}
              onClick={() => {
                setFormfile(null);
                setPageNumber(1);
                setNumPages(0);
              }}
            >
              {Icons.close}
            </div>
            <Document file={Formfile} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={pageNumber}
                className="pdfPage"
                renderAnnotationLayer={null}
                renderTextLayer={false}
              />
            </Document>

            <div
              className=""
              style={{
                position: "sticky",
                bottom: "10px",
                display: "flex",
                opacity: "0.4",
                background: "#F0F3F4",
                width: "max-content",
                margin: "0 auto",
                borderRadius: "8px",
                padding: "0.5rem 1rem",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  transform: "rotate(180deg)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "2rem",
                }}
                onClick={handlePrev}
              >
                {Icons.arrowFormward}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.2rem",
                }}
              >
                {pageNumber} / {numPages}
              </div>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "2rem",
                }}
                onClick={handleNext}
              >
                {Icons.arrowFormward}
              </div>
            </div>
          </div>
        ) : (
          <DragDropFile file={Formfile} onChange={handleFileChange} />
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          {/* <button className="expenseFormSubmitButton" type="submit">
            Save
          </button> */}

          <CustomButton title="Save" type="submit" />
        </div>
      </form>

      <Dialog open={confirmDialog}>
        <DialogBody>
          <div className="closeButton" onClick={() => setConfirmDialog(false)}>
            {Icons.close}
          </div>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            <div style={{ marginBottom: "1rem" }}>
              Are you sure ? Data will be lost!
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton title={"Confirm"} onClickHandler={props.onClose} />
            </div>
          </DialogContent>
        </DialogBody>
      </Dialog>
    </DialogBody>
  );
};
export default ExpensesForm;
