import styled from "styled-components";
const CustomExpenseCatTab = ({ finCatTabs, activeTab, changeActiveTab }) => {
  return (
    <Container>
      {Object.keys(finCatTabs).map((startupTab, i) => (
        <div
          className={`tab-item ${activeTab == i ? "active" : ""}`}
          onClick={() => changeActiveTab(i)}
          key={i}
        >
          {finCatTabs[startupTab]}
          <div className="finCatFormCount"> 00</div>
          <div
            style={{
              width: "2px",
              height: "100%",
              backgroundColor: "black",
              marginLeft: "9px",
            }}
          ></div>
        </div>
      ))}
    </Container>
  );
};
export default CustomExpenseCatTab;
const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  .finCatFormCount {
    padding: 2px;
    margin-left: 10px;
    border-radius: 20px;
    background-color: white;
  }
  .active .finCatFormCount {
    padding: 2px;
    margin-left: 10px;
    border-radius: 20px;
    background-color: var(--primary);
  }
  .tab-item {
    color: var(--text1);
    letter-spacing: 1px;
    font-size: 1rem;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid #D0D3D4;
    transition: 0.1s;
    position: relative;
    margin-bottom: 0.5rem;
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0rem;
      width: 0%;
      height: 100%;
      border-bottom: 2px solid var(--primary);
      transition: 0.3s;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
  .active {
    pointer-events: none;
    text-shadow: 0px 0px 0.8px var(--text1);
    &::after {
      width: 100%;
      border-bottom: 3px solid var(--primary);
      transition: 0.05s;
    }
  }
`;