import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  application: null,
  applicationData: null,
  progress: {},
  isLateral: false,
}

const BigApplicationSlice = createSlice({
  name: 'big_application',
  initialState: { ...INITIAL_STATE },
  reducers: {
    updateBigApplication: (state, actions) => {
      console.log(actions)
      state[actions.payload.id] = actions.payload.value
    },
  },
})

export const { updateBigApplication } = BigApplicationSlice.actions

export default BigApplicationSlice.reducer
