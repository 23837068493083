import NewApplicationModel from "../customComponents/modals/NewApplicantModal";
import CustomTextSearch from "../../customComponents/CustomSearchText";
import CustomButton from "../../customComponents/CustomButton";
import { useState, useEffect } from "react";
import { ApplicationStatus } from "../../../utils/constants";
import { Gif } from "../../../utils/mediaExports";
import {
  TableContainer,
  Table,
  TableCell,
  Dialog,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SchemePhaseTabContainer } from "../ikp/prayas/components/Applications";
import { useSearchParams } from "react-router-dom";
import {
  globalDispatch,
  globalSelector,
} from "../../../providers/GlobalProvider";
import { useSnackbar } from "notistack";
import { getInvestmentApplications } from "../../../data/network/api/investment";
import { handleTopBarTitle } from "../../../utils/helperHandler";
import paths from "../../../utils/links";

const HeadColumn = ({ loading, invites }) => {
  const navigate = useNavigate();
  return (
    <TableContainer className="tableContainer pm_applicationsTable">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={"50px"} align="center">
              Sl No.
            </TableCell>
            <TableCell>Company Name</TableCell>
            {/* <TableCell>Scheme</TableCell> */}
            <TableCell>Email ID</TableCell>
            <TableCell>Date of Application</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5}>
                <div className="loading">
                  <img src={Gif.flying} alt="loading" />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            invites.map((invite, index) => {
              return (
                <TableRow
                  key={index}
                  className="applicationRow"
                  onClick={() =>
                    // call_id
                    navigate(`/investment/applications/${invite._id}`)
                  }
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell>{invite.companyName}</TableCell>
                  <TableCell>{invite?.applicantEmail}</TableCell>
                  <TableCell>
                    {invite?.createdAt
                      ? new Date(invite?.createdAt).toLocaleDateString()
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <span className="statusText">
                      {ApplicationStatus[invite?.status] || invite?.status}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })
          )}

          {!loading && invites.length == 0 ? (
            <TableRow>
              <TableCell colSpan={5}>
                <div className="no-application">No Applications!</div>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const InvestmentApplications = ({ call }) => {
  const { user } = globalSelector((state) => state.auth);
  let [searchParams, setSearchParams] = useSearchParams();
  let schemeId = searchParams.get("schemeId");
  if (!schemeId || (schemeId && schemeId.length <= 1)) {
    schemeId = "none";
  }
  const [open, setOpen] = useState(false);
  const [investmentInvites, setInvestmentInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const controller = new AbortController();
  const dispatch = globalDispatch();

  const modalHandler = (e, reason) => {
    if (reason == "backdropClick") return;
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const getInvites = async () => {
    const response = await getInvestmentApplications({
      data: { schemeId: schemeId },
      controller,
      dispatch,
    });
    if (response && response.status == 200) {
      setInvestmentInvites(response?.msg?.applications || []);
    } else {
      snackbarHandler(
        response?.msg?.msg || response?.msg || "Something went wrong!",
        "error"
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    handleTopBarTitle(dispatch, paths.investment);
    // setSidebarActive(paths.big.id);

    document.getElementById("pageNavStackContainer").style.display = "none";

    getInvites();
  }, []);

  return (
    <SchemePhaseTabContainer>
      {
        user.role.roleId == "program_manager" || user.role.roleId == "super_admin" ? <div className="topRow">
        <CustomTextSearch setValue={"Search..."} upcomingBanner={true} />
        <CustomButton title="Invite" onClickHandler={() => setOpen(true)} />
      </div> : <></>
      }

      <HeadColumn loading={loading} invites={investmentInvites}></HeadColumn>

      <Dialog open={open} onClose={modalHandler}>
        <NewApplicationModel
          modalHandler={modalHandler}
          refreshApplications={getInvites}
          call={null}
          componentId={"investmentApplication"}
          scheme={"investment"}
        />
      </Dialog>
    </SchemePhaseTabContainer>
  );
};

export default InvestmentApplications;
