export const domainAndSectors = [
  'Automobile',
  'Information Technology',
  'Medtech',
  'Agritech',
  'Diagnostics',
  'Biotechnology',
  'Manufacturing',
]

export const companyStatus = [
  {
    title: 'Active',
    value: 'Active',
  },
  {
    title: 'Closed',
    value: 'Closed',
  },
  {
    title: 'Acquired',
    value: 'Acquired',
  },
  {
    title: 'In Process of Closing',
    value: 'In Process of Closing',
  },
]

export const booleanOptions = [
  {
    title: 'Yes',
    value: true,
  },
  {
    title: 'No',
    value: false,
  },
]

export const trlLevels = [
  {
    title: '1 - Basic principles observed and reported',
    value: 1,
  },
  {
    title: '2 - Technology concept and/or application formulated.',
    value: 2,
  },
  {
    title:
      '3 - Analytical and experimental critical function and/or characteristic proof of concept.',
    value: 3,
  },
  {
    title:
      '4 - Component and/or breadboard validation in laboratory environment',
    value: 4,
  },
  {
    title:
      '5 - Component and/or breadboard validation in relevant environment.',
    value: 5,
  },
  {
    title:
      '6 - System/sub-system model or prototype demonstration in an operational environment.',
    value: 6,
  },
  {
    title: '7 - System prototype demonstration in an operational environment.',
    value: 7,
  },
  {
    title:
      '8 - Actual system completed and flight qualified through test and demonstration.',
    value: 8,
  },
  {
    title:
      '9 - Actual system flight proven through successful mission operations.',
    value: 9,
  },
]

export const investmentsKind = [
  {
    title: 'Debt',
    value: 'debt',
  },
  {
    title: 'Equity',
    value: 'equity',
  },
]

export const gender = [
  {
    title: 'Female',
    value: 'female',
  },
  {
    title: 'Male',
    value: 'male',
  },
  {
    title: 'Others',
    value: 'others',
  },
]

export const educationQualification = [
  {
    title: 'Highschool',
    value: 'highschool',
  },
  {
    title: 'Bachelors',
    value: 'bachelors',
  },
  {
    title: 'Masters',
    value: 'masters',
  },
]

export const planDuration = [
  {
    title: '3 Months',
    value: '3_months',
  },
  {
    title: '6 Months',
    value: '6_months',
  },
  {
    title: '1 year',
    value: '1_year',
  },
  {
    title: '5 year',
    value: '5_year',
  },
]

export const schemes = [
  {
    id: 'big',
    title: 'Biotechnology Ignition Grant',
    value: 'big',
  },
  {
    id: 'prayas',
    title: 'Prayas Grant',
    value: 'prayas',
  },
]
