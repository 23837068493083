import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getStartupData } from "../../../data/network/api/startupForm";
import { globalDispatch, globalSelector } from "../../../providers/GlobalProvider";
import { OutletContainer } from "../../../utils/customStyledComponents";
import { handleTopBarTitle, setSidebarActive } from "../../../utils/helperHandler";
import paths from "../../../utils/links";

const StartupData = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  const dispatch = globalDispatch();
  const controller = new AbortController();

  useEffect(() => {
    setData(null);
    handleTopBarTitle(dispatch, {title: '', link: paths.startups.path});
    setSidebarActive(paths.startups.id);
    getStartupDataHandler();
  }, []);

  const getStartupDataHandler = async () => {
    try {
      const response = await getStartupData({ id: id }, dispatch, controller);
      if (response && response.status == 200) {
        setData(response.msg);
        handleTopBarTitle(dispatch, {title: response.msg?.companyName, link: paths.startups.path});
      } else if (response) {
        alert("Something went wrong!");
      } else {
        alert("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong!");
    }
  };

  return (
    <OutletContainer>
      <Fields>
        <div className="field">
          <div className="title">Brand Name</div>
          <div className="content">
            {data?.brandName ? data?.brandName : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Company Status</div>
          <div className="content">
            {data?.companyStatus ? data?.companyStatus : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Date of Incorporation</div>
          <div className="content">
            {data?.dateOfIncorporation
              ? new Date(data?.dateOfIncorporation).toLocaleDateString()
              : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Domain & Sector</div>
          <div className="content">
            {data?.companydetails?.domainAndSector
              ? data?.companydetails?.domainAndSector.toString()
              : "-"}
          </div>
        </div>


        <div className="field">
          <div className="title">Founders</div>
          <div className="content">
            {data?.people
              ? data?.people?.map((people, i) => {
                  return <span>{people}, </span>;
                })
              : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Female Founder</div>
          <div className="content">
            {data?.femaleFounder ? data?.femaleFounder.toString() : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Registered Address</div>
          <div className="content">
            {data?.contact?.officeAddress ? data?.contact?.officeAddress : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">City</div>
          <div className="content">
            {data?.contact?.city ? data?.contact?.city : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">State</div>
          <div className="content">
            {data?.contact?.state ? data?.contact?.state : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Pincode</div>
          <div className="content">
            {data?.contact?.pincode ? data?.contact?.pincode : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Website</div>
          <div className="content">
            {data?.contact?.website ? (
              <a href={data?.contact?.website}>{data?.contact?.website}</a>
            ) : (
              "-"
            )}
          </div>
        </div>

        <div className="field">
          <div className="title">LinkedIn</div>
          <div className="content">
            {data?.contact?.linkedin ? data?.contact?.linkedin : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">LinkedIn Founders</div>
          <div className="content">
            {data?.contact?.linkedinFounders
              ? data?.contact?.linkedinFounders.toString()
              : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Twitter</div>
          <div className="content">
            {data?.contact?.twitter ? data?.contact?.twitter.toString() : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Mail Ids</div>
          <div className="content">
            {data?.contact?.emails ? data?.contact?.emails?.toString() : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Phone Number</div>
          <div className="content">
            {data?.contact?.phoneNumber
              ? data?.contact?.phoneNumber?.toString()
              : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Startup Description</div>
          <div className="content">
            {data?.summary?.aboutCompany
              ? data?.summary?.aboutCompany.toString()
              : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">IKP Association</div>
          <div className="content">
            {data?.companydetails?.ikpAssociation
              ? data?.companydetails?.ikpAssociation.toString()
              : "-"}
          </div>
        </div>

        <div className="field">
          <div className="title">Remarks</div>
          <div className="content">
            {data?.companydetails?.remarks
              ? data?.companydetails?.remarks.toString()
              : "-"}
          </div>
        </div>
      </Fields>
    </OutletContainer>
  );
};

export default StartupData;

const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .field {
    .title {
        color: var(--text1);
        margin-bottom: 0.2rem;
        font-size: 1.2rem;
    }

    .content {
        font-size: 1.1rem;
    }
  }
`;
