import React from 'react'
import {
  Provider,
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
} from 'react-redux'
import GlobalStore from '../store/globalStore';

const MyContext = React.createContext(null)

// Export your custom hooks if you wish to use them in other files.
export const globalStore = createStoreHook(MyContext)
export const globalDispatch = createDispatchHook(MyContext)
export const globalSelector = createSelectorHook(MyContext)

export function GlobalProvider({ children }) {
  return (
    <Provider context={MyContext} store={GlobalStore}>
      {children}
    </Provider>
  )
}