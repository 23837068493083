import {
	Alert,
	Autocomplete,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { sendInvite } from "../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../providers/GlobalProvider";
import { postRequest } from "../../../../utils/axiosClient/axios";
import { DialogBody } from "../../../../utils/customStyledComponents";
import { Validator } from "../../../../utils/helperHandler";
import { Icons } from "../../../../utils/mediaExports";
import CustomButton from "../../../customComponents/CustomButton";
import { inviteApplicationInvestment } from "../../../../data/network/api/investment";

const initialState = {
	companyName: "",
	email: "",
	applicationType: "application",
};

const NewApplicantModal = ({
	modalHandler,
	refreshApplications,
	call,
	scheme = null,
}) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({ ...initialState });
	const [error, setError] = useState({ ...initialState });
	const [successDialog, setSuccessDialog] = useState(false);
	const [serverResponse, setServerResponse] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [fromDb, setFromDb] = useState(null);
	const [companyId, setCompanyId] = useState(null);

	const dispatch = globalDispatch();
	const controller = new AbortController();

	const handleChange = async (id, value) => {
		setFromDb(null);
		setCompanyId(null);
		setData({ ...data, [id]: value });
		if (id === "companyName") {
			await queryCompany(value);
		}
	};

	const queryCompany = async (value) => {
		let customConfig = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		if (value && value?.length && value.length >= 1) {
			let filterCompanies = await postRequest({
				endpoint: "/scheme/queryCompany",
				data: { companyName: value, isApply: true },
				headers: customConfig,
				dispatch: dispatch,
				controller: controller,
			});
			if (filterCompanies.msg.length > 0 && filterCompanies.status === 200) {
				setCompanies(filterCompanies?.msg);
			}
		}
	};

	const submitHandler = async () => {
		let error = { ...initialState };
		setServerResponse(null);
		let isvalid = true;
		Object.keys(data).map((obj, i) => {
			if (data[obj].trim() == "" || data[obj] == -1) {
				isvalid = false;
				error[obj] = `${obj} is required!`;
			}

			if (obj == "email") {
				if (!Validator.validateEmail(data[obj])) {
					isvalid = false;
					error[obj] = `Email is invalid!`;
				}
			}
		});
		setError(error);
		if (!isvalid) return;

		try {
			setLoading(true);
			let response;
			if (scheme == "investment") {
				response = await inviteApplicationInvestment({
					data: {
						email: data.email.trim(),
						companyName: data.companyName.trim(),
					},
					dispatch,
					controller,
				});
			} else {
				response = await sendInvite(
					{
						email: data.email.trim(),
						companyName: data.companyName.trim(),
						applicationType:
							call?.scheme?.schemeId == "big" ||
							call?.scheme?.schemeId == "prayas" ||
							call?.scheme?.schemeId == "titan"
								? data?.applicationType
								: null,
						callId: call?._id,
						companyDB: fromDb,
						companyId: companyId,
					},
					dispatch,
					controller
				);
			}

			if (response && response.status == 200) {
				setSuccessDialog(true);
				refreshApplications();
			} else if (response) {
				setServerResponse({
					type: "error",
					msg: response.msg || "Something went wrong!",
				});
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	const handleSuccessDialog = () => {
		setSuccessDialog(false);
		modalHandler();
	};

	if (successDialog) {
		return (
			<Dialog open={true}>
				<DialogBody>
					<div className="closeButton" onClick={handleSuccessDialog}>
						{Icons.close}
					</div>
					<DialogTitle className="dialogTitle"></DialogTitle>

					<DialogContent className="content">
						<h2 className="invite">
							Invitation Successfully sent to <span>{data.email.trim()}</span>{" "}
							for{" "}
							{scheme
								? scheme.toUpperCase()
								: `${call?.scheme?.abbr?.toUpperCase()} - ${call?.callName}`}
						</h2>

						<div style={{ display: "flex", justifyContent: "center" }}>
							<CustomButton
								title={"Continue"}
								onClickHandler={handleSuccessDialog}
							/>
						</div>
					</DialogContent>
				</DialogBody>
			</Dialog>
		);
	}

	return (
		<>
			<DialogBody>
				<div className="closeButton" onClick={modalHandler}>
					{Icons.close}
				</div>
				<DialogTitle className="dialogTitle">{`Invite Applicant for ${
					scheme == "investment"
						? "Investment"
						: `${call?.scheme?.abbr.toUpperCase()} - ${call?.callName}`
				}`}</DialogTitle>
				<DialogContent className="content">
					<Autocomplete
						className="firstField"
						label={"Company Name *"}
						size={"small"}
						freeSolo
						onChange={(e, newValue) => {
							if (newValue) {
								if (["new", "update"].includes(newValue?.queueType)) {
									setFromDb("queue");
								} else {
									setFromDb("main");
								}
								setCompanyId(newValue?._id);
								setData({
									companyName: newValue.companyName,
									email: newValue.email,
									applicationType: data?.applicationType,
								});
							}
						}}
						options={companies}
						getOptionLabel={(option) => option.companyName}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Company Name *"
								onChange={(e) => handleChange("companyName", e.target.value)}
							/>
						)}
						helperText={error.companyName}
						error={!!error.companyName}
					/>
					<TextField
						label={"Email Address *"}
						size={"small"}
						value={data.email}
						error={!!error.email}
						onChange={(e) => handleChange("email", e.target.value)}
						helperText={error.email}
					/>

					{call?.scheme?.schemeId == "big" ||
					call?.scheme?.schemeId == "prayas" ||
					call?.scheme?.schemeId == "titan" ? (
						<FormControl>
							<FormLabel>Application Type</FormLabel>
							<RadioGroup row>
								<FormControlLabel
									value="application"
									control={
										<Radio checked={data?.applicationType == "application"} />
									}
									onChange={(e) =>
										handleChange("applicationType", "application")
									}
									label="Application"
								/>
								<FormControlLabel
									value="agreement"
									control={
										<Radio checked={data?.applicationType == "agreement"} />
									}
									onChange={(e) => handleChange("applicationType", "agreement")}
									label="Agreement"
								/>
							</RadioGroup>
						</FormControl>
					) : null}

					<div className="actionField">
						{loading ? (
							<CircularProgress />
						) : (
							<Button
								onClick={submitHandler}
								className="button"
								variant="contained"
							>
								Send Invite
							</Button>
						)}
					</div>

					<div>
						{serverResponse ? (
							<Alert color={serverResponse.type}>{serverResponse.msg}</Alert>
						) : null}
					</div>
				</DialogContent>
			</DialogBody>
		</>
	);
};

export default NewApplicantModal;
