import {
	Alert,
	Chip,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormLabel,
} from "@mui/material";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { bulkUpload } from "../../../data/network/api/startupForm";
import { globalDispatch } from "../../../providers/GlobalProvider";
import { DialogBody } from "../../../utils/customStyledComponents";
import { Icons } from "../../../utils/mediaExports";
import CustomButton from "../../customComponents/CustomButton";
import { FileArea } from "../../schemes/ikp/widgets/FormBuilder";

const BulkUploadDialog = ({ handleDialogClose }) => {
	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const dispatch = globalDispatch();
	const controller = new AbortController();
	const [serverResponse, setServerResponse] = useState(null);

	const handleFile = (file) => {
		if (!file) return;
		setFile(file);
	};

	const sendFile = async () => {
		setServerResponse(null);
		try {
			setLoading(true);
			let formData = new FormData();
			formData.append("file", file);
			const response = await bulkUpload(formData, dispatch, controller);
			console.log(response);
			if (response && response.status == 200) {
				setServerResponse(response);
			} else {
				setServerResponse(
					response || {
						status: 400,
						msg: "Something went wrong!",
					}
				);
			}
		} catch (err) {
			console.log(err);
			alert("Something went wrong!");
		} finally {
			setLoading(false);
		}
	};

	return (
		<DialogBody>
			<div className="closeButton" onClick={handleDialogClose}>
				{Icons.close}
			</div>
			<DialogTitle className="dialogTitle">Upload File</DialogTitle>
			<DialogContent className="content">
				<div className="fileUploader">
					<FileUploader
						classes="component"
						name="file"
						types={["xlsx"]}
						multiple={false}
						handleChange={(file) => handleFile(file)}
						children={
							<FileArea>
								<div className="message">
									<div>Drag and Drop</div>
									<div>Or</div>
									<div>Browse for files</div>
								</div>
							</FileArea>
						}
					/>

					<div className="files">
						{file ? (
							<div className="file mt1">
								<Chip
									label={
										<a href={file.url} target={"_blank"}>
											{file.name}
										</a>
									}
									onDelete={() => {
										setFile(null);
									}}
									deleteIcon={Icons.delete}
								/>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>

				<div className="actionField">
					{loading ? (
						<CircularProgress />
					) : (
						<CustomButton title={"Upload"} onClickHandler={sendFile} />
					)}
				</div>

				{serverResponse ? (
					<div className="serverResponse">
						<Alert color={serverResponse?.status == 200 ? "success" : "error"}>
							{serverResponse?.status == 200
								? serverResponse?.msg?.rowsAdded
								: serverResponse?.msg}
						</Alert>
					</div>
				) : (
					<></>
				)}
			</DialogContent>
		</DialogBody>
	);
};

export default BulkUploadDialog;
