import { Alert, FormLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { changePassword } from "../../data/network/api/user";
import { globalDispatch } from "../../providers/GlobalProvider";
import GlobalStore from "../../store/globalStore";
import { OutletContainer } from "../../utils/customStyledComponents";
import { handleTopBarTitle, setSidebarActive, validatePasswordConstraints } from "../../utils/helperHandler";
import paths from "../../utils/links";
import { Icons } from "../../utils/mediaExports";
import CustomButton from "../customComponents/CustomButton";
import CustomTab from "../customComponents/CustomTab";
import PasswordConstraints from "../customComponents/PasswordConstraints";

const passwordData = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const Profile = () => {
  const user = GlobalStore.getState().auth.user;
  const dispatch = globalDispatch();
  const controller = new AbortController();

  const [password, setPassword] = useState({ ...passwordData });
  const [errPassword, setErrPassword] = useState({ ...passwordData });

  const [serverResponse, setServerResponse] = useState(null);

  useEffect(() => {
    handleTopBarTitle(dispatch, paths.profile);
    document.getElementById("pageNavStackContainer").style.display = "none";
  }, []);

  const changePasswordHandler = async () => {
    setServerResponse(null);
    setErrPassword({});
    if (!validatePassword()) {
      return;
    }

    const response = await changePassword(
      {
        currentPassword: password.currentPassword,
        newPassword: password.newPassword,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      setServerResponse({
        type: "success",
        body: response?.msg || "Password changed..",
      });
      setPassword({
        ...passwordData
      });
    } else {
      setServerResponse({
        type: "error",
        body: response?.msg || "Something went wrong!",
      });
    }
  };

  const validatePassword = () => {
    let err = {};
    if (!password.currentPassword) {
      err.currentPassword = "This field is required!";
    }
    if (!password.newPassword) {
      err.newPassword = "This field is required!";
    }
    if (!password.confirmPassword) {
      err.confirmPassword = "This field is required!";
    }

    if (Object.keys(err).length > 0) {
      setErrPassword(err);
      return false;
    }

    if (password.newPassword != password.confirmPassword) {
      setErrPassword({
        ...errPassword,
        confirmPassword: "Password does not match!",
      });
      return false;
    }

    const passwordConstraintsErr = validatePasswordConstraints(password.newPassword);
    let isValid = true;
    Object.keys(passwordConstraintsErr).map((key) => {
      if (!passwordConstraintsErr[key]) {
        isValid = false;
      }
    });
    if (!isValid) return false;
    return true;
  };

  return (
    <OutletContainer>
      <ProfileContainer>
        <div className="container">
          <div className="title">Details</div>
          <div className="item">
            <span className="itemTitle">Name</span>
            <span className="colon">:</span>
            <span className="itemValue">{user?.name || "-"}</span>
          </div>

          <div className="item">
            <span className="itemTitle">Phone</span>
            <span className="colon">:</span>
            <span className="itemValue">{user?.phone || "-"}</span>
          </div>

          <div className="item">
            <span className="itemTitle">Email</span>
            <span className="colon">:</span>
            <span className="itemValue">{user?.email || "-"}</span>
          </div>

          <div className="item">
            <span className="itemTitle">Role</span>
            <span className="colon">:</span>
            <span className="itemValue">{user?.role?.roleName || "-"}</span>
          </div>
        </div>

        <div className="container">
          <div className="title">Change Password</div>

          <div className="row">
            <div className="item">
              <TextField
                className="textField"
                label="Current Password"
                error={!!errPassword?.currentPassword}
                helperText={errPassword?.currentPassword || ""}
                size="small"
                type={"password"}
                value={password.currentPassword}
                onChange={(e) =>
                  setPassword({ ...password, currentPassword: e.target.value })
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="item">
              <TextField
                className="textField"
                label="New Password"
                size="small"
                type={"password"}
                value={password.newPassword}
                onChange={(e) =>
                  setPassword({ ...password, newPassword: e.target.value })
                }
                error={!!errPassword?.newPassword}
                helperText={errPassword?.newPassword || ""}
              />
              <div className="item passwordConstraintsContainer">
                <PasswordConstraints value={password.newPassword} />
              </div>
            </div>

            <div className="item">
              <TextField
                className="textField"
                label="Confirm Password"
                size="small"
                type={"password"}
                value={password.confirmPassword}
                onChange={(e) =>
                  setPassword({ ...password, confirmPassword: e.target.value })
                }
                error={!!errPassword?.confirmPassword}
                helperText={errPassword?.confirmPassword || ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="item">
              <div className="actionField">
                <CustomButton
                  title={"Submit"}
                  onClickHandler={changePasswordHandler}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="item alert">
              {serverResponse ? (
                <Alert color={serverResponse?.type}>
                  {serverResponse?.body}
                </Alert>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </ProfileContainer>
    </OutletContainer>
  );
};

export default Profile;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .container {
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.5rem;
    letter-spacing: 1.2px;
    border-radius: 8px;

    .title {
      color: var(--text3);
      font-weight: bold;
      font-size: 1.2rem;
    }

    .item {
      .itemTitle {
        color: var(--text3);
        width: 5rem;
        display: inline-block;
      }

      .colon {
        display: inline-block;
        width: 2rem;
        color: var(--text3);
      }
    }

    .row {
      display: flex;
      gap: 1.5rem;
      flex-wrap: wrap;
    }

    .passwordConstraintsContainer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 0.9rem;
      color: var(--text3);
      margin-top: 1rem;
    }
  }
`;
