import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveUser } from "../../../data/network/api/user";
import { globalDispatch, globalSelector } from "../../../providers/GlobalProvider";
import { updateData } from "../../../reducers/startupform/formReducer";
import { DialogBody } from "../../../utils/customStyledComponents";
import { Icons } from "../../../utils/mediaExports";
import { domainAndSectors } from "../../../utils/optionValues";

const MentorAddModal = ({ handleDialogClose }) => {
  const [data, setData] = useState({
    name: "",
    sector: [],
    mentoredCompanies: [],
    specialization: "",
    type: "mentor",
  });
  const [loading, setLoading] = useState(false);
  const fields = [
    "_id",
    "name",
    "sector",
    "mentoredCompanies",
    "specialization",
  ];
  let mentorData = globalSelector((state) => state.startup.association.mentors);
  const dispatch = globalDispatch();
  const controller = new AbortController();

  useEffect(() => {
    setData({ ...data, name: mentorData.name.value });
  }, []);

  useEffect(() => {
    console.log(mentorData);
  }, [mentorData]);

  const handleChange = (id, value) => {
    setData({ ...data, [id]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await addUser();
    setLoading(false);
  };

  const addUser = async () => {
    const response = await saveUser(data, dispatch, controller);
    if (response && response?.status == 200) {
      const data = response?.msg;
      fields.map((field, i) => {
        dispatch(
          updateData({
            category: "association",
            subCategory: "mentors",
            id: field,
            type: "value",
            value: data?.[field] || "",
          })
        );
      });
      handleDialogClose();
    }
  };

  return (
    <DialogBody>
      <div className="closeButton" onClick={handleDialogClose}>
        {Icons.close}
      </div>
      <DialogTitle className="dialogTitle">Add Mentor</DialogTitle>
      <DialogContent className="content">
        <TextField
          className="firstField"
          label={"Mentor Name"}
          size={"small"}
          value={data.name}
          onChange={(e) => handleChange("name", e.target.value)}
        />
        <Autocomplete
          multiple
          id="tags-outlined"
          options={domainAndSectors}
          getOptionLabel={(option) => option}
          defaultValue={[]}
          filterSelectedOptions
          onChange={(event, newValue) => {
            let values = newValue.map((object) => {
              return object;
            });
            handleChange("sector", values);
          }}
          renderInput={(params) => (
            <TextField {...params} size={"small"} label="Domain and Sector" />
          )}
        />
        <MuiChipsInput
          value={data.mentoredCompanies}
          onChange={(value) => handleChange("mentoredCompanies", value)}
          size={"small"}
          label="Mentored Companies"
        />
        <TextField
          label={"Specialization"}
          size={"small"}
          value={data.specialization}
          onChange={(e) => handleChange("specialization", e.target.value)}
        />

        <div className="actionField ">
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              className="button"
              onClick={handleSubmit}
              variant="contained"
            >
              Add
            </Button>
          )}
        </div>
      </DialogContent>
    </DialogBody>
  );
};

export default MentorAddModal;
