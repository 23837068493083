import {
    Alert,
    Autocomplete,
    Chip,
    DialogContent,
    DialogTitle,
    TextField,
    AccordionSummary,
    AccordionDetails,
    Accordion
  } from '@mui/material'
  import { useState } from 'react'
  import { useParams } from 'react-router-dom'
  import {
  
    inviteReviewerForApplication,
  } from '../../../../../../../data/network/api/scheme'
  import { globalDispatch } from '../../../../../../../providers/GlobalProvider'
  import { DialogBody } from '../../../../../../../utils/customStyledComponents'
  import { Icons } from '../../../../../../../utils/mediaExports'
  import CustomButton from '../../../../../../customComponents/CustomButton'
import { trim ,} from 'validator'
import { Validator } from '../../../../../../../utils/helperHandler'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const initialState = {
	name: "",
	email: "",
};
  const InviteReviewerActionDialog = ({
    handleActionDialog,
    getApplicationDataHandlerForManager,
    
  }) => {
    const [loading, setLoading] = useState(false)
    const [serverResponse, setServerResponse] = useState(null)
    const [inviteAssociate, setInviteAssociate] = useState([])
    const[inputData,setInputData]=useState({...initialState})
    const [error, setError] = useState({ ...initialState });
    const[expand,setExpand]=useState('true')
    const dispatch = globalDispatch()
    const controller = new AbortController()
  
    const { id, } = useParams()
  
   
   
    const handleInviteSelect = (e, value) => {
      setInviteAssociate(value)
    }
  
   
    const onInviteAssociates = async () => {
      setServerResponse(null)
     
      try {

        setLoading(true)
        let associatesData = inviteAssociate?.map((data) => {
          const reviewerData=data.split(',')
          const name=trim(reviewerData[0])
          const email=trim(reviewerData[1])
          return { name, email}
        })
       const response = await inviteReviewerForApplication(
          {
            callId: id,
            reviewers: associatesData,
          },
          dispatch,
          controller
        )
        if (response && response?.msg[0]?.status == 200) {
          setServerResponse({ type: 'success', body: response?.msg })
          getApplicationDataHandlerForManager()
        } else {
          setServerResponse({
            type: 'error',
            body: response?.msg || 'Something went wrong!',
          })
        }
      } catch (err) {
      } finally {
        setLoading(false)
      }
      setExpand(true)
    }
    const handleChange = async (id, value) => {
      const data=value.replace(/^\s/ ,'')
        setInputData({ ...inputData, [id]: data });
      
    
    };
    const onAddReviewer=()=>{
      let error = { ...initialState };
      let isvalid = true;
		Object.keys(inputData).map((obj, i) => {
			if (inputData[obj].trim() == "" || inputData[obj] == -1) {
				isvalid = false;
				error[obj] = `${obj} is required!`;
			}

			if (obj == "email") {
				if (!Validator.validateEmail(inputData[obj])) {
					isvalid = false;
					error[obj] = `Email is invalid!`;
				}
			}
		});
		setError(error);
		if (!isvalid) return;
      setInviteAssociate((state)=>([...state,`${inputData.name},${inputData.email}`]))
    setInputData({name:'',email:''}
    )
  }
  const handleAccordionChange=(e)=>{
    setExpand((prev)=>!prev)
  }
        return (
          <DialogBody >
            <div className='closeButton' onClick={handleActionDialog}>
              {Icons.close}
            </div>
  
            <DialogTitle className='dialogTitle' sx={{}}>Invite Reviewer</DialogTitle>
            <DialogContent className='content' sx={{padding:'16px'}}>
            <TextField
            label={'Name'}
            value={inputData.name}
            onChange={(e)=>handleChange('name',e.target.value)}
            sx={{marginTop:"16px"}}
            size='small'
            />
            <TextField
            label={'Email'}
            value={inputData.email}
            onChange={(e)=>handleChange('email',e.target.value)}
            size='small'
            error={!!error.email}
						helperText={error.email}
            />
            <div className='actionField'>
                <CustomButton
                  title={'Add Reviwer'}
                  onClickHandler={onAddReviewer}
                disabled={inputData.name.length>0 &&  inputData.email.length>0?false:true}

                />
              </div>
              <Autocomplete
                freeSolo
                disableClearable
                onChange={handleInviteSelect}
                options={[]}
                multiple={true}
                value={inviteAssociate}
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip
                      variant='outlined'
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    className='firstField'
                    {...params}
                    label='Invite Associates'
                  />
                )}
              />
  
              <div className='actionField' style={{marginBottom:'4px'}}>
                <CustomButton
                  title={'Submit'}
                  onClickHandler={onInviteAssociates}
                  disabled={inviteAssociate.length>0?false:true}
                />
              </div>
  
              {typeof serverResponse?.body ==='object' && serverResponse?.body?.length>0? (
                <Accordion style={{margin:'0px'}} expanded={expand} onChange={handleAccordionChange}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon  />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{backgroundColor:'#F2F2F2',margin:'0px'}}
                >
                  Invite reviewer response
                </AccordionSummary>
                <AccordionDetails style={{padding:'8px 4px',paddingTop:'4px',maxHeight:'200px',overflowY:'auto'}}  >
              {serverResponse?.body?.map((data, i) => {
                const reviewerData=inviteAssociate[i]?.split(',')
                const email=trim(reviewerData[1])
                return (
                  <AccordionDetails style={{padding:'0',marginBottom:'3px'}}>
                      
                       <Alert color={data?.status=== 200? 'success':'error'} style={{padding:'0px 2px'}} >
<div
                        
                      >
                        <div className="" style={{ display:'flex',gap:'18px'}}>
                        <div className="" style={{width:'45%',wordBreak:'break-word'}}>
                           {email}
                        </div>
                        <div className="">
                          {data?.message}
                        </div>
                        </div>
                       
                      </div>
                </Alert>
                      
                  </AccordionDetails>
                );
              })}
              </AccordionDetails>

            </Accordion>
              ) : (
                serverResponse?.body && <Alert color={'error'} style={{padding:'0px 2px'}} >
{serverResponse?.body}
                </Alert>
              )}
            </DialogContent>
          </DialogBody>
        )
    
  }
  
  export default InviteReviewerActionDialog
  