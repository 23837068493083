export class File {
  static IMAGE_MAX_SIZE = 1048576
  static VIDEO_MAX_SIZE = 15728640
  static GALLERY_VALID_FILE_TYPES = [
    'image/png',
    'image/svg+xml',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'video/mp4',
    'image/avif',
  ]
  static PDF_VALID_TYPES = ['application/pdf']
  static VIDEO_VALID_TYPES = ['video/mp4']
}
