import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const theadings = [
    {
        title: "Sl No.",
        width: "50px",
    },
    {
        title: "Startup Details", 
        width: '',
    },
    {
        title: "Clarity in Value Proposition",
        width: '',
    },
    {
        title: "Is there an unmet need ?",
        width: "",
    },
    {
        title: "Will the customer be willing to pay?",
        width: "",
    },
    {
        title: "Novelty",
        width: "",
    },
    {
        title: "Technical Feasibility ",
        width: "",
    },
    {
        title: "Team Strength",
        width: "",
    }, 
    {
        title: "Total Score",
        width: "",
    },
    {
        title: "Recommend",
        width: "",
    },
    {
        title: "Comments",
        width: "",
    },
];

const PersonTable = ({person, applications = [], callId}) => {
    const navigate = useNavigate();

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            theadings.map((heading, i) => {
                                return <TableCell align="center" width={heading.width} key={i}>{heading.title}</TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>

                <TableBody>
                        {
                            applications.map((application, i) => {
                                const data = application?.applicationData?.phase2?.data?.[person?._id];
                                return <TableRow key={i} onClick={() => navigate(`/schemes/prayas/${callId}/${application?._id}`)} className="pointer">
                                    <TableCell align="center">{i+1}</TableCell>
                                    <TableCell align="center">{application?.companyName}</TableCell>
                                    <TableCell align="center">{data?.clarity || 0}</TableCell>
                                    <TableCell align="center">{data?.unmetNeed || 0}</TableCell>
                                    <TableCell align="center">{data?.customerPay || 0}</TableCell>
                                    <TableCell align="center">{data?.novelty || 0}</TableCell>
                                    <TableCell align="center">{data?.techFeasibility || 0}</TableCell>
                                    <TableCell align="center">{data?.teamStrength || 0}</TableCell>
                                    <TableCell align="center">{data?.total || 0}</TableCell>
                                    <TableCell align="center">{data?.recommend ? "Yes" : "No" || "No"}</TableCell>
                                    <TableCell align="center">{data?.comment || "-"}</TableCell>
                                </TableRow>
                            })
                        }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PersonTable;