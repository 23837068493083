import { CircularProgress, LinearProgress } from "@mui/material"

const CustomLoader = () => {
    return (
        <div>
            <LinearProgress />
        </div>
    )
}

export default CustomLoader;