import styled from "styled-components";
import { Gif } from "../../utils/mediaExports";
import CustomButton from "../customComponents/CustomButton";

const LoadingScreen = ({ retryButton }) => {

  return (
    <Container>
      {retryButton ? (
        <CustomButton
          title={"Retry"}
          onClickHandler={() => window.location.reload()}
        />
      ) : (
        <img src={Gif.flying} />
      )}
    </Container>
  );
};

export default LoadingScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 8rem;
  }
`;
