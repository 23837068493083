import styled from "styled-components";
import { Image, Logo } from "./utils/mediaExports";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import CustomButton from "./components/customComponents/CustomButton";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { callRegister } from "./data/network/api/scheme";
import validator from "validator";
import { LoginPanel, autoLoginAttempt } from "./pages/auth/Login";
import { useEffect } from "react";
import { globalDispatch } from "./providers/GlobalProvider";
import { RegisterPanel } from "./pages/auth/Signup";

const PrayasReg = () => {
  const navigate = useNavigate();
  const dispatch = globalDispatch();
  const [email, setEmail] = useState("");
  const [callId, setCallId] = useState("65e8274d1484a8f8fce27070");

  useEffect(() => {
    autoLoginAttempt(dispatch, navigate);
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const controller = new AbortController();

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const submitHandler = async () => {
    if (!validator.isEmail(email.trim())) {
      snackbarHandler("Email is invalid!", "error");
      return;
    }
    const response = await callRegister(
      { email: email.trim(), callId: callId },
      null,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(response?.msg, "success");
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  return (
    <Container>
      <Navbar>
        <div className="logo" onClick={() => navigate("/")}>
          <img src={Logo.athenaLogo} />
        </div>
      </Navbar>

      <Content>
        <div className="panel leftpanel">
          <div className="backdrop">
            <img src={Image.loginBg} alt="Backdrop" />
          </div>

          <div className="top">
            <img src={Image.nidhiPrayas} className="nidhiLogo" />
          </div>

          <div className="middle">
            <i>Powered by</i>
          </div>

          <div className="bottom">
            <img src={Image.dstNidhi} className="dstLogo" />
            <img src={Logo.ikpLogo} className="ikpLogo" />
          </div>
        </div>

        <div className="panel rightpanel">
          <RegisterPanel
            fromScheme={"prayas"}
            heading={"Sign up here to Apply !"}
          />
        </div>
      </Content>
    </Container>
  );
};

export default PrayasReg;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100vh;
`;

const Navbar = styled.div`
  display: flex;
  height: 5rem;
  width: 100%;
  background: var(--primary);
  align-items: center;

  .logo {
    cursor: pointer;
    margin: 0 1rem;
    img {
      width: 250px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  position: relative;

  .panel {
    flex: 1;
    position: relative;
    box-sizing: border-box;
  }

  .leftpanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    overflow: hidden;
    width: 100%;

    .backdrop {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .top {
      .nidhiLogo {
        width: 20vw;
      }
    }

    .middle {
      color: var(--text1);
    }

    .bottom {
      display: flex;
      gap: 5rem;
      align-items: center;

      .dstLogo {
        width: 16vw;
        height: 6vw;
      }

      .ikpLogo {
        width: 8vw;
      }
    }
  }

  .rightpanel {
    // margin: 0 5rem;

    .topBar {
      width: 100%;
      .links {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 2rem;
        color: var(--text2);
        font-size: 0.9rem;

        span {
          cursor: not-allowed;
          transition: 0.3s color;

          &:hover {
            color: var(--primary);
          }
        }
      }
    }

    .loginForm {
      margin-top: 3rem;
      min-width: 25rem;

      .title {
        font-size: 2.5rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }

      .fields {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;

        .field {
          display: flex;
          flex-direction: column;
          z-index: 0;

          .signInfo {
            color: var(--text3);
            letter-spacing: 2px;
            font-size: 12px;
          }

          .label {
            color: var(--link-active);
            font-weight: 600;
            margin-bottom: 0.2rem;
          }

          .forgotPassword {
            margin-top: 0.2rem;
            font-size: 0.9rem;
            color: var(--primary);
            display: flex;
            justify-content: flex-end;
            cursor: not-allowed;
            transition: 0.2s color;

            &:hover {
              color: var(--text2);
            }
          }

          .socialLinks {
            display: flex;
            gap: 2rem;

            .signInSocialImg {
              width: 1rem;
              background: #fff;
              padding: 1rem;
              cursor: pointer;
              box-shadow: 2px 8px 32px rgb(1 206 175 / 32%);
              transition: 0.1s;
              border-radius: 50%;

              &:hover {
                box-shadow: 0px 2px 3px rgb(1 206 175 / 32%);
              }
            }
          }
        }
      }
    }
  }
`;
