export class ApplicationStatus {
  static pending = 'Pending'
  static rejected = 'Rejected'
  static approved = 'Approved'
  static invited = 'Invited'
  static applied = 'Applied'
  static review = 'Review'
  static eligibility = 'Eligibility Check'
  static pitchingSelection = 'Pitching Selection'

  static preInvestment = 'Pre Investment'
  static investment = 'Investment'
  static postInvestment = 'Post Investment'
  static exitInvestment = 'Exit Investment'
}

export const ApplicaitonStatusColor = {
  approved: 'green',
  rejected: '#EC7063',
  pending: 'gray',
  applied: 'gray',
  invited: 'gray',
  review: 'green',
  eligibility: 'green',
  pitchingSelection: 'green',
}

export const ApplicationStatusTextColor = {
  approved: '#fff',
  rejected: '#fff',
  pending: '#fff',
  applied: '#fff',
  invited: '#fff',
  review: '#fff',
  eligibility: '#fff',
  pitchingSelection: '#fff',
  preInvestment: '#fff',
  investment: '#fff',
  postInvestment: '#fff',
  exitInvestment: '#fff',
}

export const PasswordConstraintsText = {
  1: 'Atleast 8 and upto 16 characters',
  2: 'Atleast 1 lowercase character',
  3: 'Atleast 1 uppercase character',
  4: 'Atleast 1 special character',
  5: 'Atleast 1 number',
}

export const applicationPageStack = {
  scheme: {
    id: 'scheme',
    title: 'Schemes',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
  schemeName: {
    id: 'schemeName',
    title: 'scheme Name',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
  callName: {
    id: 'callName',
    title: 'Call Name',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
  companyName: {
    id: 'companyName',
    title: 'Company Name',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
  milestone: {
    id: 'milestone',
    title: 'Milestone NUmber',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
  expense: {
    id: 'expense',
    title: 'Expense ID',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
}

export const milestonePageStack = {
  milestones: {
    id: 'milestones',
    title: 'Milestones',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
  companyName: {
    id: 'companyName',
    title: 'Company Name',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
  milestoneNumber: {
    id: 'milestoneNumber',
    title: 'MilestoneNumber',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
  expenseManagement: {
    id: 'expenseManagement',
    title: 'Expense Management',
    isActive: false,
    block: false,
    show: false,
    path: null,
  },
}

export const documentFolderPageStack = {
  scheme: {
    id: 'scheme',
    title: 'Schemes',
    isActive: false,
    block: false,
    show: true,
    path: null,
  },
  schemeName: {
    id: 'schemeName',
    title: 'scheme Name',
    isActive: false,
    block: false,
    show: true,
    path: null,
  },
  callName: {
    id: 'callName',
    title: 'Call Name',
    isActive: false,
    block: false,
    show: true,
    path: null,
  },
  companyName: {
    id: 'companyName',
    title: 'Company Name',
    isActive: false,
    block: false,
    show: true,
    path: null,
  },
  documents: {
    id: 'documents',
    title: 'Documents',
    isActive: true,
    block: false,
    show: true,
    path: null,
  },
}

export const StartupsPage = {
  athena: {
    id: 'athena',
    title: 'Athena 1.5',
    isActive: false,
    block: true,
    show: true,
    path: null,
  },
  startups: {
    id: 'startups',
    title: 'Startups',
    isActive: true,
    block: false,
    show: true,
    path: '/',
  },
}
