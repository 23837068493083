import { Alert, Button, TextField } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/customComponents/CustomButton";
import CustomLoader from "../../components/customComponents/CustomLoader";
import LoadingScreen from "../../components/customComponents/LoadingScreen";
import PasswordConstraints from "../../components/customComponents/PasswordConstraints";
import {
  createUser,
  resendOtp,
  verifyEmail,
} from "../../data/network/api/user";
import { globalDispatch } from "../../providers/GlobalProvider";
import {
  validatePasswordConstraints,
  Validator,
} from "../../utils/helperHandler";
import paths from "../../utils/links";
import { Gif, Image, Logo } from "../../utils/mediaExports";
import { AuthContainer, AuthPanel, autoLoginAttempt } from "./Login";

const initialState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  otp: "",
};

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = globalDispatch();

  const [pageLoading, setPageLoading] = useState(true);


  useEffect(() => {
    const login = async () => {
      setPageLoading(true);
      await autoLoginAttempt(dispatch, navigate);
      setPageLoading(false);
    };
    login();
  }, []);

  if (pageLoading) {
    return <LoadingScreen />;
  }

  return (
    <AuthContainer>
      <AuthPanel className="leftPanel">
        <img className="loginBg" src={Image.loginBg} alt="LoginBg" />
        <img
          className="pageLogo"
          src={Logo.athenaLogo}
          onClick={() => navigate("/")}
        />
        <div className="content">
          <div className="logo">
            <img src={Logo.ikpLogo} />
          </div>

          <div className="textwriter">
            <div className="row">
              <div className="text text1">IKP Presents</div>
            </div>
            <div className="row">
              <div className="logo">
                <img src={Gif.flying} />
              </div>
              <div className="text text2">Athena</div>
            </div>
          </div>
        </div>
      </AuthPanel>

      <RegisterPanel />
    </AuthContainer>
  );
};

export const RegisterPanel = ({fromScheme=null, loginPath=null, heading=null}) => {
  const [registered, setRegistered] = useState(false);
  const controller = new AbortController();
  const [data, setData] = useState({ ...initialState });
  const [error, setError] = useState({ ...initialState });
  const [serverResponse, setServerResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otpPageOpen, setOtpPageOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = globalDispatch();

  const updateFields = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };
  useEffect(() => {
    if (!otpPageOpen) {
      setServerResponse(null);
    }
  }, [otpPageOpen]);


  const validate = () => {
    let isValid = true;
    let err = { ...initialState };
    Object.keys(data).map((prop, i) => {
      if (prop == "otp" || prop == "password") return;
      if (data[prop].trim() == "") {
        isValid = false;
        err[prop] = `${prop} is required!`;
        return;
      }
      if (prop == "email") {
        if (!Validator.validateEmail(data[prop])) {
          err[prop] = `Email is invalid!`;
          isValid = false;
        }
      }
    });
    setError(err);
    return isValid;
  };

  const handleSignup = async () => {
    setServerResponse(null);
    if (!validate()) {
      return;
    }

    setLoading(true);
    const response = await createUser(
      {
        name: data.name.trim(),
        email: data.email.trim(),
        phone: data.phone.trim(),
      },
      dispatch,
      controller
    );
    console.log(response);
    if (response.status != 200) {
      setServerResponse({
        type: "error",
        msg: response.msg,
      });
    } else {
      setOtpPageOpen(true);
      setServerResponse({
        type: "success",
        msg: response.msg,
      });
    }
    setLoading(false);
  };

  const resendOtpHandler = async () => {
    setServerResponse(null);
    const response = await resendOtp(
      { email: data.email },
      dispatch,
      controller
    );

    if (response && response.status != 200) {
      setServerResponse({
        type: "error",
        msg: response.msg,
      });
    } else if (response) {
      setServerResponse({
        type: "success",
        msg: response.msg,
      });
    }
  };

  const verifyEmailHandler = async () => {
    setServerResponse(null);
    let error = { ...initialState };
    let isValid = true;
    ["otp", "password"].map((property, i) => {
      if (!data[property] || data[property] == "") {
        error[property] = `${property} is required!`;
        isValid = false;
        return;
      }

      if (property == "otp") {
        if (data.otp.length != 6) {
          isValid = false;
          error.otp = `OTP is invalid!`;
        }
      }
    });
    setError(error);
    if (!isValid) {
      return;
    }

    const passwordConstraintsErr = validatePasswordConstraints(data.password);
    Object.keys(passwordConstraintsErr).map((key) => {
      if (!passwordConstraintsErr[key]) {
        isValid = false;
      }
    });
    if (!isValid) return false;

    setLoading(true);
    const response = await verifyEmail(
      {
        email: data.email,
        otp: data.otp,
        password: data.password,
      },
      dispatch,
      controller
    );

    if (response && response.status != 200) {
      setServerResponse({
        type: "error",
        msg: response?.msg,
      });
    } else if (response && response.status == 200) {
      setServerResponse({
        type: "success",
        msg: response?.msg,
      });
      setRegistered(true);
      setTimeout(() => navigate(fromScheme ? "/prayasReg" : paths.login.path), 3000);
    }
    setLoading(false);
  };
  return (
    <AuthPanel
      className="rightPanel"
      style={loading ? { pointerEvents: "none" } : null}
    >
      <div className="content">
        {/* <div className="topBar">
      <div className="links">
        <span>About</span>
        <span>Blog</span>
        <span>Terms</span>
        <span>Privacy Policy</span>
        <span>Contact Us</span>
      </div>
    </div> */}

        {!otpPageOpen ? (
          <div className="loginForm">
            <div className="title">{heading ? heading : "Create Account"}</div>

            <div className="fields">
              <div className="field">
                <div className="label">Name</div>
                <TextField
                  id="name"
                  fullWidth
                  size="small"
                  placeholder="Name"
                  onChange={updateFields}
                  value={data.name}
                  error={!!error.name}
                  helperText={!!error.name ? error.name : " "}
                />
              </div>

              <div className="field">
                <div className="label">Email</div>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Email"
                  id="email"
                  onChange={updateFields}
                  value={data.email}
                  error={!!error.email}
                  helperText={!!error.email ? error.email : " "}
                />
              </div>

              <div className="field">
                <div className="label">Phone Number</div>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Phone Number"
                  id="phone"
                  type={"tel"}
                  onChange={updateFields}
                  value={data.phone}
                  error={!!error.phone}
                  helperText={!!error.phone ? error.phone : " "}
                />
              </div>

              <div className="field mt1">
                <>
                  {loading ? (
                    <CustomLoader />
                  ) : (
                    <Button
                      className="button"
                      disableElevation
                      variant="contained"
                      fullWidth
                      onClick={handleSignup}
                    >
                      Create Account
                    </Button>
                  )}
                </>
                {serverResponse ? (
                  <div className="mt1">
                    <Alert color={serverResponse.type}>
                      {serverResponse.msg}
                    </Alert>
                  </div>
                ) : null}
              </div>

              {/* <div className="field mt2 center">
            <div className="mediumFont">Or Use</div>
            <div className="socialLinks">
              <img
                className="signInSocialImg"
                id="googleSignIn"
                src={Image.google}
              />
              <img className="signInSocialImg" src={Image.facebook} />
              <img className="signInSocialImg" src={Image.linkedin} />
            </div>
          </div> */}

              <div className="field mt2">
                <Button
                  className="button colorprimary"
                  disableElevation
                  variant="outlined"
                  fullWidth
                  onClick={() => navigate(fromScheme ? "/prayasReg" : paths.login.path)}
                >
                  Already have account? Login Here
                </Button>
              </div>
            </div>
          </div>
        ) : !registered ? (
          <div className="loginForm">
            <div className="title">Email OTP Verification</div>

            <div className="field">
              <div className="text mt2">
                Please enter the One Time Password sent via email:
                <b>{data.email}</b>
              </div>
            </div>

            <div className="fields">
              <div className="field">
                <div className="label">OTP *</div>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="OTP"
                  id="otp"
                  onChange={updateFields}
                  value={data.otp}
                  error={!!error.otp}
                  helperText={!!error.otp ? error.otp : " "}
                />
              </div>

              <div className="field">
                <div className="label">Set Password *</div>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Password"
                  id="password"
                  type={"password"}
                  onChange={updateFields}
                  value={data.password}
                  error={!!error.password}
                  helperText={!!error.password ? error.password : " "}
                />

                <PasswordConstraints value={data.password} />
              </div>

              <div className="field mt1">
                {loading ? (
                  <CustomLoader />
                ) : (
                  <Button
                    className="button"
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={verifyEmailHandler}
                  >
                    Verify Email
                  </Button>
                )}
              </div>

              <div className="field">
                <div className="col-2">
                  <span>
                    Didn’t receive the OTP yet ?{" "}
                    <span className="click" onClick={resendOtpHandler}>
                      Resend OTP
                    </span>
                  </span>

                  <span className="click" onClick={() => setOtpPageOpen(false)}>
                    Back to Signup?
                  </span>
                </div>
              </div>

              {serverResponse ? (
                <div className="mt1">
                  <Alert color={serverResponse?.type}>
                    {serverResponse?.msg}
                  </Alert>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="loginForm emailVerifiedMsg">
            <div className="msg">
              {serverResponse?.msg || "Email Verified! Login to Continue.."}
            </div>
            <div className="actionField">
              <CustomButton
                title="Login"
                onClickHandler={() => navigate(fromScheme ? "/prayasReg" : paths.login.path)}
              />
            </div>
          </div>
        )}
      </div>
    </AuthPanel>
  );
};

export default SignUp;
