import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  applications: {
    title: 'Applications',
    notifications: 0,
  },
  review: {
    title: 'Online Review',
    notifications: 0,
  },
  // pitchingSelection: {
  //   title: "Pitching Selection",
  //   notifications: 0,
  // },
  // dueDeligence: {
  //   title: "Due Diligence",
  //   notifications: 0,
  // },
  // award: {
  //   title: "Award",
  //   notifications: 0,
  // },
  // agreementSigning: {
  //   title: "Agreement",
  //   notifications: 0,
  // },
  // milestones: {
  //   title: "Milestones",
  //   notifications: 0,
  // },
}

const TitanTabsApplicanteeDataSlice = createSlice({
  name: 'titan_applicantee_tabs',
  initialState: { ...INITIAL_STATE },
  reducers: {
    updateTitanNotification1: (state, actions) => {
      state[actions.payload.id].notifications = actions.payload.value
    },
  },
})

export const { updateTitanNotification1 } =
  TitanTabsApplicanteeDataSlice.actions

export default TitanTabsApplicanteeDataSlice.reducer
