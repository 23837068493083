import { Button, Dialog, FormLabel, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  checkApplication,
  saveComment,
  updatePhaseData,
} from "../../../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import { BigDispatch } from "../../../../../../../providers/scheme/big/BigProvider";
import { updateDocument } from "../../../../../../../reducers/DocumentReducer";
import { updateNavigation } from "../../../../../../../reducers/navigationReducer";
import { updateBigApplication } from "../../../../../../../reducers/schemes/big/applications";
import { updateBigPathNav } from "../../../../../../../reducers/schemes/big/pathNav";
import GlobalStore from "../../../../../../../store/globalStore";
import BigSchemeStore from "../../../../../../../store/scheme/big/big";
import { File } from "../../../../../../../utils/config";
import {
  ApplicaitonStatusColor,
  ApplicationStatus,
  ApplicationStatusTextColor,
} from "../../../../../../../utils/constants";
import { getFileName } from "../../../../../../../utils/helperHandler";
import paths from "../../../../../../../utils/links";
import { Icons } from "../../../../../../../utils/mediaExports";
import {
  applicanteeSideRole,
  applicationSideRole,
} from "../../../../../customComponents/CustomTabView";
import { SchemePhaseTabContainer } from "../../ikp/Applications";
import ActionDialog from "../applicationAction/ActionModal";

const TEP = ({ call, from, setActive }) => {
  const { user } = globalSelector((state) => state.auth);
  const controller = new AbortController();
  const dispatch = globalDispatch();
  const bigDispatch = BigDispatch();
  const params = useParams();

  const [application, setApplication] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [comment, setComment] = useState("");

  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const isProgramAssociate = applicanteeSideRole.includes(user.role.roleId);
  const isFounder = applicationSideRole.includes(user.role.roleId);

  const { enqueueSnackbar } = useSnackbar();

  //function to get application details and application phase data
  const getApplication = async () => {
    let bodyData = {};
    if (isProgramAssociate) {
      bodyData = {
        fromIkp: true,
        applicationId: params.applicationId,
      };
    } else if (isFounder) {
      bodyData = { callId: call._id };
    }

    try {
      const response = await checkApplication(bodyData, dispatch, controller);
      if (response && response.status == 200) {
        setApplication(response.msg?.application);
        setApplicationData(response.msg?.applicationData);
        bigDispatch(updateBigApplication({
          id: "application",
          value: response?.msg?.application
        }));
        setComment(response.msg?.applicationData?.["phase5"]?.comment);
        let progress = {};
        for (let i = 1; i <= 8; i++) {
          progress["phase" + i] =
            response?.msg?.applicationData?.["phase" + i]?.status;
        }
        progress.milestone = response?.msg?.applicationData?.milestone?.status;
        bigDispatch(
          updateBigApplication({
            id: "progress",
            value: progress,
          })
        );

        if (user.role.roleId != "founder") {
          let pageStackData = JSON.parse(
            JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
          );
          Object.keys(pageStackData).map((id) => {
            pageStackData[id].isActive = false;
          });
          pageStackData.companyName.title =
            response.msg?.application?.companyName;
          pageStackData.companyName.show = true;
          pageStackData.companyName.isActive = true;
          pageStackData.companyName.path = `/schemes/big/${call?._id}/${response?.msg?.application?._id}`;
          dispatch(
            updateNavigation({
              type: "pageNavStack",
              value: { ...pageStackData },
            })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getApplication();

    return () => {
      let pageStackData = JSON.parse(
        JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
      );
      pageStackData.callName.isActive = true;
      pageStackData.companyName.title = "";
      pageStackData.companyName.show = false;
      pageStackData.companyName.isActive = false;

      dispatch(
        updateNavigation({
          type: "pageNavStack",
          value: { ...pageStackData },
        })
      );
    };
  }, []);

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
    if (comment) {
      submitComment();
    }
  };

  const submitComment = async () => {
    const response = await saveComment(
      { applicationId: application._id, phase: "phase5", values: comment },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(`Comment for ${"TEP"} submitted!`, "success");
      setApplicationData(response?.msg);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  //function to move to next phase automatically when moved to next round
  const [movingNextRound, setMovingNextRound] = useState(false);
  useEffect(() => {
    if (movingNextRound) {
      setActionDialogOpen(false);
      setActive(5);
      setMovingNextRound(false);
    }
  }, [movingNextRound]);

  const uploadFile = async (file, fileCategory) => {
    if (fileCategory == "video" && !File.VIDEO_VALID_TYPES.includes(file?.type)) {
      snackbarHandler("Invalid Media!", "error");
      return;
    }
    const formData = new FormData();
    formData.append("applicationId", application._id);
    formData.append("phase", "phase5");
    formData.append("field", fileCategory);
    formData.append("file", file);

    const response = await updatePhaseData(formData, dispatch, controller);
    if (response && response.status == 200) {
      snackbarHandler("File Uploaded", "success");
      setApplicationData(response?.msg);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const deleteFile = async (fileCategory) => {
    const response = await updatePhaseData({applicationId: application._id, field: fileCategory, deleteFile: true, phase: "phase5"}, dispatch, controller);
    if (response && response.status == 200) {
      snackbarHandler("File Deleted", "success");
      setApplicationData(response?.msg);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  }

  const handleDocument = (url) => {
    dispatch(
      updateDocument({
        url: url,
        open: true,
      })
    );
  };

  return (
    <SchemePhaseTabContainer>
      <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
        <div
          className={`statusTextApplication `}
          style={{
            background:
              ApplicaitonStatusColor[applicationData?.["phase5"]?.status],
            color:
              ApplicationStatusTextColor[applicationData?.["phase5"]?.status],
          }}
        >
          {ApplicationStatus[applicationData?.["phase5"]?.status] || "Pending"}
        </div>

        {from == "associate" ? (
          <div className="assignedText" style={{ fontSize: "1.2rem" }}>
            Assigned to:{" "}
            {application?.reviewer?.map((person, i) => {
              return (
                <span className="rName">
                  {person.name}{" "}
                  {application?.reviewer?.length != i + 1 ? ", " : ""}
                </span>
              );
            })}
            {application?.reviewer?.length == 0 ? "-" : ""}
          </div>
        ) : (
          <></>
        )}
      </div>

      <Files>
        <div className="fileItem">
          <FormLabel>Video</FormLabel>
          <div className="filearea">
            {applicationData?.["phase5"]?.data?.video ? (
              <FileViewer>
                <div
                  className="content"
                  onClick={() => handleDocument(applicationData.phase5.data.video)}
                >
                  <div className="icon">{Icons.file}</div>
                  <div className="fileName">{getFileName(applicationData.phase5.data.video)}</div>
                </div>

                {isFounder &&
                  <div
                    className="reportAction icon"
                    onClick={() => deleteFile("video")}
                  >
                    {Icons.delete}
                  </div>
                }
              </FileViewer>
            ) : (
              <FileComponent>
                <label className="text">
                  <input
                    type="file"
                    className="fileInput"
                    hidden
                    onChange={(e) => uploadFile(e.target.files?.[0], "video")}
                  />
                  Drag and Drop or Browse for files
                </label>
              </FileComponent>
            )}
          </div>
        </div>

        <div className="fileItem">
          <FormLabel>File</FormLabel>
          <div className="filearea">
          {applicationData?.["phase5"]?.data?.file ? (
              <FileViewer>
                <div
                  className="content"
                  onClick={() => handleDocument(applicationData.phase5.data.file)}
                >
                  <div className="icon">{Icons.file}</div>
                  <div className="fileName">{getFileName(applicationData.phase5.data.file)}</div>
                </div>

                {isFounder &&
                  <div
                    className="reportAction icon"
                    onClick={() => deleteFile("file")}
                  >
                    {Icons.delete}
                  </div>
                }
              </FileViewer>
            ) : (
              <FileComponent>
                <label className="text">
                  <input
                    type="file"
                    className="fileInput"
                    hidden
                    onChange={(e) => uploadFile(e.target.files?.[0], "file")}
                  />
                  Drag and Drop or Browse for files
                </label>
              </FileComponent>
            )}
          </div>
        </div>
      </Files>

      <CommentActionContainer>
        <div className="commentSection">
          <textarea
            readOnly={from != "associate"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Add your comments.."
          ></textarea>
          {from == "associate" ? (
            <Button
              className="muiOutlinedBtn"
              variant="outlined"
              onClick={submitComment}
            >
              Save
            </Button>
          ) : null}
        </div>

        <div className="actionSection">
          {from == "associate" && application?.status == "tep" ? (
            <div className="actions">
              <div className="title">Action: </div>
              <div className="input">
                <Select
                  size="small"
                  value={selectedAction}
                  onChange={handleActionManager}
                >
                  <MenuItem value={-1}>
                    <FormLabel>Select</FormLabel>
                  </MenuItem>
                  <MenuItem value={"nextRound"}>
                    <FormLabel>Move to next round</FormLabel>
                  </MenuItem>
                  <MenuItem value={"reject"}>
                    <FormLabel style={{ color: "red" }}>Reject</FormLabel>
                  </MenuItem>
                </Select>
              </div>
            </div>
          ) : (
            <></>
          )}
          <Dialog open={actionDialogOpen} onClose={handleActionDialog}>
            <ActionDialog
              handleActionDialog={handleActionDialog}
              activeAction={selectedAction}
              application={application}
              getApplicationDataHandlerForManager={getApplication}
              phase={"phase5"}
              setMovingNextRound={setMovingNextRound}
              applicationDataVersion={applicationData?.__v}
            />
          </Dialog>
        </div>
      </CommentActionContainer>
    </SchemePhaseTabContainer>
  );
};

export default TEP;

export const CommentActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  gap: 3rem;
  flex-wrap: wrap;

  .commentSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    textarea {
      resize: none;
      min-width: 300px;
      height: 150px;
      padding: 1rem;
      font-size: 1.1rem;
    }

    button {
      width: max-content;
      margin: 0 auto;
      padding: 0.3rem 1.5rem;
    }
  }
`;

const Files = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;

  .fileItem {
    width: 250px;
  }
`;

const FileComponent = styled.div`
  height: 40px;
  width: 100%;
  border-style: dashed;
  border-color: var(--borderBottomColor);
  border-width: 0.5px;
  cursor: pointer;

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 10px;
    color: var(--text3);
    letter-spacing: 1px;
    cursor: pointer;
  }
`;

const FileViewer = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content {
    display: flex;
    gap: 0.3rem;
    cursor: pointer;
    width: 100%;

    .icon {
      color: var(--text1);
    }

    .fileName {
      color: var(--text3);
      font-size: 14px;
      white-space: nowrap;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .reportAction {
    cursor: pointer;
    color: var(--text3);

    svg {
      width: 20px;
    }
  }
`;
