import { useSelect } from "@mui/base";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { globalSelector } from "../../providers/GlobalProvider";
import { BigSelector } from "../../providers/scheme/big/BigProvider";
import BigSchemeStore from "../../store/scheme/big/big";

const PageNavigation = () => {
  const navigate = useNavigate();
  const { pageNavStack } = globalSelector((state) => state.navigation);

  return (
    <Container>
      {Object.keys(pageNavStack).map((id) => (
        <>
          <div
            className={`text ${pageNavStack[id].block ? "block" : ""} ${
              pageNavStack[id].show ? "" : "hide"
            } ${pageNavStack[id].isActive ? "active" : ""}`}
            onClick={() => navigate(pageNavStack[id].path)}
            key={id}
          >
            {pageNavStack[id].title}
          </div>

          {
            pageNavStack[id].show ? <div className={`bar ${pageNavStack[id].isActive ? "hide" : ""}`}>/</div> : null
          }
        </>
      ))}
    </Container>
  );
};

export default PageNavigation;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  display: flex;
  font-size: 0.9rem;
  color: var(--text1);

  .text {
    cursor: pointer;
    transition: 0.1s color;

    &:hover {
      color: var(--primary);
    }
  }

  .active {
    color: var(--primary);
  }

  .block {
    color: var(--text3);
    cursor: default;
    pointer-events: none;
  }

  .hide {
    display: none;
  }

  .bar {
    margin: 0 0.3rem;
  }
`;
