import {
  OutletContainer,
  SchemeTabContainer,
  SchemeTopPanel,
} from "../../../../utils/customStyledComponents";
import PageNavigation from "../../../customComponents/PageNavigation";
import paths from "../../../../utils/links";
import SchemeViewToggle from "../../customComponents/SchemeViewToggle";
import { useEffect, useState } from "react";
import CustomSchemeTabs from "../../customComponents/CustomSchemeTab";
import CustomTabView, {
  applicanteeSideRole,
  applicationSideRole,
} from "../../customComponents/CustomTabView";
import {
  setSidebarActive,
  handleTopBarTitle,
} from "../../../../utils/helperHandler";
import { getSchemeDetails } from "../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../providers/GlobalProvider";
import {
  BigDispatch,
  BigSelector,
  BigStore,
} from "../../../../providers/scheme/big/BigProvider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import OwlLoader from "../../../customComponents/OwlLoader";
import BigSchemeStore from "../../../../store/scheme/big/big";
import { updateBigPathNav } from "../../../../reducers/schemes/big/pathNav";
import BigProgressView from "./BigProgressView";
import ApplicationsPage from "../../../dashboard/projectManagement/aplication_dashboard/application_page";
import { applicationPageStack } from "../../../../utils/constants";
import { updateNavigation } from "../../../../reducers/navigationReducer";
import GlobalStore from "../../../../store/globalStore";
import styled from "styled-components";
import { Icons } from "../../../../utils/mediaExports";

const BIG = ({ from }) => {
  //get big scheme id from url
  const { id, applicationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [activeView, setActiveView] = useState(0);
  const [loading, setLoading] = useState(true);
  const [call, setCall] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const { user } = globalSelector((state) => state.auth);

  const [isFounder, setFounder] = useState(false);

  const dispatch = globalDispatch();
  const bigDispatch = BigDispatch();
  const controller = new AbortController();

  const [isLateral, setIsLateral] = useState(false);

  useEffect(() => {
    setCall(null);
    setLoading(true);
    setActiveView(0);
    setActiveTab(0);
    handleTopBarTitle(dispatch, paths.big);
    setSidebarActive(paths.big.id);
    setIsLateral(false);

    getCallDetailsHandler();
    if (user.role.roleId == "founder") {
      setFounder(true);
    }

    document.getElementById("pageNavStackContainer").style.display = "block";

    return () => {
      setCall(null);
      setLoading(true);
    };
  }, [id]);

  useEffect(() => {
    let pageNavStack = JSON.parse(JSON.stringify(applicationPageStack));
    pageNavStack.scheme.block = true;
    pageNavStack.scheme.show = true;
    pageNavStack.schemeName.block = false;
    pageNavStack.schemeName.show = true;
    pageNavStack.schemeName.title = "BIG";
    pageNavStack.schemeName.path = `/schemes/big`;

    dispatch(
      updateNavigation({
        type: "pageNavStack",
        value: { ...pageNavStack },
      })
    );
  }, []);

  useEffect(() => {
    // const subValue = sessionStorage.getItem("active-sub-link");
    // const string = String("big").concat(subValue?.toString());
    // if (subValue) {
    //   try {
    //     for (let i = 0; i <= 4; i++) {
    //       try {
    //         document.getElementById("big" + i).classList.remove("active");
    //       } catch (e) {}
    //     }
    //     sessionStorage.setItem("active-sub-link", subValue);
    //     document.getElementById(string).classList.toggle("active");
    //   } catch (err) {
    //     // console.log(err);
    //   }
    // }
  }, [searchParams]);

  //function to get the details of the call extracting callId from the url
  const getCallDetailsHandler = async () => {
    const response = await getSchemeDetails(
      { callId: id },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      setCall(response.msg);
      let pageStackData = JSON.parse(
        JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
      );
      Object.keys(pageStackData).map((id, i) => {
        if (i <= 2) {
          return;
        }
        pageStackData[id].isActive = false;
        pageStackData[id].show = false;
      });
      pageStackData.callName.title = response.msg?.callName;
      pageStackData.callName.show = true;
      pageStackData.callName.isActive = true;
      pageStackData.callName.path = `/schemes/big/${response.msg._id}`;

      dispatch(
        updateNavigation({
          type: "pageNavStack",
          value: { ...pageStackData },
        })
      );
      handleTopBarTitle(
        dispatch,
        paths.big,
        paths.big.title + " - " + response.msg?.callName,
        paths.big.link + `/${response.msg._id}`
      );
    } else if (response) {
      alert(response.msg);
    } else {
      alert("Something went wrong! Try again");
    }
    setLoading(false);
  };

  if (loading) {
    return <OwlLoader />;
  }

  if (!call) {
    return <OwlLoader />;
  }

  if (!user) {
    return <div>User not found!</div>;
  }

  if (call._id != id) {
    return <></>;
  }

  return (
    <OutletContainer>
      {/* <PageNavigation scheme={call?.scheme?.abbr} /> */}
      {applicationId ? (
        <></>
      ) : activeTab == 0 || !isFounder ? (
        <SchemeTopPanel>
          <div className="leftPanel">
            <div className="title">About</div>
            <div className="content">{call?.scheme?.description}</div>
          </div>

          {user.role.roleId == "program_manager" ||
          user.role.roleId == "super_admin" ? (
            <div className="rightPanel">
              <SchemeViewToggle active={activeView} setActive={setActiveView} />
            </div>
          ) : (
            <></>
          )}
        </SchemeTopPanel>
      ) : (
        <></>
      )}

      {applicationId || isFounder ? <ViewDocument /> : null}

      <SchemeTabContainer>
        {activeView == 0 ? (
          <>
            <CustomSchemeTabs
              active={
                isFounder
                  ? isLateral
                    ? activeTab < 7
                      ? 7
                      : activeTab
                    : activeTab
                  : activeTab
              }
              setActive={setActiveTab}
              isFounder={isFounder}
              afterApplicationSelect={false}
              from={from}
              scheme={"big"}
            />
            <CustomTabView
              from={from}
              active={
                isFounder
                  ? isLateral
                    ? activeTab < 7
                      ? 7
                      : activeTab
                    : activeTab
                  : activeTab
              }
              call={call}
              setActive={setActiveTab}
            />
          </>
        ) : (
          <>
            <BigProgressView setActiveView={setActiveView} />
          </>
        )}
      </SchemeTabContainer>
    </OutletContainer>
  );
};

const ViewDocument = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { application } = BigSelector((state) => state.application);

  return (
    <ViewDocContainer
    >
      <div className="content" onClick={() => navigate(`/documents/${params.applicationId || application?._id}`)}>
      <div className="text">View Documents</div>
      <div className="icon">{Icons.download}</div>
      </div>
    </ViewDocContainer>
  );
};

export default BIG;

export const ViewDocContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  color: var(--text1);
  margin-bottom: 0rem;
  align-items: center;

  .content {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .text {
    font-weight: 500;
  }

  .icon {
    color: var(--primary);
    display: flex;
    align-items: center;
  }


`;
