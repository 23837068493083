import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { getLatestSchemes } from "../../data/network/api/scheme";
import { logout } from "../../data/network/api/user";
import { globalDispatch, globalSelector } from "../../providers/GlobalProvider";
import { updateNavigation } from "../../reducers/navigationReducer";
import paths, { SidebarLinks } from "../../utils/links";
import { Icons, Logo } from "../../utils/mediaExports";
import { setSidebarActive } from "../../utils/helperHandler";
import GlobalStore from "../../store/globalStore";

const Sidebar = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [isMobileScreen, setMobileScreen] = useState(false);
  const [roleId, setRoleId] = useState(null);

  const dispatch = globalDispatch();
  const controller = new AbortController();

  //schemes data
  const [schemes, setSchemes] = useState(null);
  const [activeScheme, setActiveScheme] = useState(null);

  //function to handle click on collapse icon
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  //function to get latest 5 of all schemes
  const getLatestSchemesHandler = async () => {
    try {
      const response = await getLatestSchemes({}, dispatch, controller);
      if (response && response.status == 200) {
        setSchemes(response.msg);
      }
    } catch (err) {}
  };

  const updateNavigationStore = (link, name) => {
    if (name) {
      const title = paths[name].title;
      dispatch(
        updateNavigation({
          title: title,
          path: link,
        })
      );
    }
  };

  useEffect(() => {
    setRoleId(GlobalStore.getState().auth.user.role.roleId);
  }, []);

  const { user } = globalSelector((state) => state.auth);

  //if sidebar collpased then add class to sidebar div to apply collapsed styling
  useEffect(() => {
    try {
      getLatestSchemesHandler();
      if (collapsed) {
        document
          .getElementById("sidebar")
          .classList.toggle("sidebar-collapsed");
      } else {
        document
          .getElementById("sidebar")
          .classList.remove("sidebar-collapsed");
      }
    } catch (e) {
      window.location.reload();
    }
  }, [collapsed]);

  //function to handle navigation from sidebar links and store routename in session storage
  const navigation = (link, name) => {
    setActiveScheme(null);
    sessionStorage.setItem("active-link", name);
    Object.keys(SidebarLinks).map((category) =>
      Object.keys(SidebarLinks[category].links).map((link) =>
        document.getElementById(link).classList.remove("active")
      )
    );
    document.getElementById(name).classList.toggle("active");

    if (isMobileScreen) {
      setCollapsed(true);
    }

    navigate(link);
    updateNavigationStore(link, name);
  };

  //highlight the link when page is refreshed from other than home tab
  useEffect(() => {
    window.addEventListener("resize", windowResize);
    if (window.innerWidth <= 1000) {
      setMobileScreen(true);
    }
  }, []);

  const windowResize = () => {
    if (window.innerWidth <= 1000) {
      setMobileScreen(true);
    } else {
      setMobileScreen(false);
    }
  };

  return (
    <SidebarContainer collapsed={collapsed}>
      <div className="sidebar-overlay" id="sidebar">
        <div className="sidebar-logo">
          <div className="logo">
            <img
              src={Logo.athenaLogo}
              alt="Athena-2.0 Logo"
              onClick={() => navigation("/", "home")}
            />
          </div>

          <div
            className="shrinkArrow"
            id="shrinkArrow"
            onClick={toggleCollapsed}
          >
            {isMobileScreen
              ? collapsed
                ? Icons.hamburger
                : Icons.close
              : !collapsed
              ? Icons.arrowShrinkLeft
              : Icons.arrowShrinkRight}
          </div>
        </div>

        <div className="sidebar-link-container">
          <div className="sidebar-link-container-relative">
            {Object.keys(SidebarLinks).map((linkCategory, i) => (
              <div className="sidebar-link-subContainer" key={i}>
                {SidebarLinks[linkCategory].title &&
                (!SidebarLinks[linkCategory].hide || isMobileScreen) ? (
                  <div className="heading">
                    <div className="text">
                      {SidebarLinks[linkCategory].title}
                    </div>
                    <hr />
                  </div>
                ) : (
                  <></>
                )}

                {Object.keys(SidebarLinks[linkCategory].links).map(
                  (link, i) => {
                    // if (link == "home" && roleId != "founder") {
                    //   return <></>
                    // }
                    return                     <>
                    <div
                      className="linkContainer"
                      style={{
                        display:
                          link == "startups" &&
                          roleId != "super_admin" &&
                          user.role.roleCategory != "ikp"
                            ? "none"
                            : "",
                      }}
                    >
                      <div
                        className={`link ${
                          SidebarLinks[linkCategory].links[link].hide
                            ? "extras"
                            : ""
                        }`}
                        id={link}
                        key={i}
                        onClick={() => {
                          if (linkCategory == "schemes") {
                            setSidebarActive(link);
                            setActiveScheme(
                              activeScheme == link ? null : link
                            );
                            if (collapsed) {
                              setCollapsed(false);
                              setActiveScheme(link);
                            }
                            return;
                          }
                          sessionStorage.setItem("active-sub-link", -1);
                          if (link == "logout") {
                            logout(dispatch);
                            return;
                          }
                          navigation(
                            SidebarLinks[linkCategory].links[link].link,
                            link
                          );
                        }}
                      >
                        <div className="sidebar-icon">
                          {SidebarLinks[linkCategory].links[link].icon}
                        </div>
                        <div className="text">
                          {SidebarLinks[linkCategory].links[link].title}
                        </div>
                      </div>

                      <div
                        className="subLinks"
                        style={{
                          display: link == activeScheme ? "flex" : "none",
                        }}
                      >
                        {schemes &&
                        schemes[link] &&
                        !collapsed &&
                        activeScheme == link ? (
                          schemes?.[link].map((scheme, i) => {
                            return (
                              <div
                                // className={`${
                                //   i ==
                                //     sessionStorage.getItem(
                                //       "active-sub-link"
                                //     ) &&
                                //   sessionStorage.getItem("active-link") ==
                                //     "big"
                                //     ? "active"
                                //     : ""
                                // }`}
                                className="linkRow"
                                id={scheme?.category + "" + i}
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "active-sub-link",
                                    i
                                  );

                                  if (
                                    user.role.roleId == "finance_manager" ||
                                    user.role.roleId == "accountant"
                                  ) {
                                    navigate(`/schemes/${scheme._id}`);
                                  } else {
                                    navigate({
                                      pathname: `/schemes/${link}/${scheme._id}`,
                                      search: `?sub=${i}`,
                                    });
                                  }
                                }}
                              >
                                {scheme?.callName}

                                {scheme?.others?.isLive ? (
                                  <span className="live">
                                    <span className="icon liveIcon">
                                      {Icons.circleFilled}
                                    </span>
                                    <span className="text">Live</span>
                                  </span>
                                ) : null}
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                        {schemes &&
                        schemes?.[link] &&
                        schemes?.[link]?.length == 0 ? (
                          <div style={collapsed ? { display: "none" } : null}>
                            No Calls
                          </div>
                        ) : (
                          <></>
                        )}

                        {schemes &&
                        schemes?.[link] &&
                        schemes?.[link].length == 5 ? (
                          <div
                            style={collapsed ? { display: "none" } : null}
                            onClick={() =>
                              navigate(`/schemes/${activeScheme}`)
                            }
                          >
                            More
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </>
                  }
                  )
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;

const live_animation = keyframes`
  20% {opacity: 0.33},
  40% {opacity: 0.66},
  60% {opacity: 1},
  80% {opacity: 0.66},
  100% {opacity: 0.33}
`;

const SidebarContainer = styled.div`
  max-width: ${(props) => (props.collapsed ? "7rem" : "17.6rem")};
  height: 100vh;
  flex: 1;
  position: relative;
  transition: max-width 0.3s;

  .sidebar-overlay {
    height: 100%;

    .sidebar-logo {
      display: flex;
      justify-content: flex-start;
      background: var(--primary);
      padding: 0rem 2rem;
      height: var(--navHeight);
      position: relative;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        transition: 0.2s;

        img {
          width: 170px;
        }
      }

      .shrinkArrow {
        background: #fff;
        position: absolute;
        right: -10px;
        box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
        border-radius: 8px;
        z-index: 999;
        cursor: pointer;
        top: 50%;
        transform: translate(0%, -50%);

        svg {
          font-size: 1.5rem;
          display: flex;
          padding: 5px;
          color: var(--text1);
        }
      }
    }

    .sidebar-link-container {
      background: linear-gradient(213.56deg, #00d1b1 30.06%, #0074d7 166.45%);
      width: 100%;
      height: calc(100vh - var(--navHeight));
      z-index: 5;
      position: relative;
      overflow-y: auto;

      .sidebar-link-container-relative {
        background: rgba(0, 0, 0, 0.1);
        padding: 1rem 2rem;
        max-height: max-content;
        min-height: calc(100vh - var(--navHeight));

        .sidebar-link-subContainer {
          .heading {
            display: flex;
            gap: 1rem;
            align-items: center;
            margin: 1.5rem 0;

            .text {
              color: #fff;
              opacity: 0.8;
              font-weight: 500;
              line-height: 15px;
              text-transform: uppercase;
              font-size: 0.9rem;
              letter-spacing: 1px;
            }

            hr {
              flex: 1;
              height: 1px;
              color: #fff;
              background: #fff;
              border: none;
            }
          }

          .link {
            color: #fff;
            cursor: pointer;
            padding: 0.5rem 0.9rem;
            border-radius: 8px;
            margin-top: 0.5rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            transition: 0.2s;
            height: 2.5rem;

            .sidebar-icon {
              background: transparent;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 16px;

                path {
                  fill: #fff;
                }
              }
              -webkit-box-shadow: 0px 0px 5px 3px rgba(255, 255, 255, 1);
              -moz-box-shadow: 0px 0px 2px 1.5px rgba(255, 255, 255, 1);
              box-shadow: 0px 0px 2px 1.5px rgba(255, 255, 255, 1);
            }

            .text {
              font-size: 0.9rem;
              letter-spacing: 0px;
              font-weight: 500;
              transition: 0.3s;
              transition-timing-function: linear;
              overflow: clip;
              transform: translateX(0vw);
            }

            &:hover {
              .sidebar-icon {
                background: var(--link-active);

                svg {
                  path {
                    fill: #fff;
                  }
                }

                -webkit-box-shadow: 0px 0px 5px 3px rgba(255, 255, 255, 1);
                -moz-box-shadow: 0px 0px 2px 1.5px rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 2px 1.5px rgba(255, 255, 255, 1);
              }
            }
          }

          .extras {
            display: none;
          }

          .active {

            .sidebar-icon {
              background: var(--link-active);

              svg {
                path {
                  fill: #fff;
                }
              }

              -webkit-box-shadow: 0px 0px 5px 3px rgba(255, 255, 255, 1);
              -moz-box-shadow: 0px 0px 2px 1.5px rgba(255, 255, 255, 1);
              box-shadow: 0px 0px 2px 1.5px rgba(255, 255, 255, 1);
            }
          }

          .subLinks {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
            color: #fff;

            div {
              padding: 0.5rem;
              border-radius: 8px;
              cursor: pointer;
              transition: 0.2s;

              &:hover {
                opacity: 0.8;
              }
            }

            .liveIcon {
              color: var(--errorTextColor);
              display: flex;
              align-items: center;
            }

            .linkRow {
              display: flex;
              gap: 0.5rem;
              align-items: center;

              .live {
                display: flex;
                align-items: center;
                font-size: 0.9rem;
                animation-name: ${live_animation};
                animation-duration: 1s;
                animation-iteration-count: infinite;
                transition: 0.2s;
                color: red;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        display: block !important;
        width: 6px;
        background-color: #61758a !important;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 12px !important;
        background: #00244b;
      }
    }
  }

  .sidebar-collapsed {
    .sidebar-logo {
      .logo {
        width: 50px;
      }
    }

    .sidebar-link-container {
      .sidebar-link-container-relative {
        padding: 1rem 1.65rem;

        .sidebar-link-subContainer {
          .heading {
            .text {
              display: none;
            }
          }

          .link {
            .text {
              transform: translateX(-100vw);
            }

            .sidebar-icon {
              width: 30px;
              height: 30px;
              min-width: 30px;
              min-height: 30px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    max-width: ${(props) => (props.collapsed ? "0rem" : "17rem")};
    position: fixed;
    z-index: 5;

    .sidebar-overlay {
      .sidebar-logo {
        min-width: 100vw;
        display: flex;

        .shrinkArrow {
          right: 1rem;
        }
      }

      .sidebar-link-container {
        transition: 0.3s;

        .sidebar-link-container-relative {
          .sidebar-link-subContainer {
            .extras {
              display: flex;
            }
          }
        }
      }
    }

    .sidebar-collapsed {
      .sidebar-logo {
        .logo {
          width: 100%;
        }
      }

      .sidebar-link-container {
        transform: translateX(-100vw);
      }
    }
  }
`;
