import { FormLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logout } from "../../data/network/api/user";
import { globalDispatch, globalSelector } from "../../providers/GlobalProvider";
import GlobalStore from "../../store/globalStore";
import paths from "../../utils/links";
import { Gif, Icons } from "../../utils/mediaExports";

const TopBar = ({ topbarDropdownActive, handleDropdown }) => {
  const navigate = useNavigate();
  const dispatch = globalDispatch();
  const navigationDetails = globalSelector((state) => state.navigation);

  const getName = () => {
    try {
      return GlobalStore.getState().auth.user.name;
    } catch (err) {
      return "null";
    }
  }

  return (
    <Container id="topbar">
      <div className="leftPanel">
        <h3>{navigationDetails.title}</h3>
      </div>

      <div className="rightPanel">
        <div
          className="notification-container container"
          onClick={() => navigate(paths.notifications)}
        >
          <div className="icon">{Icons.notification}</div>
          <h3 className="badge">0</h3>
        </div>

        <div
          className="account-dropdown-container container"
          onClick={handleDropdown}
        >
          <div className="gif">
            <img src={Gif.flying} />
          </div>

          <div className="drop">
            <div className="text">{getName()}</div>
            <div className="icon">{Icons.keyboardArrowDown}</div>
          </div>

          {topbarDropdownActive ? (
            <div className="dropdown">
              <span
                onClick={() => {
                  navigate(paths.profile.path);
                }}
              >
                Profile
              </span>
              <span
                onClick={() => {
                  logout(dispatch);
                }}
              >
                Logout
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </Container>
  );
};

export default TopBar;

const Container = styled.div`
  width: 100%;
  height: var(--navHeight);
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftPanel {
    font-size: 1.3rem;
    margin-left: 1.5rem;

    h3 {
      letter-spacing: 2px;
      color: var(--text1);
      font-size: 27px;
      font-weight: 700;
    }
  }

  .rightPanel {
    margin-right: 1.5rem;
    display: flex;
    gap: 2rem;

    .container {
      border-radius: 8px;
      transition: 0.3s;
      cursor: pointer;
      background: #fff;
      box-shadow: 4px 4px 24px rgb(0 0 0 / 5%);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
    }

    .notification-container {
      width: 45px;
      height: 45px;
      position: relative;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: #000;
          font-size: 1.3rem;
          padding: 0px;
        }
      }

      .badge {
        position: absolute;
        top: -18px;
        right: -9px;
        font-size: 0.6rem;
        background: var(--primary);
        color: #fff;
        border-radius: 50%;
        font-weight: bold;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .account-dropdown-container {
      gap: 0.5rem;
      padding: 0 0.5rem;
      height: 45px;
      position: relative;

      .gif {
        border: 1px solid #d0d3d4;
        border-radius: 50%;
        width: 35px;
        height: 35px;

        img {
          width: 35px;
          height: 35px;
        }
      }

      .drop {
        display: flex;
        align-items: center;
        gap: 0.3rem;

        .icon {
          display: flex;
          align-items: center;
          color: var(--text1);
        }

        .text {
          color: var(--text1);
          font-size: 0.8rem;
          letter-spacing: 1px;
        }
      }

      .dropdown {
        position: absolute;
        background: #fff;
        display: flex;
        flex-direction: column;
        color: var(--text1);
        gap: 1rem;
        width: 8rem;
        align-items: center;
        top: calc(var(--navHeight));
        padding: 1rem 0;
        border-radius: 10px;
        z-index: 3;
        box-shadow: 0 16px 32px rgb(221 230 237 / 54%);

        span {
          cursor: pointer;
          font-size: 0.9rem;
          transition: 0.3s;
          letter-spacing: 1px;

          &:hover {
            color: var(--primary);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 650px) {
    .rightPanel {
      display: none;
    }
  }
`;
