import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAllCalls } from "../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../providers/GlobalProvider";
import { BigDispatch } from "../../../../providers/scheme/big/BigProvider";
import { updateNavigation } from "../../../../reducers/navigationReducer";
import { updateBigPathNav } from "../../../../reducers/schemes/big/pathNav";
import GlobalStore from "../../../../store/globalStore";
import BigSchemeStore from "../../../../store/scheme/big/big";
import { applicationPageStack } from "../../../../utils/constants";
import { OutletContainer } from "../../../../utils/customStyledComponents";
import { handleTopBarTitle } from "../../../../utils/helperHandler";
import paths from "../../../../utils/links";
import { Icons } from "../../../../utils/mediaExports";
import CustomButton from "../../../customComponents/CustomButton";
import PageNavigation from "../../../customComponents/PageNavigation";

const DisplayCalls = ({schemeId}) => {
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [calls, setCalls] = useState([]);
  const [scheme, setScheme] = useState(null);
  const navigate = useNavigate();

  const { user } = globalSelector((state) => state.auth);

  useEffect(() => {
    let pageNavStack = JSON.parse(JSON.stringify(applicationPageStack));
    pageNavStack.scheme.block = true;
    pageNavStack.scheme.show = true;
    pageNavStack.schemeName.block = false;
    pageNavStack.schemeName.show = true;
    pageNavStack.schemeName.title = schemeId.toUpperCase();
    pageNavStack.schemeName.path = `/schemes/${schemeId}`;
    pageNavStack.schemeName.isActive = true;

    dispatch(updateNavigation({
      type: "pageNavStack",
      value: {...pageNavStack},
    }));

    document.getElementById("pageNavStackContainer").style.display = "block";
  }, []);


  useEffect(() => {
    if (schemeId == "big") {
      handleTopBarTitle(dispatch, paths.big, paths.big.title, paths.big.link);
    } else if (schemeId == "prayas") {
      handleTopBarTitle(dispatch, paths.prayas, paths.prayas.title, paths.prayas.link);
    }
    getAllCallsHandler();
  }, []);

  const getAllCallsHandler = async () => {
    const response = await getAllCalls(
      { schemeId: schemeId },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      setScheme(response.msg?.scheme);
      setCalls(response.msg?.calls);
    } else {
      alert(response?.msg || "Failed to fetch calls!");
    }
  };

  return (
    <OutletContainer>
      <CallsContainer>
        {calls.map((call, i) => {
          return (
            <div
              key={i}
              className="callItem"
              onClick={() => {
                if (user.role.roleId == "finance_manager") {
                  navigate("/schemes/" + call?._id);
                } else {
                  navigate(`/schemes/${schemeId}/` + call?._id);
                }
              }}
            >
              {call?.callName}
            </div>
          );
        })}
      </CallsContainer>
    </OutletContainer>
  );
};

export default DisplayCalls;

const CallsContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .callItem {
    cursor: pointer;
    color: var(--text1);

    &:hover {
      color: var(--primary);
    }
  }
`;
