import { SnackbarProvider } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import PageNavigation from "../components/customComponents/PageNavigation";
import PdfPopUp from "../components/customComponents/pdfPopUp";
import Sidebar from "../components/navbars/Sidebar";
import TopBar from "../components/navbars/TopBar";
import { globalDispatch, globalSelector } from "../providers/GlobalProvider";
import { updateDocument } from "../reducers/DocumentReducer";
import GlobalStore from "../store/globalStore";
import { Button } from "@mui/material";

const Dashboard = () => {
  const [topbarDropdownActive, setDropdown] = useState(false);
  const { document } = globalSelector((state) => state.document);
  const dispatch = globalDispatch();
  const notistackRef = useRef();

  const handleDropdown = () => {
    setDropdown(!topbarDropdownActive);
  };

  // useEffect(() => {
  //   console.log(document);
  // }, [document])

  return (
    <Container>
      <SnackbarProvider
      ref={notistackRef}
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        action={(snakbarId) => (
          <Button onClick={() => notistackRef.current.closeSnackbar(snakbarId)}>Dismiss</Button>
        )}
      >
        <Sidebar />

        <ContentContainer id="content-container">
          {topbarDropdownActive ? (
            <div className="overlay" onClick={handleDropdown}></div>
          ) : null}

          <TopBar
            topbarDropdownActive={topbarDropdownActive}
            handleDropdown={handleDropdown}
          />

          <Out>
            <PageNavigationStackContainer
              id="pageNavStackContainer"
              style={{ display: "none" }}
            >
              <PageNavigation />
            </PageNavigationStackContainer>
            <Outlet />
          </Out>
        </ContentContainer>

        {GlobalStore.getState().document.open ? (
          <PdfPopUp
            closePdfView={() => {
              dispatch(
                updateDocument({
                  url: "",
                  open: false,
                })
              );
            }}
            url={GlobalStore.getState().document.url}
            pdfDialogOpen={GlobalStore.getState().document.open}
          />
        ) : null}
      </SnackbarProvider>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex: 1;
  height: 100vh;
  overflow: hidden;

  .overlay {
    position: absolute;
    top: var(--navHeight);
    left: 0;
    right: 0;
    bottom: 0;
    background: hsla(0, 0%, 100%, 0.7);
    z-index: 3;
  }

  @media only screen and (max-width: 1000px) {
    margin-top: var(--navHeight);
  }
`;

const Out = styled.div`
  min-height: calc(100vh - var(--navHeight));
  background: #f8f8f8;
  overflow: auto;
  padding: 1rem;
`;

const PageNavigationStackContainer = styled.div`
  width: 100%;
`;
