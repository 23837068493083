import { createSlice } from '@reduxjs/toolkit'
import paths from '../../../utils/links'

const INITIAL_STATE = {
  pathArr: [
    {
      id: 'schemes',
      text: 'Schemes',
      block: true,
      link: '',
    },
    {
      id: 'schemeName',
      text: 'BIG',
      block: true,
      link: paths.big.link,
    },
    {
      id: 'callName',
      text: 'Call',
      block: true,
      link: paths.big.link,
    },
    {
      id: 'companyName',
      text: 'Company Name',
      block: true,
      link: paths.big.link,
    },
  ],
  activePath: '',
}

const BigPathNavSlice = createSlice({
  name: 'big_application',
  initialState: { ...INITIAL_STATE },
  reducers: {
    updateBigPathNav: (state, actions) => {
      state.pathArr = actions.payload.pathArr
      state.activePath = actions.payload.activePath
    },
  },
})

export const { updateBigPathNav } = BigPathNavSlice.actions

export default BigPathNavSlice.reducer
