import { createSlice } from '@reduxjs/toolkit'

export const NavigationDataSlice = createSlice({
  name: 'navigation',
  initialState: {
    title: 'Home',
    path: '/',
    pageNavStack: {},
  },
  reducers: {
    updateNavigation: (state, actions) => {
      if (actions.payload.type == 'pageNavStack') {
        state['pageNavStack'] = actions.payload.value
        return
      }

      state['title'] = actions.payload['title']
      state['path'] = actions.payload['path']
    },
  },
})

export const { updateNavigation } = NavigationDataSlice.actions

export default NavigationDataSlice.reducer
