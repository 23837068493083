import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import OnlineReviewApplications from "../../../../../reducers/schemes/titan/onlineReviewApplications";
import GlobalStore from "../../../../../store/globalStore";
import CustomSchemeTabs from "../../../customComponents/CustomSchemeTab";
import Agreement from "./application/agreement/Agreement";
import ApplicantApplicationSide from "./application/applicationPhase/ApplicantApplicationSide";
import Award from "./application/award/Award";
import DueDiligence from "./application/dueDiligence/DueDeligence";
import DueDiligenceApplications from "./application/dueDiligence/DueDiligenceApplications";
import Milestone from "./application/milestone/Milestone";
import OnlineReview from "./application/onlineReview/OnlineReview";
import OnlineReviewApplicant from "./application/onlineReview/OnlineReviewApplicant";
import PitchingSelection from "./application/pitchingSelection/PitchingSelection";
import Applications from "./Applications";
import { TitanSelector } from "../../../../../providers/scheme/titan/TitanProvider";

export const applicanteeSideRole = [
  "super_admin",
  "program_manager",
  "program_associate",
  "external_reviewer",
  "founder",
];
export const applicationSideRole = ["founder"];

const TitanTabView = ({ from, active, call, setActive }) => {
  const user = GlobalStore.getState().auth.user;
  const { applicationId } = useParams();
  const Tab = () => {
    if (applicationId && applicanteeSideRole.includes(user.role.roleId)) {
      switch (active) {
        case 0:
          return (
            <ApplicantApplicationSide
              call={call}
              from={from}
              setActive={setActive}
            />
          );
        case 1:
          return (
            <OnlineReviewApplicant
              call={call}
              from={from}
              setActive={setActive}
            />
          );
        case 2:
          return (
            <PitchingSelection call={call} from={from} setActive={setActive} />
          );
        case 3:
          return <DueDiligence call={call} from={from} setActive={setActive} />;
        case 4:
          return <Award call={call} from={from} setActive={setActive} />;
        case 5:
          return <Agreement call={call} from={from} setActive={setActive} />;
        case 6:
          return <Milestone call={call} from={from} setActive={setActive} />;
      }
    } else if (applicanteeSideRole.includes(user.role.roleId)) {
      switch (active) {
        case 0:
          return (
            <Applications call={call} active={active} setActive={setActive} />
          );
        case 1:
          return <OnlineReview call={call} active={active} />;
        case 2:
          return <Applications call={call} active={active} />;
        case 3:
          return <DueDiligenceApplications call={call} active={active} />;
        case 4:
          return <Applications call={call} active={active} />;
        case 5:
          return <Applications call={call} active={active} />;
        case 6:
          return <Applications call={call} active={active} />;
      }
    }
  };

  return (
    <Container>
      {from == "associate" ? (
        <CustomSchemeTabs
          active={active}
          setActive={setActive}
          isFounder={true}
          afterApplicationSelect={true}
          from={from}
          scheme={"titan"}
        />
      ) : (
        <></>
      )}
      <Tab />
    </Container>
  );
};

export default TitanTabView;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
