import { useEffect, useState } from "react";
import {
  globalDispatch,
  globalSelector,
} from "../../../../providers/GlobalProvider";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFileName,
  handleTopBarTitle,
} from "../../../../utils/helperHandler";
import paths from "../../../../utils/links";
import { getAllDocumentsScheme } from "../../../../data/network/api/scheme";
import { useSnackbar } from "notistack";
import { OutletContainer } from "../../../../utils/customStyledComponents";
import styled from "styled-components";
import { Icons } from "../../../../utils/mediaExports";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { downloadFile } from "../../../../data/network/api/download";
import { updateDocument } from "../../../../reducers/DocumentReducer";
import { documentFolderPageStack } from "../../../../utils/constants";
import { updateNavigation } from "../../../../reducers/navigationReducer";
import OwlLoader from "../../../customComponents/OwlLoader";
import { render } from "react-dom";

const ViewAccess = [
  "founder",
  "program_manager",
  "super_admin",
  "program_associate",
];

const keyNames = {
  application: "Application",
  projectManagement: "Project Management",
  data: "PPT",
  video: "Video",
  file: "File",
  agreementDocument: "Agreement",
  techreport: "Technical Reports",
  ucDraft: "UC Draft",
  ucReport: "UC Report",
  bankStatement: "Bank Statement",
  expenseBills: "Expense Bills",
  proposalConceptFiles: "Proposal Concept Files",
  tableFile: "Table File",
  documents: "Documents",
  completionForm: "Completion Form",
  innovatorForm: "Innovator Form",
  itr: "ITR",
  noc: "NOC",
  photo: "Photo",
  signature: "Signature",
  preInvestment: "Pre Investment",
  organizations: "Organizations",
  articlesOfAssociation: "Articles of association",
  gstRegistration: "GST registration",
  incoporationCertificate: "Incorporation certificate",
  listOfDirectories: "List of directories",
  memorandumOfAssociation: "Memorandum of association",
  organizationStructure: "Organization structure",
  otherRegistration: "Other registration",
  panCopy: "PAN",
  startupRegistration: "Startup registration",
  tanCopy: "TAN",
  business: "Business",
  investmentCommittee: "Investment committee",
  entityPitchDeck: "Entity pitch deck",
  bussinessEntityInfo: "Business entity info",
  investmentCommitteeMinutesForApproval: "Investment committee approval",
  termSheet: "Term sheet",
  anyFundingFromDbtDstTdb: "Funding from DBT/DST/TDB",
  consultantEngagementLetter: "Consultant engagement letter",
  employeeOfferLetter: "Employee offer letter",
  founderAgreement: "Founder agreement",
  incubationAgreement: "Incubation agreement",
  licenseAgreement: "License agreement",
  fundingStatus: "Funding status",
  lastThreeYearsAnnual: "Last three years annual",
  financialProjectionForNextFiveYears: "Projection for next five years",
  lastThreeYearsAnnualReturnFinancial: "Last three years annual return",
  lastThreeYearsAnnualReturnRegistration:
    "Last three years annual return registration",
  latestShareholdingPattern: "Latest shareholding pattern",
  movementsShareholdingPatternCertifiedByCaCs:
    "Movement shareholding pattern ceritified by CACS",
  "noticesFromRegulators&Status": "Notices from regulators & status",
  pas3FormSumbittedAtROCForReturnOfAllotment: "Pas3 form",
  pas4FormSumbittedAtROCForReturnOfAllotment: "Pas4 form",
  proposedInvestmentPlan: "Proposed investment plan",
  ConditionsPrecedentCertificate: "Conditions precendent certificate",
  CopiesOfAnyOtherFormsSubmittedAtROC:
    "Copies of any other forms submitted at ROC",
  Mgt1CopiesSubmittedAtRoC: "Mgt1 copies submitted at ROC",
  Mgt14CopiesSubmittedAtRoC: "Mgt14 copies submitted at ROC",
  ShareSubscriptionAgreement: "Share subscription agreement",
  bankDetailsWithCancelledCheque: "Bank details with cancelled cheque",
  declarationForNoValuationreportAndValuationRefrence:
    "Declaration for no valuation report and valuation reference",
  financialDueDiligenceReport: "Financial due diligence report",
  shareHolderAgreement: "Shareholder agreement",
  shareSubscriptionAgreement: "Share subscription agreement",
  technicalDueDiligenceReport: "Technical due diligence report",
  technicalWriteup: "Technical writeup",
  typeOfSubscriptionOcdOops: "Type of subscription",
  valuationReportCertifiedbyARegisteredValuer:
    "Valuation report certified by registered valuer",
};

const schemes = {
  big: {
    phase1: "Application Phase",
    phase5: "TEP Phase",
    phase8: "Agreement Phase",
  },
  prayas: {
    phase1: "Appleication Phase",
    phase6: "Agreement Phase",
  },
  investment: {
    organizations: "Organizations",
    agreements: "Agreements",
    business: "Business",
    financialInformation: "Financial Information",
    transactionRelated: "Transaction Related",
    investmentCommittee: "Investment Committee",
  },
};

const DocumentFolders = () => {
  const user = globalSelector((state) => state.auth.user);
  const role = user.role.roleId;
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const { applicationId } = useParams();
  const [documents, setDocuments] = useState(null);
  const [scheme, setScheme] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [call, setCall] = useState(null);
  const [query, setQuery] = useState(null);
  const [error, setError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  useEffect(() => {
    handleTopBarTitle(dispatch, paths.documentFoldersScheme);
    const urlQueryParams = new URLSearchParams(window.location.search);
    const schemeType = urlQueryParams.get("schemeType");
    fetchDocuments(schemeType);
  }, []);

  useEffect(() => {
    if (!loading && !error) {
      document.getElementById("pageNavStackContainer").style.display = "block";

      let pageNavStack = JSON.parse(JSON.stringify(documentFolderPageStack));
      pageNavStack.scheme.block = true;
      pageNavStack.schemeName.title = scheme?.schemeId?.toUpperCase() || "";
      if (scheme.schemeId == "investment") {
        pageNavStack.schemeName.path = `investment/applications`;
      } else {
        pageNavStack.schemeName.path = `/schemes/${scheme?.schemeId}`;
      }
      pageNavStack.callName.title = call?.callName?.toUpperCase();
      pageNavStack.callName.path =
        role == "founder"
          ? `/schemes/${scheme.schemeId}/${call?.id}`
          : `/schemes/${scheme.schemeId}/${call?.id}`;
      if (scheme.schemeId == "investment") {
        pageNavStack.callName.show = false;
      }
      pageNavStack.companyName.title = companyName;
      if (scheme.schemeId == "investment") {
        pageNavStack.companyName.path = `investment/applications/${applicationId}`;
      } else {
        pageNavStack.companyName.path =
          role == "founder"
            ? `/schemes/${scheme.schemeId}/${call?.id}`
            : `/schemes/${scheme.schemeId}/${call?.id}/${applicationId}`;
      }
      pageNavStack.documents.path = `/documents/${applicationId}`;

      dispatch(
        updateNavigation({
          type: "pageNavStack",
          value: { ...pageNavStack },
        })
      );
    }
  }, [loading]);

  useEffect(() => {
    return () => {
      try {
        let pageNavStack = JSON.parse(JSON.stringify(documentFolderPageStack));
        pageNavStack.schemeName.title = "Scheme Name";
        pageNavStack.schemeName.path = ``;
        pageNavStack.callName.title = "Call Name";
        pageNavStack.callName.path = "";
        pageNavStack.companyName.title = "Company Name";
        pageNavStack.documents.path = "";

        dispatch(
          updateNavigation({
            type: "pageNavStack",
            value: { ...pageNavStack },
          })
        );
      } catch (err) {}
    };
  }, []);

  const fetchDocuments = async (schemeType = null) => {
    const response = await getAllDocumentsScheme(
      { applicationId: applicationId, schemeType },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      setScheme(response?.msg?.scheme);
      setCompanyName(response?.msg?.companyName);
      setCall(response?.msg?.call);
      setDocuments(response?.msg?.documents);
    } else {
      setError(true);
      snackbarHandler(
        response?.msg?.msg || response?.msg || "Something went wrong!",
        "error"
      );
    }
    setLoading(false);
  };

  const toggleDropdown = (id) => {
    const status = document.getElementById(id).style.display;
    if (status == "none") {
      document.getElementById(id).style.display = "block";
    } else {
      document.getElementById(id).style.display = "none";
    }
  };

  const handleDocument = (url) => {
    dispatch(
      updateDocument({
        open: true,
        url: url,
      })
    );
  };

  const download = async () => {
    var zip = new JSZip();
    const fileName = `${scheme?.schemeId.toUpperCase()}_${companyName}`;
    snackbarHandler(`Downloading: ${fileName}`, "info");
    for (let category in documents) {
      const categoryName = `${fileName}_${keyNames[category]}`;
      zip.folder(`${fileName}/${categoryName}`);

      for (let subPhase1 in documents[category]) {
        const subPhaseName = `${categoryName}_${
          schemes[scheme?.schemeId][subPhase1] ||
          "Milestone " + subPhase1.substring(9)
        }`;
        zip.folder(`${fileName}/${categoryName}/${subPhaseName}`);

        for (let subPhase2 in documents[category][subPhase1]) {
          const isArray = Array.isArray(
            documents[category][subPhase1][subPhase2]
          );
          const subPhase2Name = `${subPhaseName}_${keyNames[subPhase2]}`;

          zip.folder(
            `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}`
          );

          if (["completionForm", "innovatorForm"].includes(subPhase2)) {
            for (let subPhase3 in documents[category][subPhase1][subPhase2]) {
              const subPhase3Name = `${subPhase2Name}_${keyNames[subPhase3]}`;
              zip.folder(
                `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}/${subPhase3Name}`
              );

              for (
                let i = 0;
                i <
                documents[category][subPhase1][subPhase2][subPhase3]?.length;
                i++
              ) {
                const document =
                  documents[category][subPhase1][subPhase2][subPhase3][i];

                const request = new Request(document.url);
                const response = await fetch(request).then((response) => {
                  return response.arrayBuffer();
                });

                zip
                  .folder(
                    `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}/${subPhase3Name}`
                  )
                  .file(getFileName(document.url), response, { binary: true });
              }
            }
          } else if (isArray) {
            zip.folder(
              `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}`
            );

            for (
              let i = 0;
              i < documents[category][subPhase1][subPhase2]?.length;
              i++
            ) {
              const document = documents[category][subPhase1][subPhase2][i];
              const request = new Request(document);
              const response = await fetch(request).then((response) => {
                return response.arrayBuffer();
              });

              zip
                .folder(
                  `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}`
                )
                .file(getFileName(document), response, { binary: true });
            }
          } else {
            const request = new Request(
              documents[category][subPhase1][subPhase2]
            );
            const response = await fetch(request).then((response) => {
              return response.arrayBuffer();
            });
            zip
              .folder(
                `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}`
              )
              .file(
                getFileName(documents[category][subPhase1][subPhase2]),
                response,
                { binary: true }
              );
          }
        }
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, fileName);
      snackbarHandler(`${fileName} downloaded successfully`, "success");
    });
  };

  const downloadSingle = async (fileName, url) => {
    // snackbarHandler("Downloading", "info");
    await downloadFile(url, fileName);
  };

  const downloadPhase = async (category) => {
    var zip = new JSZip();
    const fileName = `${scheme?.schemeId.toUpperCase()}_${companyName}_${
      keyNames[category]
    }`;
    snackbarHandler(`Downloading: ${fileName}`, "info");
    const categoryName = `${scheme?.schemeId.toUpperCase()}_${companyName}_${
      keyNames[category]
    }`;
    for (let subPhase1 in documents[category]) {
      const subPhaseName = `${categoryName}_${
        schemes[scheme?.schemeId][subPhase1] ||
        "Milestone " + subPhase1.substring(9)
      }`;
      zip.folder(`${fileName}/${categoryName}/${subPhaseName}`);

      for (let subPhase2 in documents[category][subPhase1]) {
        const isArray = Array.isArray(
          documents[category][subPhase1][subPhase2]
        );
        const subPhase2Name = `${subPhaseName}_${keyNames[subPhase2]}`;

        zip.folder(
          `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}`
        );

        if (["completionForm", "innovatorForm"].includes(subPhase2)) {
          for (let subPhase3 in documents[category][subPhase1][subPhase2]) {
            const subPhase3Name = `${subPhase2Name}_${keyNames[subPhase3]}`;
            zip.folder(
              `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}/${subPhase3Name}`
            );

            for (
              let i = 0;
              i < documents[category][subPhase1][subPhase2][subPhase3]?.length;
              i++
            ) {
              const document =
                documents[category][subPhase1][subPhase2][subPhase3][i];

              const request = new Request(document.url);
              const response = await fetch(request).then((response) => {
                return response.arrayBuffer();
              });

              zip
                .folder(
                  `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}/${subPhase3Name}`
                )
                .file(getFileName(document.url), response, { binary: true });
            }
          }
        } else if (isArray) {
          zip.folder(
            `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}`
          );

          for (
            let i = 0;
            i < documents[category][subPhase1][subPhase2]?.length;
            i++
          ) {
            const document = documents[category][subPhase1][subPhase2][i];
            const request = new Request(document);
            const response = await fetch(request).then((response) => {
              return response.arrayBuffer();
            });

            zip
              .folder(
                `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}`
              )
              .file(getFileName(document), response, { binary: true });
          }
        } else {
          const request = new Request(
            documents[category][subPhase1][subPhase2]
          );
          const response = await fetch(request).then((response) => {
            return response.arrayBuffer();
          });
          zip
            .folder(
              `${fileName}/${categoryName}/${subPhaseName}/${subPhase2Name}`
            )
            .file(
              getFileName(documents[category][subPhase1][subPhase2]),
              response,
              { binary: true }
            );
        }
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, fileName);
      snackbarHandler(`${fileName} downloaded successfully`, "success");
    });
  };

  if (!ViewAccess.includes(role)) {
    return <div style={{ color: "red" }}>Forbidden!</div>;
  }

  if (loading) {
    return <OwlLoader />;
  }

  if (error) {
    return <div>Error!</div>;
  }

  return (
    <OutletContainer>
      <Header>
        <div className="item">
          <div className="text">View Documents</div>
          <div className="icon downloadIcon" onClick={() => download()}>
            {Icons.download}
          </div>
        </div>

        <div className="item" onClick={() => navigate(-1)}>
          <div className="icon">{Icons.close}</div>
        </div>
      </Header>

      {scheme?.schemeId && documents ? (
        <DocumentFolder>
          {Object.keys(documents).map((category, i) => {
            const categoryName = `${scheme?.schemeId.toUpperCase()}_${companyName}_${
              keyNames[category]
            }`;
            return (
              <div className="category" key={category}>
                <div className="heading">
                  <div
                    className="content"
                    onClick={() => toggleDropdown(category)}
                  >
                    <div className="icon arrowDownIcon">{Icons.arrowDown}</div>
                    <div className="text">{keyNames[category]}</div>
                  </div>

                  <div
                    className="icon downloadIcon"
                    onClick={() => downloadPhase(category)}
                  >
                    {Icons.download}
                  </div>
                </div>

                <div className="subCategories" id={category}>
                  {Object.keys(documents[category]).map((phase, i) => {
                    const subphase1Name = `${categoryName}_${
                      schemes[scheme?.schemeId][phase] ||
                      "Milestone " + phase.substring(9)
                    }`;

                    <div className="subCategories" id={category}>
                      {Object.keys(documents[category]).map((phase, i) => {
                        const subphase1Name = `${categoryName}_${
                          schemes[scheme?.schemeId][phase] ||
                          "Milestone " + phase.substring(9)
                        }`;
                        return (
                          <div
                            className="category"
                            key={phase}
                            style={{ marginLeft: "1.5rem" }}
                          >
                            <div className="heading">
                              <div
                                className="content"
                                onClick={() => toggleDropdown(phase)}
                              >
                                <div className="icon arrowDownIcon">
                                  {Icons.arrowDown}
                                </div>
                                <div className="text">
                                  {schemes[scheme?.schemeId][phase] ||
                                    "Milestone " + phase.substring(9)}
                                </div>
                              </div>
                            </div>

                            <div className="subCategories" id={phase}>
                              {Object.keys(documents[category][phase]).map(
                                (subPhase, index) => {
                                  const subphase2Name = `${subphase1Name}_${keyNames[subPhase]}`;
                                  const isArray = Array.isArray(
                                    documents[category][phase][subPhase]
                                  );

                                  if (
                                    [
                                      "completionForm",
                                      "innovatorForm",
                                    ].includes(subPhase)
                                  ) {
                                    return (
                                      <div
                                        className="category"
                                        key={subPhase}
                                        style={{ marginLeft: "1.5rem" }}
                                      >
                                        <div className="heading">
                                          <div
                                            className="content"
                                            onClick={() =>
                                              toggleDropdown(subPhase)
                                            }
                                          >
                                            <div className="icon arrowDownIcon">
                                              {Icons.arrowDown}
                                            </div>
                                            <div className="text">
                                              {keyNames[subPhase]}
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="subCategories"
                                          id={subPhase}
                                        >
                                          {Object.keys(
                                            documents[category][phase][subPhase]
                                          ).map((subPhase_l2, i) => {
                                            const subphase3Name = `${subphase2Name}_${keyNames[subPhase_l2]}`;
                                            return (
                                              <div
                                                className="category"
                                                key={subPhase_l2}
                                                style={{
                                                  marginLeft: "1.5rem",
                                                }}
                                              >
                                                <div className="heading">
                                                  <div
                                                    className="content"
                                                    onClick={() =>
                                                      toggleDropdown(
                                                        subPhase_l2
                                                      )
                                                    }
                                                  >
                                                    <div className="icon arrowDownIcon">
                                                      {Icons.arrowDown}
                                                    </div>
                                                    <div className="text">
                                                      {keyNames[subPhase_l2]}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  className="subCategories"
                                                  id={subPhase_l2}
                                                >
                                                  {documents[category][phase][
                                                    subPhase
                                                  ][subPhase_l2].map(
                                                    (document, i) => {
                                                      return (
                                                        <div
                                                          className="category"
                                                          style={{
                                                            marginLeft:
                                                              "1.5rem",
                                                          }}
                                                          key={i}
                                                        >
                                                          <div className="heading">
                                                            <div
                                                              className="content"
                                                              onClick={() =>
                                                                handleDocument(
                                                                  document?.url
                                                                )
                                                              }
                                                            >
                                                              <div className="icon arrowDownIcon fileIcon">
                                                                {Icons.file}
                                                              </div>
                                                              <div className="text fileName">
                                                                {i + 1}.{" "}
                                                                {getFileName(
                                                                  document?.url
                                                                )}
                                                              </div>
                                                            </div>

                                                            <div
                                                              className="downloadIcon icon"
                                                              onClick={() =>
                                                                downloadSingle(
                                                                  `${subphase3Name}_${
                                                                    i + 1
                                                                  }_${getFileName(
                                                                    document?.url
                                                                  )}`,
                                                                  document?.url
                                                                )
                                                              }
                                                            >
                                                              {Icons.download}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                  }

                                  return (
                                    <div
                                      className="category"
                                      key={subPhase}
                                      style={{ marginLeft: "1.5rem" }}
                                    >
                                      <div className="heading">
                                        <div
                                          className="content"
                                          onClick={() =>
                                            toggleDropdown(phase + subPhase)
                                          }
                                        >
                                          <div className="icon arrowDownIcon">
                                            {Icons.arrowDown}
                                          </div>
                                          <div className="text">
                                            {keyNames[subPhase]}
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="subCategories"
                                        id={phase + subPhase}
                                      >
                                        {isArray ? (
                                          documents[category][phase][
                                            subPhase
                                          ].map((document, i) => {
                                            return (
                                              <div
                                                className="category"
                                                key={i}
                                                style={{
                                                  marginLeft: "1.5rem",
                                                }}
                                              >
                                                <div className="heading">
                                                  <div
                                                    className="content"
                                                    onClick={() =>
                                                      handleDocument(
                                                        document?.url ||
                                                          document
                                                      )
                                                    }
                                                  >
                                                    <div className="icon arrowDownIcon fileIcon">
                                                      {Icons.file}
                                                    </div>
                                                    <div className="text fileName">
                                                      {i + 1}.{" "}
                                                      {getFileName(
                                                        document?.url ||
                                                          document
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div
                                                    className="downloadIcon icon"
                                                    onClick={() =>
                                                      downloadSingle(
                                                        `${subphase2Name}_${
                                                          i + 1
                                                        }_${getFileName(
                                                          document?.url ||
                                                            document
                                                        )}`,
                                                        document?.url ||
                                                          document
                                                      )
                                                    }
                                                  >
                                                    {Icons.download}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })
                                        ) : documents[category][phase][
                                            subPhase
                                          ] ? (
                                          <div
                                            className="category"
                                            style={{ marginLeft: "1.5rem" }}
                                          >
                                            <div className="heading">
                                              <div
                                                className="content"
                                                onClick={() =>
                                                  handleDocument(
                                                    documents[category][phase][
                                                      subPhase
                                                    ]
                                                  )
                                                }
                                              >
                                                <div className="icon arrowDownIcon fileIcon">
                                                  {Icons.file}
                                                </div>
                                                <div className="text fileName">
                                                  {getFileName(
                                                    documents[category][phase][
                                                      subPhase
                                                    ]
                                                  )}
                                                </div>
                                              </div>

                                              <div
                                                className="downloadIcon icon"
                                                onClick={() =>
                                                  downloadSingle(
                                                    `${subphase2Name}_${getFileName(
                                                      documents[category][
                                                        phase
                                                      ][subPhase]
                                                    )}`,
                                                    documents[category][phase][
                                                      subPhase
                                                    ]
                                                  )
                                                }
                                              >
                                                {Icons.download}
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>;
                    return (
                      <div
                        className="category"
                        key={phase}
                        style={{ marginLeft: "1.5rem" }}
                      >
                        <div className="heading">
                          <div
                            className="content"
                            onClick={() => toggleDropdown(phase)}
                          >
                            <div className="icon arrowDownIcon">
                              {Icons.arrowDown}
                            </div>
                            <div className="text">
                              {schemes[scheme?.schemeId][phase] ||
                                "Milestone " + phase.substring(9)}
                            </div>
                          </div>
                        </div>

                        <div className="subCategories" id={phase}>
                          {Object.keys(documents[category][phase]).map(
                            (subPhase, index) => {
                              const subphase2Name = `${subphase1Name}_${keyNames[subPhase]}`;
                              const isArray = Array.isArray(
                                documents[category][phase][subPhase]
                              );

                              if (
                                ["completionForm", "innovatorForm"].includes(
                                  subPhase
                                )
                              ) {
                                return (
                                  <div
                                    className="category"
                                    key={subPhase}
                                    style={{ marginLeft: "1.5rem" }}
                                  >
                                    <div className="heading">
                                      <div
                                        className="content"
                                        onClick={() => toggleDropdown(subPhase)}
                                      >
                                        <div className="icon arrowDownIcon">
                                          {Icons.arrowDown}
                                        </div>
                                        <div className="text">
                                          {keyNames[subPhase]}
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="subCategories"
                                      id={subPhase}
                                    >
                                      {Object.keys(
                                        documents[category][phase][subPhase]
                                      ).map((subPhase_l2, i) => {
                                        const subphase3Name = `${subphase2Name}_${keyNames[subPhase_l2]}`;
                                        return (
                                          <div
                                            className="category"
                                            key={subPhase_l2}
                                            style={{ marginLeft: "1.5rem" }}
                                          >
                                            <div className="heading">
                                              <div
                                                className="content"
                                                onClick={() =>
                                                  toggleDropdown(subPhase_l2)
                                                }
                                              >
                                                <div className="icon arrowDownIcon">
                                                  {Icons.arrowDown}
                                                </div>
                                                <div className="text">
                                                  {keyNames[subPhase_l2]}
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              className="subCategories"
                                              id={subPhase_l2}
                                            >
                                              {documents[category][phase][
                                                subPhase
                                              ][subPhase_l2].map(
                                                (document, i) => {
                                                  return (
                                                    <div
                                                      className="category"
                                                      style={{
                                                        marginLeft: "1.5rem",
                                                      }}
                                                      key={i}
                                                    >
                                                      <div className="heading">
                                                        <div
                                                          className="content"
                                                          onClick={() =>
                                                            handleDocument(
                                                              document?.url
                                                            )
                                                          }
                                                        >
                                                          <div className="icon arrowDownIcon fileIcon">
                                                            {Icons.file}
                                                          </div>
                                                          <div className="text fileName">
                                                            {i + 1}.{" "}
                                                            {getFileName(
                                                              document?.url
                                                            )}
                                                          </div>
                                                        </div>

                                                        <div
                                                          className="downloadIcon icon"
                                                          onClick={() =>
                                                            downloadSingle(
                                                              `${subphase3Name}_${
                                                                i + 1
                                                              }_${getFileName(
                                                                document?.url
                                                              )}`,
                                                              document?.url
                                                            )
                                                          }
                                                        >
                                                          {Icons.download}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              }

                              return (
                                <div
                                  className="category"
                                  key={subPhase}
                                  style={{ marginLeft: "1.5rem" }}
                                >
                                  <div className="heading">
                                    <div
                                      className="content"
                                      onClick={() =>
                                        toggleDropdown(phase + subPhase)
                                      }
                                    >
                                      <div className="icon arrowDownIcon">
                                        {Icons.arrowDown}
                                      </div>
                                      <div className="text">
                                        {keyNames[subPhase]}
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="subCategories"
                                    id={phase + subPhase}
                                  >
                                    {isArray ? (
                                      documents[category][phase][subPhase].map(
                                        (document, i) => {
                                          return (
                                            <div
                                              className="category"
                                              key={i}
                                              style={{ marginLeft: "1.5rem" }}
                                            >
                                              <div className="heading">
                                                <div
                                                  className="content"
                                                  onClick={() =>
                                                    handleDocument(
                                                      document?.url || document
                                                    )
                                                  }
                                                >
                                                  <div className="icon arrowDownIcon fileIcon">
                                                    {Icons.file}
                                                  </div>
                                                  <div className="text fileName">
                                                    {i + 1}.{" "}
                                                    {getFileName(
                                                      document?.url || document
                                                    )}
                                                  </div>
                                                </div>

                                                <div
                                                  className="downloadIcon icon"
                                                  onClick={() =>
                                                    downloadSingle(
                                                      `${subphase2Name}_${
                                                        i + 1
                                                      }_${getFileName(
                                                        document?.url ||
                                                          document
                                                      )}`,
                                                      document?.url || document
                                                    )
                                                  }
                                                >
                                                  {Icons.download}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    ) : documents[category][phase][subPhase] ? (
                                      <div
                                        className="category"
                                        style={{ marginLeft: "1.5rem" }}
                                      >
                                        <div className="heading">
                                          <div
                                            className="content"
                                            onClick={() =>
                                              handleDocument(
                                                documents[category][phase][
                                                  subPhase
                                                ]
                                              )
                                            }
                                          >
                                            <div className="icon arrowDownIcon fileIcon">
                                              {Icons.file}
                                            </div>
                                            <div className="text fileName">
                                              {getFileName(
                                                documents[category][phase][
                                                  subPhase
                                                ]
                                              )}
                                            </div>
                                          </div>

                                          <div
                                            className="downloadIcon icon"
                                            onClick={() =>
                                              downloadSingle(
                                                `${subphase2Name}_${getFileName(
                                                  documents[category][phase][
                                                    subPhase
                                                  ]
                                                )}`,
                                                documents[category][phase][
                                                  subPhase
                                                ]
                                              )
                                            }
                                          >
                                            {Icons.download}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </DocumentFolder>
      ) : (
        <></>
      )}
    </OutletContainer>
  );
};

export default DocumentFolders;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin-top: 1.5rem;

  .item {
    color: var(--text1);
    font-size: 19px;
    display: flex;
    gap: 1rem;
    align-items: center;

    .icon {
      display: flex;
      align-items: center;
    }

    .downloadIcon {
      color: var(--primary);

      &:hover {
        color: var(--text1);
      }
    }
  }
`;

const DocumentFolder = styled.div`
  margin-top: 1rem;
  font-size: 19px;
  color: var(--text1);
  display: flex;
  flex-direction: column;

  .category {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    .heading {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex: 1;

      .content {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    .downloadIcon {
      color: var(--primary);

      &:hover {
        color: var(--text1);
      }
    }

    .icon {
      display: flex;
      align-items: center;
    }

    .subCategories {
      display: flex;
      flex-direction: column;
    }
  }

  .downloadIcon {
    color: var(--primary);

    &:hover {
      color: var(--text1);
    }
  }

  .fileIcon {
    font-size: 0.8rem;
    color: var(--text3);
  }

  .fileName {
    color: var(--text3);
  }
`;
