import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Icons } from "../../../../../../../utils/mediaExports";
import BuildFormField from "../../../../widgets/FormBuilder";
import CustomButton from "../../../../../../customComponents/CustomButton";
import { uploadFile } from "../../../../../../../data/network/api/startupForm";
import { globalDispatch } from "../../../../../../../providers/GlobalProvider";
import {
  getApplicationSubphaseData,
  getFormData,
  saveApplicationData,
  saveComment,
  saveFormInputs,
} from "../../../../../../../data/network/api/scheme";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

const fields = {
  proposalSummary: {
    title: "Proposal Summary",
    inputs: {
      proposalSummary: {
        id: "proposalSummary",
        title: "Proposal Summary",
        sl: 1,
        label:
          "[Provide a brief one paragraph overview of the proposal, i.e. the idea and the problem it may solve and brief project plan.]",
        inputType: "text",
        default: "",
      },
      proposalConceptFiles: {
        id: "proposalConceptFiles",
        inputType: "file",
        default: [],
        label:
          "Please upload a concept note explaining the technology with necessary figures and diagrams :",
        mt: true,
        fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
      },
    },
  },
  approach: {
    title: "Objective & Approach",
    inputs: {
      approachDesc: {
        id: "approachDesc",
        title: "Objectives and Approach",
        sl: 2,
        label:
          "[Describe how the proposed project addresses the problem. Clarify the current status of the innovation.] The description should cover the following points: \n1). Strategy and/or methodology of work. \n2). Scope and boundaries of the work, including any issues that will not be covered. \n3). Data analysis (sample size,data collection)",
        inputType: "text",
        default: "",
      },
    },
  },
  novelty: {
    title: "Novelty",
    inputs: {
      noveltyDesc: {
        id: "noveltyDesc",
        inputType: "text",
        default: "",
        title: "Novelty",
        sl: 3,
        label:
          "[Explain how your idea is innovative and how it is different from the existing products in the markets or current state-of-the-art. Tabular representation of the difference between your idea and the other products in market or competitive product which are under development will be appreciated. Concrete market data is encouraged.]",
      },
      tableFile: {
        id: "tableFile",
        inputType: "file",
        default: [],
        label: "Upload table",
        mt: true,
        fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
      },
    },
  },
  opportunity: {
    title: "Opportunity",
    inputs: {
      opportunityDesc: {
        id: "opportunityDesc",
        inputType: "text",
        default: "",
        title: "Opportunity",
        sl: 4,
        label:
          "[Explain how your idea is innovative and how it is different from the existing products in the markets or current state-of-the-art. Tabular representation of the difference between your idea and the other products in market or competitive product which are under development will be appreciated. Concrete market data is encouraged.]",
      },
    },
  },
  risk: {
    title: "Risk",
    inputs: {
      riskDesc: {
        id: "riskDesc",
        inputType: "text",
        default: "",
        sl: 5,
        title: "Challenges or risk factors associated with the project",
        label:
          "Challenges or risk factors associated with the project\n[What are the challenges and risk factors that you envision whichmay affect this project?] What are the critical success factors/potential barriers",
      },
    },
  },
  preliminaryWork: {
    title: "Preliminary Work",
    inputs: {
      preliminaryWorkStatus: {
        id: "preliminaryWorkStatus",
        inputType: "text",
        default: "",
        sl: 6,
        title: "Preliminary Work",
        label:
          "Has any preliminary work been carried out? Give status of work done? If no, please provide the background details.",
      },
    },
  },
  outcomes: {
    title: "End Outcomes",
    inputs: {
      endOutcomes: {
        id: "endOutcomes",
        inputType: "checkbox",
        default: [],
        label:
          "7. Proposed end-outcomes (Your BIG Project is expected to result in the following end-outcomes ).",
        options: [
          {
            value: "1",
            title: "A Product for customers",
          },
          {
            value: "2",
            title: "A knowledge based service for customers",
          },
          {
            value: "3",
            title: "A technology (knowhow) for sale or licensing to industry",
          },
          {
            value: "4",
            title: "An intellectual property right for licensing or sale",
          },
          {
            value: "5",
            title: "Any other, please specify",
          },
          {
            textbox: true,
            title: "Others",
            default: "",
            id: "others",
          },
        ],
      },
    },
  },
  futurePlan: {
    title: "Future Plan",
    inputs: {
      planDesc: {
        id: "planDesc",
        inputType: "text",
        default: "",
        sl: 8,
        title: "Future Plan",
        label:
          "[What do you envision to be the key next step to making impact with this innovation (e.g., sponsored research support, licensing, venture financing)? What is the time frame?]\nCommercialization plan should indicate :\n1). Market entry strategy. \n2). Timelines and Milestones. \n3). Data analysis (sample size,data collection)",
      },
    },
  },
  intellectualProperty: {
    title: "Intellectual Property",
    inputs: {
      ipOwned: {
        id: "ipOwned",
        inputType: "text",
        default: "",
        sl: 9,
        title: "Intellectual Property",
        label:
          "i. Does the applicant or the applicant company own any IP related to this project. If yes, give ",
      },
      patents: {
        id: "patents",
        inputType: "text",
        default: "",
        label:
          "ii. List Of Patents That Appear To Cover Any Part Of The Technology Of Interest Or Similar (And Possibly Overlapping) Technologies And Thereby Restrict The Freedom-To-Operate In The Envisaged Area. (Please mention Patent Number, Patent Title and Patent Assignee)",
        mt: true,
      },
      license: {
        id: "license",
        inputType: "text",
        default: "",
        label:
          "iii. If there are patents that are overlapping and may restrict FTO, does the applicant have the required license/s to practise these inventions for the purposes of the proposed project? Please provide license agreement details if any or provide information of the proposed next steps to obtain said license/s.",
        mt: true,
      },
    },
  },
  references: {
    title: "References",
    inputs: {
      references: {
        id: "references",
        inputType: "text",
        default: "",
        title: "Relevant References",
        sl: 10,
        label: "",
      },
    },
  },
  documents: {
    title: "Declaration Documents",
    inputs: {
      documents: {
        id: "documents",
        inputType: "file",
        default: [],
        label:
          "11. Please upload declaration document on ethical/legal/safety/regulatory issues involved, if any :",
          fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
      },
    },
  },
};

const initialData = {
  proposalSummary: {
    proposalSummary: "",
    proposalConceptFiles: [],
  },
  approach: {
    approachDesc: "",
  },
  novelty: {
    noveltyDesc: "",
    tableFile: [],
  },
  opportunity: {
    opportunityDesc: "",
  },
  risk: {
    riskDesc: "",
  },
  preliminaryWork: {
    preliminaryWorkStatus: "",
  },
  outcomes: {
    endOutcomes: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      others: "",
    },
  },
  futurePlan: {
    planDesc: "",
  },
  intellectualProperty: {
    ipOwned: "",
    patents: "",
    license: "",
  },
  references: {
    references: "",
  },
  documents: {
    documents: [],
  },
};

const CompletionOfForm = ({
  handleForm,
  application,
  applicationData,
  call,
  from,
}) => {
  const FORM_NAME = "completionForm";
  const [data, setData] = useState({ ...initialData });
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [fileLoading, setFileLoading] = useState({
    proposalConceptFiles: false,
    tableFile: false,
    documents: false,
  });
  const [openComments, setOpenComments] = useState(false);
  const [commentsStatus, setCommentsStatus] = useState({});
  const [commentsStatusState, setCommentsStatusState] = useState({});
  const [formVersion, setFormVersion] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const handleFiles = async (field, id, file) => {
    if (file.length > 0) {
      handleUpload(file[0], field, id);
    }
  };

  useEffect(() => {
    getFormDataHandler();
  }, []);

  const getFormDataHandler = async () => {
    const response = await getFormData(
      {
        formName: "completionForm",
        applicationId: application._id,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      let dataObj = {};
      let commentObj = {};
      Object.keys(initialData).map((field, i) => {
        dataObj[field] = response.msg[field]?.data;
        commentObj[field] = {
          comment: response.msg[field]?.comment,
          status: response.msg[field]?.status,
        };
      });
      setData(dataObj);
      setCommentsStatus({...commentObj});
      setCommentsStatusState({...commentObj});
      setFormVersion(response?.msg?.__v);
    }
  };

  const changeComment = (id, value) => {
    setCommentsStatusState({
      ...commentsStatusState,
      [id]: { ...commentsStatusState[id], comment: value },
    });
  };

  const handleApproveCheckbox = (id, e) => {
    setCommentsStatusState({
      ...commentsStatusState,
      [id]: {
        ...commentsStatusState[id],
        status: e.target.checked ? "approved" : "pending",
      },
    });
  };

  const handleChange = (field, id, value) => {
    setData({ ...data, [field]: { ...data[field], [id]: value } });
  };

  const handleCheckbox = (field, id, value, isChecked) => {
    setData({
      ...data,
      [field]: {
        ...data[field],
        [id]: { ...data[field][id], [value]: isChecked },
      },
    });
  };

  const removeFile = (field, id, index) => {
    let array = [...data[field][id]];
    array.splice(index, 1);
    handleChange(field, id, array);
  };

  const handleUpload = async (file, field, id) => {
    setFileLoading({ ...fileLoading, [id]: true });
    const fileData = new FormData();
    fileData.append("file", file);
    const response = await uploadFile(fileData, dispatch, controller);
    if (response && response.status == 200) {
      setData({
        ...data,
        [field]: {
          ...data[field],
          [id]: [...data[field][id], { name: file.name, url: response.msg }],
        },
      });
    } else {
      alert(`Failed to upload ${file.name}!`);
    }
    setFileLoading({ ...fileLoading, [id]: false });
  };

  const submitData = async () => {
    const response = await saveFormInputs(
      {
        formData: data,
        applicationId: application._id,
        formName: FORM_NAME,
        formVersion: formVersion,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler("Form Submitted", "success");
      handleForm(null, null, true);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const saveCommentHandler = async (id, name) => {
    const response = await saveComment(
      {
        applicationId: application._id,
        fieldId: id,
        values: commentsStatusState[id],
        formName: FORM_NAME,
        formVersion: formVersion,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(`Comment for ${name} submitted!`, "success");
      let dataObj = {};
      let commentObj = {};
      Object.keys(initialData).map((field, i) => {
        dataObj[field] = response.msg[field]?.data;
        commentObj[field] = {
          comment: response.msg[field]?.comment,
          status: response.msg[field]?.status,
        };
      });
      setData(dataObj);
      setCommentsStatus({...commentObj});
      setCommentsStatusState({...commentObj});
      setFormVersion(response?.msg?.__v);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  return (
    <PhaseContainer>
      <div className="header">
        <div className="title">Enter Proposal Details</div>
        <div className="closeIcon" onClick={() => handleForm(null)}>
          {Icons.close}
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="fields">
          {Object.keys(fields).map((field, index) => {
            return (
              <div className="field" key={index} id={field}>
                <span className="inputField">
                  {Object.keys(fields[field].inputs).map((input, i) => {
                    return (
                      <BuildFormField
                        key={i}
                        options={fields[field].inputs[input]}
                        value={data[field][input]}
                        handleChange={handleChange}
                        handleFiles={handleFiles}
                        removeFile={removeFile}
                        field={field}
                        handleCheckbox={handleCheckbox}
                        fileLoading={fileLoading}
                        index={i}
                        status={commentsStatus?.[field]?.status || "Pending"}
                        from={from}
                      />
                    );
                  })}
                </span>

                <CommentsContainer index={index} openComments={openComments}>
                  <div
                    className="arrow"
                    onClick={() => setOpenComments(!openComments)}
                  >
                    {Icons.doubleArrow}
                  </div>
                  <div className="commentsSection">
                    <span>
                      {index + 1}. {fields[field].title}
                    </span>
                    <textarea
                      onChange={(e) => changeComment(field, e.target.value)}
                      value={commentsStatusState?.[field]?.comment}
                      readOnly={from != "associate"}
                    ></textarea>

                    {from == "associate" ? (
                      <div className="buttons">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                commentsStatusState?.[field]?.status == "approved"
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleApproveCheckbox(field, e)}
                            />
                          }
                          label="Ok"
                        />
                        <Button
                          onClick={() =>
                            saveCommentHandler(field, fields[field].title)
                          }
                          className="muiBtn"
                          variant="outlined"
                        >
                          Save
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </CommentsContainer>
              </div>
            );
          })}

          {from == "associate" ? (
            <></>
          ) : (
            <div className="buttons">
              <CustomButton title="Submit" onClickHandler={submitData} />
            </div>
          )}
        </div>
      </div>
    </PhaseContainer>
  );
};

export default CompletionOfForm;

export const PhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
      color: var(--text1);
      font-size: 1.2rem;
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .statusContainer {
    margin: 0.5rem 0;
  }

  .status {
    background: yellow;
    width: max-content;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    text-transform: capitalize;
  }

  .approved {
    background: #58d68d;
    color: #fff;
    text-transform: capitalize;
  }

  hr {
    margin: 1rem 0;
    color: var(--text3);
    width: 100%;
    opacity: 0.3;
  }

  .row {
    display: flex;
  }

  .formgroup {
    color: var(--text3);
  }

  .fields {
    display: flex;
    flex-direction: column;

    .field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;

      .inputField {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0;
      }

      .label {
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
        white-space: pre-wrap;
      }

      .fileUploader {
        width: 100%;
        padding: 1rem;
        border: 1px solid var(--text3);
        border-style: dashed;
        border-radius: 4px;
        min-height: 5rem;
        cursor: pointer;

        .files {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .file {
            margin-right: 1rem;
          }
        }

        .component {
          box-sizing: border-box;
          min-width: 100%;
          min-height: 5rem;
        }
      }
    }

    .addRowButton {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;

      button {
        color: var(--primary) !important;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }

  .tableContainer {
    background: var(--light);

    table {
      border-collapse: separate;
      border-spacing: 10px;
      table-layout: fixed;
      thead {
        tr {
          th {
            border: none;
            padding: 0 !important;
            color: var(--text3);
            font-size: 1.1rem;
            text-align: center;
          }

          .tc-50 {
            width: 50px !important;
          }

          .tc-200 {
            min-width: 200px !important;
          }
        }
      }

      tbody {
        tr {
          height: 180px;
          td {
            border: none;
            color: var(--text1);
            background: #fff;
            height: 100%;

            textarea {
              width: 100%;
              height: 100%;
              height: 8rem;
              line-height: 1.4;
              color: var(--text3);
              resize: none;
              border: none;
              outline: none;
              font-size: 1rem;
            }

            .actions {
              display: flex;
              justify-content: center;
              flex-direction: column;
              gap: 1rem;
              align-items: center;

              .action {
                cursor: pointer;
                transition: 0.1s;

                &:hover {
                  color: var(--text3);
                }
              }
            }
          }

          .va-center {
            text-align: center;
          }
        }
      }
    }
  }

  .objectiveTable {
    table {
      thead {
        tr {
          // height: 6rem;
          th {
            height: auto !important;
            overflow: hidden;
            text-overflow: clip;
          }
        }
      }
    }
  }
`;

export const CommentsContainer = styled.div`
  width: ${(props) => (props.openComments ? "25rem" : "2rem")};
  transition: 0.2s width;
  display: flex;
  height: 100%;
  .arrow {
    background: var(--primary);
    height: 100%;
    min-width: 2rem;
    max-width: 2rem;
    cursor: pointer;
    border-top-left-radius: ${(props) => (props.index == 0 ? "15px" : "none")};
    border-bottom-left-radius: ${(props) =>
      props.index == 10 ? "15px" : "none"};
    svg {
      font-size: 1.7rem;
      color: #fff;
      transform: ${(props) =>
        props.openComments ? "rotate(0deg)" : "rotate(180deg)"};
      display: ${(props) => (props.index == 0 ? "flex" : "none")};
      margin-top: 0.5rem;
      justify-content: center;
      width: 100%;
    }
  }
  .commentsSection {
    width: 100%;
    height: 100%;
    border: 1px solid #d0d3d4;
    border-left: none;
    border-top: ${(props) => (props.index == 0 ? "" : "none")};
    padding: 1rem;
    display: ${(props) => (props.openComments ? "flex" : "none")};
    flex-direction: column;
    textarea {
      margin-top: 0.5rem;
      resize: none;
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      color: var(--text1);
      height: 100%;
      border: 1px solid #d0d3d4;
      outline: none;
      border-radius: 8px;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .muiBtn {
        // padding: 0rem 1.5rem;
        height: 2rem;
        color: var(--primary) !important;
      }
      .radioButtons {
        font-size: 0.9rem;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        label {
          width: max-content;
        }
      }
    }
  }
`;
