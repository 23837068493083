import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";
import { globalDispatch } from "../../../../providers/GlobalProvider";
import { updateDocument } from "../../../../reducers/DocumentReducer";
import { Icons } from "../../../../utils/mediaExports";

const BuildFormField = ({
  field,
  options,
  value,
  handleChange,
  handleFiles,
  removeFile,
  handleCheckbox,
  fileLoading,
  from,
  index,
  status,
  addRow,
  deleteRow,
  changeRowTfValue,
  changeStaticTableValue,
  changeBooleanTableValue,
}) => {
  const isReadOnly = from == "associate";

  const dispatch = globalDispatch();

  const setDocument = (url) => {
    dispatch(
      updateDocument({
        url: url,
        open: true,
      })
    );
  };

  switch (options.inputType) {
    case "text":
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options?.sl ? (
              <>
                {options.sl}.&nbsp;{options?.title}
                <br />
              </>
            ) : (
              <></>
            )}
            <div className="statusContainer">
              {index == 0 ? (
                <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                  <br />
                </span>
              ) : (
                <></>
              )}
              {options?.sl ? <div className="mt1"></div> : <></>}
              {options.label}
            </div>
          </FormLabel>
          <TextField
            size="small"
            className="input"
            multiline
            // rows={3}
            fullWidth
            disabled={isReadOnly}
            value={value}
            onChange={(e) => handleChange(field, options.id, e.target.value)}
          />
        </>
      );

    case "file":
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options.label}
            <div className="statusContainer">
              {index == 0 ? (
                <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                </span>
              ) : (
                <></>
              )}
            </div>
          </FormLabel>
          <div className="fileUploader">
            {from == "associate" ? (
              <></>
            ) : (
              <FileUploader
                classes="component"
                handleChange={(file) => handleFiles(field, options.id, file)}
                name="file"
                types={options.fileType || ["PDF"]}
                multiple={true}
                children={
                  <FileArea>
                    <div className="message">
                      <div>Drag and Drop</div>
                      <div>Or</div>
                      <div>Browse for files</div>
                      <CircularProgress
                        style={{
                          display: fileLoading[options["id"]]
                            ? "block"
                            : "none",
                        }}
                        size={20}
                      />
                    </div>
                  </FileArea>
                }
              />
            )}
            <div className="files">
              {value.map((file, index) => {
                return (
                  <div className="file mt1" key={index}>
                    <Chip
                      label={
                        <div
                          className="download"
                          onClick={() => {
                            setDocument(file?.url);
                          }}
                        >
                          {file?.name}
                        </div>
                      }
                      onDelete={() => {
                        removeFile(field, options.id, index);
                      }}
                      deleteIcon={from == "associate" ? <></> : Icons.delete}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      );

    case "checkbox":
      console.log(options);
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options.label}
            <div className="statusContainer">
              {index == 0 ? (
                <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                </span>
              ) : (
                <></>
              )}
            </div>
          </FormLabel>
          <FormGroup className="formgroup">
            {options.options.map((option, index) => (
              <>
                {option.textbox ? (
                  <TextField
                    size="small"
                    disabled={isReadOnly}
                    onChange={(e) =>
                      handleCheckbox(
                        field,
                        options.id,
                        option.id,
                        e.target.value,
                        true
                      )
                    }
                    value={value[option.id]}
                    label="Others"
                  />
                ) : (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        disabled={isReadOnly}
                        value={option.value}
                        onChange={(e) => {
                          if (isReadOnly) return;
                          handleCheckbox(
                            field,
                            options.id,
                            e.target.value,
                            e.target.checked
                          );
                        }}
                        checked={!!value[option.value]}
                      />
                    }
                    label={option.title}
                  />
                )}
              </>
            ))}
          </FormGroup>
        </>
      );

    case "table":
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options.label}
            <div className="statusContainer">
              {index == 0 ? (
                <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                </span>
              ) : (
                <></>
              )}
            </div>
          </FormLabel>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "40px" }}>Sl No</TableCell>
                  {options?.theadings?.map((heading, i) => {
                    return <TableCell key={i}>{heading}</TableCell>;
                  })}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {value?.map((value, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      {Object.keys(value).map((field, index) => (
                        <TableCell key={index}>
                          <TextField
                            size="small"
                            fullWidth
                            value={value[field]}
                            onChange={(e) =>
                              changeRowTfValue(
                                i,
                                options.id,
                                field,
                                e.target.value
                              )
                            }
                            disabled={isReadOnly}
                          />
                        </TableCell>
                      ))}
                      <TableCell>
                        {isReadOnly ? (
                          "-"
                        ) : (
                          <div
                            className="actions"
                            onClick={() => deleteRow(options.id, i)}
                          >
                            {Icons.delete}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}

                {isReadOnly ? (
                  <></>
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={Object.keys(options.theadings).length + 2}
                    >
                      <Button
                        className="muiOutlinedBtn"
                        variant="outlined"
                        onClick={() => addRow(options.id)}
                      >
                        Add Row
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );

    case "radio":
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options.label}
            <div className="statusContainer">
              {index == 0 ? (
                <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                </span>
              ) : (
                <></>
              )}
            </div>
          </FormLabel>

          <RadioGroup
            value={value}
            onChange={(e) => {
              handleChange(field, options.id, e.target.value);
            }}
          >
            {options?.options?.map((option, i) => {
              return (
                <FormControlLabel
                  value={option}
                  control={<Radio disabled={isReadOnly} />}
                  label={option}
                  key={i}
                />
              );
            })}
          </RadioGroup>
        </>
      );

    case "staticTable":
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options.label}
            <div className="statusContainer">
              {index == 0 ? (
                <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                </span>
              ) : (
                <></>
              )}
            </div>
          </FormLabel>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={"40px"}>Sl No</TableCell>
                  {options.columns.map((column, i) => {
                    return <TableCell key={i}>{column}</TableCell>;
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {options.rows.map((row, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{row}</TableCell>
                      {options.columns.map((column, j) => {
                        if (j == 0) return <></>;
                        return (
                          <TableCell key={j}>
                            <TextField
                              size="small"
                              value={
                                value["item" + (i + 1)][options.columnIds[j]]
                              }
                              onChange={(e) =>
                                changeStaticTableValue(
                                  options.id,
                                  "item" + (i + 1).toString(),
                                  options.columnIds[j],
                                  e.target.value
                                )
                              }
                              disabled={isReadOnly}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );

    case "booleanTable":
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options.label}
            <div className="statusContainer">
              {index == 0 ? (
                <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                </span>
              ) : (
                <></>
              )}
            </div>
          </FormLabel>

          <TableContainer>
            <Table>
              <TableBody>
                {options.rows.map((row, i) => {
                  return (
                    <TableRow>
                      <TableCell width={"70%"}>{row}</TableCell>
                      <TableCell width={"30%"} align="center">
                        <RadioGroup
                          row
                          value={value[i]}
                          onChange={(e) => {
                            changeBooleanTableValue(
                              options.id,
                              i,
                              e.target.value
                            );
                          }}
                        >
                          <FormControlLabel
                            value={"yes"}
                            control={<Radio />}
                            label={"Yes"}
                            disabled={isReadOnly}
                          />
                          <FormControlLabel
                            value={"no"}
                            control={<Radio />}
                            label={"No"}
                            disabled={isReadOnly}
                          />
                        </RadioGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
  }
};

export default BuildFormField;

export const FileArea = styled.div`
  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: var(--text3);
    width: 100%;
    height: 100%;
  }
`;
