import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { border } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { applicationsProgress } from "../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../providers/GlobalProvider";
import CustomTextSearch from "../../../customComponents/CustomSearchText";

const tableHeadings = [
  {
    id: "sl",
    title: "Sl No.",
  },
  {
    id: "companyName",
    title: "Company Name",
  },
  {
    id: "applied",
    title: "Application",
  },
  {
    id: "eligibility",
    title: "Eligibility Check",
  },
  {
    id: "review",
    title: "Online Review",
  },
  {
    id: "selection",
    title: "BIRAC Selection",
  },
  {
    id: "tep",
    title: "TEP",
  },
  {
    id: "award",
    title: "Award",
  },
  {
    id: "dueDiligence",
    title: "Due Diligence",
  },
  {
    id: "agreement",
    title: "Agreement Signing",
  },
  {
    id: "milestone",
    title: "Milestone",
  },
];

const BigProgressView = ({setActiveView}) => {
  const [applications, setApplications] = useState([]);
  const { id } = useParams();
  const controller = new AbortController();
  const dispatch = globalDispatch();
  const [width, setWidth] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getApplications();
    const element = document.getElementById("progressTable");
    new ResizeObserver(outputSize).observe(element);
  }, []);

  function outputSize() {
    const widthCur = document.getElementById("progressTable").offsetWidth;
    if (widthCur != width) {
      setWidth(widthCur);
    }
  }

  const getApplications = async () => {
    const response = await applicationsProgress(
      { callId: id },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      setApplications(response.msg);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  return (
    <ProgressContainer>
      <div className="row">
        <CustomTextSearch
          value={""}
          setValue={(value) => {}}
          upcomingBanner={true}
        />
        <ColorIndicator />
      </div>
      <TableContainer sx={{ border: "2px solid rgba(224, 224, 224, 1)" }}>
        <Table id="progressTable">
          <TableHead>
            <TableRow>
              {tableHeadings.map((heading, i) => {
                return (
                  <>
                    <TableCell
                      style={
                        i == 0 || i == 1
                          ? { borderRight: "2px solid rgba(224, 224, 224, 1)" }
                          : {}
                      }
                      align={i != 0 ? "center" : "left"}
                      key={i}
                    >
                      {heading.title}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {applications.map((application, i) => {
              return <RowBuilder application={application} key={i} index={i} callId={id} setActiveView={setActiveView} width={width} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ProgressContainer>
  );
};

export default BigProgressView;

const ColorIndicator = () => {
  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            border: "1px solid gray",
            borderRadius: "50%",
            background: "#33AD55",
          }}
        ></div>
        <span style={{ fontSize: "0.9rem", color: "var(--text3)" }}>Done</span>
      </div>
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            border: "1px solid gray",
            borderRadius: "50%",
            background: "#EFE614",
          }}
        ></div>
        <span style={{ fontSize: "0.9rem", color: "var(--text3)" }}>
          In Process
        </span>
      </div>
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            border: "1px solid gray",
            borderRadius: "50%",
            background: "#fff",
          }}
        ></div>
        <span style={{ fontSize: "0.9rem", color: "var(--text3)" }}>
          Not Started
        </span>
      </div>
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            border: "1px solid gray",
            borderRadius: "50%",
            background: "#FA8484",
          }}
        ></div>
        <span style={{ fontSize: "0.9rem", color: "var(--text3)" }}>
          Rejected
        </span>
      </div>
    </div>
  );
};

const StatusMark = ({ status, applicationStatus, id }) => {
  let statusColor = "#fff";
  console.log(id);
  if (id == "applied") {
    if (status == "approved") {
      statusColor = "#33AD55";
    } else if (status == "pending") {
      statusColor = "#EFE614";
    }
    if (status == "rejected") {
      statusColor = "#FA8484";
    }
  } else if (id == "milestone") {
    if (status == "approved") {
      statusColor = "#33AD55";
    } else if (status == "pending" && applicationStatus == "selected") {
      statusColor = "#EFE614";
    }
  } else if (status == "rejected") {
    statusColor = "#FA8484";
  } else if (id == applicationStatus) {
    statusColor = "#EFE614";
  } else if (status == "approved") {
    statusColor = "#33AD55";
  } else if (status == "pending") {
    statusColor = "#fff";
  }

  return (
    <div
      style={{
        margin: "0 auto",
        width: "30px",
        height: "30px",
        border: "1px solid gray",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "20px",
          height: "20px",
          border: "1px solid gray",
          borderRadius: "50%",
          background: statusColor,
        }}
      ></div>
    </div>
  );
};

const RowBuilder = ({ application, index, callId, setActiveView, width }) => {
  const navigate = useNavigate();
  return (
    <TableRow className="rowItem" onClick={() => {
      setActiveView(0);
      navigate(`/schemes/big/${callId}/${application?._id}`)
    }}> 
      {tableHeadings.map((heading, i) => {
        if (i == 0) {
          return (
            <TableCell
              style={{ borderRight: "2px solid rgba(224, 224, 224, 1)" }}
              width="60px"
              align="center"
            >
              {index + 1}
            </TableCell>
          );
        }
        if (i == 1) {
          return (
            <TableCell
              style={{ borderRight: "2px solid rgba(224, 224, 224, 1)" }}
              key={i}
              align="center"
              width="300px"
            >
              {application?.companyName}
            </TableCell>
          );
        }
        if (heading.id == "milestone") {
          return (
            <TableCell align="center" key={i} width={((width - 260)/9) + "px"}>
              <StatusMark
                status={application?.applicationData?.["milestone"]?.status}
                applicationStatus={application?.status}
                id={heading.id}
              />
            </TableCell>
          );
        }
        return (
          <TableCell align="center" key={i} width={((width - 260)/9) + "px"}>
            <StatusMark
              status={application?.applicationData?.["phase" + (i - 1)]?.status}
              applicationStatus={application?.status}
              id={heading.id}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .row {
    display: flex;
    justify-content: space-between;
  }

  .rowItem {
    cursor: pointer;
    transition: background 0.2s;
    &:hover {
      background: rgba(1, 210, 178, 0.2);
    }
  }
`;
