import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);

import { Radar as RD } from "react-chartjs-2";
import "./score.css";
import { TextField } from "@mui/material";
import { globalDispatch, globalSelector } from "../../providers/GlobalProvider";
import { useEffect } from "react";
import { updateData } from "../../reducers/startupform/formReducer";
import { useState } from "react";
import { FcInfo } from "react-icons/fc";

const MAX_VALUE = 5;
const MIN_VALUE = 1;
const RADAR_GRID_COLOR = "rgba(0, 0, 0, 0.4)";

export var rdOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: (x) => {
          console.log(x);
        },
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    r: {
      max: 5,
      min: 0,
      ticks: {
        stepSize: 1,
        callback: function (value, index, ticks) {
          return "LEVEL " + value;
        },
      },
      grid: {
        color: RADAR_GRID_COLOR,
      },
      pointLabels: {
        font: {
          size: 20,
        },
      },
    },
  },
};

let model = {
  people: 0,
  process: 2,
  product: 1,
};

const ScoreView = ({ data }) => {
  const [dataChart, setDataChart] = useState([0, 0, 0]);

  const [activeLegend, setActiveLegend] = useState("People");

  var rdData = {
    labels: ["People", "Process", "Product"],
    datasets: [
      {
        label: " ",
        fill: true,
        backgroundColor: "rgba(136, 132, 216, 0.7)",
        data: dataChart,
        hoverBackgroundColor: ["#8389ae", "#FDFDFD"],
      },
    ],
  };

  const { imm } = globalSelector((state) => state.startup.imm);
  const dispatch = globalDispatch();


  useEffect(() => {
    setDataChart([imm.people.value, imm.process.value, imm.product.value]);
  }, []);


  const handleChange = (id, value, e, i) => {
    if (e.target.value > MAX_VALUE) {
      e.target.value = MAX_VALUE;
    } else if (e.target.value < MIN_VALUE && e.target.value.length > 0) {
      e.target.value = MIN_VALUE;
    }

    let arr = [...dataChart];
    arr[i] = e.target.value;
    setDataChart(arr);

    dispatch(
      updateData({
        id: id,
        category: "imm",
        subCategory: "imm",
        type: "value",
        value: parseInt(e.target.value),
      })
    );
  };

  return (
    <div className="scoreContainer">
      <div className="scoreForm">
        <TextField
          className="textbox1"
          label={"People"}
          fullWidth
          size="small"
          id="People"
          onFocus={() => setActiveLegend("People")}
          type={"number"}
          value={imm?.people?.value}
          onChange={(e) => handleChange("people", e.target.value, e, 0)}
        />

        <TextField
          className="textbox2"
          label={"Product"}
          id="Product"
          onFocus={() => setActiveLegend("Product")}
          size="small"
          fullWidth
          type={"number"}
          value={imm?.product?.value}
          onChange={(e) => handleChange("product", e.target.value, e, 2)}
        />

        <TextField
          className="textbox3"
          label={"Process"}
          size="small"
          id="Process"
          fullWidth
          type={"number"}
          value={imm?.process?.value}
          onFocus={() => setActiveLegend("Process")}
          onChange={(e) => handleChange("process", e.target.value, e, 1)}
        />
      </div>

      <div className="radarChart">
        <RD data={rdData} id="canvas" options={rdOptions} />
      </div>

      <div className="legendContainerDropdown">
        <div className="legends">
          <div className="legened_info">
            <FcInfo className="info-icon" />
            <h3 className="legendHeading">{activeLegend}</h3>
          </div>
          <p>
            <span className="span-head">1</span> -{" "}
            <span className="span-tail"> Level 1</span>
          </p>
          <p>
            <span className="span-head">2</span> -{" "}
            <span className="span-tail"> Level 2</span>
          </p>
          <p>
            <span className="span-head">3</span> -{" "}
            <span className="span-tail"> Level 3</span>
          </p>
          <p>
            <span className="span-head">4</span> -{" "}
            <span className="span-tail"> Level 4</span>
          </p>
          <p>
            <span className="span-head">5</span> -{" "}
            <span className="span-tail"> Level 5</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScoreView;
