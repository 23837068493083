import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  Stepper,
  Step,
  StepLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./milestonedash.css";
import DragDropFileMileDocs from "./milestone_drag_drop";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  deletePrjReport,
  getMileInfo,
  getProjectInfo,
  reportComment,
  submitForDispersion,
  submitForNo,
  submitForYes,
  submitReport,
  uploadPrjReport,
} from "../../../../../data/network/api/milestone";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../providers/GlobalProvider";
import PdfPopUp from "../../../../customComponents/pdfPopUp";
import CustomButton from "../../../../customComponents/CustomButton";
import { Icons } from "../../../../../utils/mediaExports";
import validator from "validator";
import moment from "moment";
import { useSnackbar } from "notistack";
import { OutletContainer } from "../../../../../utils/customStyledComponents";
import MilestoneTab from "./MilestoneTab";
import { milestonePageStack } from "../../../../../utils/constants";
import { updateNavigation } from "../../../../../reducers/navigationReducer";
import GlobalStore from "../../../../../store/globalStore";
import { getFileName, inrAddCommas } from "../../../../../utils/helperHandler";
import { updateDocument } from "../../../../../reducers/DocumentReducer";
import { MentionsInput, Mention } from "react-mentions";
import { FormLabel } from "react-bootstrap";

const expenseTableRows = [
  {
    id: "capital",
    title: "Capital",
  },
  {
    id: "consumables",
    title: "Consumables",
  },
  {
    id: "salaries",
    title: "Salaries",
  },
  {
    id: "outsourcing",
    title: "Outsourcing",
  },
  {
    id: "rent_facilities",
    title: "Rent & Facilities",
  },
  {
    id: "travel",
    title: "Travel",
  },
  {
    id: "line",
  },
  {
    id: "total",
    title: "Total",
  },
];

const initialCommentFields = [
  {
    id: "technicalReport1",
    display: "@technicalReport1",
  },
  {
    id: "ucDraft",
    display: "@ucDraft",
  },
  {
    id: "ucReport",
    display: "@ucReport",
  },
  {
    id: "bankStatement",
    display: "@bankStatement",
  },
];

const reportEditAccess = ["founder"];
const reportViewAccess = [
  "founder",
  "program_manager",
  "finance_manager",
  "super_admin",
  "accountant",
];
const commentEditAccess = [
  "program_manager",
  "finance_manager",
  "super_admin",
  "accountant",
];
const commentViewAccess = [
  "founder",
  "program_manager",
  "finance_manager",
  "super_admin",
  "accountant",
];
const progressBoxEditAccess = [
  "program_manager",
  "finance_manager",
  "super_admin",
];
const progessBoxViewAccess = [
  "program_manager",
  "finance_mananger",
  "super_admin",
];

const MilestoneDash = () => {
  const user = globalSelector((state) => {
    return state.auth.user;
  });

  const [comment, setComment] = useState("");
  const role = user.role.roleId;
  const [commentFields, setCommentFields] = useState([...initialCommentFields]);

  const userId = user._id;
  const [mileStatus, setMileStatus] = useState(null);
  const [mileStartDate, setMileStartDate] = useState(null);
  const [mileEndDate, setMileEndDate] = useState(null);
  const [mileBudget, setMileBudget] = useState("0");
  const [techReports, setTechReports] = useState([""]);
  const [ucDraft, setUcDraft] = useState(null);
  const [ucReport, setUcReport] = useState(null);
  const [bankReport, setBankReport] = useState(null);
  const [techSubStatus, setTechSubStatus] = useState(false);
  const [finSubStatus, setFinSubStatus] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [expenseCounts, setExpenseCounts] = useState(null);

  const [activeStepValue, setActiveStep] = useState(0);
  const [techActiveStep, setTechStep] = useState(0);

  //added by bikas to set which report has been clicked for view
  const [currentRepStatus, setCurrentRepStatus] = useState(null);

  const [reportVersion, setReportVersion] = useState(null);

  const tHeadStyle = {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "19px",
    lineHeight: "40px",
    color: "rgba(173, 178, 180, 1)",
  };
  const navigate = useNavigate();

  //functions to upload reports of different categories
  async function handleTechFileChange(newValue, index) {
    const formData = new FormData();
    formData.append("file", newValue);
    formData.append("reportId", reportId);
    formData.append("type", "tech");
    formData.append("index", index);
    formData.append("applicationId", params.applicationId);
    const uploadres = await uploadPrjReport({
      data: formData,
      controller,
      dispatch,
    });
    if (uploadres && uploadres.status == 200) {
      snackbarHandler(`Technical Report Uploaded`, "success");
      if (uploadres?.msg?.techreport?.length == 0) {
        setTechReports([""]);
      } else {
        setTechReports(uploadres?.msg?.techreport);
      }
      switch (uploadres?.msg?.techStatus) {
        case "non_submit":
          setTechStep(0);
          break;
        case "submit":
          setTechStep(1);
          break;
        case "approved":
          setTechStep(2);
          break;
      }
    } else {
      snackbarHandler("Something went wrong!", "error");
    }
  }
  async function handleUcFileCahnge(newValue) {
    const formData = new FormData();
    formData.append("file", newValue);
    formData.append("reportId", reportId);
    formData.append("type", "uc");
    formData.append("applicationId", params.applicationId);
    const uploadres = await uploadPrjReport({
      data: formData,
      controller,
      dispatch,
    });
    if (uploadres && uploadres.status == 200) {
      snackbarHandler(`UC Report Uploaded`, "success");
      setUcReport(uploadres?.msg?.ucReport);
      setFinSubStatus(uploadres?.msg?.finSubStatus);
    } else {
      snackbarHandler("Something went wrong!", "error");
    }
  }
  async function handleUcDraftFileChange(newValue) {
    const formData = new FormData();
    formData.append("file", newValue);
    formData.append("reportId", reportId);
    formData.append("type", "ucDraft");
    formData.append("applicationId", params.applicationId);
    const uploadres = await uploadPrjReport({
      data: formData,
      controller,
      dispatch,
    });
    if (uploadres && uploadres.status == 200) {
      snackbarHandler(`UC Draft Uploaded`, "success");
      setUcDraft(uploadres?.msg?.ucDraft);
      setFinSubStatus(uploadres?.msg?.finSubStatus);
    } else {
      snackbarHandler("Something went wrong!", "error");
    }
  }
  async function handleBankFormFile(newValue) {
    const formData = new FormData();
    formData.append("file", newValue);
    formData.append("reportId", reportId);
    formData.append("type", "bank");
    formData.append("applicationId", params.applicationId);
    const uploadres = await uploadPrjReport({
      data: formData,
      controller,
      dispatch,
    });
    if (uploadres && uploadres.status == 200) {
      snackbarHandler(`Bank Statement Uploaded`, "success");
      setBankReport(uploadres?.msg?.bankStatement);
      switch (uploadres?.msg?.finstatus) {
        case "non_submit":
          setActiveStep(0);
          break;
        case "submit":
          setActiveStep(1);
          break;
        case "billsChecked":
          setActiveStep(2);
          break;
        case "billsApproved":
          setActiveStep(3);
          break;
        case "billsRejected":
          setActiveStep(0);
          break;
        case "ucDraftApproved":
          setActiveStep(4);
          break;
        case "ucFinalApproved":
          setActiveStep(5);
          break;
      }
    } else {
      snackbarHandler("Something went wrong!", "error");
    }
  }
  //end of functions to upload reports

  //function to submit TAR/FAR by founder
  const submitTechRep = async () => {
    const uploadres = await submitReport({
      data: {
        repId: reportId,
        type: "tech",
        applicationId: params.applicationId,
      },
      controller,
      dispatch,
    });
    if (uploadres && uploadres.status == 200) {
      snackbarHandler(`TAR Submitted`, "success");
      changeActiveTab(activeTab, milestones[activeTab]?._id);
    } else {
      snackbarHandler("Something went wrong!", "error");
    }
  };

  const submitFinRep = async () => {
    const uploadres = await submitReport({
      data: {
        repId: reportId,
        type: "fin",
        applicationId: params.applicationId,
      },
      controller,
      dispatch,
    });
    if (uploadres && uploadres.status == 200) {
      snackbarHandler(`Expense Bills Submitted`, "success");
      changeActiveTab(activeTab, milestones[activeTab]?._id);
    } else {
      snackbarHandler("Something went wrong!", "error");
    }
  };

  const approveTechReport = async (status) => {
    const uploadres = await submitReport({
      data: {
        repId: reportId,
        type: "tech",
        applicationId: params.applicationId,
        status: status,
      },
      controller,
      dispatch,
    });
    if (uploadres && uploadres.status == 200) {
      snackbarHandler(`TAR Approved`, "success");
      changeActiveTab(activeTab, milestones[activeTab]?._id);
    } else {
      snackbarHandler("Something went wrong!", "error");
    }
  };
  //end of function to submit TAR/FAR by founder

  const updateFinStatus = async (status) => {
    const uploadres = await submitReport({
      data: {
        repId: reportId,
        type: "fin",
        applicationId: params.applicationId,
        status: status,
        reportVersion: reportVersion,
      },
      controller,
      dispatch,
    });
    if (uploadres && uploadres.status == 200) {
      snackbarHandler(`Status Updated`, "success");
      switch (status) {
        case "billsChecked":
          setActiveStep(2);
          break;
        case "billsApproved":
          setActiveStep(3);
          break;
        case "billsRejected":
          setActiveStep(0);
          break;
        case "ucDraftApproved":
          setActiveStep(4);
          break;
        case "ucFinalApproved":
          setActiveStep(5);
          break;
      }
    } else {
      snackbarHandler("Something went wrong!", "error");
    }
    changeActiveTab(activeTab, milestones[activeTab]?._id);
  };

  const close_pdf = () => {
    setPdfDialogOpen(false);
  };
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const params = useParams();
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [activeTab, setActiveTab] = useState(null);
  const [projectManId, setProjectManId] = useState(null);
  const [financeGuyId, setFinanceGuyId] = useState(null);
  const [milestoneStatus, setMilestoneStatus] = useState(null);
  const [allBillsApproved, setAllBillsApproved] = useState(false);

  const changeActiveTab = async (i, mileId) => {
    setCommentFields([...initialCommentFields]);
    setActiveStep(0);
    setTechStep(0);
    const mileInfo = await getMileInfo({
      data: { milestoneId: mileId, applicationId: params.applicationId },
      controller: controller,
      dispatch,
    });
    setTechReports([""]);
    // setProjectManId(mileInfo.msg[0].projects[0].roles[0]);
    // setFinanceGuyId(mileInfo.msg[0].projects[0].roles[1]);
    setMileStartDate(mileInfo.msg[0].startDate.slice(0, 10));
    setMileEndDate(mileInfo.msg[0].endDate.slice(0, 10));
    setMileStatus(mileInfo.msg[0].status);
    setEnteredBudget(mileInfo.msg[0].disbursedAmt);
    setremarks(mileInfo.msg[0].pmremarks);
    setMilestoneStatus(mileInfo?.msg[0]?.status);
    const reports = mileInfo.msg[0].report;
    setReportId(reports._id);
    setReportVersion(reports.__v);
    setMileBudget(mileInfo.msg[0].budget);

    let submitted = 0;
    let approved = 0;
    Object.keys(mileInfo?.msg[0]?.expenseFormCount).map((category) => {
      submitted += mileInfo?.msg[0]?.expenseFormCount?.[category]?.total;
      approved += mileInfo?.msg[0]?.expenseFormCount?.[category]?.approved;
    });
    if (submitted == approved) {
      setAllBillsApproved(true);
    } else {
      setAllBillsApproved(false);
    }

    switch (reports.finstatus) {
      case "non_submit":
        setActiveStep(0);
        break;
      case "submit":
        setActiveStep(1);
        break;
      case "billsChecked":
        setActiveStep(2);
        break;
      case "billsApproved":
        setActiveStep(3);
        break;
      case "billsRejected":
        setActiveStep(0);
        break;
      case "ucDraftApproved":
        setActiveStep(4);
        break;
      case "ucFinalApproved":
        setActiveStep(5);
        break;
    }

    switch (reports.techStatus) {
      case "non_submit":
        setTechStep(0);
        break;
      case "submit":
        setTechStep(1);
        break;
      case "approved":
        setTechStep(2);
        break;
    }

    setUcDraft(reports.ucDraft);
    setUcReport(reports.ucReport);
    setBankReport(reports.bankStatement);
    setComment(reports?.comments || "");
    if (reports.techreport.length > 0) {
      setTechReports(reports.techreport);
    }
    setExpenseCounts(mileInfo?.msg[0]?.expenseFormCount);
    setActiveTab(i);
  };
  const [companyName, setCompanay] = useState(null);
  const [industryType, setIndustry] = useState(null);
  const [amtDispersed, setAmtDispersed] = useState(null);
  const [totalBudget, setTotalBudget] = useState(null);
  const [prjStatus, setPrjStatus] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [prjStartDate, setPrjStartDate] = useState(null);
  const [prjEndDate, setPrjEndDate] = useState(null);

  const getProjectData = async (active = activeTab) => {
    setActiveTab(active);
    const prjInfo = await getProjectInfo({
      data: { applicationId: params.applicationId },
      controller: controller,
      dispatch,
    });
    if (prjInfo && prjInfo.status == 200) {
      setCompanay(prjInfo.msg[0].startUpName);
      setIndustry(prjInfo.msg[0].startUpType);
      setMilestones(prjInfo.msg[0].milestones);
      setTotalBudget(prjInfo.msg[0].totalBudget);
      setAmtDispersed(prjInfo.msg[0].totalAmtDispersed);
      setPrjStatus(prjInfo.msg[0].status);
      setPrjEndDate(prjInfo.msg[0].endDate);
      setPrjStartDate(prjInfo.msg[0].startDate);
      changeActiveTab(active, prjInfo.msg[0].milestones[active]._id);
    }
    return prjInfo?.msg[0]?.startUpName || "null";
  };

  const [enteredBudget, setEnteredBudget] = useState(null);
  const [remarks, setremarks] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const submitingDispersion = async () => {
    if (!validator.isNumeric(`${enteredBudget}`)) {
      snackbarHandler("Not valid number!", "error");
      return;
    }
    if (enteredBudget <= 0) {
      snackbarHandler("Budget cannot be negative or zero!", "error");
      return;
    }
    if (!enteredBudget) {
      snackbarHandler("Budget is required!", "error");
      return;
    }
    const res = await submitForDispersion({
      data: {
        milestoneId: milestones[activeTab]._id,
        budget: enteredBudget,
        remarks: remarks,
        applicationId: params.applicationId,
      },
      controller: controller,
      dispatch,
    });
    // console.log(res);
    if (res && res.status == 200) {
      snackbarHandler("Submitted for disbursal..", "success");
      getProjectData();
    } else {
      snackbarHandler(res?.msg || "Failed to submit!", "error");
    }
  };

  const financeYes = async () => {
    const res = await submitForYes({
      data: {
        milestoneId: milestones[activeTab]._id,
        budget: enteredBudget,
        remarks: remarks,
        applicationId: params.applicationId,
      },
      controller: controller,
      dispatch,
    });
    if (res && res.status == 200) {
      snackbarHandler("Approved", "success");
      getProjectData();
    } else {
      snackbarHandler(res?.msg || "Failed!", "error");
    }
  };
  const financeNo = async () => {
    const res = await submitForNo({
      data: {
        milestoneId: milestones[activeTab]._id,
        budget: enteredBudget,
        remarks: remarks,
        applicationId: params.applicationId,
      },
      controller: controller,
      dispatch,
    });
    if (res && res.status == 200) {
      snackbarHandler("Declined!", "success");
      getProjectData();
    } else {
      snackbarHandler(res?.msg || "Failed!", "error");
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let active = searchParams.get("active");
    if (active) {
      active = parseInt(active);
    } else if (activeTab == null) {
      active = 0;
      setSearchParams({ active: 0 });
    } else {
      active = parseInt(activeTab);
    }
    (async () => {
      const companyName = await getProjectData(active);
      let pageNavStack = JSON.parse(JSON.stringify(milestonePageStack));
      pageNavStack.milestones.show = true;
      pageNavStack.milestones.block = true;
      // pageNavStack.milestones.path = `/schemes/${}`;
      pageNavStack.companyName.show = true;
      pageNavStack.companyName.title = companyName;
      pageNavStack.companyName.path = `/schemes/milestones/${params?.applicationId}?active=0`;
      pageNavStack.milestoneNumber.show = true;
      pageNavStack.milestoneNumber.isActive = true;
      pageNavStack.milestoneNumber.title = `Milestone ${parseInt(active + 1)}`;
      pageNavStack.milestoneNumber.path = `/schemes/milestones/${params?.applicationId}?active=${active}`;
      dispatch(
        updateNavigation({
          type: "pageNavStack",
          value: { ...pageNavStack },
        })
      );

      document.getElementById("pageNavStackContainer").style.display = "block";
    })();
  }, []);

  useEffect(() => {
    try {
      if (activeTab != null) {
        let pageNavStack = JSON.parse(
          JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
        );
        const active = parseInt(activeTab);
        setSearchParams({ active: active });
        pageNavStack.milestoneNumber.title = `Milestone ${active + 1}`;
        pageNavStack.milestoneNumber.path = `/schemes/milestones/${params?.applicationId}?active=${active}`;

        dispatch(
          updateNavigation({
            type: "pageNavStack",
            value: { ...pageNavStack },
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [activeTab]);

  const stepActionHandler = (id, value) => {
    if (value == "undecided") {
      return;
    }

    if (id == "techApproval") {
      approveTechReport(value);
    } else updateFinStatus(value);
  };

  const uploadFile = async (file, fileType) => {
    switch (fileType) {
      case "techFile":
        handleTechFileChange(file);
        break;
    }
  };

  const handleDocument = (url) => {
    dispatch(
      updateDocument({
        url: url,
        open: true,
      })
    );
  };

  const deleteDocument = async (fileCategory, index) => {
    const response = await deletePrjReport({
      data: {
        reportId: reportId,
        fileCategory: fileCategory,
        index: index,
        applicationId: params.applicationId,
      },
      dispatch,
      controller,
    });
    if (response && response.status == 200) {
      snackbarHandler("File deleted", "success");
      const responseData = response?.msg;
      if (responseData?.techreport?.length == 0) {
        setTechReports([""]);
      } else {
        setTechReports(responseData?.techreport);
      }
      setUcDraft(responseData?.ucDraft || null);
      setUcReport(responseData?.ucReport || null);
      setBankReport(responseData?.bankStatement || null);

      switch (responseData?.techStatus) {
        case "non_submit":
          setTechStep(0);
          break;
        case "submit":
          setTechStep(1);
          break;
        case "approved":
          setTechStep(2);
          break;
      }

      switch (responseData?.finstatus) {
        case "non_submit":
          setActiveStep(0);
          break;
        case "submit":
          setActiveStep(1);
          break;
        case "billsChecked":
          setActiveStep(2);
          break;
        case "billsApproved":
          setActiveStep(3);
          break;
        case "billsRejected":
          setActiveStep(0);
          break;
        case "ucDraftApproved":
          setActiveStep(4);
          break;
        case "ucFinalApproved":
          setActiveStep(5);
          break;
      }
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const addTechReport = () => {
    let techReportsArr = [...techReports];
    techReportsArr.push("");
    setTechReports([...techReportsArr]);
  };

  useEffect(() => {
    if (techReports.length > 0) {
      let arr = JSON.parse(JSON.stringify(commentFields));
      for (let i = 1; i < techReports.length; i++) {
        arr.push({
          id: `technicalReport${i + 1}`,
          display: `@technicalReport${i + 1}`,
        });
      }
      setCommentFields(arr);
    } else {
      let arr = JSON.parse(JSON.stringify(commentFields));
      arr = arr.slice(0, 4);
      setCommentFields(arr);
    }
  }, [techReports]);

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const submitComment = async () => {
    const response = await reportComment({
      data: {
        comment: comment,
        reportId: reportId,
        applicationId: params.applicationId,
      },
      dispatch,
      controller,
    });
    if (response && response.status == 200) {
      snackbarHandler("Comment Submitted", "success");
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const [farSteps, setFarSteps] = useState([
    {
      id: "submit",
      label: "Expense Bills Submission",
      actionBy: "founder",
    },
    {
      id: "billsChecked",
      label: "Expense Bills Evaluation",
      actionBy: "accountant",
    },
    {
      id: "billsApproval",
      label: "Expense Bills Approval",
      actionBy: "finance_manager",
    },
    {
      id: "ucDraftCheck",
      label: "UC Draft Approval",
      actionBy: "finance_manager",
    },
    {
      id: "ucCheck",
      label: "UC Approval",
      actionBy: "finance_manager",
    },
  ]);
  const [tarSteps, setTarSteps] = useState([
    {
      id: "submit",
      label: "Technical Report Submission",
      actionBy: ["founder"],
    },
    {
      id: "reportApproved",
      label: "Technical Report Approval",
      actionBy: ["program_manager", "super_admin"],
    },
  ]);

  return (
    <OutletContainer className="milestoneDash">
      <MilestoneTab
        milestones={milestones}
        activeTab={activeTab}
        changeActiveTab={changeActiveTab}
      />

      <TableContainer component={Paper} className="mileStoneTableContainer">
        <Table
          style={{ width: "100%" }}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          size="medium"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" style={tHeadStyle}>
                Company Name
              </TableCell>
              <TableCell align="left" style={tHeadStyle}>
                Amount Disbursed
              </TableCell>
              <TableCell align="left" style={tHeadStyle}>
                Milestone Status
              </TableCell>
              <TableCell align="left" style={tHeadStyle}>
                Start Date
              </TableCell>
              <TableCell align="left" style={tHeadStyle}>
                End Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">{companyName}</TableCell>
              <TableCell align="left">
                ₹{inrAddCommas(amtDispersed || "0")}&nbsp;/&nbsp;₹
                {inrAddCommas(totalBudget || "0")}
              </TableCell>
              <TableCell align="left" style={{ textTransform: "capitalize" }}>
                {prjStatus == "started"
                  ? "1/" + milestones?.length
                  : parseInt(prjStatus) + 1 > milestones?.length
                  ? "Project Compeleted"
                  : parseInt(prjStatus) + 1 + "/" + milestones?.length}
              </TableCell>
              <TableCell align="left">
                {moment(prjStartDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell align="left">
                {moment(prjEndDate).format("DD/MM/YYYY")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {reportViewAccess.includes(role) ? (
        <div className="milestoneDoc">
          <div className={`panel techPanel`}>
            <div className="row">
              <div className="title">Technical</div>
            </div>

            <div className="row">
              <div className="milestoneReportCard">
                <div className="cardTitle">Uploads</div>
                <div className="cardContent">
                  {techReports?.map((techReport, i) => {
                    return (
                      <div className="row">
                        <div className="title">Technical Report {i + 1}</div>
                        {techReport ? (
                          <FileViewer>
                            <div
                              className="content"
                              onClick={() => handleDocument(techReport)}
                            >
                              <div className="icon">{Icons.file}</div>
                              <div className="fileName">
                                {getFileName(techReport)}
                              </div>
                            </div>

                            {reportEditAccess.includes(role) &&
                            ["initial", "current"].includes(mileStatus) ? (
                              <div
                                className="reportAction icon"
                                onClick={() => deleteDocument("tech", i)}
                              >
                                {Icons.delete}
                              </div>
                            ) : (
                              <></>
                            )}
                          </FileViewer>
                        ) : (
                          <FileComponent>
                            <label className="text">
                              {reportEditAccess.includes(role) ? (
                                <>
                                  <input
                                    type="file"
                                    className="fileInput"
                                    hidden
                                    onChange={(e) =>
                                      handleTechFileChange(e.target.files?.[0])
                                    }
                                  />
                                  Drag and Drop or Browse for files
                                </>
                              ) : (
                                <>File not Uploaded!</>
                              )}
                            </label>
                          </FileComponent>
                        )}
                      </div>
                    );
                  })}

                  {techReports?.length < 5 &&
                  reportEditAccess.includes(role) &&
                  ["initial", "current"].includes(mileStatus) ? (
                    <div
                      className="addTechReportBtn"
                      onClick={() => addTechReport()}
                    >
                      {Icons.plus}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="row actionRow">
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={techActiveStep} alternativeLabel>
                  {tarSteps.map((step, i) => {
                    return (
                      <Step key={step.id}>
                        <StepLabel style={{ height: "85px" }}>
                          {step.label}
                        </StepLabel>

                        {i == 0 &&
                        step.actionBy.includes(role) &&
                        techActiveStep == 0 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CustomButton
                              title="Submit"
                              onClickHandler={submitTechRep}
                            />
                          </div>
                        ) : null}

                        {i == 1 &&
                        step.actionBy.includes(role) &&
                        techActiveStep == 1 ? (
                          <Select
                            size="small"
                            fullWidth
                            value={"undecided"}
                            onChange={(e) =>
                              stepActionHandler("techApproval", e.target.value)
                            }
                          >
                            <MenuItem value={"undecided"}>
                              <FormLabel className="smallText">
                                Undecided
                              </FormLabel>
                            </MenuItem>
                            <MenuItem value={"techReportApproved"}>
                              <FormLabel className="smallText">
                                Approve
                              </FormLabel>
                            </MenuItem>
                          </Select>
                        ) : null}
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </div>
          </div>

          <hr />

          <div className="panel financePanel">
            <div className="row titleRow">
              <div className="title">Finance</div>
            </div>
            <div className="row">
              {/* <div
                className="milestoneReportCard pointer"
                onClick={() =>
                  navigate(
                    `/schemes/milestones/${params.applicationId}/${reportId}`
                  )
                }
              >
                <div className="cardTitle">Expense Management</div>
                <div className="cardContent">
                  <table className="expenseCountTable">
                    <tr>
                      <td align="left"></td>
                      <td align="center">Submitted</td>
                      <td align="center" className="approved">
                        Approved
                      </td>
                    </tr>

                    {expenseTableRows.map((row) => {
                      if (row.id == "line") {
                        return (
                          <tr key={row.id}>
                            <td colSpan={3}>
                              <hr
                                style={{ border: "1px solid var(--light)" }}
                              />
                            </td>
                          </tr>
                        );
                      }
                      return (
                        <tr key={row.id} className={`expenseRow ${row.id}`}>
                          <td align="left" className="expenseCategory">
                            {row.title}
                          </td>
                          <td align="center">
                            {expenseCounts?.[row.id]?.total}
                          </td>
                          <td align="center" className="approved">
                            {expenseCounts?.[row.id]?.approved}
                          </td>
                        </tr>
                      );
                    })}
                  </table>

                  <div className="" style={{display: "flex", justifyContent: "flex-end"}}>
                    <CustomButton title="Manage" />
                  </div>
                </div>
              </div> */}
              <div className="milestoneReportCard">
                <div className="cardTitle">Uploads</div>
                <div className="cardContent">
                  <div className="row">
                    <div className="title">UC Draft</div>
                    {ucDraft ? (
                      <FileViewer>
                        <div
                          className="content"
                          onClick={() => handleDocument(ucDraft)}
                        >
                          <div className="icon">{Icons.file}</div>
                          <div className="fileName">{getFileName(ucDraft)}</div>
                        </div>

                        {reportEditAccess.includes(role) &&
                        ["initial", "current", "dispersion"].includes(
                          mileStatus
                        ) ? (
                          <div
                            className="reportAction icon"
                            onClick={() => deleteDocument("ucDraft")}
                          >
                            {Icons.delete}
                          </div>
                        ) : (
                          <></>
                        )}
                      </FileViewer>
                    ) : (
                      <FileComponent>
                        <label className="text">
                          {reportEditAccess.includes(role) ? (
                            <>
                              <input
                                type="file"
                                className="fileInput"
                                hidden
                                onChange={(e) =>
                                  handleUcDraftFileChange(e.target.files?.[0])
                                }
                              />
                              Drag and Drop or Browse for files
                            </>
                          ) : (
                            <>File not Uploaded!</>
                          )}
                        </label>
                      </FileComponent>
                    )}
                  </div>

                  <div className="row">
                    <div className="title">UC Report</div>
                    {ucReport ? (
                      <FileViewer>
                        <div
                          className="content"
                          onClick={() => handleDocument(ucReport)}
                        >
                          <div className="icon">{Icons.file}</div>
                          <div className="fileName">
                            {getFileName(ucReport)}
                          </div>
                        </div>

                        {reportEditAccess.includes(role) &&
                        ["initial", "current", "dispersion"].includes(
                          mileStatus
                        ) ? (
                          <div
                            className="reportAction icon"
                            onClick={() => deleteDocument("ucReport")}
                          >
                            {Icons.delete}
                          </div>
                        ) : (
                          <></>
                        )}
                      </FileViewer>
                    ) : (
                      <FileComponent>
                        <label className="text">
                          {reportEditAccess.includes(role) ? (
                            <>
                              <input
                                type="file"
                                className="fileInput"
                                hidden
                                onChange={(e) =>
                                  handleUcFileCahnge(e.target.files?.[0])
                                }
                              />
                              Drag and Drop or Browse for files
                            </>
                          ) : (
                            <>File not Uploaded!</>
                          )}
                        </label>
                      </FileComponent>
                    )}
                  </div>

                  {/* <div className="row">
                    <div className="title">Bank Statement</div>
                    {bankReport ? (
                      <FileViewer>
                        <div
                          className="content"
                          onClick={() => handleDocument(bankReport)}
                        >
                          <div className="icon">{Icons.file}</div>
                          <div className="fileName">
                            {getFileName(bankReport)}
                          </div>
                        </div>

                        {reportEditAccess.includes(role) &&
                        ["initial", "current", "dispersion"].includes(
                          mileStatus
                        ) ? (
                          <div
                            className="reportAction icon"
                            onClick={() => deleteDocument("bankStatement")}
                          >
                            {Icons.delete}
                          </div>
                        ) : (
                          <></>
                        )}
                      </FileViewer>
                    ) : (
                      <FileComponent>
                        <label className="text">
                          {reportEditAccess.includes(role) ? (
                            <>
                              {" "}
                              <input
                                type="file"
                                className="fileInput"
                                hidden
                                onChange={(e) =>
                                  handleBankFormFile(e.target.files?.[0])
                                }
                              />
                              Drag and Drop or Browse for files
                            </>
                          ) : (
                            <>File not Uploaded!</>
                          )}
                        </label>
                      </FileComponent>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row actionRow">
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStepValue} alternativeLabel>
                  {farSteps.map((step, i) => {
                    return (
                      <Step key={step.id}>
                        <StepLabel style={{ height: "85px" }}>
                          {step.label}
                        </StepLabel>
                        {i == 0 &&
                        user.role.roleId == "founder" &&
                        activeStepValue == 0 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CustomButton
                              title="Submit"
                              onClickHandler={submitFinRep}
                            />
                          </div>
                        ) : null}
                        {i == 1 &&
                        user.role.roleId == "accountant" &&
                        activeStepValue == 1 &&
                        allBillsApproved ? (
                          <Select
                            size="small"
                            fullWidth
                            value={"undecided"}
                            onChange={(e) =>
                              stepActionHandler("billChecked", e.target.value)
                            }
                          >
                            <MenuItem value={"undecided"}>
                              <FormLabel className="smallText">
                                Undecided
                              </FormLabel>
                            </MenuItem>
                            <MenuItem value={"billsChecked"}>
                              <FormLabel className="smallText">
                                Checked
                              </FormLabel>
                            </MenuItem>
                          </Select>
                        ) : null}

                        {i == 2 &&
                        user.role.roleId == "finance_manager" &&
                        activeStepValue == 2 ? (
                          <Select
                            size="small"
                            fullWidth
                            value={"undecided"}
                            onChange={(e) =>
                              stepActionHandler("billsApproval", e.target.value)
                            }
                          >
                            <MenuItem value={"undecided"}>
                              <FormLabel className="smallText">
                                Undecided
                              </FormLabel>
                            </MenuItem>
                            <MenuItem value={"billsApproved"}>
                              <FormLabel className="smallText">
                                Approve
                              </FormLabel>
                            </MenuItem>
                            <MenuItem value={"billsRejected"}>
                              <FormLabel className="smallText">
                                Reject
                              </FormLabel>
                            </MenuItem>
                          </Select>
                        ) : null}

                        {i == 3 &&
                        user.role.roleId == "finance_manager" &&
                        activeStepValue == 3 ? (
                          <Select
                            size="small"
                            fullWidth
                            value={"undecided"}
                            onChange={(e) =>
                              stepActionHandler(
                                "ucDraftApproval",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value={"undecided"}>
                              <FormLabel className="smallText">
                                Undecided
                              </FormLabel>
                            </MenuItem>
                            <MenuItem value={"ucDraftApproved"}>
                              <FormLabel className="smallText">
                                Approve
                              </FormLabel>
                            </MenuItem>
                          </Select>
                        ) : null}

                        {i == 4 &&
                        user.role.roleId == "finance_manager" &&
                        activeStepValue == 4 ? (
                          <Select
                            size="small"
                            fullWidth
                            value={"undecided"}
                            onChange={(e) =>
                              stepActionHandler(
                                "ucFinalApproval",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value={"undecided"}>
                              <FormLabel className="smallText">
                                Undecided
                              </FormLabel>
                            </MenuItem>
                            <MenuItem value={"ucFinalApproved"}>
                              <FormLabel className="smallText">
                                Approve
                              </FormLabel>
                            </MenuItem>
                          </Select>
                        ) : null}
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </div>
          </div>
        </div>
      ) : null}

      {commentViewAccess.includes(role) ? (
        <div className="milestoneCommentContainer">
          <MentionsInput
            className="mentionInput"
            style={mentionStyle}
            value={comment}
            onChange={handleComment}
            placeholder={
              commentEditAccess.includes(role)
                ? "Add your Comments"
                : "Comments"
            }
            readOnly={!commentEditAccess.includes(role)}
            a11ySuggestionsListLabel={"Suggested mentions"}
          >
            <Mention
              style={defaultStyles}
              data={commentFields}
              markup="[__display__]"
            />
          </MentionsInput>
          {commentEditAccess.includes(role) ? (
            <CustomButton title={"Submit"} onClickHandler={submitComment} />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      {progressBoxEditAccess.includes(role) &&
      (techActiveStep == 2 ||
        (mileStatus != "current" && mileStatus != "initial")) ? (
        <ProgressContainer>
          <div className="item" style={{ position: "relative" }}>
            {((role == "program_manager" || role == "super_admin") &&
              (mileStatus == "dispersion" ||
                mileStatus == "financeApproved")) ||
            (role != "program_manager" && role != "super_admin") ? (
              <div
                id="glass"
                style={{
                  position: "absolute",
                  width: "100%",
                  top: "0px",
                  left: "0px",
                  height: "100%",
                  zIndex: "5",
                }}
              ></div>
            ) : null}
            <div className="row">
              <span className="role">Program Manager&nbsp;:</span>
              <span>
                ₹
                <input
                  className="amtInput"
                  placeholder="Budget"
                  value={inrAddCommas(enteredBudget)}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replaceAll(",", "");
                    setEnteredBudget(parseInt(value));
                  }}
                />
                <span className="total" placeholder="Budget">
                  {" "}
                  / ₹{inrAddCommas(mileBudget)}
                </span>
              </span>
            </div>

            <div className="row">
              <textarea
                value={remarks}
                placeholder="Add your comments"
                onChange={(e) => {
                  setremarks(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="item br-">
            <div
              className="arrowIcon"
              style={{
                pointerEvents:
                  ["program_manager", "super_admin"].includes(role) &&
                  mileStatus == "current"
                    ? ""
                    : "none",
              }}
              onClick={submitingDispersion}
            >
              {Icons.arrowFormward}
            </div>
          </div>

          <div className="item" style={{ position: "relative" }}>
            {(role == "finance_manager" && mileStatus != "dispersion") ||
            role != "finance_manager" ? (
              <div
                id="glass"
                style={{
                  position: "absolute",
                  width: "100%",
                  top: "0px",
                  left: "0px",
                  height: "100%",
                  zIndex: "5",
                }}
              ></div>
            ) : null}

            <div
              className="row"
              style={{
                zIndex: "0",
              }}
            >
              <div className="role">Finance Manager&nbsp;:</div>
            </div>

            <div className="row">
              <div className="actions">
                <CustomButton title="Approve" onClickHandler={financeYes} />
                <CustomButton title="Reject" onClickHandler={financeNo} />
              </div>
            </div>
          </div>
        </ProgressContainer>
      ) : null}

      {milestoneStatus == "financeApproved" ? (
        <DisbursedCard>
          <span>Amount Disbursed </span>{" "}
          <span className="amt">
            <span>₹</span>
            <span>{inrAddCommas(enteredBudget)}</span>
          </span>
        </DisbursedCard>
      ) : null}

      <PdfPopUp
        url={currentRepStatus}
        closePdfView={close_pdf}
        pdfDialogOpen={pdfDialogOpen}
      />
    </OutletContainer>
  );
};
export default MilestoneDash;

const DisbursedCard = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
  border: 1px solid gray;
  border-radius: 8px;
  width: max-content;
  margin: 2rem auto;
  font-size: 1.4rem;
  color: var(--text1);
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  .amt {
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
`;

const ProgressContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
  flex-wrap: wrap;
  .item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: 1px solid #d0d3d4;
    padding: 1rem;
    min-width: 450px;
    .row {
      width: 100%;
      gap: 1rem;
      display: flex;
      align-items: center;
      min-height: 1.5rem;

      .role {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
      }
      .amtInput {
        width: 7rem;
        padding: 0.3rem;
        font-size: 1.1rem;
      }
      .total {
        font-size: 1.3rem;
        color: var(--text1);
      }
      textarea {
        resize: none;
        height: 5rem;
        padding: 0.5rem;
        font-size: 1.1rem;
        outline: none;
        width: 100%;
        box-sizing: border-box;
      }
      .action {
        display: flex;
        justify-content: center;
      }
      .actions {
        display: flex;
        justify-content: center;
        gap: 2rem;
        height: 100%;
        margin-top: 4rem;
        width: 100%;
      }
    }
    .arrowIcon {
      svg {
        font-size: 2rem;
        color: var(--text2);
      }
    }
  }
  .br- {
    border: none;
    justify-content: center;
    width: max-content;
    flex-basis: 100px;
    min-width: 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    .arrowIcon {
      border: 1px solid var(--successTextColor);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      svg {
        color: var(--successTextColor);
      }
    }
  }
`;

const FileComponent = styled.div`
  height: 30px;
  width: 100%;
  border-style: dashed;
  border-color: var(--borderBottomColor);
  border-width: 0.5px;
  cursor: pointer;

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 10px;
    color: var(--text3);
    letter-spacing: 1px;
    cursor: pointer;
  }
`;

const FileViewer = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content {
    display: flex;
    gap: 0.3rem;
    cursor: pointer;
    width: 100%;

    .icon {
      color: var(--text1);
    }

    .fileName {
      color: var(--text3);
      font-size: 14px;
      white-space: nowrap;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .reportAction {
    cursor: pointer;
    color: var(--text3);

    svg {
      width: 20px;
    }
  }
`;

const mentionStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },

  input: {
    overflow: "auto",
    height: 100,
  },
  highlighter: {
    boxSizing: "border-box",
    overflow: "hidden",
    height: 100,
  },

  "&multiLine": {
    control: {
      fontFamily: "monospace",
      minHeight: 100,
    },
    highlighter: {
      padding: 10,
      border: "1px solid transparent",
    },
    input: {
      padding: 10,
      border: "1px solid silver",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#BCF6EE",
      },
    },
  },
};

const defaultStyles = {
  backgroundColor: "#BCF6EE",
};
