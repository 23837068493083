import {
  Alert,
  Autocomplete,
  Chip,
  DialogContent,
  DialogTitle,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  assignAssociateForApplication,
  changeApplicationStatus,
  getFormData,
  getSchemeAssociates,
  moveToDb,
  inviteReviewerForApplication,
  getApplicationReviewerData,
  getAllReviewersData,
  revokeReviewersForApplications,
} from '../../../../../../../data/network/api/scheme'
import { globalDispatch } from '../../../../../../../providers/GlobalProvider'
import { DialogBody } from '../../../../../../../utils/customStyledComponents'
import { Icons } from '../../../../../../../utils/mediaExports'
import CustomButton from '../../../../../../customComponents/CustomButton'
import { assignAssociateForInvestment } from '../../../../../../../data/network/api/investment'
import { Table } from 'react-bootstrap'
import { MuiChipsInput } from 'mui-chips-input'

const ActionDialog = ({
  handleActionDialog,
  activeAction,
  application,
  getApplicationDataHandlerForManager,
  phase,
  selectApplication,
  setMovingNextRound,
  applicationDataVersion,
  scheme = null,
}) => {
  const [loading, setLoading] = useState(false)
  const [serverResponse, setServerResponse] = useState(null)

  const [associate, setAssociate] = useState([])
  const [associates, setAssociates] = useState([])
	const [revokeAssociate, setRevokeAssociate] = useState([]);
	const [revokeAssociates, setRevokeAssociates] = useState(application?.reviewer);
  const [inviteAssociates, setInviteAssociates] = useState([])
  const [inviteAssociate, setInviteAssociate] = useState([])

  const dispatch = globalDispatch()
  const controller = new AbortController()

  const { id, applicationId } = useParams()
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    handleAssociateSearch()
  }, [])

  const handleAssociateSearch = async () => {
    try {
     
      const response = await getAllReviewersData(
				{
					callId: id,
				},
				dispatch,
				controller
			);			
      // const response1=await getApplicationReviewerData(
      //   {callId:id,
      //   applicationId:applicationId},
      //   dispatch,
      //   controller
      // )
      // if(response1 && response1.status==200){
      //   const reviewersData=[]
      //   response1?.msg?.map((data)=>{
      //     if(reviewersData.indexOf(data?.email)=== -1){
      //       reviewersData.push(data?.email)
      //     }
      //   })
      //   setInviteAssociates(()=>[...reviewersData])
      // }
      // else{
      //   setInviteAssociates([])
      // }
      if ( response && response.status == 200) {
        setAssociates([...response.msg])
      } else {
        setAssociates([])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSelect = (e, value) => {
    let isStringFound=false;
		value?.map((data)=>{
			if(typeof data === 'string'){
				isStringFound=true;
				
			}
		})
		if(isStringFound){
			setServerResponse({
				type: "error",
				body:  "Incorrect reviewer data found",
			});
		}
		else{
			setServerResponse();
		}

    setAssociate(value)
  }
  const handleInviteSelect = (e, value) => {
    setInviteAssociate(value)
  }

  const submitHandler = async () => {
    try {
      setServerResponse(null)
      setLoading(true)
      let associates = []
      associate?.map((person, i) => {
        associates.push(person._id)
      })
      let response

      if (scheme == 'investment') {
        response = await assignAssociateForInvestment(
          {
            associates: associates,
            applicationId: application?._id,
          },
          dispatch,
          controller
        )
      } else {
        response = await assignAssociateForApplication(
          {
            associates: associates,
            applicationId: applicationId,
          },
          dispatch,
          controller
        )
      }

      if (response && response.status == 200) {
        setServerResponse({ type: 'success', body: response.msg })
        getApplicationDataHandlerForManager()
      } else {
        setServerResponse({
          type: 'error',
          body: response?.msg || 'Something went wrong!',
        })
      }
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }
  const onInviteAssociates = async () => {
    try {
      setServerResponse(null)
      setLoading(true)
      let associatesData = inviteAssociate?.map((data) => {
        return { name: 'reviewer', email: data }
      })
      let response

      response = await inviteReviewerForApplication(
        {
          callId: id,
          reviewers: associatesData,
        },
        dispatch,
        controller
      )

      if (response && response?.msg[0]?.status == 200) {
        setServerResponse({ type: 'success', body: response?.msg[0]?.message })
        getApplicationDataHandlerForManager()
      } else {
        setServerResponse({
          type: 'error',
          body: response?.msg[0]?.message || 'Something went wrong!',
        })
      }
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }
  const changeApplicationStatusHandler = async (status) => {
    setServerResponse(null)
    const response = await changeApplicationStatus(
      {
        status: status,
        applicationId: applicationId,
        phase: phase,
        applicationDataVersion: applicationDataVersion,
      },
      dispatch,
      controller
    )
    if (response && response.status == 200) {
      setServerResponse({ type: 'success', body: response.msg })
      getApplicationDataHandlerForManager()
      if (status == 'approved') {
        setTimer(
          setTimeout(() => {
            setMovingNextRound(true)
          }, [3000])
        )
      }
    } else {
      setServerResponse({
        type: 'error',
        body: response?.msg || 'Something went wrong!',
      })
    }
  }

  const initialData = {
    company: {
      name: '',
    },
    founder: {
      name: '',
      gender: '',
      email: '',
      phone: '',
      address: '',
      pincode: '',
      state: '',
      city: '',
    },
    allFounders: {
      names: [],
    },
    documents: {
      pan: '',
      aadhaar: '',
    },
  }

  const [formData, setFormData] = useState({ ...initialData })
  const [formVersion, setFormVersion] = useState(null)

  const getFormDataHandler = async () => {
    const response = await getFormData(
      {
        formName: 'innovatorForm',
        applicationId: application._id,
        purpose: 'db',
      },
      dispatch,
      controller
    )
    if (response && response.status == 200) {
      let dataObj = { ...initialData }
      Object.keys(initialData)?.map((field, i) => {
        if (field == 'company') {
          dataObj.company.name = application.companyName
          return
        }
        dataObj[field] = response.msg?.[field]
      })
      setFormData(dataObj)
      setFormVersion(response?.msg?.__v)
    } else {
      snackbarHandler(response?.msg || 'Something went wrong!', 'error')
    }
  }

  const handleDbActionChange = (category, id, value) => {
    setFormData({
      ...formData,
      [category]: { ...formData[category], [id]: value },
    })
  }

  const moveToDbSubmitHandler = async () => {
    const response = await moveToDb(
      { data: formData, from: 'innovatorForm', callId: application.call._id },
      dispatch,
      controller
    )
    if (response && response.status == 200) {
      setServerResponse({
        body: response?.msg || 'Submitted',
        type: 'success',
      })
    } else {
      setServerResponse({
        body: response?.msg || 'Failed to submit!',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (activeAction == 'moveToDb') {
      getFormDataHandler()
    }
  }, [activeAction])
  const handleRevokeReveiwerSelect=(e,value)=>{
    let isStringFound=false;
		value?.map((data)=>{
			if(typeof data === 'string'){
				isStringFound=true;
				
			}
		})
		if(isStringFound){
			setServerResponse({
				type: "error",
				body:  "Incorrect reviewer data found",
			});
		}
		else{
			setServerResponse();
		}
		setRevokeAssociate(value);

	}
	const submitRevokeReviewersHandler=async ()=>{
		let response=''
		try{

			const reviewersIDs=[]
			revokeAssociate?.map((data)=>{
				 if(reviewersIDs.indexOf(data?._id)===-1){
					reviewersIDs.push(data?._id)
				 }
			})
			 response=await revokeReviewersForApplications({
				callId: id,
				applications: [applicationId],
				reviewer:reviewersIDs,
			},
			dispatch,
			controller)
			if (response && response?.status == 200) {
				setServerResponse({ type: "success", body: response?.msg});
				window.location.reload()
			} else {
				setServerResponse({
					type: "error",
					body:   response?.msg[0]?.data||response?.msg || "Something went wrong!",
				});
			}
		} catch (error) {
			setServerResponse({
				type: "error",
				body: response?.msg[0]?.data ||response?.msg|| "Something went wrong!",
			});
		}
	}
  switch (activeAction) {
    case 'invite':
      return (
        <DialogBody>
          <div className='closeButton' onClick={handleActionDialog}>
            {Icons.close}
          </div>

          <DialogTitle className='dialogTitle'>Invite Reviewer</DialogTitle>
          <DialogContent className='content'>
            <Autocomplete
              freeSolo
              disableClearable
              options={inviteAssociates}
              onChange={handleInviteSelect}
              multiple={true}
              value={inviteAssociate}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                  <Chip
                    variant='outlined'
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  className='firstField'
                  {...params}
                  label='Invite Associates'
                />
              )}
            />

            <div className='actionField'>
              <CustomButton
                title={'Submit'}
                onClickHandler={onInviteAssociates}
              />
            </div>

            {serverResponse ? (
              <Alert color={serverResponse?.type || 'error'}>
                {serverResponse.body}
              </Alert>
            ) : (
              <></>
            )}
          </DialogContent>
        </DialogBody>
      )
    case 'assign':
      return (
        <DialogBody>
          <div className='closeButton' onClick={handleActionDialog}>
            {Icons.close}
          </div>

          <DialogTitle className='dialogTitle'>Assign Reviewer</DialogTitle>
          <DialogContent className='content'>
            <Autocomplete
              freeSolo
              disableClearable
              options={associates}
              getOptionLabel={(option) => `${option?.name} - (${option?.email})`}
              onChange={handleSelect}
              multiple={true}
              value={associate}
              renderInput={(params) => (
                <TextField
                  className='firstField'
                  {...params}
                  // onChange={handleAssociateSearch}
                  label='Search Associates'
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />

            <div className='actionField'>
              <CustomButton title={'Submit'} onClickHandler={submitHandler} disabled={serverResponse  || associate?.length===0?true:false} />
            </div>

            {serverResponse ? (
              <Alert color={serverResponse?.type || 'error'}>
                {serverResponse.body}
              </Alert>
            ) : (
              <></>
            )}
          </DialogContent>
        </DialogBody>
      )
      case "revoke":
				return (
					<DialogBody>
						<div className="closeButton" onClick={handleActionDialog}>
							{Icons.close}
						</div>
	
						<DialogTitle className="dialogTitle">Revoke Reviewer</DialogTitle>
						<DialogContent className="content">
							<Autocomplete
								freeSolo
								disableClearable
								options={revokeAssociates}
								getOptionLabel={(option) => `${option?.name} - (${option?.email})`}
								onChange={handleRevokeReveiwerSelect}
								multiple={true}
								value={revokeAssociate}
								renderInput={(params) => (
									<TextField
										className="firstField"
										{...params}
										// onChange={handleAssociateSearch}
										label="Search Associates"
										InputProps={{
											...params.InputProps,
											type: "search",
										}}
									/>
								)}
							/>
	
							<div className="actionField">
								<CustomButton title={"Submit"} onClickHandler={submitRevokeReviewersHandler} disabled={serverResponse || revokeAssociate?.length===0?true:false} />
							</div>
	
							{serverResponse ? (
								<Alert color={serverResponse?.type || "error"}>
									{serverResponse.body}
								</Alert>
							) : (
								<></>
							)}
						</DialogContent>
					</DialogBody>
				);

    case 'nextRound':
      return (
        <DialogBody>
          <div
            className='closeButton'
            onClick={() => {
              clearTimeout(timer)
              handleActionDialog()
            }}
          >
            {Icons.close}
          </div>

          <DialogTitle className='dialogTitle'>
            {selectApplication
              ? 'Select Application'
              : 'Move Application to next Round'}
          </DialogTitle>
          <DialogContent className='content'>
            <div className='actionField'>
              <CustomButton
                title={'Confirm'}
                onClickHandler={() =>
                  changeApplicationStatusHandler('approved')
                }
              />
            </div>

            {serverResponse ? (
              <Alert color={serverResponse?.type || 'error'}>
                {serverResponse.body}
              </Alert>
            ) : (
              <></>
            )}
          </DialogContent>
        </DialogBody>
      )

    case 'reject':
      return (
        <DialogBody>
          <div className='closeButton' onClick={handleActionDialog}>
            {Icons.close}
          </div>

          <DialogTitle className='dialogTitle'>Reject Application</DialogTitle>
          <DialogContent className='content'>
            <div className='actionField'>
              <CustomButton
                title={'Confirm'}
                onClickHandler={() =>
                  changeApplicationStatusHandler('rejected')
                }
              />
            </div>
            {serverResponse ? (
              <Alert color={serverResponse?.type || 'error'}>
                {serverResponse.body}
              </Alert>
            ) : (
              <></>
            )}
          </DialogContent>
        </DialogBody>
      )

    case 'moveToDb':
      return (
        <DialogBody className='dialogPaper'>
          <div
            className='closeButton'
            onClick={() => {
              handleActionDialog()
            }}
          >
            {Icons.close}
          </div>

          <DialogTitle className='dialogTitle'>
            Move Data to Database
          </DialogTitle>

          <DialogContent className='content'>
            <div className='column firstField'>
              <div className='fields'>
                <div className='row fullWidth'>
                  <div className='field'>
                    <TextField
                      size='small'
                      label='Company Name / Individual Name'
                      fullWidth
                      value={formData.company.name}
                      onChange={(e) =>
                        handleDbActionChange('company', 'name', e.target.value)
                      }
                      // disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='column'>
              <div className='header mb1'>Founder Details</div>
              <div className='fields'>
                <div className='row'>
                  <div className='field'>
                    <TextField
                      size='small'
                      label='Name'
                      fullWidth
                      value={formData.founder.name}
                      onChange={(e) =>
                        handleDbActionChange('founder', 'name', e.target.value)
                      }
                    />
                  </div>
                  <div className='field'>
                    <TextField
                      size='small'
                      label='Email'
                      fullWidth
                      value={formData.founder.email}
                      onChange={(e) =>
                        handleDbActionChange('founder', 'email', e.target.value)
                      }
                    />
                  </div>
                  <div className='field'>
                    <TextField
                      size='small'
                      label='Phone Number'
                      fullWidth
                      value={formData.founder.phone}
                      onChange={(e) =>
                        handleDbActionChange('founder', 'phone', e.target.value)
                      }
                    />
                  </div>
                  <div className='field'>
                    <TextField
                      size='small'
                      label='Gender'
                      fullWidth
                      value={formData.founder.gender}
                      onChange={(e) =>
                        handleDbActionChange(
                          'founder',
                          'gender',
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                {/* <div className="row fullWidth">
                  <div className="field">
                    <TextField
                      label="Address"
                      fullWidth
                      size="small"
                      value={formData.founder.address}
                      onChange={(e) =>
                        handleDbActionChange(
                          "founder",
                          "address",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div> */}

                {/* <div className="row">
                  <div className="field">
                    <TextField
                      size="small"
                      label="City"
                      fullWidth
                      value={formData.founder.city}
                      onChange={(e) =>
                        handleDbActionChange("founder", "city", e.target.value)
                      }
                    />
                  </div>
                  <div className="field">
                    <TextField
                      size="small"
                      label="State"
                      fullWidth
                      value={formData.founder.state}
                      onChange={(e) =>
                        handleDbActionChange("founder", "state", e.target.value)
                      }
                    />
                  </div>
                  <div className="field">
                    <TextField
                      size="small"
                      label="Pincode"
                      fullWidth
                      value={formData.founder.pincode}
                      onChange={(e) =>
                        handleDbActionChange(
                          "founder",
                          "pincode",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <div className='column'>
              <div className='header mb1'>Documents</div>
              <div className='fields'>
                <div className='row'>
                  <div className='field'>
                    <TextField
                      size='small'
                      label='AADHAAR Number'
                      fullWidth
                      value={formData.documents.aadhaar}
                      onChange={(e) =>
                        handleDbActionChange(
                          'founder',
                          'aadhaar',
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='field'>
                    <TextField
                      size='small'
                      label='PAN Number'
                      fullWidth
                      value={formData.documents.pan}
                      onChange={(e) =>
                        handleDbActionChange('founder', 'pan', e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='column'>
              <div className='header mb1'>Other Founders</div>
              <div className='fields'>
                <div className='row'>
                  <div className='field'>
                    <MuiChipsInput
                      size='small'
                      fullWidth
                      value={formData.allFounders.names}
                      onChange={(newValue) =>
                        handleDbActionChange('allFounders', 'names', newValue)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='column mt1'>
              <div className='fields'>
                <div className='row center'>
                  <div className='field'>
                    <div className='infoText'>
                      Are you sure you want to move it to Database ?
                    </div>
                    <CustomButton
                      title='Submit'
                      onClickHandler={moveToDbSubmitHandler}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='column mt1'>
              <div className='fiedls'>
                <div className='row center'>
                  <div className='field'>
                    {serverResponse ? (
                      <Alert color={serverResponse?.type || 'error'}>
                        {serverResponse.body}
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </DialogBody>
      )
  }
}

export default ActionDialog
