import FormBuilder from "./FormBuilder";
import { PhaseContainer } from "../ikp/prayas/components/application/applicationPhase/CompletionOfForm";
import { OutletContainer } from "../../../utils/customStyledComponents";
import { useNavigate, useParams } from "react-router-dom";
import { ViewDocContainer } from "../ikp/big/Big";
import { Icons } from "../../../utils/mediaExports";
import { useEffect } from "react";

const InvestmentInfo = () => {
  useEffect(() => {
    document.getElementById("pageNavStackContainer").style.display = "none";
  }, [])

  return (
    <OutletContainer>
      <ViewDocument />
      <PhaseContainer style={{marginTop: "1rem"}}>
        <FormBuilder />
      </PhaseContainer>
    </OutletContainer>
  );
};

export default InvestmentInfo;


const ViewDocument = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <ViewDocContainer
    >
      <div className="content" onClick={() => navigate(`/documents/${params.id}?schemeType="investment"`)}>
      <div className="text">View Documents</div>
      <div className="icon">{Icons.download}</div>
      </div>
    </ViewDocContainer>
  );
};
