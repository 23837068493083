import { ThemeProvider } from '@emotion/react'
import { Provider, useDispatch } from 'react-redux'
import { Outlet, Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './components/dashboard/Home'
import OtherUsers from './components/dashboard/otherUsers/OtherUsers'
import Startups from './components/dashboard/startups/Startups'
import TabContainer from './components/dataCreator/TabContainer'
import { theme } from './utils/customStyledComponents'
import Dashboard from './pages/Dashboard'
import GlobalStore from './store/globalStore'
import LoginPage from './pages/auth/Login'
import PrivateRoute from './pages/auth/PrivateRoute'
import BIG from './components/schemes/ikp/big/Big'
import SignUp from './pages/auth/Signup'
import paths from './utils/links'
import { GlobalProvider, globalSelector } from './providers/GlobalProvider'
import { SnackbarProvider } from 'notistack'
import StartupData from './components/dashboard/startups/StartupData'
import Profile from './components/profile/Profile'
import { BigProvider } from './providers/scheme/big/BigProvider'
import Prayas from './components/schemes/ikp/prayas/Prayas'
import Titan from './components/schemes/ikp/titan/Titan'
import { PrayasProvider } from './providers/scheme/prayas/PrayasProvider'
import BigCalls from './components/schemes/ikp/big/BigCalls'
import ApplicationsPage from './components/dashboard/projectManagement/aplication_dashboard/application_page'
import MilestoneDash from './components/dashboard/projectManagement/milestones/milestone_dash/milestone_dash'
import Expenses from './components/dashboard/projectManagement/milestones/expenses/expenses'
import ExpenseViewer from './components/dashboard/projectManagement/milestones/viewExpense/expense_viewer'
import BigProgressView from './components/schemes/ikp/big/BigProgressView'
import { useEffect } from 'react'
import DisplayCalls from './components/schemes/ikp/big/BigCalls'
import InvestmentInfo from './components/schemes/investment/investmentInfo'
import InvestementApplications from './components/schemes/investment/InvestmentApplications'
import DocumentFolders from './components/schemes/ikp/documentFolders/DocumentFolders'
import PrayasHome from './PrayasHome'
import { useRef } from 'react'
import PrayasReg from './PrayasReg'
import { TitanProvider } from './providers/scheme/titan/TitanProvider'
import CustomReport from './components/dashboard/startups/custom-report/CustomReport'

function App() {
  const GlobalDebug = (function () {
    var savedConsole = console
    /**
     * @param {boolean} debugOn
     * @param {boolean} suppressAll
     */
    return function (debugOn, suppressAll) {
      var suppress = suppressAll || false
      if (debugOn === false) {
        // supress the default console functionality
        console = {}
        console.log = function () {}
        // supress all type of consoles
        if (suppress) {
          console.info = function () {}
          console.warn = function () {}
          console.error = function () {}
        } else {
          console.info = savedConsole.info
          console.warn = savedConsole.warn
          console.error = savedConsole.error
        }
      } else {
        console = savedConsole
      }
    }
  })()

  const notistackRef = useRef()

  useEffect(() => {
    GlobalDebug(true)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalProvider>
        <Routes>
          <Route path='/auth'>
            <Route path={paths.login.path} element={<LoginPage />} />
            <Route path={paths.signUp.path} element={<SignUp />} />
          </Route>

          <Route path='/prayasReg'>
            <Route
              index
              element={
                <SnackbarProvider
                  ref={notistackRef}
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <PrayasHome />
                </SnackbarProvider>
              }
            />
          </Route>

          <Route path='/prayas'>
            <Route
              index
              element={
                <SnackbarProvider
                  ref={notistackRef}
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <PrayasReg />
                </SnackbarProvider>
              }
            />
          </Route>

          <Route
            path='/'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >
            <Route index element={<Home />} />

            <Route path='/startups'>
              <Route index element={<Startups />} />
              <Route path='/startups/add' element={<TabContainer />} />
              <Route
                path='/startups/custom-report'
                element={<CustomReport />}
              />

              <Route path='/startups/:id' element={<TabContainer />} />
            </Route>

            <Route path='/investment/applications'>
              <Route index element={<InvestementApplications />} />
            </Route>

            <Route path='/investment/applications/:id'>
              <Route index element={<InvestmentInfo />} />
            </Route>

            <Route path='/otherUsers'>
              <Route index element={<OtherUsers />} />
            </Route>

            <Route path='/schemes'>
              <Route path='/schemes/big'>
                <Route
                  index
                  element={
                    <BigProvider>
                      <DisplayCalls schemeId={'big'} />
                    </BigProvider>
                  }
                />
                <Route
                  path='/schemes/big/:id'
                  element={
                    <BigProvider>
                      <BIG />
                    </BigProvider>
                  }
                />

                <Route
                  path='/schemes/big/:id/:applicationId'
                  element={
                    <BigProvider>
                      <BIG from='associate' />
                    </BigProvider>
                  }
                />
              </Route>

              <Route path='/schemes/prayas'>
                <Route
                  index
                  element={
                    <PrayasProvider>
                      <DisplayCalls schemeId={'prayas'} />
                    </PrayasProvider>
                  }
                />
                <Route
                  path='/schemes/prayas/:id'
                  element={
                    <PrayasProvider>
                      <Prayas />
                    </PrayasProvider>
                  }
                />
                <Route
                  path='/schemes/prayas/:id/:applicationId'
                  element={
                    <PrayasProvider>
                      <Prayas from='associate' />
                    </PrayasProvider>
                  }
                />
              </Route>

              <Route path='/schemes/titan'>
                <Route
                  index
                  element={
                    <TitanProvider>
                      <DisplayCalls schemeId={'titan'} />
                    </TitanProvider>
                  }
                />
                <Route
                  path='/schemes/titan/:id'
                  element={
                    <TitanProvider>
                      <Titan />
                    </TitanProvider>
                  }
                />
                <Route
                  path='/schemes/titan/:id/:applicationId'
                  element={
                    <TitanProvider>
                      <Titan from='associate' />
                    </TitanProvider>
                  }
                />
              </Route>

              <Route path='/schemes/:callId' element={<ApplicationsPage />} />

              <Route
                path='/schemes/milestones/:applicationId'
                element={<MilestoneDash />}
              />

              <Route
                path='/schemes/milestones/:applicationId/:repId'
                element={<ExpenseViewer />}
              />
            </Route>

            <Route path='/profile'>
              <Route path='/profile/:id' element={<Profile />} />
              <Route path='/profile/:id/documents' element={<></>} />
            </Route>

            <Route
              path='/documents/:applicationId'
              element={<DocumentFolders />}
            />

            <Route path='/milestones/expenses'>
              <Route
                path='/milestones/expenses/:repId'
                element={<Expenses />}
              ></Route>
            </Route>
          </Route>
        </Routes>
      </GlobalProvider>
    </ThemeProvider>
  )
}

export default App
