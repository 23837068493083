import { Button, Dialog, FormLabel, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { checkApplication, saveComment } from "../../../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import { BigDispatch } from "../../../../../../../providers/scheme/big/BigProvider";
import { updateNavigation } from "../../../../../../../reducers/navigationReducer";
import { updateBigApplication } from "../../../../../../../reducers/schemes/big/applications";
import { updateBigPathNav } from "../../../../../../../reducers/schemes/big/pathNav";
import GlobalStore from "../../../../../../../store/globalStore";
import BigSchemeStore from "../../../../../../../store/scheme/big/big";
import {
  ApplicaitonStatusColor,
  ApplicationStatus,
  ApplicationStatusTextColor,
} from "../../../../../../../utils/constants";
import paths from "../../../../../../../utils/links";
import {
  applicanteeSideRole,
  applicationSideRole,
} from "../../../../../customComponents/CustomTabView";
import { SchemePhaseTabContainer } from "../../ikp/Applications";
import ActionDialog from "../applicationAction/ActionModal";

const EligibilityCheck = ({ call, from, setActive }) => {
  const { user } = globalSelector((state) => state.auth);
  const controller = new AbortController();
  const dispatch = globalDispatch();
  const bigDispatch = BigDispatch();
  const [comment, setComment] = useState("");
  const params = useParams();

  const [application, setApplication] = useState(null);
  const [applicationData, setApplicationData] = useState(null);

  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const isProgramAssociate = applicanteeSideRole.includes(user.role.roleId);
  const isFounder = applicationSideRole.includes(user.role.roleId);

  const { enqueueSnackbar } = useSnackbar();

  //function to get application details and application phase data
  const getApplication = async () => {
    let bodyData = {};
    if (isProgramAssociate) {
      bodyData = {
        fromIkp: true,
        applicationId: params.applicationId,
      };
    } else if (isFounder) {
      bodyData = { callId: call._id };
    }

    try {
      const response = await checkApplication(bodyData, dispatch, controller);
      if (response && response.status == 200) {
        setApplication(response.msg?.application);
        setApplicationData(response.msg?.applicationData);
        bigDispatch(updateBigApplication({
          id: "application",
          value: response?.msg?.application
        }));
        setComment(response.msg?.applicationData?.["phase2"]?.comment)
        let progress = {};
        for (let i=1;i<=8;i++) {
          progress["phase"+i] = response?.msg?.applicationData?.["phase"+i]?.status;
        }
        progress.milestone = response?.msg?.applicationData?.milestone?.status;
        bigDispatch(
          updateBigApplication(
            {
              id: "progress",
              value: progress,
            }
          )
        );

        if (user.role.roleId != "founder") {
          let pageStackData = JSON.parse(
            JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
          );
          Object.keys(pageStackData).map((id) => {
            pageStackData[id].isActive = false;
          })
          pageStackData.companyName.title = response.msg?.application?.companyName;
          pageStackData.companyName.show = true;
          pageStackData.companyName.isActive = true;
          pageStackData.companyName.path = `/schemes/big/${call?._id}/${response?.msg?.application?._id}`;
          dispatch(
            updateNavigation({
              type: "pageNavStack",
              value: { ...pageStackData },
            })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getApplication();

    return () => {
      let pageStackData = JSON.parse(
        JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
      );
      pageStackData.callName.isActive = true;
      pageStackData.companyName.title = "";
      pageStackData.companyName.show = false;
      pageStackData.companyName.isActive = false;

      dispatch(
        updateNavigation({
          type: "pageNavStack",
          value: { ...pageStackData },
        })
      );
    }
  }, []);

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  const submitComment = async () => {
    const response = await saveComment({applicationId: application._id, phase: "phase2", values: comment}, dispatch, controller);
    if (response && response.status == 200) {
      snackbarHandler(`Comment for ${"Eligibility Check"} submitted!`, "success");
      setApplicationData(response?.msg);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  }

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
    if (comment) {
      submitComment();
    }
  };

    //function to move to next phase automatically when moved to next round
    const [movingNextRound, setMovingNextRound] = useState(false);
    useEffect(() => {
      if (movingNextRound) {
        setActionDialogOpen(false);
        setActive(2);
        setMovingNextRound(false);
      }
    }, [movingNextRound]);

  return (
    <SchemePhaseTabContainer>
      <div style={{display: 'flex', gap: '2rem', alignItems: 'center'}}>
      <div
        className={`statusTextApplication `}
        style={{
          background:
            ApplicaitonStatusColor[applicationData?.["phase2"]?.status],
          color:
            ApplicationStatusTextColor[applicationData?.["phase2"]?.status],
        }}
      >
        {ApplicationStatus[applicationData?.["phase2"]?.status] || "Pending"}
      </div>

      {
        from == "associate" ? <div className="assignedText" style={{fontSize: '1.2rem'}}>
        Assigned to: {
          application?.reviewer?.map((person, i) => {
            return <span className="rName">{person.name} {application?.reviewer?.length != i+1 ? ", " : ""}</span>
          })
        }
        {
          application?.reviewer?.length == 0 ? "-" : ""
        }
      </div> : <></>
      }
      </div>

      <CommentActionContainer>
        <div className="commentSection">
          <textarea readOnly={from != "associate"} value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Add your comments.."></textarea>
          {
            from == "associate" ? <Button className="muiOutlinedBtn" variant="outlined" onClick={submitComment}>
            Save
          </Button> : null
          }
        </div>

        <div className="actionSection">
          {from == "associate" && application?.status == "eligibility" ? (
            <div className="actions">
              <div className="title">Action: </div>
              <div className="input">
                <Select
                  size="small"
                  value={selectedAction}
                  onChange={handleActionManager}
                >
                  <MenuItem value={-1}>
                    <FormLabel>Select</FormLabel>
                  </MenuItem>
                  <MenuItem value={"nextRound"}>
                    <FormLabel>Move to next round</FormLabel>
                  </MenuItem>
                  <MenuItem value={"reject"}>
                    <FormLabel style={{ color: "red" }}>Reject</FormLabel>
                  </MenuItem>
                </Select>
              </div>
            </div>
          ) : (
            <></>
          )}
          <Dialog open={actionDialogOpen} onClose={handleActionDialog}>
            <ActionDialog
              handleActionDialog={handleActionDialog}
              activeAction={selectedAction}
              application={application}
              getApplicationDataHandlerForManager={getApplication}
              phase={"phase2"}
              setMovingNextRound={setMovingNextRound}
              applicationDataVersion={applicationData?.__v}
            />
          </Dialog>
        </div>
      </CommentActionContainer>
    </SchemePhaseTabContainer>
  );
};

export default EligibilityCheck;

export const CommentActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  gap: 3rem;
  flex-wrap: wrap;

  .commentSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    textarea {
      resize: none;
      min-width: 300px;
      height: 150px;
      padding: 1rem;
      font-size: 1.1rem;
    }

    button {
      width: max-content;
      margin: 0 auto;
      padding: 0.3rem 1.5rem;
    }
  }
`;
