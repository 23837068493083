import React from 'react'
import {
  Provider,
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
} from 'react-redux'
import TitanSchemeStore from '../../../store/scheme/titan/titan'

const MyContext = React.createContext(null)

// Export your custom hooks if you wish to use them in other files.
export const TitanStore = createStoreHook(MyContext)
export const TitanDispatch = createDispatchHook(MyContext)
export const TitanSelector = createSelectorHook(MyContext)

export function TitanProvider({ children }) {
  return (
    <Provider context={MyContext} store={TitanSchemeStore}>
      {children}
    </Provider>
  )
}
