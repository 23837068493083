import "./applications.css";
const SchemeCardInfo = () => {
  return (
    <div className="paperBox">
      <div className="paper">
        <div
          className="cornerColor"
          style={{ backgroundColor: "rgba(73, 239, 120, 0.34)" }}
        />
        <div className="schemeCardData">
          <p>Total Number</p>
          <p>Companies</p>
          <p></p>
        </div>
      </div>
      <div className="paper">
        <div
          className="cornerColor"
          style={{ backgroundColor: "rgba(255, 160, 72, 0.53)" }}
        />
        <div className="schemeCardData">
          <p>Total Number</p>
          <p>Companies</p>
          <p></p>
        </div>
      </div>
      <div className="paper">
        <div
          className="cornerColor"
          style={{ backgroundColor: "rgba(250, 132, 132, 0.7)" }}
        />
        <div className="schemeCardData">
          <p>Total Number</p>
          <p>Companies</p>
          <p></p>
        </div>
      </div>
      <div className="paper">
        <div
          className="cornerColor"
          style={{ backgroundColor: "rgba(188, 246, 238, 1)" }}
        />
        <div className="schemeCardData">
          <p>Total Number</p>
          <p>Companies</p>
          <p></p>
        </div>
      </div>
    </div>
  );
};
export default SchemeCardInfo;
