import {
  Button,
  Dialog,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  checkApplication,
  saveComment,
  updatePhaseData,
} from "../../../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import { TitanDispatch } from "../../../../../../../providers/scheme/titan/TitanProvider";
import { updateTitanApplication } from "../../../../../../../reducers/schemes/titan/applications";
import TitanSchemeStore from "../../../../../../../store/scheme/titan/titan";
import {
  ApplicaitonStatusColor,
  ApplicationStatus,
  ApplicationStatusTextColor,
} from "../../../../../../../utils/constants";
import CustomButton from "../../../../../../customComponents/CustomButton";
import CustomTextSearch from "../../../../../../customComponents/CustomSearchText";
import {
  applicanteeSideRole,
  applicationSideRole,
} from "../../../../../customComponents/CustomTabView";
import { CommentActionContainer } from "../../../../big/phases/applicant/agreement/Agreement";
import ActionDialog from "../../../../big/phases/applicant/applicationAction/ActionModal";
import { SchemePhaseTabContainer } from "../../../../big/phases/ikp/Applications";

const PHASE = "phase2";
const initialData = {
  clarity: 0,
  unmetNeed: 0,
  customerPay: 0,
  novelty: 0,
  techFeasibility: 0,
  teamStrength: 0,
  total: 0,
  recommend: false,
  comment: "",
};

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

let totalMarks = [];

for (let i = 10; i <= 60; i += 10) {
  totalMarks.push({
    label: String(i),
    value: i,
  });
}

const OnlineReviewApplicant = ({ call, from, setActive }) => {
  const { user } = globalSelector((state) => state.auth);
  const controller = new AbortController();
  const dispatch = globalDispatch();
  const titanDispatch = TitanDispatch();
  const params = useParams();

  const [application, setApplication] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [comment, setComment] = useState("");

  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const isProgramAssociate = applicanteeSideRole.includes(user.role.roleId);
  const isFounder = applicationSideRole.includes(user.role.roleId);

  const [data, setData] = useState({
    ...initialData,
  });

  const { enqueueSnackbar } = useSnackbar();

  //function to get application details and application phase data
  const getApplication = async () => {
    let bodyData = {};
    if (isProgramAssociate) {
      bodyData = {
        fromIkp: true,
        applicationId: params.applicationId,
      };
    } else if (isFounder) {
      bodyData = { callId: call._id };
    }

    try {
      const response = await checkApplication(bodyData, dispatch, controller);
      if (response && response.status == 200) {
        setApplication(response.msg?.application);
        setApplicationData(response.msg?.applicationData);
        titanDispatch(
          updateTitanApplication({
            id: "application",
            value: response?.msg?.application,
          })
        );
        setComment(response.msg?.applicationData?.[PHASE]?.comment);
        if (response?.msg?.applicationData?.phase2?.data?.[user?._id]) {
          setData(response?.msg?.applicationData?.phase2?.data?.[user?._id]);
        }

        let progress = {};
        for (let i = 1; i <= 6; i++) {
          progress["phase" + i] =
            response?.msg?.applicationData?.["phase" + i]?.status;
        }
        progress.milestone = response?.msg?.applicationData?.milestone?.status;
        titanDispatch(
          updateTitanApplication({
            id: "progress",
            value: progress,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getApplication();
  }, []);

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  const submitComment = async () => {
    const response = await saveComment(
      { applicationId: application._id, phase: PHASE, values: comment },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(`Comment for ${"Online Review"} submitted!`, "success");
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
    if (comment) {
      submitComment();
    }
  };

  //function to move to next phase automatically when moved to next round
  const [movingNextRound, setMovingNextRound] = useState(false);
  useEffect(() => {
    if (movingNextRound) {
      setActionDialogOpen(false);
      setActive(2);
      setMovingNextRound(false);
    }
  }, [movingNextRound]);

  const handleChange = (id, value) => {
    let total = 0;
    Object.keys(data).map((keyname) => {
      if (keyname == "recommend" || keyname == "total" || keyname == id) return;
      total += parseInt(data[keyname]) || 0;
    });
    total += parseInt(value) || 0;
    setData({ ...data, [id]: value, ["total"]: total });
  };

  const submitScore = async () => {
    let obj = { ...data };
    delete obj["total"];
    const response = await updatePhaseData(
      { applicationId: application._id, phase: "phase2", data: obj },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(response?.msg?.message || "Saved..", "success");
    } else {
      snackbarHandler(
        response?.msg?.message || "Something went wrong!",
        "error"
      );
    }
  };

  return (
    <SchemePhaseTabContainer>
      <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
        <div
          className={`statusTextApplication `}
          style={{
            background:
              ApplicaitonStatusColor[applicationData?.[PHASE]?.status],
            color: ApplicationStatusTextColor[applicationData?.[PHASE]?.status],
          }}
        >
          {ApplicationStatus[applicationData?.[PHASE]?.status] || "Pending"}
        </div>

        {from == "associate" ? (
          <div className="assignedText" style={{ fontSize: "1.2rem" }}>
            Assigned to:{" "}
            {application?.reviewer?.map((person, i) => {
              return (
                <span className="rName">
                  {person.name}{" "}
                  {application?.reviewer?.length != i + 1 ? ", " : ""}
                </span>
              );
            })}
            {application?.reviewer?.length == 0 ? "-" : ""}
          </div>
        ) : (
          <></>
        )}
      </div>

      <Container>
        {from == "associate" ? <div className="title">Score</div> : <></>}

        {from == "associate" ? (
          <div className="content">
            <div className="panel">
              <div className="field">
                <FormLabel className="formLabel">
                  1. Clarity in Value Proposition
                </FormLabel>
                <TextField
                  className="formValue"
                  type="number"
                  inputProps={{ style: { textAlign: "center" } }}
                  value={data?.clarity}
                  size="small"
                  onChange={(e) => {
                    var value = parseInt(e.target.value, 10);
                    if (value > 10) value = 10;
                    if (value < 0) value = 0;
                    handleChange("clarity", value);
                  }}
                  disabled={from == "associate" ? false : true}
                />
              </div>

              <div className="field">
                <FormLabel className="formLabel">
                  2. Is there an unmet need ?
                </FormLabel>
                <TextField
                  className="formValue"
                  inputProps={{ style: { textAlign: "center" } }}
                  type="number"
                  value={data?.unmetNeed}
                  size="small"
                  onChange={(e) => {
                    var value = parseInt(e.target.value, 10);
                    if (value > 10) value = 10;
                    if (value < 0) value = 0;
                    handleChange("unmetNeed", value);
                  }}
                  disabled={from == "associate" ? false : true}
                />
              </div>

              <div className="field">
                <FormLabel className="formLabel">
                  3. Will the customer be willing to pay ?
                </FormLabel>
                <TextField
                  className="formValue"
                  type="number"
                  inputProps={{ style: { textAlign: "center" } }}
                  value={data?.customerPay}
                  size="small"
                  onChange={(e) => {
                    var value = parseInt(e.target.value, 10);
                    if (value > 10) value = 10;
                    if (value < 0) value = 0;
                    handleChange("customerPay", value);
                  }}
                  disabled={from == "associate" ? false : true}
                />
              </div>

              <div className="field">
                <FormLabel className="formLabel">4. Novelty</FormLabel>
                <TextField
                  className="formValue"
                  inputProps={{ style: { textAlign: "center" } }}
                  type="number"
                  value={data?.novelty}
                  size="small"
                  onChange={(e) => {
                    var value = parseInt(e.target.value, 10);
                    if (value > 10) value = 10;
                    if (value < 0) value = 0;
                    handleChange("novelty", value);
                  }}
                  disabled={from == "associate" ? false : true}
                />
              </div>

              <div className="field">
                <FormLabel className="formLabel">
                  5. Technical Feasibility
                </FormLabel>
                <TextField
                  className="formValue"
                  type="number"
                  inputProps={{ style: { textAlign: "center" } }}
                  value={data?.techFeasibility}
                  size="small"
                  onChange={(e) => {
                    var value = parseInt(e.target.value, 10);
                    if (value > 10) value = 10;
                    if (value < 0) value = 0;
                    handleChange("techFeasibility", value);
                  }}
                  disabled={from == "associate" ? false : true}
                />
              </div>

              <div className="field">
                <FormLabel className="formLabel">6. Team Strength</FormLabel>
                <TextField
                  className="formValue"
                  type="number"
                  inputProps={{ style: { textAlign: "center" } }}
                  value={data?.teamStrength}
                  size="small"
                  onChange={(e) => {
                    var value = parseInt(e.target.value, 10);
                    if (value > 10) value = 10;
                    if (value < 0) value = 0;
                    handleChange("teamStrength", value);
                  }}
                  disabled={from == "associate" ? false : true}
                />
              </div>

              <div className="field totalScore">
                <FormLabel className="formLabel">
                  Total Score&nbsp;&nbsp; :{" "}
                </FormLabel>
                <TextField
                  className="formValue"
                  inputProps={{ style: { textAlign: "center" } }}
                  value={String(data?.total) + "/60"}
                  disabled
                  size="small"
                />
              </div>
            </div>

            <div className="panel rPanel">
              <CommentActionContainer style={{ marginTop: "1rem" }}>
                <div className="commentSection">
                  <textarea
                    readOnly={from != "associate"}
                    value={data?.comment}
                    onChange={(e) =>
                      setData({ ...data, comment: e.target.value })
                    }
                    placeholder={
                      from == "associate" ? "Add your comments.." : "Comments"
                    }
                  ></textarea>
                </div>
              </CommentActionContainer>

              <div className="field">
                <FormLabel className="recommendLabel">Recommend</FormLabel>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item>No</Grid>
                  <Grid item>
                    <Switch
                      disabled={from == "associate" ? false : true}
                      checked={data?.recommend}
                      onChange={(e, value) => handleChange("recommend", value)}
                    />
                  </Grid>
                  <Grid item>Yes</Grid>
                </Grid>
              </div>

              <div className="">
                <div className="action">
                  {from == "associate" ? (
                    <CustomButton
                      title="Submit Scores"
                      onClickHandler={submitScore}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="actionSection">
                    {from == "associate" &&
                    application?.status == "review" &&
                    user.role.roleId !== "external_reviewer" &&
                    user.role.roleId !== "founder" ? (
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <div className="">Action: </div>
                        <div className="input">
                          <Select
                            size="small"
                            value={selectedAction}
                            onChange={handleActionManager}
                          >
                            <MenuItem value={-1}>
                              <FormLabel>Select</FormLabel>
                            </MenuItem>
                            <MenuItem value={"nextRound"}>
                              <FormLabel>Move to next round</FormLabel>
                            </MenuItem>
                            <MenuItem value={"reject"}>
                              <FormLabel style={{ color: "red" }}>
                                Reject
                              </FormLabel>
                            </MenuItem>
                          </Select>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Dialog
                      open={actionDialogOpen}
                      onClose={handleActionDialog}
                    >
                      <ActionDialog
                        handleActionDialog={handleActionDialog}
                        activeAction={selectedAction}
                        application={application}
                        getApplicationDataHandlerForManager={getApplication}
                        phase={"phase2"}
                        setMovingNextRound={setMovingNextRound}
                        applicationDataVersion={applicationData?.__v}
                        isInviteAplicationVisible={true}
                      />
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Container>
    </SchemePhaseTabContainer>
  );
};

export default OnlineReviewApplicant;

export const Container = styled.div`
  .MuiSwitch-track {
    background-color: #de0c0c;
    opacity: 0.5;
  }

  margin: 0rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .title {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--text1);
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #d0d3d4;
    border-radius: 8px;
    padding: 20px;
    gap: 50px;
    width: max-content;
    margin: 0 auto;

    .panel {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .rPanel {
      gap: 1rem;
    }

    .column {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      border: 1px solid #d0d3d4;
      gap: 2rem;

      .row {
        display: flex;
        flex-wrap: wrap;
      }

      .checkField {
        width: 15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
      }

      .heading {
        display: flex;
        border-bottom: 1px solid #d0d3d4;
        height: 40px;
        font-size: 1.3rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .icon {
          svg {
            display: flex;
            align-items: center;
          }
        }
      }

      .multipleHeading {
        display: flex;
        height: 40px !important;
        border-bottom: 1px solid #d0d3d4;
        font-size: 1.3rem;
        justify-content: space-around;
        align-items: center;
      }

      textarea {
        width: 100%;
        // height: 100%;
        box-sizing: border-box;
        padding: 0.5rem;
        resize: none;
        font-size: 1.1rem;
        outline: none;
        border: none;
        min-height: 150px;
      }

      button {
        display: flex;
        justify-content: center;
        align-self: center;
        margin-bottom: 0.5rem;
      }
    }

    .col {
      display: flex;
      flex-direction: column;
      flex-wrap: unset;
      min-width: 250px;
      // gap: 1rem;
      border-left: none;
    }

    .field {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      padding: 1rem;
      border-radius: 8px;
      align-items: center;

      .formLabel {
        width: 300px;
      }

      .formValue {
        width: 100px;
        text-align: center;
      }
    }

    .totalScore {
      // margin-top: 5px;
      .formLabel {
        text-align: right;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .recommendLabel {
      font-weight: 500;
    }

    .action {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      flex-wrap: wrap;
    }
  }
`;
