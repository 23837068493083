import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/customComponents/LoadingScreen";
import { getUser } from "../../data/network/api/user";
import { globalDispatch, globalSelector } from "../../providers/GlobalProvider";
import { updateAuth } from "../../reducers/auth/authReducer";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = globalSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const [retryButton, setRetryButton] = useState(false);
  const controller = new AbortController();

  const navigate = useNavigate();
  const dispatch = globalDispatch();

  //verify login using token
  const verifyLogin = async () => {
    setLoading(true);
    const token = localStorage.getItem("access-token");
    if (!token) {
      setLoading(false);
      return;
    };

    const response = await getUser(dispatch, controller);
    if (response.status == 200) {
      const user = (JSON.parse(response.msg));
      dispatch(
        updateAuth({
          isAuthenticated: true,
          user: user,
        })
      );
      setLoading(false);
    } else {
      setRetryButton(true);
    }
  };

  useEffect(() => {
    const login = async () => {
      await verifyLogin();
    };
    login();
  }, []);

  return (
    <>
      {loading ? (
        <><LoadingScreen retryButton={retryButton} /></>
      ) : isAuthenticated ? (
        children
      ) : (
        <Navigate to={"/auth/login"} />
      )}
    </>
  );
};

export default PrivateRoute;
