import React, { useEffect, useState } from "react";
import CustomButton from "../../../customComponents/CustomButton";
import MultipleSelectCheckmarks from "../../../customComponents/CustomMultiSelect";
import DataTable from "../../../customComponents/CustomTableWithPagination";
import {
  getCategories,
  getScheme,
  getCustomReportData,
} from "../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../providers/GlobalProvider";
import formatDateString from "../../../../utils/dateConvertor/date";
import OwlLoader from "../../../customComponents/OwlLoader";

const CustomReport = () => {
  const [getCategoriesData, setGetCategoriesData] = useState([]);
  const [getProgramsData, setGetProgramsData] = useState([]);
  const [categories, setCategories] = useState();
  const [programs, setPrograms] = useState();
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterApply, setFilterApply] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);

  const dispatch = globalDispatch();
  const controller = new AbortController();

  const tableHeadings = {
    companyName: {
      text: "Company Name",
      width: "250px",
    },
    location: {
      text: "Location",
      width: "120px",
    },
    phoneNumber: {
      text: "Phone Number",
      width: "150px",
    },
    emailID: {
      text: "Email ID",
      width: "150px",
    },
    founders: {
      text: "Founders",
      width: "120px",
    },
    companyDescription: {
      text: "Company Description",
      width: "200px",
    },
    productDescription: {
      text: "Product Description",
      width: "200px",
    },
    programs: {
      text: "Program",
      width: "300px",
    },
    domain: {
      text: "Domain",
      width: "300px",
    },
  };

  const fetchData = async () => {
    try {
      const categoriesData = await getCategories({}, dispatch, controller);
      const updatedCategoriesData = categoriesData?.msg?.map((ele) => ({
        abbr: ele._id,
        count: ele.count,
      }));
      setGetCategoriesData(updatedCategoriesData);
    } catch (error) {
      console.error(error);
    }

    try {
      const programsData = await getScheme({}, dispatch, controller);
      const processedData = programsData?.msg?.associations?.map((ele) => ({
        abbr: ele,
      }));
      setGetProgramsData(processedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setPageNumber(0);
  }, [programs, categories]);

  useEffect(() => {
    handleFilterReport();
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    if (programs?.length > 0 || categories?.length > 0) setFilterApply(false);
    else {
      setFilterApply(true);
      setTableData([]);
    }
  }, [programs, categories]);

  const handleDownloadReport = () => {
    if (
      (programs && programs.length > 0) ||
      (categories && categories.length > 0)
    ) {
      setDownloadingReport(true);
      getCustomReportData(
        {
          categories: categories,
          schemes: programs,
          shouldGenerateReport: true,
          limit: 10,
          page: 2,
        },
        dispatch,
        controller
      )
        .then((response) => {
          if (response && response.msg) {
            let blob;
            if (response.msg instanceof Blob) {
              blob = response.msg;
            } else {
              blob = new Blob([response.msg], {
                type: "application/octet-stream",
              });
            }
            const href = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "Report.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          }
          setDownloadingReport(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleFilterReport = () => {
    if (
      (programs && programs.length > 0) ||
      (categories && categories.length > 0)
    ) {
      setPageLoading(true);
      getCustomReportData(
        {
          categories: categories,
          schemes: programs,
          shouldGenerateReport: false,
          limit: pageLimit,
          page: pageNumber + 1,
        },
        dispatch,
        controller
      )
        .then((res) => {
          setPageLoading(false);
          if (!res.msg.data.hasOwnProperty("error")) {
            const response = res?.msg?.data?.map((ele, index) => ({
              companyName: ele?.companyName,
              location:
                ele?.officeAddress +
                " " +
                ele?.city +
                " " +
                ele?.state +
                " " +
                ele?.country,
              phoneNumber: ele?.phoneNumber,
              emailID: ele?.email,
              founders: ele?.Founders?.split("-").join(","),
              companyDescription: ele?.companyDescription,
              productDescription: ele?.productDescription,
              // programEnrollmentStartDate: formatDateString(
              //   ele?.bigStartDate || ele?.prayasStartDate
              // ),
              // programEnrollmentEndDate: formatDateString(
              //   ele?.bigEndDate || ele?.prayasEndDate
              // ),
              program: ele?.program,
              domain: ele?.domain,
            }));
            setTableData(response);
            setTotalRows(res?.msg?.totalRows);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setTableData([]);
    }

    setFilterApply(true);
  };

  return (
    <>
      <div style={{ paddingTop: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <MultipleSelectCheckmarks
              Title={"Categories"}
              setState={setCategories}
              data={getCategoriesData}
            />
            <MultipleSelectCheckmarks
              Title={"Programs"}
              setState={setPrograms}
              data={getProgramsData}
            />
            <CustomButton
              title={"Apply"}
              disabled={filterApply}
              onClickHandler={handleFilterReport}
            ></CustomButton>
          </div>
          <CustomButton
            title={downloadingReport ? "Downloading..." : "Download Report"}
            disabled={downloadingReport || !tableData.length > 0}
            onClickHandler={handleDownloadReport}
          ></CustomButton>
        </div>
        <DataTable
          columns={tableHeadings}
          tableData={tableData}
          totalRows={totalRows}
          setPageNumber={setPageNumber}
          setPageLimit={setPageLimit}
          pageNumber={pageNumber}
          pageLoading={pageLoading}
        />
      </div>
    </>
  );
};

export default CustomReport;
