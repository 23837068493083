import { Search } from "@mui/icons-material";
import { FormLabel } from "@mui/material";
import styled from "styled-components";

const CustomTextSearch = ({ value, setValue }) => {
  return (
    <Container>
      <div>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="text"
          placeholder="Search for Applicant"
        />
        <Search className="icon" />
      </div>
      <FormLabel style={{fontSize: '0.7rem', padding: '0.2rem'}}>* Upcoming</FormLabel>
    </Container>
  );
};

export default CustomTextSearch;

const Container = styled.div`
width: 30%;
  div {
    background: #fff;
    height: 2.5rem;
    padding: 0.2rem 1rem;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;

    input {
      border: none;
      color: var(--text3);
      outline: none;
      font-size: 0.9rem;
      width: 100%;
    }

    .icon {
      color: var(--text3);
      cursor: pointer;

      &:hover {
        color: var(--primary);
      }
    }

    @media only screen and (max-width: 1000px) {
      width: max-content;
    }
  }
`;
