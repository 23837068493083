import React, { useEffect, useState } from "react";
import { DialogBody } from "../../../../../../../utils/customStyledComponents";
import { Icons } from "../../../../../../../utils/mediaExports";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ExportExcel from "../../../../../../customComponents/ExportExcel";
import dayjs from "dayjs";
import { getStatusToTitanFilterResults } from "../../../../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../../../../providers/GlobalProvider";
import { Alert, TextField } from "@mui/material";

const StatusToTitanActionModel = ({ handleActionDialog, call }) => {
	const dispatch = globalDispatch();
	const controller = new AbortController();
	const [excelData, setExcelData] = useState([]);
	const [startDate, setStartDate] = useState(
		dayjs(
			`${new Date().getFullYear()}-${
				new Date().getMonth() + 1
			}-${new Date().getDate()}`
		)
	);
	const [endDate, setEndDate] = useState(
		dayjs(
			`${new Date().getFullYear()}-${
				new Date().getMonth() + 1
			}-${new Date().getDate()}`
		)
	);
	const [errorMessage, setErrorMessage] = useState("");
	const [isExcelExportDisabled, setIsExcelExportDisabled] = useState(false);
	const rangeConditionDateFileName1 = `results_from_${
		startDate?.$D < 10 ? "0" + startDate?.$D.toString() : startDate?.$D
	}_${
		startDate?.$M + 1 < 10
			? "0" + (startDate?.$M + 1).toString()
			: startDate?.$M + 1
	}_${startDate?.$y}_to_${
		endDate?.$D < 10 ? "0" + endDate?.$D.toString() : endDate?.$D
	}_${
		endDate?.$M + 1 < 10 ? "0" + (endDate?.$M + 1).toString() : endDate?.$M + 1
	}_${endDate?.$y}`;

	useEffect(() => {
		onSelectEndDate(endDate);
	}, []);
	const onGetExportResultsData = (
		newValue1,
		newValue2,
		setState,
		isEndDate
	) => {
		const sYear = newValue1?.$y;
		const sMonth = newValue1?.$M + 1;
		const sDate = newValue1?.$D;
		const eYear = newValue2?.$y;
		const eMonth = newValue2?.$M + 1;
		const eDate = newValue2?.$D;
		const startingDate = `${sYear}-${sMonth}-${sDate}`;
		const endingDate = `${eYear}-${eMonth}-${eDate}`;
		if (isEndDate) {
			setState(newValue2);
		} else {
			setState(newValue1);
		}

		if (sYear > eYear) {
			setErrorMessage(
				"The Start Date cannot be later than the End Date. Please select valid date ranges."
			);
			setIsExcelExportDisabled(true);
		} else if (eYear === sYear && eMonth === sMonth && sDate > eDate) {
			setErrorMessage(
				"The Start Date cannot be later than the End Date. Please select valid date ranges."
			);
			setIsExcelExportDisabled(true);
		} else if (eYear === sYear && eMonth < sMonth) {
			setErrorMessage(
				"The Start Date cannot be later than the End Date. Please select valid date ranges."
			);
			setIsExcelExportDisabled(true);
		} else {
			onGetStatusToTitanFilterResults(startingDate, endingDate);
			setErrorMessage();
			setIsExcelExportDisabled(false);
		}
	};
	const onSelectStartDate = async (newValue) => {
		onGetExportResultsData(newValue, endDate, setStartDate);
	};
	const onGetStatusToTitanFilterResults = async (sDate, eDate) => {
		const response = await getStatusToTitanFilterResults(
			{
				callId: call._id,
				startDate: sDate,
				endDate: eDate,
			},
			dispatch,
			controller
		);
		if (response?.status === 200 && response) {
			setExcelData(response?.msg?.applications);
		}
	};
	const onSelectEndDate = async (newValue) => {
		onGetExportResultsData(startDate, newValue, setEndDate, true);
	};
	return (
		<DialogBody>
			<div className="closeButton" onClick={handleActionDialog}>
				{Icons.close}
			</div>

			<div
				className=""
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
					padding: "12px",
					paddingBottom: "30px",
				}}
			>
				<div
					className=""
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						gap: "30px",
					}}
				>
					<h3>Select application upload date range</h3>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							inputFormat="DD/MM/YYYY"
							label="Start Date"
							value={startDate}
							onChange={onSelectStartDate}
							renderInput={(params) => <TextField {...params} name="date" />}
						/>
						<DatePicker
							inputFormat="DD/MM/YYYY"
							label="End Date"
							value={endDate}
							onChange={onSelectEndDate}
							renderInput={(params) => <TextField {...params} name="date" />}
						/>
					</LocalizationProvider>
					{errorMessage && (
						<Alert color={"error"} severity="error">
							{errorMessage}
						</Alert>
					)}
					<ExportExcel
						excelData={excelData}
						fileName={rangeConditionDateFileName1}
						disabled={isExcelExportDisabled}
					/>
				</div>
			</div>
		</DialogBody>
	);
};

export default StatusToTitanActionModel;
