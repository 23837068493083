import { SearchOutlined } from "@mui/icons-material";
import {
  InputLabel,
  TextField,
  InputAdornment,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TablePagination,
  FormLabel,
  Autocomplete,
  Chip,
  IconButton,
  Switch,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { set } from "immutable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  fetchAllStartups,
  fetchCompanies,
  fetchIndustries,
  fetchPopularTags,
  fetchStartups,
  fetchStats,
  saveTags,
  tagsQuery,
} from "../../../data/network/api/startupForm";
import {
  globalDispatch,
  globalSelector,
} from "../../../providers/GlobalProvider";
import { updateStartupList } from "../../../reducers/startups/startupsList";
import { handleTopBarTitle } from "../../../utils/helperHandler";
import { Icons } from "../../../utils/mediaExports";
import { StartupsPage } from "../../../utils/constants";
import { updateNavigation } from "../../../reducers/navigationReducer";
import { useSnackbar } from "notistack";
import StartupsTable from "./StartupsTable";

const filtersBoxes = {
  totalCompanies: {
    text: "Total number of Companies",
    color: "#C1F9D1",
  },
  femaleFounders: {
    text: "Startups with Female Founders",
    color: "#FFCD9E",
  },
  big: {
    text: "BIG Awardees",
    color: "#FCA9A9",
  },
  prayas: {
    text: "PRAYAS Awardees",
    color: "#BCF6EE",
  },
  ikpEden: {
    text: "Startups at IKP EDEN",
    color: "#C1F9D1",
  },
  cif: {
    text: "Startups at KTECH HUB",
    color: "#FCA9A9",
  },
};

const tableHeadingsList = [
  {
    id: "sl",
    title: "Sl No.",
    sort: false,
    align: "center",
    width: "70px",
  },
  {
    id: "companyName",
    title: "Company Name",
    sort: false,
    align: "left",
    width: "250px",
  },
  {
    id: "founders",
    title: "Founders",
    sort: false,
    align: "left",
    width: "",
  },
  {
    id: "industry",
    title: "Industry",
    sort: false,
    align: "left",
    width: "350px",
  },
  {
    id: "location",
    title: "Location",
    sort: false,
    align: "left",
    width: "200px",
  },
];

const StartupsList = () => {
  const { user } = globalSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [viewAllClicked, setViewAllClicked] = useState(false);
  const [hidePopular, setHidePopular] = useState(false);

  const [counts, setCounts] = useState(null);
  const dispatch = globalDispatch();
  const controller = new AbortController();

  const [activeFilterBox, setActiveFilterBox] = useState(null);

  const [industries, setIndustries] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);
  const [popularTags, setPopularTags] = useState([]);
  const [searchSelected, setSearchSelected] = useState("none");

  const [currentSearchTagTyping, setTagTyping] = useState("");
  const [tagsFound, setTagsFound] = useState([]);
  const [andChecked, setAndChecked] = useState(false);
  const [searchboxTags, setSearchBoxTags] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const [savedSearches, setSavedSearches] = useState(user?.others?.tags || []);
  const [currentSearchSaved, setCurrentSearchSaved] = useState(false);

  const handleSnackbar = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  useEffect(() => {
    let pageNavStack = JSON.parse(JSON.stringify(StartupsPage));
    dispatch(
      updateNavigation({
        type: "pageNavStack",
        value: { ...pageNavStack },
      })
    );
    fetchStatsHandler();
    fetchIndustriesHandler();
    fetchPopularTagsHandler();
  }, []);

  const fetchIndustriesHandler = async () => {
    const response = await fetchIndustries({
      data: {},
      controller: controller,
      dispatch: dispatch,
    });
    console.log(response);
    if (response && response.status == 200) {
      setIndustries(response.msg);
    } else {
      handleSnackbar(response?.msg || "Failed to load industries!", "error");
    }
  };

  const clearFilters = () => {
    setCategorySelected(null);
    setSearchBoxTags([]);
    setCompanies([]);
    setActiveFilterBox(null);
    setCurrentSearchSaved(false);
    setAndChecked(null);
  };

  const handleFilterBox = (id) => {
    if (id == activeFilterBox) {
      clearFilters();
      setActiveFilterBox(null);
      return;
    }
    clearFilters();
    setActiveFilterBox(id);
    fetchCompaniesHandler([], null, "filter", id);
  };

  const handleCategoryBox = (industry) => {
    if (industry._id == categorySelected?._id) {
      clearFilters();
      setCategorySelected(null);
      return;
    }
    clearFilters();
    setCategorySelected(industry);
    fetchCompaniesHandler([], null, "category", industry?._id);
  };

  //handlers related to search box
  useEffect(() => {
    if (!!currentSearchTagTyping == false) {
      setTagsFound([]);
      return;
    }
    const timeOutId = setTimeout(
      () => fetchSearchTags(currentSearchTagTyping),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [currentSearchTagTyping]);

  const fetchSearchTags = async (query) => {
    const response = await tagsQuery(
      { searchQuery: query },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      setTagsFound(response?.msg);
    }
  };

  const handleSearchSelect = async (value, operation) => {
    clearFilters();
    setAndChecked(operation == "and" ? true : false);
    setSearchBoxTags(value);
    fetchCompaniesHandler(value, operation);
  };

  const fetchCompaniesHandler = async (
    value,
    operation,
    filter = null,
    filterValue = null
  ) => {
    if ((operation == "and" || operation == "or") && value.length == 0) {
      return;
    }

    setCompanies([]);
    setLoading(true);
    const response = await fetchCompanies(
      { tags: value, operation: operation, filter, filterValue },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      setCompanies(response?.msg || []);
      setLoading(false);
    }
  };

  const fetchPopularTagsHandler = async () => {
    const response = await fetchPopularTags({}, dispatch, controller);
    if (response && response.status == 200) {
      setPopularTags(response?.msg);
    } else {
      handleSnackbar("Failed to load popular tags!", "error");
    }
  };

  const handlePopularTags = (tag) => {
    setCompanies([]);
    setCategorySelected(null);
    setActiveFilterBox(null);
    setCurrentSearchSaved(null);
    const array = [...searchboxTags];
    if (array.includes(tag)) {
      const index = array.indexOf(tag);
      array.splice(index, 1);
    } else {
      array.push(tag);
    }
    setSearchBoxTags(array);
    if (array.length > 0) {
      fetchCompaniesHandler(array, andChecked ? "and" : "or");
    }
  };

  const handleAndToggle = (e) => {
    if (currentSearchSaved) {
      setCurrentSearchSaved(false);
    }
    if (e.target.checked) {
      setAndChecked(true);
      fetchCompaniesHandler(searchboxTags, "and");
    } else {
      setAndChecked(false);
      fetchCompaniesHandler(searchboxTags, "or");
    }

    const operation = e.target.checked ? "and" : "or";
    savedSearches?.map((search) => {
      if (
        search.operation == operation &&
        search.tags.every((element) => {
          return searchboxTags.includes(element);
        })
      ) {
        setCurrentSearchSaved(true);
      }
    });
  };
  //end handlers related to search box

  const fetchStatsHandler = async () => {
    const response = await fetchStats({}, dispatch, controller);
    if (response && response.status == 200) {
      setCounts(response.msg);
    } else {
      handleSnackbar(response?.msg || "Failed to load stats!", "error");
    }
  };

  //handlers for search save
  const handleBookmark = async () => {
    if (currentSearchSaved) {
      setCurrentSearchSaved(false);
      saveBookmark("remove");
    } else {
      setCurrentSearchSaved(true);
      saveBookmark("add");
    }
  };

  const saveBookmark = async (action, tags = null) => {
    const response = await saveTags(
      {
        value: tags || searchboxTags,
        action,
        operation: andChecked ? "and" : "or",
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      handleSnackbar(
        `${action == "add" ? "Search tags saved" : "Search tags removed"}`,
        "success"
      );

      if (action == "delete" || action == "remove") {
        setCurrentSearchSaved(false);
      }
      setSavedSearches(response?.msg);
    } else {
      handleSnackbar(response?.msg || "Failed to save!", "error");
      setCurrentSearchSaved(action == "add" ? false : true);
    }
  };
  //end handlers for search save

  return (
    <Container>
      <FilterContainer>
        {Object.keys(filtersBoxes).map((filter, index) => {
          return (
            <div className="box" onClick={() => handleFilterBox(filter)}>
              <div className="number">{counts?.[filter] || "-"}</div>
              <div className="text">{filtersBoxes[filter].text}</div>
              <div
                className="overlay"
                style={
                  filter == activeFilterBox
                    ? {
                        backgroundColor: filtersBoxes[filter].color,
                        width: "100%",
                        height: "unset",
                        borderRadius: 0,
                        left: "unset",
                      }
                    : { backgroundColor: filtersBoxes[filter].color }
                }
              ></div>
            </div>
          );
        })}
      </FilterContainer>

      <CategoriesContainer>
        <div className="heading">Categories ({industries.length})</div>

        <div
          className="list"
          style={viewAllClicked ? { height: "unset" } : null}
        >
          {industries.map((industry, i) => {
            return (
              <div
                className={`listItem ${
                  categorySelected?._id == industry._id ? "listItemActive" : ""
                }`}
                key={i}
                onClick={() => handleCategoryBox(industry)}
              >
                <div className="icon">
                  <img src={Icons.robotics} />
                </div>
                <div className="text">{industry._id}</div>
                <div className="number">
                  {industry.count > 99 ? "99+" : industry.count}
                </div>
              </div>
            );
          })}
        </div>

        <div className="viewall">
          <div
            className="text"
            onClick={() => setViewAllClicked(!viewAllClicked)}
          >
            {viewAllClicked ? "Hide" : "View all"}
          </div>
        </div>
      </CategoriesContainer>

      <SearchContainer>
        <div className="searchSection">
          <div className="searchBox">
            <Autocomplete
              multiple
              id="tags-filled"
              options={tagsFound.map((option) => option._id)}
              value={searchboxTags}
              disableClearable={true}
              onChange={(event, newValue) => {
                handleSearchSelect(newValue, andChecked ? "and" : "or");
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={""}
                  placeholder=""
                  size="small"
                  className="searchbox"
                  onChange={(e) => setTagTyping(e.target.value)}
                  value={currentSearchTagTyping}
                />
              )}
            />

            <div className="icon" onClick={() => handleBookmark()}>
              {currentSearchSaved ? Icons.bookmarkFilled : Icons.bookmark}
            </div>
          </div>

          <div className="andorToggle">
            <span className="">Or</span>
            <span className="switch">
              <Switch
                sx={{
                  "&.MuiSwitch-root .MuiSwitch-switchBase": {
                    color: "var(--text1)",
                  },

                  "&.MuiSwitch-root .MuiSwitch-track": {
                    background: "var(--text1) !important",
                  },

                  "&.MuiSwitch-root .Mui-checked": {
                    color: "var(--text1)",
                  },
                }}
                checked={andChecked}
                onChange={handleAndToggle}
              />
            </span>
            <span className="">And</span>
          </div>
        </div>

        <div className="savedSearchContainer">
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              defaultValue=""
              id="grouped-select"
              size="small"
              placeholder="Saved Searches"
              label="Saved Searches"
              value={searchSelected}
            >
              <MenuItem
                style={{ fontSize: "12px", color: "var(--text1)" }}
                value="none"
              >
                Saved Searches
              </MenuItem>

              {savedSearches?.map((tags, i) => {
                return (
                  <>
                    <ListSubheader className="listHeader">
                      <div className="icon">{Icons.bookmarkFilled}</div>
                      <div className="text">Saved {i + 1}</div>
                    </ListSubheader>
                    <li className="savedItem">
                      <div
                        className="savedTags"
                        style={{ pointerEvents: "none !important" }}
                      >
                        {tags?.tags?.map((tag, j) => {
                          return (
                            <Chip
                              label={tag}
                              sx={{
                                fontSize: "12px",
                                background: "var(--hoverColor)",
                              }}
                            />
                          );
                        })}
                      </div>
                      <div className="savedItemAction">
                        <div
                          className="btn apply"
                          onClick={() => {
                            handleSearchSelect(tags?.tags, tags?.operation);
                            setCurrentSearchSaved(true);
                          }}
                        >
                          Apply
                        </div>
                        <div
                          className="btn remove"
                          onClick={() => {
                            saveBookmark("delete", tags);
                          }}
                        >
                          Remove
                        </div>
                      </div>
                    </li>
                  </>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </SearchContainer>

      <PopularTagsSection style={hidePopular ? { display: "none" } : null}>
        {/* <div className="panel">
          <div className="heading">Popular tags</div>
          <div className="heading">Popular locations</div>
        </div> */}
        <div className="panel">
          <div className="row">
            <div className="heading">
              <div className="text">Popular tags</div>
              <div className="colon">:</div>
            </div>
            <div className="list">
              {popularTags?.industries?.map((_, i) => {
                return (
                  <Chip
                    onClick={() => {
                      handlePopularTags(_);
                    }}
                    label={_}
                    className={`chip ${
                      searchboxTags.includes(_) ? "chipActive" : null
                    }`}
                  />
                );
              })}
            </div>
          </div>
          <div className="row">
            <div className="heading">
              <div className="text">Popular locations</div>
              <div className="colon">:</div>
            </div>
            <div className="list">
              {popularTags?.city?.map((_, i) => {
                return (
                  <Chip
                    onClick={() => {
                      handlePopularTags(_);
                    }}
                    label={_}
                    className={`chip ${
                      searchboxTags.includes(_) ? "chipActive" : null
                    }`}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="panel">
          <div className="heading btn" onClick={() => setHidePopular(true)}>
            Hide
          </div>
        </div>
      </PopularTagsSection>

      {hidePopular ? (
        <div className="showPopularTagsSection">
          <div className="text" onClick={() => setHidePopular(false)}>
            Show Popular tags
          </div>
        </div>
      ) : null}

      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <StartupsTable
          companies={companies}
          tagsSelected={searchboxTags}
          tagCategory={categorySelected}
        />
      )}
    </Container>
  );
};

export default StartupsList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .loader {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .showPopularTagsSection {
    display: flex;
    justify-content: center;
    color: var(--text1);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  gap: 2rem;
  padding: 0.5rem 0;
  position: relative;

  .box {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    cursor: pointer;
    border-radius: 12px;
    height: 100%;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
    width: 100%;

    .number {
      font-size: 2vw;
      padding: 1vw 1vw;
      color: var(--text3);
      z-index: 1;
    }

    .text {
      font-size: 0.8vw;
      text-align: center;
      padding-right: 1vw;
      color: var(--text1);
      z-index: 1;
    }

    .overlay {
      z-index: 0;
      height: 75%;
      border-bottom-left-radius: 31px;
      position: absolute;
      top: 0px;
      right: 0px;
      width: 4vw;
      left: calc(100% - 4vw);
      transition: 0.2s;
      opacity: 0.7;
    }

    &:hover {
      .overlay {
        width: 100%;
        border-radius: 0px;
        height: unset;
        left: unset;
      }
    }
  }
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;

  .heading {
    color: var(--text3);
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .list {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    flex-flow: row wrap;
    height: 100px;
    overflow: hidden;
    position: relative;
    padding-bottom: 0.2rem;

    &:after {
      content: "";
      flex: auto;
    }

    .listItem {
      justify-content: space-between;
      display: flex;
      align-items: center;
      gap: 0.8rem;
      background: #fff;
      border-radius: 12px;
      padding: 0.5rem 1rem;
      font-size: 15px;
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .text {
        color: var(--text1);
        font-weight: 400;
        width: max-content;
      }

      .number {
        background: var(--text1);
        border-radius: 50%;
        padding: 0.3rem;
        color: #fff;
        font-size: 12px;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
      }
    }

    .listItemActive {
      background: var(--text1);

      .text {
        color: #fff;
      }

      .number {
        background: #fff;
        color: var(--text1);
      }

      .icon {
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  .viewall {
    display: flex;
    justify-content: flex-end;

    .text {
      color: var(--text1);
      cursor: pointer;
      font-size: 15px;
      margin-top: 0.5rem;
      width: max-content;

      &:hover {
        color: var(--primary);
      }
    }
  }
`;

const SearchContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 0.5rem 0;

  .searchSection {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: center;

    .searchBox {
      width: 40%;
      padding: 0.1rem 0;
      background: #fff;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      border-radius: 12px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      min-height: 50px;

      .MuiAutocomplete-root {
        width: 100%;

        .MuiChip-root {
          font-size: 12px;
          padding: 0px;
          margin: 3px;
          background: var(--hoverColor);
          border: none;
          color: var(--text1);
        }

        .MuiAutocomplete-endAdornment {
          display: none;
        }
      }

      .searchbox {
        fieldset {
          border: none !important;
        }
      }

      .icon {
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        color: var(--text1);
      }
    }

    .andorToggle {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: var(--text1);
    }
  }

  .savedSearchContainer {
    position: absolute;
    right: 0;
    width: 200px;
    background: #fff;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    align-items: center;

    fieldset {
      border: none !important;
    }

    .MuiOutlinedInput-input {
      color: var(--text1);
      font-size: 15px;
    }
  }
`;

const PopularTagsSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;

  .panel {
    .heading {
      color: var(--text1);
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    display: flex;
    gap: 1rem;
    flex-direction: column;

    .row {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      height: 32px;
      overflow: hidden;

      .heading {
        width: 150px;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: center;
      }

      .list {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        .chip {
          background: var(--hoverColor);
        }

        .chipActive {
          background: var(--primary);
        }
      }
    }

    .btn {
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`;
