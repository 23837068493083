import styled from "styled-components";

const ReviewTab = ({
  person,
  activeReviewTab,
  setActiveReviewTab,
}) => {
  const changeTab = (value) => {
    setActiveReviewTab(value);
  };

  return (
    <Container>
      {person.map((individual, i) => {
        return (
          <div
            className={`tab ${activeReviewTab == i ? "active" : ""}`}
            onClick={() => changeTab(i)}
          >
            {individual?.name}
          </div>
        );
      })}
      <div
        className={`tab ${activeReviewTab == person.length ? "active" : ""}`}
        onClick={() => changeTab(person.length)}
      >
        Consolidated score card
      </div>
      {/* <div
        className={`tab ${
          activeReviewTab == person.length + 1 ? "active" : ""
        }`}
        onClick={() => changeTab(person.length + 1)}
      >
        Finalists
      </div> */}
    </Container>
  );
};

export default ReviewTab;

const Container = styled.div`
  width: 100%;
  background: rgba(181, 170, 170, 0.25);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 2rem;

  .tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 1px solid var(--light);
    flex-wrap: wrap;
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
  }

  .active {
    background: var(--primary);
  }
`;
