import { minHeight } from "@mui/system";
import { useState } from "react";
import "./expenses.css";
import CustomExpenseCatTab from "../../../../customComponents/CustomFinCategoryTab";
import CustomButton from "../../../../customComponents/CustomButton";
import PageNavigation from "../../../../customComponents/PageNavigation";
import ExpensesForm from "./expenseForm";
import Popup from "reactjs-popup";
import { createTheme, Dialog, Modal, ThemeProvider } from "@mui/material";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
const Expenses = () => {
  const Scheme = "Big";
  const SchemeId = "mwjdfhwefd";
  const milestonesId = "mnqwrav";
  const milestoneName = "milestone2";
  const financeCategories = [
    "Capital",
    "Consumables",
    "Salaries",
    "Outsourcing",
    "Rent and Failities",
    "Travel",
  ];
  const router = useNavigate();
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const changeActiveTab = (i) => {
    console.log(i);
    setActiveTab(i);
  };
  const theme = createTheme({
    components: {
      MuiModal: {
        styleOverrides: {
          root: {
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // backgroundColor: "red",
          },
        },
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Modal
          classes={"expensePopUpModal"}
          open={open}
          onClose={closeModal}
          container={() => document.getElementById("outlet")}
        >
          <ExpensesForm onClose={closeModal} />
        </Modal>
      </ThemeProvider>

      <PageNavigation
        paths={[
          { text: "Financial", links: "/" },
          { text: "BIG", links: "/" },
          { text: "Schemes", links: "/" },
        ]}
      />
      {/* <CustomExpenseCatTab
        finCatTabs={{
          a: "Capital",
          b: "Consumables",
          c: "Salaries",
          d: "Outsourcing",
          e: "Rent and Failities",
          f: "Travel",
        }}
        activeTab={activeTab}
        changeActiveTab={changeActiveTab}
      /> */}
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          minHeight: "40px",
          padding: "10px 20px 10px 20px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "fit-content" }}>
          <CustomButton
            title={"View Expenses"}
            onClickHandler={() => {
              router(`/schemes/big/CrossCheckingForm/${params.repId}`);
            }}
          />
        </div>
        <div style={{ height: "30px" }}></div>
        <div style={{ width: "fit-content" }}>
          <CustomButton
            title={"Add Expense"}
            onClickHandler={() => setOpen((o) => !o)}
          />
        </div>
      </div>
    </>
  );
};
export default Expenses;
