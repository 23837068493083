import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const tableHeadings = {
  sl: {
    text: "Sl No.",
    width: "50px",
  },
  companyName: {
    text: "Company Name",
    width: "250px",
  },
  founders: {
    text: "Founders",
    width: "400px",
  },
  tags: {
    text: "Tags",
  },
};

const StartupsTable = ({ companies = [], tagsSelected = [], tagCategory = null }) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableWrapper style={{ display: companies.length == 0 ? "none" : null }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(tableHeadings).map((heading) => {
                return (
                  <TableCell
                    sx={{ width: tableHeadings[heading]?.width }}
                    key={heading}
                  >
                    {tableHeadings[heading].text}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {companies
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((company, index) => {
                return (
                  <TableRow
                    className="companyRow"
                    onClick={() => {
                      navigate(`/startups/${company.companyId}`);
                    }}
                    key={index}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell>{company?.companyName}</TableCell>
                    <TableCell>
                      {company?.founders?.join(", ") || "-"}
                    </TableCell>
                    <TableCell>
                      {company?.tags?.map((tag, index) => {
                        if (tag) {
                          return (
                            <Chip
                              className={`chip ${
                                tagCategory?._id == tag || tagsSelected.includes(tag) ? "chipActive" : null
                              }`}
                              label={tag}
                            />
                          );
                        }
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
                colSpan={tableHeadings.length}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                count={companies.length}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default StartupsTable;

const TableWrapper = styled.div`
  margin-top: 1rem;

  .chip {
    margin: 0.2rem 0.2rem 0.2rem 0rem;
  }

  .chipActive {
    background: var(--hoverColor);
  }

  .companyRow {
    cursor: pointer;
    &:hover {
      background: var(--hoverColor);
    }
  }
`;
