import {
	Alert,
	Chip,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormLabel,
} from "@mui/material";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { globalDispatch } from "../../../../providers/GlobalProvider";
import { DialogBody } from "../../../../utils/customStyledComponents";
import { Icons } from "../../../../utils/mediaExports";
import CustomButton from "../../../customComponents/CustomButton";
import { FileArea } from "../../ikp/widgets/FormBuilder";
import { bulkUploadApplications } from "../../../../data/network/api/scheme";
const ExcelFileUploadDialog = ({ modalHandler }) => {
	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const dispatch = globalDispatch();
	const controller = new AbortController();
	const [serverResponse, setServerResponse] = useState(null);
	const { id} = useParams();
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = ".xlsx";
	const handleFile = (file) => {
		if (!file) return;
		setFile(file);
	};

	const sendFile = async () => {
		setServerResponse(null);
		try {
			setLoading(true);
			let formData = new FormData();
			formData.append("file", file);
			formData.append("callId", id);
			const response = await bulkUploadApplications(formData, dispatch, controller);
			if (response && response.status == 200) {
				setServerResponse(response);
				console.log(response?.msg?.data?.rejectedCompanies)
				if(response?.msg?.data?.totalRejectedCompanies>0){
					
					const ws = XLSX.utils.json_to_sheet(response?.msg?.data?.rejectedCompanies);
			
				 const wb = {Sheets: {'rejected Companies': ws}, SheetNames: ['rejected Companies']};
			
		 
			const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
			const data = new Blob([excelBuffer], {type: fileType});
			FileSaver.saveAs(data, 'Titan Rejected Companies' + fileExtension);
				}
				window.location.reload()
			} else {
				setServerResponse(
					response || {
						status: 400,
						msg: "Something went wrong!",
					}
				);
			}
		} catch (err) {
			console.debug(err);
			alert("Something went wrong!");
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<DialogBody>
				<div className="closeButton" onClick={modalHandler}>
					{Icons.close}
				</div>
				<DialogTitle className="dialogTitle">Upload File</DialogTitle>
				<DialogContent className="content">
					<div className="fileUploader">
						<FileUploader
							classes="component"
							name="file"
							types={["xlsx"]}
							multiple={false}
							handleChange={(file) => handleFile(file)}
							children={
								<FileArea>
									<div className="message">
										<div>Drag and Drop</div>
										<div>Or</div>
										<div>Browse for files</div>
									</div>
								</FileArea>
							}
						/>

						<div className="files">
							{file ? (
								<div className="file mt1">
									<Chip
										label={
											<a href={file.url} target={"_blank"}>
												{file.name}
											</a>
										}
										onDelete={() => {
											setFile(null);
										}}
										deleteIcon={Icons.delete}
									/>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>

					<div className="actionField">
						{loading ? (
							<CircularProgress />
						) : (
							<CustomButton title={"Upload"} onClickHandler={sendFile}  />
						)}
					</div>

					{serverResponse ? (
						<div className="serverResponse">
							<Alert
								color={serverResponse?.status == 200 ? "success" : "error"}
							>
								{serverResponse?.status == 200
									? <div><div>{serverResponse?.msg?.message}</div>
									
									<div>Total Companies: {serverResponse?.msg?.data?.totalCompanies}</div>
									
								
									<div>Total Rejected Companies: {serverResponse?.msg?.data?.totalRejectedCompanies}</div>
								
									
									</div>
									: serverResponse?.msg}

							</Alert>
						</div>
					) : (
						<></>
					)}
				</DialogContent>
			</DialogBody>
		</>
	);
};

export default ExcelFileUploadDialog;
