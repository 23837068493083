import styled from "styled-components";
import { Gif } from "../../utils/mediaExports";

const OwlLoader = () => {
    return (
        <Container>
            <img src={Gif.flying} />
        </Container>
    );
}

export default OwlLoader;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
        width: 10rem;
    }
`;