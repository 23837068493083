const ClickableLinksIntoString = ({ textWithLinks }) => {
  // Regular expression to match URLs in the text
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the text by URLs
  const parts = textWithLinks.split(urlRegex);

  return (
    <div>
      {parts.map((part, index) =>
        // Check if the part is a URL or regular text
        urlRegex.test(part) ? (
          // If it's a URL, create a clickable link
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        ) : (
          // If it's regular text, display it as is
          <span key={index}>{part}</span>
        )
      )}
    </div>
  );
};

export default ClickableLinksIntoString;
