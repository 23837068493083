import {
  Alert,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  globalDispatch,
  globalSelector,
} from "../../../../providers/GlobalProvider";
import { DialogBody } from "../../../../utils/customStyledComponents";
import { Icons } from "../../../../utils/mediaExports";
import CustomButton from "../../../customComponents/CustomButton";
import { CommentActionContainer } from "../../ikp/big/phases/applicant/agreement/Agreement";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import OwlLoader from "../../../customComponents/OwlLoader";
import {
  checkApplication,
  saveComment,
  updatePhaseData,
} from "../../../../data/network/api/scheme";
import { TitanDispatch } from "../../../../providers/scheme/titan/TitanProvider";
import { updateTitanApplication } from "../../../../reducers/schemes/titan/applications";
import { applicanteeSideRole, applicationSideRole } from "../CustomTabView";
import {
  ApplicationStatusTextColor,
  ApplicaitonStatusColor,
  ApplicationStatus,
} from "../../../../utils/constants";
import ActionDialog from "../../ikp/big/phases/applicant/applicationAction/ActionModal";
import { Container } from "../../ikp/titan/components/application/onlineReview/OnlineReviewApplicant";
import { SchemePhaseTabContainer } from "../../ikp/titan/components/Applications";
const PHASE = "phase2";
const initialData = {
  clarity: 0,
  unmetNeed: 0,
  customerPay: 0,
  novelty: 0,
  techFeasibility: 0,
  teamStrength: 0,
  total: 0,
  recommend: false,
  comment: "",
};

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

let totalMarks = [];

for (let i = 10; i <= 60; i += 10) {
  totalMarks.push({
    label: String(i),
    value: i,
  });
}

const TablePopUp = ({
  modalHandler,
  applicationsID,
  selectedApplicationID,
  setSelectedApplicationID,
}) => {
  const from = "associate";
  const { user } = globalSelector((state) => state.auth);
  const controller = new AbortController();
  const dispatch = globalDispatch();
  const titanDispatch = TitanDispatch();
  const params = useParams();

  const [application, setApplication] = useState(null);
  const [assignedReviewerEmails, setAssignedReviewerEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [applicationData, setApplicationData] = useState(null);
  const [comment, setComment] = useState("");
  const [applicationIDIndex, setApplicationIDIndex] = useState(
    applicationsID.indexOf(selectedApplicationID)
  );
  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const isExternalReviewer = applicanteeSideRole.includes(user.role.roleId);
  const isProgramAssociate = applicanteeSideRole.includes(user.role.roleId);
  const isFounder = applicationSideRole.includes(user.role.roleId);

  const [data, setData] = useState({
    ...initialData,
  });

  const { enqueueSnackbar } = useSnackbar();
  //function to get application details and application phase data
  const getApplication = async (index) => {
    setIsLoading(true);
    let bodyData = {};
    if (isProgramAssociate || isExternalReviewer || isFounder) {
      bodyData = {
        fromIkp: true,
        applicationId:
          index || index === 0 ? applicationsID[index] : selectedApplicationID,
      };
    }

    try {
      const response = await checkApplication(bodyData, dispatch, controller);
      if (response && response.status == 200) {
        const reviewerEmails = [];
        response.msg?.application.reviewer.map((reviewer) => {
          if (reviewer.name === "reviewer") {
            if (reviewerEmails.indexOf(reviewer.email) === -1) {
              reviewerEmails.push(reviewer.email);
            }
          } else {
            if (reviewerEmails.indexOf(reviewer.name) === -1) {
              reviewerEmails.push(reviewer.name);
            }
          }
        });
        setAssignedReviewerEmails(() => [...reviewerEmails]);
        setApplication(response.msg?.application);
        setApplicationData(response.msg?.applicationData);
        titanDispatch(
          updateTitanApplication({
            id: "application",
            value: response?.msg?.application,
          })
        );
        setComment(response.msg?.applicationData?.[PHASE]?.comment);
        if (response?.msg?.applicationData?.phase2?.data?.[user?._id]) {
          setData(response?.msg?.applicationData?.phase2?.data?.[user?._id]);
        } else {
          setData({
            ...initialData,
          });
        }

        let progress = {};
        for (let i = 1; i <= 6; i++) {
          progress["phase" + i] =
            response?.msg?.applicationData?.["phase" + i]?.status;
        }
        progress.milestone = response?.msg?.applicationData?.milestone?.status;
        titanDispatch(
          updateTitanApplication({
            id: "progress",
            value: progress,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };
  console.log(params, "params");
  useEffect(() => {
    getApplication();
  }, []);

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  const submitComment = async () => {
    const response = await saveComment(
      { applicationId: application._id, phase: PHASE, values: comment },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(`Comment for ${"Online Review"} submitted!`, "success");
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
    if (comment) {
      submitComment();
    }
  };
  console.log(application, "applicaiton");
  //function to move to next phase automatically when moved to next round
  const [movingNextRound, setMovingNextRound] = useState(false);
  useEffect(() => {
    if (movingNextRound) {
      setActionDialogOpen(false);
      setMovingNextRound(false);
    }
  }, [movingNextRound]);

  const handleChange = (id, value) => {
    let total = 0;
    Object.keys(data).map((keyname) => {
      if (keyname == "recommend" || keyname == "total" || keyname == id) return;
      total += parseInt(data[keyname]) || 0;
    });
    total += parseInt(value) || 0;
    setData({ ...data, [id]: value, ["total"]: total });
  };

  const submitScore = async () => {
    let obj = { ...data };
    delete obj["total"];
    const response = await updatePhaseData(
      { applicationId: application._id, phase: "phase2", data: obj },
      dispatch,
      controller
    );
    if (response && response.msg.status == 200) {
      snackbarHandler(response?.msg.messsage || "Saved..", "success");
    } else {
      snackbarHandler(
        response?.msg.messsage || "Something went wrong!",
        "error"
      );
    }
  };
  const onClickBackWardArrow = () => {
    setIsLoading(true);
    let prevIndex = applicationIDIndex;
    if (prevIndex === 0) {
      prevIndex = applicationsID?.length;
    } else {
      prevIndex = applicationIDIndex;
    }
    setApplicationIDIndex(prevIndex - 1);
    setSelectedApplicationID(applicationsID[prevIndex - 1]);
    getApplication(prevIndex - 1);
    setIsLoading(false);
  };
  const onClickForWardArrow = () => {
    setIsLoading(true);
    let prevIndex = applicationIDIndex;
    if (prevIndex + 1 === applicationsID?.length) {
      prevIndex = -1;
    } else {
      prevIndex = applicationIDIndex;
    }
    setApplicationIDIndex((state) => prevIndex + 1);
    setSelectedApplicationID((state) => applicationsID[prevIndex + 1]);
    getApplication(prevIndex + 1);
    setIsLoading(false);
  };

  return (
    <>
      <DialogBody>
        {isLoading ? (
          <OwlLoader />
        ) : (
          <>
            <div
              className="closeButton"
              style={{ fontSize: "25px", marginRight: "20px" }}
              onClick={modalHandler}
            >
              {Icons.close}
            </div>
            {/* <div
					style={{
						position: "fixed",
						height: "100%",
						top: "50%",
						left: "0",
						right: "0",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						margin: '0 5px'
					}}
				>
					<div
						style={{ fontSize: "35px", cursor: "pointer", height: "35px" }}
						onClick={() => onClickBackWardArrow()}
					>
						{Icons.arrowBackword}
					</div>
					<div
						style={{ fontSize: "35px", cursor: "pointer", height: "35px" }}
						onClick={() => onClickForWardArrow()}
					>
						{Icons.arrowFormward}
					</div>
				</div> */}
            <DialogContent className="content">
              <SchemePhaseTabContainer>
                <div
                  className="title"
                  style={{
                    textAlign: "center",
                    marginBottom: "8px",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Application Name: {application?.companyName}
                </div>
                <div
                  style={{ display: "flex", gap: "2rem", alignItems: "center" }}
                >
                  <div
                    className={`statusTextApplication `}
                    style={{
                      background:
                        ApplicaitonStatusColor[
                          applicationData?.[PHASE]?.status
                        ],
                      color:
                        ApplicationStatusTextColor[
                          applicationData?.[PHASE]?.status
                        ],
                    }}
                  >
                    {ApplicationStatus[applicationData?.[PHASE]?.status] ||
                      "Pending"}
                  </div>

                  {from == "associate" ? (
                    <div
                      className="assignedText"
                      style={{ fontSize: "1.2rem" }}
                    >
                      Assigned to:{" "}
                      {assignedReviewerEmails.map((email, i) => {
                        return (
                          <span className="rName">
                            {email}{" "}
                            {assignedReviewerEmails.length != i + 1 ? ", " : ""}
                          </span>
                        );
                      })}
                      {assignedReviewerEmails.length == 0 ? "-" : ""}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <Container style={{ position: "relative", zIndex: "30" }}>
                  {from == "associate" ? (
                    <div className="title">Score</div>
                  ) : (
                    <></>
                  )}

                  {from == "associate" ? (
                    <div
                      className="content"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <div className="panel">
                        <div className="field">
                          <FormLabel className="formLabel">
                            1. Relevance
                          </FormLabel>
                          <TextField
                            className="formValue"
                            type="number"
                            inputProps={{ style: { textAlign: "center" } }}
                            value={data?.clarity}
                            size="small"
                            onChange={(e) => {
                              var value = parseInt(e.target.value, 10);
                              if (value > 10) value = 10;
                              if (value < 0) value = 0;
                              handleChange("clarity", value);
                            }}
                            disabled={from == "associate" ? false : true}
                          />
                        </div>

                        <div className="field">
                          <FormLabel className="formLabel">
                            2. Effectiveness
                          </FormLabel>
                          <TextField
                            className="formValue"
                            inputProps={{ style: { textAlign: "center" } }}
                            type="number"
                            value={data?.unmetNeed}
                            size="small"
                            onChange={(e) => {
                              var value = parseInt(e.target.value, 10);
                              if (value > 10) value = 10;
                              if (value < 0) value = 0;
                              handleChange("unmetNeed", value);
                            }}
                            disabled={from == "associate" ? false : true}
                          />
                        </div>

                        <div className="field">
                          <FormLabel className="formLabel">3. Impact</FormLabel>
                          <TextField
                            className="formValue"
                            type="number"
                            inputProps={{ style: { textAlign: "center" } }}
                            value={data?.customerPay}
                            size="small"
                            onChange={(e) => {
                              var value = parseInt(e.target.value, 10);
                              if (value > 10) value = 10;
                              if (value < 0) value = 0;
                              handleChange("customerPay", value);
                            }}
                            disabled={from == "associate" ? false : true}
                          />
                        </div>

                        <div className="field">
                          <FormLabel className="formLabel">
                            4. Originality and Innovation
                          </FormLabel>
                          <TextField
                            className="formValue"
                            inputProps={{ style: { textAlign: "center" } }}
                            type="number"
                            value={data?.novelty}
                            size="small"
                            onChange={(e) => {
                              var value = parseInt(e.target.value, 10);
                              if (value > 10) value = 10;
                              if (value < 0) value = 0;
                              handleChange("novelty", value);
                            }}
                            disabled={from == "associate" ? false : true}
                          />
                        </div>

                        <div className="field">
                          <FormLabel className="formLabel">
                            5. Presentation
                          </FormLabel>
                          <TextField
                            className="formValue"
                            type="number"
                            inputProps={{ style: { textAlign: "center" } }}
                            value={data?.techFeasibility}
                            size="small"
                            onChange={(e) => {
                              var value = parseInt(e.target.value, 10);
                              if (value > 10) value = 10;
                              if (value < 0) value = 0;
                              handleChange("techFeasibility", value);
                            }}
                            disabled={from == "associate" ? false : true}
                          />
                        </div>

                        {/* <div className="field">
                          <FormLabel className="formLabel">
                            6. Team Strength
                          </FormLabel>
                          <TextField
                            className="formValue"
                            type="number"
                            inputProps={{ style: { textAlign: "center" } }}
                            value={data?.teamStrength}
                            size="small"
                            onChange={(e) => {
                              var value = parseInt(e.target.value, 10);
                              if (value > 10) value = 10;
                              if (value < 0) value = 0;
                              handleChange("teamStrength", value);
                            }}
                            disabled={from == "associate" ? false : true}
                          />
                        </div> */}

                        <div className="field totalScore">
                          <FormLabel className="formLabel">
                            Total Score&nbsp;&nbsp; :{" "}
                          </FormLabel>
                          <TextField
                            className="formValue"
                            inputProps={{ style: { textAlign: "center" } }}
                            value={String(data?.total) + "/50"}
                            disabled
                            size="small"
                          />
                        </div>
                      </div>

                      <div className="panel rPanel">
                        <CommentActionContainer style={{ marginTop: "1rem" }}>
                          <div className="commentSection">
                            <textarea
                              readOnly={from != "associate"}
                              value={data?.comment}
                              onChange={(e) =>
                                setData({ ...data, comment: e.target.value })
                              }
                              placeholder={
                                from == "associate"
                                  ? "Add your comments.."
                                  : "Comments"
                              }
                            ></textarea>
                          </div>
                        </CommentActionContainer>

                        <div className="field">
                          <FormLabel className="recommendLabel">
                            Recommend
                          </FormLabel>
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Grid item>No</Grid>
                            <Grid item>
                              <Switch
                                disabled={from == "associate" ? false : true}
                                checked={data?.recommend}
                                onChange={(e, value) =>
                                  handleChange("recommend", value)
                                }
                              />
                            </Grid>
                            <Grid item>Yes</Grid>
                          </Grid>
                        </div>

                        <div className="">
                          <div className="action">
                            {from == "associate" ? (
                              <CustomButton
                                title="Submit Scores"
                                onClickHandler={submitScore}
                              />
                            ) : (
                              <></>
                            )}
                            {user.role.roleId !== "external_reviewer" &&
                              user.role.roleId !== "founder" && (
                                <div className="actionSection">
                                  {from == "associate" &&
                                  application?.status == "review" ? (
                                    <div
                                      className=""
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                      }}
                                    >
                                      <div className="">Action: </div>
                                      <div className="input">
                                        <Select
                                          size="small"
                                          value={selectedAction}
                                          onChange={handleActionManager}
                                        >
                                          <MenuItem value={-1}>
                                            <FormLabel>Select</FormLabel>
                                          </MenuItem>
                                          <MenuItem value={"nextRound"}>
                                            <FormLabel>
                                              Move to next round
                                            </FormLabel>
                                          </MenuItem>
                                          <MenuItem value={"reject"}>
                                            <FormLabel style={{ color: "red" }}>
                                              Reject
                                            </FormLabel>
                                          </MenuItem>
                                        </Select>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <Dialog
                                    open={actionDialogOpen}
                                    onClose={handleActionDialog}
                                  >
                                    <ActionDialog
                                      handleActionDialog={handleActionDialog}
                                      activeAction={selectedAction}
                                      application={application}
                                      getApplicationDataHandlerForManager={
                                        getApplication
                                      }
                                      phase={"phase2"}
                                      setMovingNextRound={setMovingNextRound}
                                      applicationDataVersion={
                                        applicationData?.__v
                                      }
                                    />
                                  </Dialog>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </Container>
              </SchemePhaseTabContainer>
            </DialogContent>
          </>
        )}
      </DialogBody>
    </>
  );
};

export default TablePopUp;
