import { updateNavigation } from '../reducers/navigationReducer'
import validator from 'validator'
import { SidebarLinks } from './links'

export const handleTopBarTitle = (
  dispatch,
  path,
  title = null,
  dynamicPath = null
) => {
  dispatch(
    updateNavigation({
      title: title || path.title,
      path: dynamicPath || path.link,
    })
  )
}

export const setSidebarActive = (name) => {
  try {
    sessionStorage.setItem('active-link', name)
    Object.keys(SidebarLinks).map((category) => {
      try {
        Object.keys(SidebarLinks[category].links).map((link) =>
          document.getElementById(link).classList.remove('active')
        )
      } catch (err) {}
    })
    document.getElementById(name).classList.toggle('active')
  } catch (err) {
    console.log(err)
  }
}

export class Validator {
  static validateEmail(email) {
    if (validator.isEmail(email)) return true
    return false
  }

  static isInt(string) {
    if (validator.isInt(string)) {
      return true
    }
    return false
  }
}

export const validatePasswordConstraints = (value) => {
  let err = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  }
  for (let i = 0; i < value.length; i++) {
    if (value.charAt(i) >= 'a' && value.charAt(i) <= 'z') {
      err[2] = true
    }

    if (value.charAt(i) >= 'A' && value.charAt(i) <= 'Z') {
      err[3] = true
    }

    if (value.length >= 8 && value.length <= 16) {
      err[1] = true
    }

    if (/\d/.test(value)) {
      err[5] = true
    }

    if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      err[4] = true
    }
  }
  return err
}

//function to get file name from the s3 file url
export const getFileName = (url) => {
  const stringSplit = String(url).split('/')
  const string = stringSplit[stringSplit.length - 1]
  return string.substring(0, string.length - 39)
}

//-- Script to insert commas in Indian format without decimal Places
export const inrAddCommas = (value) => {
  value = parseInt(value)
  if (isNaN(value)) {
    return 0
  } else {
    if (value != 0) {
      return (value * 1).toLocaleString('en-IN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    } else return ''
  }
}

export const camelCaseWithSpaceConverter = (str) => {
  for (let i = 0; i < str.length; i++) {
    if (str[i] >= 'A' && str[i] <= 'Z' && str[i] != ' ') {
      str = str.slice(0, i) + ' ' + str.slice(i, str.length)
      i++
    }
  }
  str = str.split('')
  str[0] = str[0].toUpperCase()
  str = str.join('')
  return str
}

export const camelCaseWithoutSpaceConverter = (str) => {
  str = str.split(' ')
  str[0] = str[0].toLowerCase()
  str = str.join('')
  return str
}
