import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Icons } from "../../../utils/mediaExports";

const SchemeViewToggle = ({active, setActive}) => {
  const navigate = useNavigate();
  const {id} = useParams();
  return (
    <Container>
      <span className={`${active == 0 ? "active" : ""}`} onClick={() => setActive(0)}>Bucket View</span>
      <span>{Icons.schemeViewToggle}</span>
      <span className={`${active == 1 ? "active" : ""}`} onClick={() => {
        setActive(1);
      }}>Progress</span>
    </Container>
  );
};

export default SchemeViewToggle;

const Container = styled.div`
    width: max-content;
    display: flex;
    gap: 0.5rem;

    span {
        cursor: pointer;

        &:hover {
            color: var(--primary);
        }
    }
    
    .active {
        color: var(--primary);
    }
`;