import { TextField } from "@mui/material";
import styled from "styled-components";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import { useEffect, useState } from "react";
import { rdOptions } from "./ScoreView";

import { Radar as RD } from "react-chartjs-2";
import { globalSelector } from "../../providers/GlobalProvider";

const CoreDetailsView = ({ data }) => {
  const [dataChart, setDataChart] = useState([0, 0, 0]);
  const { imm } = globalSelector((state) => state.startup.imm);

  var rdData = {
    labels: ["People", "Process", "Product"],
    datasets: [
      {
        label: " ",
        fill: true,
        backgroundColor: "rgba(136, 132, 216, 0.7)",
        data: dataChart,
        hoverBackgroundColor: ["#8389ae", "#FDFDFD"],
      },
    ],
  };

  useEffect(() => {
    let datachart = [...dataChart];
    datachart[0] = data?.imm?.people || 0;
    datachart[1] = data?.imm?.process || 0;
    datachart[2] = data?.imm?.product || 0;
    setDataChart([...datachart]);
  }, []);

  useEffect(() => {
    let datachart = [...dataChart];
    datachart[0] = imm?.people?.value || 0;
    datachart[1] = imm?.process?.value || 0;
    datachart[2] = imm?.product?.value || 0;
    setDataChart([...datachart]);
  }, [imm]);

  return (
    <Container>
      <div className="leftPanel">
        <div className="row">
          <TextField
            className="textbox"
            label={"Company Name"}
            size="small"
            value={data?.companyName || " "}
            fullWidth
          />
          <TextField
            className="textbox"
            label={"Company Status"}
            size="small"
            value={data?.companyStatus || " "}
          />
        </div>

        <div className="row">
          <TextField
            className="textbox"
            label={"About Company"}
            size="small"
            value={data?.aboutCompany || " "}
            fullWidth
            multiline
            rows={3}
          />
        </div>

        <div className="row">
          <TextField
            className="textbox"
            label={"Brand Name"}
            size="small"
            value={data?.brandName || " "}
          />
          <TextField
            className="textbox"
            label={"Founders"}
            size="small"
            value={data?.founders || " "}
            fullWidth
            multiline
          />
        </div>

        <div className="row">
          <TextField
            className="textbox"
            label={"Female Founder"}
            size="small"
            value={data?.femaleFounder?.toString() || " "}
          />
          <TextField
            className="textbox"
            label={"Domain & Sector"}
            size="small"
            value={data?.domainAndSector || " "}
            fullWidth
            multiline
          />
        </div>

        <div className="row">
          <TextField
            className="textbox"
            label={"Date of Incorporation"}
            size="small"
            value={
              data?.registrationDate
                ? new Date(data?.registrationDate).toLocaleDateString()
                : " "
            }
          />
          <TextField
            className="textbox"
            label={"IKP Association"}
            size="small"
            value={data?.ikpAssociation || " "}
            fullWidth
            multiline
          />
        </div>

        <div className="row">
          <TextField
            className="textbox"
            label={"Address"}
            size="small"
            value={data?.officeAddress || " "}
            fullWidth
            multiline
            rows={3}
          />
        </div>

        <div className="row">
          <TextField
            className="textbox"
            label={"State"}
            size="small"
            value={data?.state || " "}
          />
          <TextField
            className="textbox"
            label={"City"}
            size="small"
            value={data?.city || " "}
          />
          <TextField
            className="textbox"
            label={"Pincode"}
            size="small"
            value={data?.pincode || " "}
          />
        </div>

        <div className="row">
          <TextField
            className="textbox"
            label={"Email IDs"}
            size="small"
            value={data?.emails || " "}
            fullWidth
            multiline
          />
        </div>

        <div className="row">
          <TextField
            className="textbox"
            label={"Phone Number"}
            size="small"
            value={data?.phoneNumber || " "}
            fullWidth
            multiline
          />
          <TextField
            className="textbox"
            label={"Website"}
            size="small"
            value={data?.website || " "}
            fullWidth
            multiline
          />
        </div>
      </div>

      <div className="rightPanel">
        <div className="radarChart">
          <RD data={rdData} id="canvas" options={rdOptions} />
        </div>
      </div>
    </Container>
  );
};

export default CoreDetailsView;

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;

  .leftPanel {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2rem;

    .row {
      display: flex;
      gap: 2rem;
    }
  }

  .rightPanel {
    width: 500px;

    .radarChartContainer {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 1300px) {
    flex-direction: column-reverse;

    .rightPanel {
      width: 100%;

      .radarChartContainer {
        height: 300px;
        width: 100%;
      }
    }
  }
`;
