import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import {
	Checkbox,
	ListSubheader,
	TextField,
	InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	autoFocus: false,
};

export default function MultipleSelectCheckmarks({
	Title,
	setState,
	data,
	selectedOptions,
}) {
	const [personName, setPersonName] = React.useState([]);
	const [searchText, setSearchText] = React.useState("");
	const [displayData, setDisplayData] = React.useState();
	const [isSelectAll, setIsSelectAll] = React.useState(false);
	const selectRef = React.useRef(null);

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		if (value?.includes("selectAll")) {
			if (!isSelectAll) {
				setPersonName(data?.map((name) => name.abbr));
				setIsSelectAll(true);
			} else {
				setPersonName([]);
				setIsSelectAll(false);
			}
		} else setPersonName(typeof value === "string" ? value.split(",") : value);
	};

	React.useEffect(() => {
		setState(personName);
	}, [personName]);
	React.useEffect(() => {
		setPersonName([]);
	}, [selectedOptions]);
	React.useEffect(() => {
		if (searchText === "") {
			setDisplayData(data);
		} else {
			setDisplayData(
				data?.filter(
					(name) =>
						name.abbr.toLowerCase().indexOf(searchText.toLowerCase()) > -1
				)
			);
		}
	}, [searchText, data]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (selectRef.current && !selectRef.current.contains(event.target)) {
				setDisplayData(data);
			}
		};
		window.addEventListener("click", handleClickOutside);
		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	});
	return (
		<div>
			<FormControl sx={{ m: 1, ml: 0, width: 300 }}>
				<InputLabel id="demo-multiple-checkbox-label">{Title}</InputLabel>
				<Select
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					multiple
					value={personName}
					onChange={handleChange}
					input={<OutlinedInput label={Title} />}
					renderValue={(selected) => selected.join(", ")}
					MenuProps={MenuProps}
				>
					<ListSubheader>
						<TextField
							size="small"
							autoFocus
							placeholder="Type to search..."
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							onChange={(e) => setSearchText(e.target.value)}
							onKeyDown={(e) => {
								if (e.key !== "Escape") {
									e.stopPropagation();
								}
							}}
						/>
					</ListSubheader>
					<MenuItem key={"selectAll"} value={"selectAll"} ref={selectRef}>
						<Checkbox checked={isSelectAll} />
						<ListItemText primary={"Select All"} />
					</MenuItem>
					{displayData?.map((name) => (
						<MenuItem key={name?.abbr} value={name?.abbr} ref={selectRef}>
							<Checkbox checked={personName.includes(name?.abbr)} />
							<ListItemText
								primary={name?.abbr}
								style={{ whiteSpace: "wrap" }}
							/>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}
