import { combineReducers, configureStore } from '@reduxjs/toolkit'
import TitanTabsReducer1 from '../../../reducers/schemes/titan/tabs'
import TitanApplication from '../../../reducers/schemes/titan/applications'
import OnlineReviewApplications from '../../../reducers/schemes/titan/onlineReviewApplications'
// import BigPathNav from "../../../reducers/schemes/big/pathNav";

const combinedReducer = combineReducers({
  titanTabs1: TitanTabsReducer1,
  application: TitanApplication,
  reviewApplications: OnlineReviewApplications,
})

const rootReducer = (state, action) => {
  //if logout is performed then reset all states
  //   if (action.type == "navigation/updateAuth") {
  //     if (action.payload.isAuthenticated == false) {
  //       state = undefined;
  //     }
  //   }
  return combinedReducer(state, action)
}

const TitanSchemeStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
})

export default TitanSchemeStore
