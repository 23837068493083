import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  approveAmount,
  checkApplication,
  saveComment,
  uploadAgreement,
} from "../../../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import { TitanDispatch } from "../../../../../../../providers/scheme/titan/TitanProvider";
import { updateTitanApplication } from "../../../../../../../reducers/schemes/titan/applications";
import {
  ApplicaitonStatusColor,
  ApplicationStatus,
  ApplicationStatusTextColor,
} from "../../../../../../../utils/constants";
import { getFileName, inrAddCommas } from "../../../../../../../utils/helperHandler";
import { Icons } from "../../../../../../../utils/mediaExports";
import CustomButton from "../../../../../../customComponents/CustomButton";
import PdfPopUp from "../../../../../../customComponents/pdfPopUp";
import {
  applicanteeSideRole,
  applicationSideRole,
} from "../../../../../customComponents/CustomTabView";
import ActionDialog from "../../../../big/phases/applicant/applicationAction/ActionModal";
import { SchemePhaseTabContainer } from "../../../../big/phases/ikp/Applications";
import { FileArea } from "../../../../widgets/FormBuilder";

const PHASE = "phase6";

const editAccess = ["program_manager", "super_admin"];
const viewAccess = ["program_manager", "founder", "program_associate", "super_admin"];

const Agreement = ({ call, from, setActive }) => {
  const { user } = globalSelector((state) => state.auth);
  const role = user.role.roleId;

  const controller = new AbortController();
  const dispatch = globalDispatch();
  const titanDispatch = TitanDispatch();
  const params = useParams();

  const [application, setApplication] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [comment, setComment] = useState("");

  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const isProgramAssociate = applicanteeSideRole.includes(user.role.roleId);
  const isFounder = applicationSideRole.includes(user.role.roleId);

  const { enqueueSnackbar } = useSnackbar();

  const [approvedAmount, setApprovedAmount] = useState(0);
  const [fileLoading, setFileLoading] = useState(false);

  const [agreement, setAgreement] = useState(null);

  const [openDocument, setOpenDocument] = useState(false);

  //function to move to next phase automatically when moved to next round
  const [movingNextRound, setMovingNextRound] = useState(false);
  useEffect(() => {
    if (movingNextRound) {
      setActionDialogOpen(false);
      setActive(6);
      setMovingNextRound(false);
    }
  }, [movingNextRound]);

  //function to get application details and application phase data
  const getApplication = async () => {
    let bodyData = {};
    if (isProgramAssociate) {
      bodyData = {
        fromIkp: true,
        applicationId: params.applicationId,
      };
    } else if (isFounder) {
      bodyData = { callId: call._id };
    }

    try {
      const response = await checkApplication(bodyData, dispatch, controller);
      if (response && response.status == 200) {
        setApplication(response.msg?.application);
        titanDispatch(updateTitanApplication({
          id: "application",
          value: response?.msg?.application,
        }));
        setApprovedAmount(response?.msg?.application?.approvedAmount || 0);
        setAgreement(
          response?.msg?.applicationData?.[PHASE]?.data?.agreementDocument ||
            null
        );
        setApplicationData(response.msg?.applicationData);
        setComment(response.msg?.applicationData?.[PHASE]?.comment);
        let progress = {};
        for (let i = 1; i <= 6; i++) {
          progress["phase" + i] =
            response?.msg?.applicationData?.["phase" + i]?.status;
        }
        progress.milestone = response?.msg?.applicationData?.milestone?.status;
        titanDispatch(
          updateTitanApplication({
            id: "progress",
            value: progress,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getApplication();
  }, []);

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
    if (comment) {
      submitComment();
    }
  };

  const submitComment = async () => {
    const response = await saveComment(
      { applicationId: application._id, phase: PHASE, values: comment },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(
        `Comment for ${"Agreement Signing"} submitted!`,
        "success"
      );
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  //function to update approved amount with api request
  const approveAmountHandler = async () => {
    let amount = approvedAmount;
    try {
      amount = parseInt(amount);
    } catch (e) {
      snackbarHandler("Invalid number!", "error");
      return;
    }
    const response = await approveAmount(
      { amount: amount, applicationId: application._id },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      snackbarHandler(response?.msg || "Saved successfully..", "success");
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const handleFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("applicationId", application?._id);
    const response = await uploadAgreement(formData, dispatch, controller);
    if (response && response.status == 200) {
      snackbarHandler("Agreement Uploaded..", "success");
      setAgreement(response?.msg);
      const url = response?.msg;
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const handleApproveAmt = (value) => {
    setApprovedAmount(value);
  };

  if (!viewAccess.includes(role)) {
    return <></>;
  }

  return (
    <SchemePhaseTabContainer>
      <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
        <div
          className={`statusTextApplication `}
          style={{
            background:
              ApplicaitonStatusColor[applicationData?.[PHASE]?.status],
            color: ApplicationStatusTextColor[applicationData?.[PHASE]?.status],
          }}
        >
          {ApplicationStatus[applicationData?.[PHASE]?.status] || "Pending"}
        </div>

        {from == "associate" ? (
          <div className="assignedText" style={{ fontSize: "1.2rem" }}>
            Assigned to:{" "}
            {application?.reviewer?.map((person, i) => {
              return (
                <span className="rName">
                  {person.name}{" "}
                  {application?.reviewer?.length != i + 1 ? ", " : ""}
                </span>
              );
            })}
            {application?.reviewer?.length == 0 ? "-" : ""}
          </div>
        ) : (
          <></>
        )}
      </div>

      <ProjectAmountContainer>
        <div>
          <div>
            <InputLabel htmlFor="outlined-adornment-amount">
              Approved Amount{" "}
              {
                editAccess.includes(role) ? <span className="smalltext">(upto: ₹{inrAddCommas(call?.totalBudget || "0")})</span> : <></>
              }
            </InputLabel>
            <OutlinedInput
              title=""
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              size="small"
              value={inrAddCommas(approvedAmount)}
              onChange={(e) => {
                let value = e.target.value;
                value = value.replaceAll(",", "");
                if (value < 0) {
                  value = 0;
                } else if (value > call?.totalBudget) {
                  value = call?.totalBudget;
                }
                handleApproveAmt(parseInt(value));
              }}
              readOnly={!editAccess.includes(role)}
              endAdornment={<InputAdornment position="end"></InputAdornment>}
            />
          </div>

          {editAccess.includes(role) ? (
            <div className="action">
              <CustomButton
                title="Submit"
                onClickHandler={approveAmountHandler}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div>
          <InputLabel htmlFor="outlined-adornment-amount">
            Agreement Document
          </InputLabel>

          <div className="fileUploader" style={{minHeight: "3rem"}}>
            <FileUploader
              classes="component"
              handleChange={(file) => handleFile(file)}
              name="file"
              types={["PDF"]}
              multiple={false}
              children={
                editAccess.includes(role) ? 
                <FileArea>
                  <div className="message">
                    <div>Drag and Drop</div>
                    <div>Or</div>
                    <div>Browse for files</div>
                    <CircularProgress
                      style={{
                        display: fileLoading ? "block" : "none",
                      }}
                      size={20}
                    />
                  </div>
                </FileArea>
              : <></>}
            />
            <div className="files">
              {agreement ? (
                <div className="file mt1">
                  <Chip
                    label={
                      <div
                        className="filename"
                        onClick={() => setOpenDocument(true)}
                      >
                        {getFileName(agreement)}
                      </div>
                    }
                    onDelete={() => {
                      setAgreement(null);
                    }}
                    deleteIcon={from == "associate" ? Icons.delete : <></>}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </ProjectAmountContainer>

      <CommentActionContainer>
        <div className="commentSection">
          <textarea
            readOnly={from != "associate"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Add your comments.."
          ></textarea>
          {from == "associate" ? (
            <Button
              className="muiOutlinedBtn"
              variant="outlined"
              onClick={submitComment}
            >
              Save
            </Button>
          ) : null}
        </div>

        {from == "associate" && application?.status == "agreement" ? (
          <div className="actionSection">
            <div className="actions">
              <div className="title">Action: </div>
              <div className="input">
                <Select
                  size="small"
                  value={selectedAction}
                  onChange={handleActionManager}
                >
                  <MenuItem value={-1}>
                    <FormLabel>Select</FormLabel>
                  </MenuItem>
                  <MenuItem value={"nextRound"}>
                    <FormLabel>Move to next round</FormLabel>
                  </MenuItem>
                  <MenuItem value={"reject"}>
                    <FormLabel style={{ color: "red" }}>Reject</FormLabel>
                  </MenuItem>
                </Select>
              </div>
            </div>

            <Dialog open={actionDialogOpen} onClose={handleActionDialog}>
              <ActionDialog
                handleActionDialog={handleActionDialog}
                activeAction={selectedAction}
                application={application}
                getApplicationDataHandlerForManager={getApplication}
                phase={PHASE}
                setMovingNextRound={setMovingNextRound}
                applicationDataVersion={applicationData?.__v}
              />
            </Dialog>

            
          </div>
        ) : (
          <></>
        )}
      </CommentActionContainer>

      <PdfPopUp closePdfView={() => setOpenDocument(false)} url={agreement} pdfDialogOpen={openDocument} />
    </SchemePhaseTabContainer>
  );
};

export default Agreement;

export const CommentActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  gap: 3rem;
  flex-wrap: wrap;

  .commentSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    textarea {
      resize: none;
      min-width: 300px;
      height: 150px;
      padding: 1rem;
      font-size: 1.1rem;
      outline: none;
    }

    button {
      width: max-content;
      margin: 0 auto;
      padding: 0.3rem 1.5rem;
    }
  }
`;

export const ProjectAmountContainer = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 5rem;
  margin-top: 1rem;

  .action {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 0.5rem;
  }

  .fileUploader {
    border: 1px solid var(--text2);
    border-style: dashed;
    border-radius: 8px;
    min-width: 15rem;
    padding: 0.5rem 0;
  }
`;
