import { updateAuth } from '../../../reducers/auth/authReducer'
import { getRequest, postRequest } from '../../../utils/axiosClient/axios'
import { logout } from './user'

export const getToken = () => {
  const token = localStorage.getItem('access-token')
  if (!token) {
    return null
  }
  return token
}

export const formSubmit = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/startups/submit',
    data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}

export const formEdit = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/startups/startupEdit',
    data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}

export const fetchAllStartups = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/startups/fetchAllStartups',
    data,
    headers: {
      'access-token': token,
    },
    controller,
    dispatch,
  })
  return response
}

export const fetchStartups = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/startups/fetchStartups',
    data,
    headers: {
      'access-token': token,
    },
    controller,
    dispatch,
  })
  return response
}

export const uploadFile = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/aws/uploadSingleFile',
    data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}

export const getStartupData = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/startups/fetchStartupData',
    data,
    controller,
    dispatch,
    headers: {
      'access-token': token,
    },
  })
  return response
}

export const bulkUpload = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/startups/bulkUpload',
    data,
    controller,
    dispatch,
    headers: {
      'access-token': token,
    },
  })
  return response
}

export const fetchIndustries = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await getRequest({
    endpoint: '/startups/industries',
    params: data,
    controller,
    dispatch,
    headers: {
      'access-token': token,
    },
  })

  return response
}

export const tagsQuery = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/startups/searchTags',
    data,
    controller,
    dispatch,
    headers: {
      'access-token': token,
    },
  })
  return response
}

export const fetchCompanies = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/startups/searchCompanies',
    data,
    controller,
    dispatch,
    headers: {
      'access-token': token,
    },
  })
  return response
}

export const fetchStats = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await getRequest({
    endpoint: '/startups/stats',
    params: data,
    controller,
    dispatch,
    headers: {
      'access-token': token,
    },
  })

  return response
}

export const saveTags = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/startups/saveTags',
    data,
    controller,
    dispatch,
    headers: {
      'access-token': token,
    },
  })
  return response
}

export const fetchPopularTags = async (data, dispatch, controller) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await getRequest({
    endpoint: '/startups/popularTags',
    params: data,
    controller,
    dispatch,
    headers: {
      'access-token': token,
    },
  })

  return response
}
