import { combineReducers, configureStore } from '@reduxjs/toolkit'
import PrayasTabsReducer1 from '../../../reducers/schemes/prayas/tabs'
import PrayasApplication from '../../../reducers/schemes/prayas/applications'
import OnlineReviewApplications from '../../../reducers/schemes/prayas/onlineReviewApplications'
// import BigPathNav from "../../../reducers/schemes/big/pathNav";

const combinedReducer = combineReducers({
  prayasTabs1: PrayasTabsReducer1,
  application: PrayasApplication,
  reviewApplications: OnlineReviewApplications,
})

const rootReducer = (state, action) => {
  //if logout is performed then reset all states
  //   if (action.type == "navigation/updateAuth") {
  //     if (action.payload.isAuthenticated == false) {
  //       state = undefined;
  //     }
  //   }
  return combinedReducer(state, action)
}

const PrayasSchemeStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
})

export default PrayasSchemeStore
