import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  checkApplication,
  getAppicationData,
} from "../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../providers/GlobalProvider";
import { BigSelector } from "../../../providers/scheme/big/BigProvider";
import GlobalStore from "../../../store/globalStore";
import Agreement from "../ikp/big/phases/applicant/agreement/Agreement";
import ApplicantApplicationSide from "../ikp/big/phases/applicant/Application";
import Award from "../ikp/big/phases/applicant/award/Award";
import DueDiligence from "../ikp/big/phases/applicant/dueDiligence/DueDiligence";
import EligibilityCheck from "../ikp/big/phases/applicant/eligibilityCheck/EligibilityCheck";
import Milestone from "../ikp/big/phases/applicant/milestone/Milestone";
import Review from "../ikp/big/phases/applicant/review/Review";
import Selection from "../ikp/big/phases/applicant/selection/Selection";
import TEP from "../ikp/big/phases/applicant/tep/TEP";
import Applications from "../ikp/big/phases/ikp/Applications";
import CustomSchemeTabs from "./CustomSchemeTab";

export const applicanteeSideRole = [
  "super_admin",
  "program_manager",
  "program_associate",
  "external_reviewer"
];
export const applicationSideRole = ["founder"];

const CustomTabView = ({ from, active, call, setActive }) => {
  const user = GlobalStore.getState().auth.user;

  const { applicationId, id } = useParams();

  const Tab = () => {
    if (
      applicationSideRole.includes(user.role.roleId) ||
      (applicationId && applicanteeSideRole.includes(user.role.roleId))
    ) {
      switch (active) {
        case 0:
          return <ApplicantApplicationSide call={call} from={from} setActive={setActive} />;
        case 1:
          return <EligibilityCheck call={call} from={from} setActive={setActive} />;
        case 2:
          return <Review call={call} from={from} setActive={setActive} />;
        case 3:
          return <Selection call={call} from={from} setActive={setActive} />;
        case 4:
          return <TEP call={call} from={from} setActive={setActive} />;
        case 5:
          return <Award call={call} from={from} setActive={setActive} />;
        case 6:
          return <DueDiligence call={call} from={from} setActive={setActive} />;
        case 7:
          return <Agreement call={call} from={from} setActive={setActive} />;
        case 8:
          return <Milestone call={call} from={from} setActive={setActive} />;
      }
    } else if (applicanteeSideRole.includes(user.role.roleId)) {
          return <Applications call={call} active={active} />;
    }
  };

  return (
    <Container>
      {from == "associate" ? (
        <CustomSchemeTabs
          active={active}
          setActive={setActive}
          isFounder={true}
          afterApplicationSelect={true}
          from={from}
          scheme={"big"}
        />
      ) : (
        <></>
      )}
      <Tab />
    </Container>
  );
};

export default CustomTabView;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
