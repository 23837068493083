import { useEffect, useState } from "react";
import styled from "styled-components";
import { PasswordConstraintsText } from "../../utils/constants";
import { validatePasswordConstraints } from "../../utils/helperHandler";
import { Icons } from "../../utils/mediaExports";

const PasswordConstraints = ({ value }) => {
  const [passwordConstraints, setPasswordConstraints] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  const handlePasswordConstraints = () => {
    const err = validatePasswordConstraints(value);
    setPasswordConstraints({ ...err });
  };

  useEffect(() => {
    handlePasswordConstraints();
  }, [value])

  return (
    <Container>
      {Object.keys(PasswordConstraintsText).map((id, i) => (
        <span key={i}>
          <span
            className="icon"
            style={{
              color: !passwordConstraints[id]
                ? "var(--errorTextColor)"
                : "var(--successTextColor)",
            }}
          >
            {!passwordConstraints[id] ? Icons.xCircle : Icons.checkMark}
          </span>
          {PasswordConstraintsText[id]}
        </span>
      ))}
    </Container>
  );
};

export default PasswordConstraints;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .icon {
      color: var(--errorTextColor);
      font-size: 1rem;
    }
  }
`;
