import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import { downloadFile } from "../../data/network/api/download";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
import { DialogBody } from "../../utils/customStyledComponents";
import { getFileName } from "../../utils/helperHandler";
import { Icons } from "../../utils/mediaExports";

const PdfPopUp = ({ url, pdfDialogOpen, closePdfView }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [fileType, setFileType] = useState("pdf");

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }
  const handlePrev = () => {
    if (pageNumber == 1) return;
    setPageNumber(pageNumber - 1);
  };
  const handleNext = () => {
    if (pageNumber == numPages) return;
    setPageNumber(pageNumber + 1);
  };

  const closePdf = (e, reason) => {
    if (reason == "backdropClick") return;
    closePdfView();
  }

  useEffect(() => {
    try {
      setNumPages(null);
      setPageNumber(1);
      const fileName = getFileName(url);
      const ext = fileName.split(".");
      const extension = ext[ext.length - 1];
      setFileType(extension);
    } catch (e) {console.log(e)}
  }, [url]);

  const initiateDownload = async () => {
    downloadFile(url);
  };

  const DisplayFile = ({ url }) => {
    switch (fileType) {
      case "pdf":
        return (
          <div className="pdfView">
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={pageNumber}
                className="pdfPage"
                renderAnnotationLayer={null}
                renderTextLayer={false}
                devicePixelRatio={3}
              />
            </Document>
          </div>
        );

      case "png":
      case "svg":
      case "jpg":
      case "jpeg":
      case "gif":
      case "avif":
        return (
          <div className="imageView">
            <img src={url} />
          </div>
        );

      case "mp4": 
      return (
        <div className="videoView">
          <video controls controlsList="nodownload">
            <source src={url} />
          </video>
        </div>
      )
    }
  };

  return (
    <Dialog
      open={pdfDialogOpen}
      onClose={closePdf}
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1400px", // Set your width here
          },
        },
      }}
    >
      <DialogBody>
        <DialogTitle className="dialogTitle pdfTitle">
          <span className="bold">{getFileName(url) || ""} </span>{" "}
          <div className="closeButton closePdfViewer" onClick={closePdf}>
            {Icons.close}
          </div>
        </DialogTitle>

        {fileType == "pdf" ? (
          <>
            {" "}
            <span className="arrowLeft arrow" onClick={handlePrev}>
              {Icons.arrowFormward}
            </span>
            <span className="arrowRight arrow" onClick={handleNext}>
              {Icons.arrowFormward}
            </span>
          </>
        ) : null}

        <DialogContent
          className={`content ${fileType == "pdf" ? "contentTop" : ""}`}
        >
          <DisplayFile url={url} />
        </DialogContent>

        {fileType == "pdf" ? (
          <FormLabel className="slideLabel">
            Slide: {pageNumber}/{numPages}
          </FormLabel>
        ) : null}

        <FormLabel
          className="download"
          onClick={() => {
            initiateDownload();
          }}
        >
          {Icons.download}
        </FormLabel>
      </DialogBody>
    </Dialog>
  );
};
export default PdfPopUp;
