import { createSlice } from '@reduxjs/toolkit'

export const StartupFormDataSlice = createSlice({
  name: 'startup',
  initialState: {
    summary: {
      summary: {
        companyName: {
          error: '',
          value: '',
          active: true,
          mandatory: true,
        },
        aboutCompany: {
          error: '',
          value: '',
          active: true,
          mandatory: false,
        },
        hashtag: {
          error: '',
          value: [],
          active: true,
          mandatory: false,
        },
        maturityLevel: {
          error: '',
          value: '',
          active: true,
          mandatory: false,
        },
        fundsRaised: {
          error: '',
          value: '',
          active: true,
          mandatory: false,
        },
        revenueGenerated: {
          error: '',
          value: '',
          active: true,
          mandatory: false,
        },
        recognitionsAndAwards: {
          error: '',
          value: '',
          active: true,
          mandatory: false,
        },
        companyStatus: {
          error: '',
          value: -1,
          active: true,
          mandatory: false,
        },
        remarks: {
          error: '',
          value: '',
          active: true,
          mandatory: false,
        },
        founders: {
          error: '',
          value: [],
          active: true,
          mandatory: false,
        },
        brandName: {
          error: '',
          value: '',
          active: true,
          mandatory: false,
        },
        femaleFounder: {
          error: '',
          value: false,
          active: true,
          mandatory: false,
        },
        ikpAssociation: {
          error: '',
          value: [],
          active: true,
          mandatory: false,
        },
        productGallery: {
          error: '',
          value: [],
          active: true,
          mandatory: false,
        },
        productDescription: {
          error: '',
          value: '',
          active: true,
          mandatory: false,
        },
        associationData: {
          error: '',
          value: [],
          active: true,
          mandatory: false,
        },
      },
    },
    imm: {
      imm: {
        people: {
          error: '',
          value: 0,
          mandatory: false,
          active: true,
        },
        process: {
          error: '',
          value: 0,
          mandatory: false,
          active: true,
        },
        product: {
          error: '',
          value: 0,
          mandatory: false,
          active: true,
        },
      },
    },
    companyDetails: {
      companyDetails: {
        domainAndSector: {
          error: '',
          value: [],
          mandatory: false,
          active: true,
        },
        gst: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        registrationDate: {
          error: '',
          value: new Date(),
          mandatory: false,
          active: true,
        },
        cin: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        tan: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        pan: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        indian: {
          error: '',
          value: 'indian',
          mandatory: false,
          active: true,
        },
        hasParentCompany: {
          error: '',
          value: 'no',
          mandatory: false,
          active: true,
        },
        parentCompanyName: {
          error: '',
          value: '',
          mandatory: false,
          active: false,
        },
        parentRegistrationDate: {
          error: '',
          value: new Date(),
          mandatory: false,
          active: false,
        },
        parentCin: {
          error: '',
          value: '',
          mandatory: false,
          active: false,
        },
        parentTan: {
          error: '',
          value: '',
          mandatory: false,
          active: false,
        },
        parentPan: {
          error: '',
          value: '',
          mandatory: false,
          active: false,
        },
      },
    },
    productShowcase: {
      productDetails: {
        array: [],
        productName: {
          error: '',
          value: '',
          active: true,
        },
        writeUp: {
          error: '',
          value: '',
          active: true,
        },
        workflow: {
          error: '',
          value: '',
          active: true,
        },
        technicalData: {
          error: '',
          value: '',
          active: true,
        },
        specifications: {
          error: '',
          value: '',
          active: true,
        },
        trl: {
          error: '',
          value: -1,
          active: true,
        },
        productGallery: {
          error: '',
          value: [],
          active: true,
        },
      },
      ipDetails: {
        array: [],
        applicant: {
          error: '',
          value: '',
          active: true,
        },
        collaborator: {
          error: '',
          value: '',
          active: true,
        },
        licensed: {
          error: '',
          value: '',
          active: true,
        },
        jointly: {
          error: '',
          value: '',
          active: true,
        },
      },
      patents: {
        array: [],
        patentStatus: {
          error: '',
          value: '',
          active: true,
        },
        patentNumber: {
          error: '',
          value: '',
          active: true,
        },
        patentTitle: {
          error: '',
          value: '',
          active: true,
        },
        productName: {
          error: '',
          value: '',
          active: true,
        },
        productDetails: {
          error: '',
          value: '',
          active: true,
        },
        jurisdiction: {
          error: '',
          value: '',
          active: true,
        },
        similarPatent: {
          error: '',
          value: '',
          active: true,
        },
      },
      copyrights: {
        array: [],
        name: {
          error: '',
          value: '',
          active: true,
        },
        registration: {
          error: '',
          value: new Date(),
          active: true,
        },
        expiry: {
          error: '',
          value: new Date(),
          active: true,
        },
        currentStatus: {
          error: '',
          value: '',
          active: true,
        },
        type: {
          error: '',
          value: '',
          active: true,
        },
        description: {
          error: '',
          value: '',
          active: true,
        },
      },
    },
    process: {
      legalDocuments: {
        memorandumOfAssociation: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        articleOfAssociation: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        gstCertification: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        shareholderAgreement: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        professionalTax: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        incorporationCertificate: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        shareholderPattern: {
          error: '',
          value: {
            indian: '0',
            foreign_nri: '0',
            foreign_individual: '0',
          },
          mandatory: false,
          active: true,
        },
      },
      certifications: {
        fssai: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        iso: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        ce: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        fda: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        dcgi: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        rcgm: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        nba: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
        pcba: {
          error: '',
          value: {},
          mandatory: false,
          active: true,
        },
      },
    },
    financials: {
      grants: {
        array: [],
        grantName: {
          error: '',
          value: '',
          active: true,
        },
        projectTitle: {
          error: '',
          value: '',
          active: true,
        },
        projectSummary: {
          error: '',
          value: '',
          active: true,
        },
        totalGrantAmount: {
          error: '',
          value: '',
          active: true,
        },
        fundingAgency: {
          error: '',
          value: '',
          active: true,
        },
        projectManagementAgency: {
          error: '',
          value: '',
          active: true,
        },
        presentProjectStatus: {
          error: '',
          value: '',
          active: true,
        },
        startDate: {
          error: '',
          value: new Date(),
          active: true,
        },
        completionDate: {
          error: '',
          value: new Date(),
          active: true,
        },
      },
      investments: {
        array: [],
        dateInvestmentRaised: {
          error: '',
          value: new Date().toString(),
          active: true,
        },
        investmentKind: {
          error: '',
          value: -1,
          active: true,
        },
        instrumentType: {
          error: '',
          value: '',
          active: true,
        },
        terms: {
          error: '',
          value: '',
          active: true,
        },
        valuation: {
          error: '',
          value: '',
          active: true,
        },
        participants: {
          error: '',
          value: '',
          active: true,
        },
        exits: {
          error: '',
          value: '',
          active: true,
        },
        series: {
          error: '',
          value: '',
          active: true,
        },
      },
      awards: {
        array: [],
        agency: {
          error: '',
          value: '',
          active: true,
        },
        prize: {
          error: '',
          value: '',
          active: true,
        },
        benefits: {
          error: '',
          value: '',
          active: true,
        },
      },
      auditedFinancialStatement: {
        array: [],
        year: {
          error: '',
          value: '',
          active: true,
        },
        annualReport: {
          error: '',
          value: '',
          active: true,
        },
        balanceSheet: {
          error: '',
          value: '',
          active: true,
        },
        revenue: {
          error: '',
          value: '',
          active: true,
        },
        books: {
          error: '',
          value: '',
          active: true,
        },
      },
    },
    people: {
      people: {
        array: [],
        name: {
          error: '',
          value: '',
          active: true,
        },
        designation: {
          error: '',
          value: '',
          active: true,
        },
        gender: {
          error: '',
          value: -1,
          active: true,
        },
        educationQualification: {
          error: '',
          value: -1,
          active: true,
        },
        workExperience: {
          error: '',
          value: '',
          active: true,
        },
        companiesWorked: {
          error: '',
          value: [],
          active: true,
        },
        emailId: {
          error: '',
          value: '',
          active: true,
        },
        phone: {
          error: '',
          value: '',
          active: true,
        },
        pan: {
          error: '',
          value: '',
          active: true,
        },
        aadhar: {
          error: '',
          value: '',
          active: true,
        },
        din: {
          error: '',
          value: '',
          active: true,
        },
        shareholding: {
          error: '',
          value: '',
          active: true,
        },
        esop: {
          error: '',
          value: '',
          active: true,
        },
        profitShare: {
          error: '',
          value: '',
          active: true,
        },
        org: {
          error: '',
          value: '',
          active: true,
        },
        keyContacts: {
          error: '',
          value: '',
          active: true,
        },
      },
    },
    association: {
      mentors: {
        array: [],
        _id: {
          mandatory: false,
          active: true,
          value: null,
          error: '',
        },
        name: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
          options: [],
        },
        sector: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        mentoredCompanies: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        specialization: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        feesCharged: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        advisoryShares: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
      },
      investors: {
        array: [],
        _id: {
          mandatory: false,
          active: true,
          value: null,
          error: '',
        },
        name: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
          options: [],
        },
        investedSectors: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        investedCompanies: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        proficiency: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        ticketSize: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        investmentInCurrentCompany: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
      },
      incubators: {
        array: [],
        _id: {
          mandatory: false,
          active: true,
          value: null,
          error: '',
        },
        name: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
          options: [],
        },
        address: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        since: {
          error: '',
          value: new Date(),
          mandatory: false,
          active: true,
        },
        mou: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        focusAreas: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        saveBtn: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
      },
      vendors: {
        array: [],
        _id: {
          mandatory: false,
          active: true,
          value: null,
          error: '',
        },
        name: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
          options: [],
        },
        natureOfWork: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
      },
    },
    jobOpenings: {
      jobPostings: {
        array: [],
        jobTitle: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        jobDescription: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        link: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
      },
    },
    planOfAction: {
      planOfAction: {
        array: [],
        duration: {
          error: '',
          value: -1,
          mandatory: false,
          active: true,
        },
        outcome: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        future: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        businessPlan: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        kpi: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        okr: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        saveBtn: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
      },
    },
    contact: {
      contact: {
        phoneNumber: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        emails: {
          error: '',
          value: [],
          mandatory: false,
          active: true,
        },
        website: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        officeAddress: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        city: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        state: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        country: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        twitter: {
          error: '',
          value: [],
          mandatory: false,
          active: true,
        },
        pincode: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        linkedin: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
        linkedinFounders: {
          error: '',
          value: [],
          mandatory: false,
          active: true,
        },
        github: {
          error: '',
          value: '',
          mandatory: false,
          active: true,
        },
      },
    },

    errorTabs: [],
  },
  reducers: {
    updateData: (state, actions) => {
      if (actions.payload['id'] == 'founders') {
        console.log(actions.payload)
      }
      if (actions.payload['type'] == 'array') {
        state[actions.payload['category']][
          actions.payload['subCategory']
        ].array = actions.payload.value
        return
      }
      if (actions.payload['type'] == 'multipleText') {
        console.log(actions.payload)
        state[actions.payload['category']][actions.payload['subCategory']][
          actions.payload['field']
        ].value[actions.payload['id']] = actions.payload.value
        return
      }
      if (actions.payload['type'] == 'both') {
        state[actions.payload['category']][actions.payload['subCategory']][
          actions.payload['id']
        ].error = actions.payload.error
        state[actions.payload['category']][actions.payload['subCategory']][
          actions.payload['id']
        ].value = actions.payload.value
        return
      }

      if (actions.payload['type'] == 'others') {
        state[actions.payload['category']][
          actions.payload['subCategory']
        ].others[actions.payload['id']].error = actions.payload.error
        state[actions.payload['category']][
          actions.payload['subCategory']
        ].others[actions.payload['id']].value = actions.payload.value
        console.log(state)
        return
      }

      if (actions.payload['type'] == 'errorTab') {
        state.errorTabs = actions.payload.errorTabs
        return
      }

      state[actions.payload['category']][actions.payload['subCategory']][
        actions.payload['id']
      ][actions.payload['type']] = actions.payload.value
    },
  },
})

export const { updateData } = StartupFormDataSlice.actions

export default StartupFormDataSlice.reducer
