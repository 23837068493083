import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { useEffect, useState } from "react";

import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

import { useNavigate, useParams } from "react-router-dom";
import { downloadFile } from "../../../../../../../data/network/api/download";
import {
  checkApplication,
  getAppicationData,
  saveApplicationData,
  saveComment,
  updatePhaseData,
} from "../../../../../../../data/network/api/scheme";
import { uploadFile } from "../../../../../../../data/network/api/startupForm";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import { TitanDispatch } from "../../../../../../../providers/scheme/titan/TitanProvider";
import { updateNavigation } from "../../../../../../../reducers/navigationReducer";
import { updateTitanApplication } from "../../../../../../../reducers/schemes/titan/applications";
import GlobalStore from "../../../../../../../store/globalStore";
import TitanSchemeStore from "../../../../../../../store/scheme/titan/titan";
import {
  ApplicaitonStatusColor,
  ApplicationStatus,
  ApplicationStatusTextColor,
} from "../../../../../../../utils/constants";
import { DialogBody } from "../../../../../../../utils/customStyledComponents";
import { getFileName } from "../../../../../../../utils/helperHandler";
import { Icons } from "../../../../../../../utils/mediaExports";
import CustomButton from "../../../../../../customComponents/CustomButton";
import ApplyModal from "../../../../../customComponents/modals/ApplyModal";
import ActionDialog from "../../../../big/phases/applicant/applicationAction/ActionModal";
import { SchemePhaseTabContainer } from "../../Applications";
import { applicanteeSideRole, applicationSideRole } from "../../TitanTabView";
import CompletionOfForm from "./CompletionOfForm";
import ObjectiveAndPlan from "./ObjectiveAndPlan";
import OwlLoader from "../../../../../../customComponents/OwlLoader";

const applyApplicationAccess = ["founder"];
const ApplicationStep = [1, 2];
const ApplicantApplicationSide = ({ from, call, setActive }) => {
  const { user } = globalSelector((state) => state.auth);
  const controller = new AbortController();
  const [activeForm, setActiveForm] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const dispatch = globalDispatch();
  const titanDispatch = TitanDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [application, setApplication] = useState(null);
  const [applicationData, setApplicationData] = useState(null);

  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [uploading, setUploading] = useState(false);

  const isProgramAssociate = applicanteeSideRole.includes(user.role.roleId);
  const isFounder = applicationSideRole.includes(user.role.roleId);

  //function to move to next phase automatically when moved to next round
  const [movingNextRound, setMovingNextRound] = useState(false);
  useEffect(() => {
    if (movingNextRound) {
      setActionDialogOpen(false);
      setActive(1);
      setMovingNextRound(false);
    }
  }, [movingNextRound]);

  const { enqueueSnackbar } = useSnackbar();
  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  //function to get application details and application phase data
  const getApplication = async () => {
    let bodyData = {};
    if (isProgramAssociate) {
      bodyData = {
        call: call._id,
        fromIkp: true,
        applicationId: params.applicationId,
      };
    } else if (isFounder) {
      bodyData = { callId: call._id };
    }

    try {
      const response = await checkApplication(bodyData, dispatch, controller);
      if (response && response.status == 200) {
        setApplication(response.msg?.application);
        setApplicationData(response.msg?.applicationData);
        handleForm(
          1,
          response?.msg?.application?.call?.phases?.application?.phase1
        );
        titanDispatch(
          updateTitanApplication({
            id: "application",
            value: response?.msg?.application,
          })
        );
        if (user.role.roleId != "founder") {
          let pageStackData = JSON.parse(
            JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
          );
          Object.keys(pageStackData).map((id) => {
            pageStackData[id].isActive = false;
          });
          pageStackData.companyName.title =
            response.msg?.application?.companyName;
          pageStackData.companyName.show = true;
          pageStackData.companyName.isActive = true;
          pageStackData.companyName.path = `/schemes/titan/${call?._id}/${response?.msg?.application?._id}`;
          dispatch(
            updateNavigation({
              type: "pageNavStack",
              value: { ...pageStackData },
            })
          );
        }

        let progress = {};
        for (let i = 1; i <= 6; i++) {
          progress["phase" + i] =
            response?.msg?.applicationData?.["phase" + i]?.status;
        }
        progress.milestone = response?.msg?.applicationData?.milestone?.status;
        titanDispatch(
          updateTitanApplication({
            id: "progress",
            value: progress,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFile = async (e, dates) => {
    if (e.target.files.length > 0) {
      const startDate = new Date(dates?.startDate);
      const endDate = new Date(dates?.endDate);
      const currentDate = new Date();
      // if (startDate <= currentDate && endDate <= currentDate) {
      const file = e.target.files[0];
      if (file.type != "application/pdf") {
        snackbarHandler("Invalid file type! Upload PDF", "error");
        return;
      }
      setUploading(true);
      const fileData = new FormData();
      fileData.append("file", file);
      const urlRes = await uploadFile(fileData, dispatch, controller);
      if (urlRes && urlRes.status == 200) {
        const response = await updatePhaseData(
          {
            data: urlRes.msg,
            applicationId: application._id,
            phase: "phase1",
            subPhase: "phase3",
          },
          dispatch,
          controller
        );
        if (response && response.status == 200) {
          snackbarHandler("PDF Uploaded", "success");
          handleForm(null, null, true);
        } else {
          snackbarHandler(response?.msg || "Something went wrong!", "error");
        }
      } else {
        snackbarHandler("Something went wrong! Try again", "error");
      }
      setUploading(false);
      // }
    }
  };

  useEffect(() => {
    setActiveForm(null);
    getApplication();

    return () => {
      try {
        let pageStackData = JSON.parse(
          JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
        );
        pageStackData.callName.isActive = true;
        pageStackData.companyName.title = "";
        pageStackData.companyName.show = false;
        pageStackData.companyName.isActive = false;

        dispatch(
          updateNavigation({
            type: "pageNavStack",
            value: { ...pageStackData },
          })
        );
      } catch (e) {}
    };
  }, []);

  const handleForm = (value, dates = null, refresh = false) => {
    if (dates) {
      const startDate = new Date(dates.startDate);
      const endDate = new Date(dates.endDate);
      const currentDate = new Date().toISOString();

      // if (startDate > currentDate) {
      //   alert(`Try on ${startDate.toLocaleDateString()}`);
      //   return;
      // } else if (endDate < currentDate) {
      //   alert(`This form and phase has been expired on ${endDate}`);
      //   return;
      // }
    }
    if (refresh) {
      getApplication();
    }
    setActiveForm(value);
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
  };

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  const [applyOpen, setApplyOpen] = useState(false);
  const applyModalHandler = (e, reason) => {
    if (reason == "backdropClick") return;
    setApplyOpen(false);
  };
  const onClickApplicationStep = (activeStepNumber) => {
    setActiveStep(() => activeStepNumber);
  };
  if (activeForm) {
    switch (activeForm) {
      case 1:
        return (
          <div className="">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "12px",
                gap: "8px",
              }}
            >
              <div
                style={{
                  color: "#00244b",
                  padding: "0px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/schemes/titan/${params?.id}`)}
              >
                {Icons.HiArrowLongLeft}
              </div>
              <div
                className=""
                style={{
                  color: "#00244b",
                  fontSize: "1.4rem",
                }}
              >
                Innovator Application form
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                margin: "20px 0 20px 12px",
                flexWrap: "wrap",
              }}
            >
              <ApplicationStepButton
                activeStep={activeStep}
                stepNumber={1}
                stepName={"Personal / Professional Details"}
                setActiveStep={setActiveStep}
                onClickApplicationStep={onClickApplicationStep}
              />
              <ApplicationStepButton
                activeStep={activeStep}
                stepNumber={2}
                stepName={"Project Details"}
                setActiveStep={setActiveStep}
                onClickApplicationStep={onClickApplicationStep}
              />
            </div>

            <CompletionOfForm
              application={application}
              handleForm={handleForm}
              applicationData={applicationData?.phase1?.subPhases?.phase1}
              call={call}
              from={from}
              activeStep={activeStep}
            />
          </div>
        );

      case 2:
        return (
          <ObjectiveAndPlan
            application={application}
            handleForm={handleForm}
            applicationData={applicationData?.phase1?.subPhases?.phase2}
            call={call}
            from={from}
          />
        );
    }
  }

  return (
    <SchemePhaseTabContainer>
      <div
        className={`statusTextApplication `}
        style={{
          background:
            ApplicaitonStatusColor[applicationData?.["phase1"]?.status],
          color:
            ApplicationStatusTextColor[applicationData?.["phase1"]?.status],
        }}
      >
        {ApplicationStatus[applicationData?.["phase1"]?.status] || ""}
      </div>
      {application ? (
        <div className="container">
          <TableContainer className="tableContainer applicantTable">
            <Table sx={{ minWidth: 650 }} aria-label="simple table ">
              <TableHead>
                <TableRow>
                  <TableCell width={"50px"} align="center">
                    Sl No
                  </TableCell>
                  <TableCell>Task</TableCell>
                  <TableCell>Application Open from</TableCell>
                  <TableCell>Apply Before</TableCell>
                  <TableCell>Progress</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <BuildApplicationPhases
                  handleForm={handleForm}
                  phase={application.call.phases.application}
                  applicationData={applicationData?.phase1?.subPhases}
                  applicationDataId={applicationData?._id}
                  handleFile={handleFile}
                  applicationId={application._id}
                  uploading={uploading}
                  schemeCategory={call.scheme.category}
                  presentationTemplateUrl={
                    call?.phases?.application?.phase3?.templateUrl
                  }
                  from={from}
                  getApplication={getApplication}
                />
              </TableBody>
            </Table>
          </TableContainer>
          {from == "associate" ? (
            <div className="actions">
              <div className="title">Action: </div>
              <div className="input">
                <Select
                  size="small"
                  value={selectedAction}
                  onChange={handleActionManager}
                >
                  <MenuItem value={-1}>
                    <FormLabel>Select</FormLabel>
                  </MenuItem>
                  {/* <MenuItem value={'invite'}>
                    <FormLabel>Invite</FormLabel>
                  </MenuItem> */}
                  <MenuItem value={"nextRound"}>
                    <FormLabel>Move to next round</FormLabel>
                  </MenuItem>
                  <MenuItem value={"assign"}>
                    <FormLabel>Assign Reviewer</FormLabel>
                  </MenuItem>
                  <MenuItem value={"revoke"}>
                    <FormLabel>Revoke Reviewer</FormLabel>
                  </MenuItem>
                  {user.role.roleId !== "external_reviewer" &&
                    user.role.roleId !== "founder" && (
                      <MenuItem value={"moveToDb"}>
                        <FormLabel>Move to Database</FormLabel>
                      </MenuItem>
                    )}
                  <MenuItem value={"reject"}>
                    <FormLabel style={{ color: "red" }}>Reject</FormLabel>
                  </MenuItem>
                </Select>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        // <div className="no-application">No Application found!</div>
        <OwlLoader />
      )}

      {/* {!application ? (
				<div className="apply">
					<CustomButton
						title="Apply for titan"
						onClickHandler={() => setApplyOpen(true)}
					/>
				</div>
			) : (
				<></>
			)} */}

      <Dialog
        open={actionDialogOpen}
        onClose={handleActionDialog}
        PaperProps={{
          style:
            selectedAction == "moveToDb"
              ? { minWidth: "1000px", maxWidth: "90vw" }
              : null,
        }}
      >
        <ActionDialog
          handleActionDialog={handleActionDialog}
          activeAction={selectedAction}
          application={application}
          applicationDataVersion={applicationData?.__v}
          getApplicationDataHandlerForManager={getApplication}
          nextStatus={"review"}
          phase={"phase1"}
          setMovingNextRound={setMovingNextRound}
        />
      </Dialog>

      <Dialog
        open={applyOpen}
        onClose={applyModalHandler}
        PaperProps={{ style: { width: "30rem" } }}
      >
        <ApplyModal
          modalHandler={applyModalHandler}
          refreshApplication={getApplication}
          call={call}
        />
      </Dialog>

      {/* {user.role.roleId == "founder" ? (
				<div className="contactPanel">
					<div className="text">For any clarifications</div>
					<div className="number">
						<span className="heading">Contact</span>
						<span>:</span>
						<span className="numberText">+91 9731687587</span>
					</div>
				</div>
			) : null} */}
    </SchemePhaseTabContainer>
  );
};
const ApplicationStepButton = ({
  stepNumber,
  stepName,
  onClickApplicationStep,
  activeStep,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        gap: "8px",
        backgroundColor: "white",
        padding: "10px 16px",
        borderRadius: "4px",
        cursor: "pointer",
        border: activeStep == stepNumber ? "2px solid lightgray" : "none",
        width: "300px",
      }}
      onClick={() => onClickApplicationStep(stepNumber)}
    >
      <div
        style={{
          backgroundColor: "#1560BD",
          color: "white",
          height: "50px",
          width: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
        }}
      >
        {stepNumber}
      </div>
      <div className="">{stepName}</div>
    </div>
  );
};
const BuildApplicationPhases = ({
  handleForm,
  phase,
  applicationData,
  applicationDataId,
  getApplicationDataHandlerForManager,
  handleFile,
  from,
  uploading,
  schemeCategory,
  applicationId,
  presentationTemplateUrl,
  getApplication,
}) => {
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [loading, setLoading] = useState(true);

  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const dialogRef = useRef();
  const [fileName, setFileName] = useState("");
  const [comments, setComments] = useState([]);
  const [isCommentsChanged, setCommentsChanged] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setFileName(getFileName(applicationData?.phase3?.data));

    if (applicationData?.phase3?.comments?.length > 0) {
      setComments(applicationData.phase3.comments);
      setLoading(false);
      return;
    }
    const commentsArray = Array(numPages).fill("");
    setComments([...commentsArray]);
    setLoading(false);
  }

  useEffect(() => {
    setFileName("");
    setNumPages(null);
    setPageNumber(1);
  }, [applicationData?.phase3?.data]);

  const submitComments = async () => {
    if (!isCommentsChanged) {
      return true;
    }
    try {
      setLoading(true);
      const response = await saveComment(
        {
          values: comments,
          phase: "phase1",
          subPhase: "phase3",
          applicationId: applicationId,
        },
        dispatch,
        controller
      );
      if (response && response.status == 200) {
        getApplication();
        return true;
      } else {
        alert(response?.msg || "Something went wrong!");
      }
    } catch (err) {
      alert("Something went wrong!");
    } finally {
      setLoading(false);
    }
    return false;
  };

  const handlePdfView = (url) => {
    setPdfDialogOpen(true);
  };

  const closePdfView = async (e, reason) => {
    if (reason == "backdropClick") {
      return;
    }

    if (from == "associate") {
      const isCommentsSaved = await submitComments();
      if (isCommentsSaved) {
        getAppicationData();
        setComments([]);
        setPdfDialogOpen(false);
      } else {
        alert("Error saving comments! Try again..");
      }
    } else {
      setComments([]);
      setPdfDialogOpen(false);
    }

    setPageNumber(1);
    setNumPages(null);
  };

  const handlePrev = () => {
    if (pageNumber == 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber == numPages) return;
    setPageNumber(pageNumber + 1);
  };

  const changeComment = (index, value) => {
    setCommentsChanged(true);
    let array = [...comments];
    array[index] = value;
    setComments(array);
  };

  const initiateDownload = async (url) => {
    downloadFile(url);
  };

  return (
    <>
      <TableRow>
        <TableCell align="center" className="text">
          1
        </TableCell>
        <TableCell>
          <div>
            <div className="text">Innovator Application form</div>
            {/* <div className="subtextAction">View form</div> */}
          </div>
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase1?.startDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase1?.endDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>{applicationData?.phase1?.progress}%</TableCell>
        <TableCell>
          <span className="statusText">{applicationData?.phase1?.status}</span>
        </TableCell>
        <TableCell
          className="actionText"
          onClick={() => handleForm(1, phase?.phase1)}
        >
          {from == "associate"
            ? "View"
            : applicationData?.phase1?.progress > 0
            ? "Edit Form"
            : "Fill Form"}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" className="text">
          2
        </TableCell>
        <TableCell>
          <div>
            <div className="text">Activity details / Work Plan</div>
            {/* <div className="subtextAction">View form</div> */}
          </div>
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase2?.startDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase2?.endDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>{applicationData?.phase2?.progress}</TableCell>
        <TableCell>
          <span className="statusText">{applicationData?.phase2?.status}</span>
        </TableCell>
        <TableCell
          className="actionText"
          onClick={() => handleForm(2, phase?.phase2)}
        >
          {from == "associate"
            ? "View"
            : applicationData?.phase1?.progress > 0
            ? "Edit Form"
            : "Fill Form"}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" className="text">
          3
        </TableCell>
        <TableCell>
          <div>
            <div className="text">All Schematics Upload</div>
          </div>
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase3?.startDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase3?.endDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>{applicationData?.phase3?.progress}%</TableCell>
        <TableCell>
          <span className="statusText">{applicationData?.phase3?.status}</span>
        </TableCell>
        <TableCell>
          {uploading ? (
            <CircularProgress />
          ) : (
            <div>
              <label className="text">
                {from == "associate" ? (
                  <></>
                ) : (
                  <input
                    onChange={(e) => handleFile(e, phase?.phase1)}
                    type="file"
                    hidden
                  />
                )}

                {from == "associate" ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handlePdfView(applicationData?.phase3?.data?.url)
                    }
                  >
                    View
                  </div>
                ) : applicationData?.phase3?.progress > 0 ? (
                  <span
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                    }}
                  >
                    ReUpload{" "}
                    <FormLabel style={{ fontSize: "0.7rem" }}>
                      (Only PDF)
                    </FormLabel>
                  </span>
                ) : (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                      cursor: "pointer",
                    }}
                  >
                    Upload{" "}
                    <FormLabel style={{ fontSize: "0.7rem" }}>
                      (Only PDF)
                    </FormLabel>
                  </span>
                )}
              </label>

              {from == "associate" ? (
                <></>
              ) : (
                <div
                  className="subtextAction"
                  onClick={() => handlePdfView(applicationData?.phase3?.data)}
                >
                  View
                </div>
              )}

              {from != "associate" ? (
                <div className="subtextAction">
                  <div
                    className="download"
                    onClick={() => initiateDownload(presentationTemplateUrl)}
                  >
                    Download Template
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </TableCell>
      </TableRow>
      <Dialog
        open={pdfDialogOpen}
        onClose={closePdfView}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1600px", // Set your width here
              maxHeight: "90vh",
              overflow: "auto",
            },
          },
        }}
        ref={dialogRef}
      >
        <DialogBody>
          <div className="closeButton closePdfViewer" onClick={closePdfView}>
            {Icons.close}
          </div>
          {loading ? <></> : <></>}
          <DialogTitle className="dialogTitle">
            <span className="bold">Presentation: </span>{" "}
            <span className="light">{fileName}</span>
          </DialogTitle>
          <DialogContent className="content pdfViewerComments">
            <div className="arrowLeft arrow" onClick={handlePrev}>
              {Icons.arrowFormward}
            </div>
            <div className="pdfView">
              <Document
                file={applicationData?.phase3?.data}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  className="pdfPage"
                  renderAnnotationLayer={null}
                  renderTextLayer={false}
                />

                <FormLabel className="slideLabel">
                  Slide: {pageNumber}/{numPages}
                </FormLabel>
              </Document>
            </div>

            <div className="commentView">
              <div className="commentTitle">
                <FormLabel>Comment for Slide {pageNumber}</FormLabel>
              </div>

              <div className="commentTextfield">
                <textarea
                  placeholder={from == "associate" ? "Add your comments.." : ""}
                  value={comments[pageNumber - 1]}
                  readOnly={from == "associate" ? false : true}
                  onChange={(e) =>
                    changeComment(pageNumber - 1, e.target.value)
                  }
                ></textarea>
                {from == "associate" ? (
                  <FormLabel>
                    Comments will get saved when popup is closed!
                  </FormLabel>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="arrowRight arrow" onClick={handleNext}>
              {Icons.arrowFormward}
            </div>
          </DialogContent>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ApplicantApplicationSide;
