import { useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import "./drag_drop_expenses.css";
function DragDropFile(props) {
  const fileTypes = ["pdf"];
  //   const [file, setFile] = useState(null);

  const handleChange = (file) => {
    props.onChange(file);
  };
  return (
    <div className="ExpenseDargDropContainer">
      <h1>Invoice Upload</h1>
      <FileUploader
        handleChange={handleChange} 
        name="file"
        types={fileTypes}
        label={"Drag and drop or Browse for files"}
        children={
          <div id="form-file-upload">
            <div id="input-file-upload" />
            <div id="label-file-upload" htmlFor="input-file-upload">
              {props.file ? (
                <div className="dragDropText">
                  <p className="single-line">{props.file.name}</p>
                </div>
              ) : (
                <div className="dragDropText">
                  <p>Drag and drop</p>
                  <p>or</p>
                  <div className="upload-button">Browse for file</div>
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}
export default DragDropFile;
