import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { applyCall, sendInvite } from "../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../providers/GlobalProvider";
import { postRequest } from "../../../../utils/axiosClient/axios";
import { DialogBody } from "../../../../utils/customStyledComponents";
import { Validator } from "../../../../utils/helperHandler";
import { Icons } from "../../../../utils/mediaExports";
import CustomButton from "../../../customComponents/CustomButton";

const initialState = {
  companyName: "",
};

const ApplyModal = ({ modalHandler, refreshApplication, call }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ ...initialState });
  const [error, setError] = useState({ ...initialState });
  const [successDialog, setSuccessDialog] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [fromDb, setFromDb] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const dispatch = globalDispatch();
  const controller = new AbortController();

  const handleChange = async (id, value) => {
    setFromDb(null);
    setCompanyId(null);
    setData({ ...data, [id]: value });
    if (value) {
      await queryCompany(value);
    }
  };

  const queryCompany = async (value) => {
    let customConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (value && value?.length && value.length >= 1) {
      let filterCompanies = await postRequest({
        endpoint: "/scheme/queryCompany",
        data: { companyName: value, isApply: true },
        headers: customConfig,
        dispatch: dispatch,
        controller: controller,
      });
      if (filterCompanies.msg.length > 0 && filterCompanies.status === 200) {
        setCompanies(filterCompanies?.msg);
      }
    }
  };

  const submitHandler = async () => {
    let error = { ...initialState };
    setServerResponse(null);
    let isvalid = true;
    if (!data.companyName || data.companyName == "") {
      isvalid = false;
      error["companyName"] = "Company Name required!";
    }

    setError(error);
    if (!isvalid) return;

    try {
      setLoading(true);
      const response = await applyCall(
        {
          companyName: data.companyName.trim(),
          callId: call?._id,
          companyDB: fromDb,
          companyId: companyId,
        },
        dispatch,
        controller
      );

      if (response && response.status == 200) {
        setServerResponse({
          type: "success",
          msg: "Application Created",
        });
        refreshApplication();
      } else if (response) {
        setServerResponse({
          type: "error",
          msg: response.msg || "Something went wrong!",
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogBody>
        <div className="closeButton" onClick={modalHandler}>
          {Icons.close}
        </div>
        <DialogTitle className="dialogTitle">{`Apply for ${call?.scheme?.abbr.toUpperCase()} - ${
          call?.callName
        }`}</DialogTitle>
        <DialogContent className="content">
          <Autocomplete
            className="firstField"
            label={"Company Name / Individual *"}
            size={"small"}
            freeSolo
            options={companies}
            getOptionLabel={(option) => option.companyName}
            onChange={(e, newValue) => {
              if (["new", "update"].includes(newValue?.queueType)) {
                setFromDb("queue");
              } else {
                setFromDb("main");
              }
              setCompanyId(newValue?._id);
              setData({companyName: newValue?.companyName});
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company Name / Individual *"
                onChange={(e) => handleChange("companyName", e.target.value)}
              />
            )}
            helperText={error.companyName}
            error={!!error.companyName}
          />

          <div className="actionField">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={submitHandler}
                className="button"
                variant="contained"
              >
                Apply
              </Button>
            )}
          </div>

          <div>
            {serverResponse ? (
              <Alert color={serverResponse.type}>{serverResponse.msg}</Alert>
            ) : null}
          </div>
        </DialogContent>
      </DialogBody>
    </>
  );
};

export default ApplyModal;
