import { updateAuth } from '../../../reducers/auth/authReducer'
import { getRequest, postRequest } from '../../../utils/axiosClient/axios'
import { getToken } from './startupForm'
import { logout } from './user'

export const getInvestmentApplications = async ({
  data,
  dispatch,
  controller,
}) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/investment/applications',
    data,
    headers: {
      'access-token': token,
    },
    controller,
    dispatch,
  })
  return response
}

export const inviteApplicationInvestment = async ({
  data,
  dispatch,
  controller,
}) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/investment/sendInvite',
    data,
    headers: {
      'access-token': token,
    },
    controller,
    dispatch,
  })
  return response
}

export const getApplicationDataInvestment = async ({
  data,
  dispatch,
  controller,
}) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/investment/application',
    data: data,
    headers: {
      'access-token': token,
    },
    controller,
    dispatch,
  })
  return response
}

export const uploadInvestmentFile = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/investment/uploadFile',
    data: data,
    headers: {
      'access-token': token,
      'Content-Type': 'multipart/form-data',
    },
    controller,
    dispatch,
  })
  return response
}

export const saveInvestementData = async ({ data, dispatch, controller }) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/investment/saveData',
    data: data,
    headers: {
      'access-token': token,
    },
    controller,
    dispatch,
  })
  return response
}

export const updateInvestmentStatus = async ({
  data,
  dispatch,
  controller,
}) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/investment/updateStatus',
    data: data,
    headers: {
      'access-token': token,
    },
    controller,
    dispatch,
  })
  return response
}

export const assignAssociateForInvestment = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken()
  if (!token) {
    logout(dispatch)
    return null
  }

  const response = await postRequest({
    endpoint: '/investment/assignAssociate',
    data,
    dispatch,
    controller,
    headers: {
      'access-token': token,
    },
  })
  return response
}
