import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  applications: [],
}

const PrayasReviewApplicationsSlice = createSlice({
  name: 'prayas_review_applications',
  initialState: { ...INITIAL_STATE },
  reducers: {
    updatePrayasReviewApplications: (state, actions) => {
      state[actions.payload.id] = actions.payload.value
    },
  },
})

export const { updatePrayasReviewApplications } =
  PrayasReviewApplicationsSlice.actions

export default PrayasReviewApplicationsSlice.reducer
