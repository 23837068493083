import { Add } from "@mui/icons-material";
import {
  Button,
  Dialog,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  checkApplication,
  getProjectDetails,
  getWorkplanData,
  submitMilestoneDetails,
} from "../../../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import { BigDispatch } from "../../../../../../../providers/scheme/big/BigProvider";
import { updateNavigation } from "../../../../../../../reducers/navigationReducer";
import { updateBigApplication } from "../../../../../../../reducers/schemes/big/applications";
import { updateBigPathNav } from "../../../../../../../reducers/schemes/big/pathNav";
import GlobalStore from "../../../../../../../store/globalStore";
import BigSchemeStore from "../../../../../../../store/scheme/big/big";
import {
  ApplicaitonStatusColor,
  ApplicationStatus,
  ApplicationStatusTextColor,
} from "../../../../../../../utils/constants";
import { Validator, inrAddCommas } from "../../../../../../../utils/helperHandler";
import paths from "../../../../../../../utils/links";
import { Icons } from "../../../../../../../utils/mediaExports";
import CustomButton from "../../../../../../customComponents/CustomButton";
import OwlLoader from "../../../../../../customComponents/OwlLoader";
import {
  applicanteeSideRole,
  applicationSideRole,
} from "../../../../../customComponents/CustomTabView";
import { SchemePhaseTabContainer } from "../../ikp/Applications";
import ActionDialog from "../applicationAction/ActionModal";

const viewAccess = ["founder", "program_associate", "super_admin"];
const editAccess = ["program_manager", "super_admin"];

const Milestone = ({ call, from, setIsLateral }) => {
  const { user } = globalSelector((state) => state.auth);
  const role = user.role.roleId;
  const controller = new AbortController();
  const dispatch = globalDispatch();
  const bigDispatch = BigDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [application, setApplication] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [workplanData, setWorkplanData] = useState([]);
  const [errors, setErrors] = useState([]);

  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const isProgramAssociate = applicanteeSideRole.includes(user.role.roleId);
  const isFounder = applicationSideRole.includes(user.role.roleId);
  const [milestoneSubmitted, setMilestoneSubmitted] = useState(false);
  const [projectdetails, setProjectDetails] = useState(null);

  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  //function to get application details and application phase data
  const getApplication = async () => {
    let bodyData = {};
    if (isProgramAssociate) {
      bodyData = {
        fromIkp: true,
        applicationId: params.applicationId,
      };
    } else if (isFounder) {
      bodyData = { callId: call._id };
    }

    try {
      setLoading(true);
      const response = await checkApplication(bodyData, dispatch, controller);
      if (response && response.status == 200) {
        setApplication(response.msg?.application);
        // setIsLateral(response?.msg?.application?.others?.lateralEntry || false);
        setApplicationData(response.msg?.applicationData);
        bigDispatch(updateBigApplication({
          id: "application",
          value: response?.msg?.application
        }));
        await getWorkplanDetails(response.msg?.application?._id);
        await getProjectDetailsHandler(response.msg?.application?._id);
        let progress = {};
        for (let i = 1; i <= 8; i++) {
          progress["phase" + i] =
            response?.msg?.applicationData?.["phase" + i]?.status;
        }
        progress.milestone = response?.msg?.applicationData?.milestone?.status;
        bigDispatch(
          updateBigApplication({
            id: "progress",
            value: progress,
          })
        );

        if (user.role.roleId != "founder") {
          let pageStackData = JSON.parse(
            JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
          );
          Object.keys(pageStackData).map((id) => {
            pageStackData[id].isActive = false;
          });
          pageStackData.companyName.title =
            response.msg?.application?.companyName;
          pageStackData.companyName.show = true;
          pageStackData.companyName.isActive = true;
          pageStackData.companyName.path = `/schemes/big/${call?._id}/${response?.msg?.application?._id}`;
          dispatch(
            updateNavigation({
              type: "pageNavStack",
              value: { ...pageStackData },
            })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //function to get projectdetails if present to fill up textbox of startdate, enddate, budget
  const getProjectDetailsHandler = async (applicationId) => {
    const response = await getProjectDetails(
      {
        applicationId: applicationId,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      let workplanData = [];
      response?.msg?.milestoneIds?.map((milestone, i) => {
        workplanData.push({ ...milestone.data });
      });
      setWorkplanData(workplanData);
      setProjectDetails(response?.msg);
      setMilestoneSubmitted(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getApplication();

    return () => {
      try {
        let pageStackData = JSON.parse(
          JSON.stringify(GlobalStore.getState().navigation.pageNavStack)
        );
        pageStackData.callName.isActive = true;
        pageStackData.companyName.title = "";
        pageStackData.companyName.show = false;
        pageStackData.companyName.isActive = false;
  
        dispatch(
          updateNavigation({
            type: "pageNavStack",
            value: { ...pageStackData },
          })
        );
      } catch (e) {}
    };
  }, []);

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  const getWorkplanDetails = async (applicationId) => {
    const response = await getWorkplanData(
      { applicationId: applicationId },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      setErrors(
        Array(response.msg?.length).fill({
          startDate: "",
          endDate: "",
          budget: "",
        })
      );
      setWorkplanData(response.msg?.workplan);
    }
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
  };

  const setValues = (index, id, value) => {
    let array = [...workplanData];
    array[index][id] = value;
    setWorkplanData(array);
  };

  const submitWorkplan = async () => {
    let data = [];
    let errors = Array(workplanData.length).fill({
      startDate: "",
      endDate: "",
      budget: "",
    });
    let isValid = true;
    let total = 0;
    workplanData.map((workplan, i) => {
      data.push({});
      data[i].milestoneNumber = i + 1;
      data[i].data = {
        objective: workplan?.objective,
        methodolody: workplan?.methodolody,
        alternateStartegies: workplan?.alternateStartegies,
      };
      data[i].workplanId = workplan._id;
      data[i].startDate = workplan?.startDate;
      data[i].endDate = workplan?.endDate;
      data[i].budget = workplan?.budget;

      let error = { startDate: "", endDate: "", budget: "" };
      if (!workplan?.startDate) {
        isValid = false;
        error.startDate = "Date required!";
      }
      if (!workplan.endDate) {
        isValid = false;
        error.endDate = "Date required!";
      }
      if (!workplan.budget) {
        isValid = false;
        error.budget = "Budget required!";
      }

      if (workplan?.startDate && workplan?.endDate) {
        if (workplan.startDate > workplan.endDate) {
          isValid = false;
          error.endDate = "End date should be greater than start date!";
        }
      }

      if (workplan?.budget) {
        if (!Validator.isInt(workplan.budget)) {
          isValid = false;
          error.budget = "Only Number is valid!";
        } else if (workplan.budget > application?.approvedAmount) {
          isValid = false;
          error.budget = "Exceeded Approved Amount!";
        } else if (workplan.budget < 0) {
          isValid = false;
          error.budget = "Invalid Amount!";
        }
      }
      errors[i] = error;
      total += parseFloat(data[i].budget);
    });

    if (total != application?.approvedAmount) {
      snackbarHandler(
        "Total Milestone budget amount should be equal to approved amount!",
        "error"
      );
      isValid = false;
    }
    setErrors(errors);

    if (isValid) {
      const response = await submitMilestoneDetails(
        {
          applicationId: application._id,
          data: data,
        },
        dispatch,
        controller
      );
      if (response && response.status == 200) {
        snackbarHandler(
          `Success: ${response?.msg || "Milestones submitted.."}`,
          "success"
        );
        getApplication();
        getProjectDetails();
      } else {
        snackbarHandler(
          `Error: ${response?.msg || "Something went wrong!"}`,
          "error"
        );
      }
    }
  };

  const addMilestone = async () => {
    let newWorkplan = {
      objective: "",
      methodolody: "",
      alternateStartegies: "",
    };
    setWorkplanData([...workplanData, { ...newWorkplan }]);
  };

  const removeMilestone = async (index) => {
    let milestones = [...workplanData];
    milestones.splice(index, 1);
    setWorkplanData([...milestones]);
  };

  const handleChange = (index, id, value) => {
    let milestones = JSON.parse(JSON.stringify(workplanData));
    let obj = { ...milestones[index], [id]: value };
    milestones.splice(index, 1, { ...obj });
    setWorkplanData(milestones);
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  if (loading) return <OwlLoader />;

  return (
    <SchemePhaseTabContainer>
      <TableContainer className="tableContainer milestoneTable">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={"40px"}>Sl No</TableCell>
              <TableCell align="center" width={"50%"}>
                Workplan
              </TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">End Date</TableCell>
              <TableCell align="center">Budget</TableCell>
              {milestoneSubmitted ? (
                <></>
              ) : (
               editAccess.includes(role) ?  <TableCell align="center">Action</TableCell> : null
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell colSpan={milestoneSubmitted ? 5 : 6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "1.1rem",
                    color: "var(--text3)",
                  }}
                >
                  Approved Grant Amount&nbsp;:&nbsp;
                  <b>₹{inrAddCommas(application?.approvedAmount) || "0"}</b>
                </div>
              </TableCell>
            </TableRow>
            {workplanData.map((row, i) => {
              return (
                <TableRow>
                  <TableCell
                    style={{
                      verticalAlign: "top",
                      padding: "",
                    }}
                    className=""
                    align="center"
                  >
                    {i + 1}
                  </TableCell>
                  <TableCell>
                    <div className="workplanContent">
                      <div className="content">
                        Objective:{" "}
                        <textarea
                          rows={3}
                          onChange={(e) =>
                            handleChange(i, "objective", e.target.value)
                          }
                          className="desc"
                          value={row?.objective}
                          readOnly={!editAccess.includes(role) || milestoneSubmitted}
                        />
                      </div>
                      <div className="content">
                        Methodology:{" "}
                        <textarea
                          rows={3}
                          onChange={(e) =>
                            handleChange(i, "methodolody", e.target.value)
                          }
                          className="desc"
                          value={row?.methodolody}
                          readOnly={!editAccess.includes(role) || milestoneSubmitted}
                        />
                      </div>
                      <div className="content">
                        Alternate Strategies:{" "}
                        <textarea
                          rows={3}
                          onChange={(e) =>
                            handleChange(
                              i,
                              "alternateStartegies",
                              e.target.value
                            )
                          }
                          readOnly={!editAccess.includes(role) || milestoneSubmitted}
                          className="desc"
                          value={row?.alternateStartegies}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      verticalAlign: "top",
                      padding: "",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setValues(i, "startDate", newValue);
                        }}
                        disabled={!editAccess.includes(role) || milestoneSubmitted}
                        value={
                          milestoneSubmitted
                            ? projectdetails?.milestoneIds?.[i]?.startDate
                            : workplanData[i]?.startDate || null
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText style={{ color: "red" }}>
                      {errors[i]?.startDate}
                    </FormHelperText>
                  </TableCell>
                  <TableCell
                    style={{
                      verticalAlign: "top",
                      padding: "",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setValues(i, "endDate", newValue);
                        }}
                        disabled={!editAccess.includes(role) || milestoneSubmitted}
                        value={
                          milestoneSubmitted
                            ? projectdetails?.milestoneIds?.[i]?.endDate
                            : workplanData[i]?.endDate || null
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText style={{ color: "red" }}>
                      {errors[i]?.endDate}
                    </FormHelperText>
                  </TableCell>
                  <TableCell
                    style={{
                      verticalAlign: "top",
                      padding: "",
                    }}
                  >
                    <FormControl>
                      <InputLabel htmlFor="outlined-adornment">
                        Budget 
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        label="Budget"
                        size="small"
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replaceAll(",", "");
                          value = (value);
                          setValues(i, "budget", value);
                        }}
                        disabled={!editAccess.includes(role) || milestoneSubmitted}
                        value={
                          milestoneSubmitted
                            ? inrAddCommas(String(projectdetails?.milestoneIds?.[i]?.budget))
                            : inrAddCommas(workplanData[i]?.budget)
                        }
                      />
                    </FormControl>
                    <FormHelperText style={{ color: "red" }}>
                      {errors[i]?.budget}
                    </FormHelperText>
                  </TableCell>

                  {!milestoneSubmitted ? (
                    editAccess.includes(role) ? <TableCell style={{ verticalAlign: "top" }}>
                      <div className="icon" onClick={() => removeMilestone(i)}>
                        {Icons.delete}
                      </div>
                    </TableCell> : null
                  ) : (
                    <></>
                  )}
                </TableRow>
              );
            })}

            {milestoneSubmitted ? (
              <></>
            ) : editAccess.includes(role) ? (
              <TableRow>
                <TableCell colspan={milestoneSubmitted ? 5 : 6} align="center">
                  <Button
                    variant="outlined"
                    className="muiOutlinedBtn"
                    startIcon={<Add />}
                    onClick={addMilestone}
                  >
                    Add Milestone
                  </Button>
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell colSpan={milestoneSubmitted ? 5 : 6}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {milestoneSubmitted ? (
                    user.role.roleId == "founder" ||
                    user.role.roleId == "program_manager" ||
                    user.role.roleId == "super_admin" ? (
                      <CustomButton
                        title={"Project Management"}
                        onClickHandler={() => {
                          if (application.status == "selected") {
                            navigate(`/schemes/milestones/${application?._id}`);
                          } else {
                            snackbarHandler(
                              "Application has not been selected yet!",
                              "error"
                            );
                          }
                        }}
                      />
                    ) : (
                      <></>
                    )
                  ) : editAccess.includes(role) ? (
                    <CustomButton
                      title={"Submit"}
                      onClickHandler={submitWorkplan}
                    />
                  ) : null}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </SchemePhaseTabContainer>
  );
};

export default Milestone;

export const CommentActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  gap: 3rem;
  flex-wrap: wrap;

  .commentSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    textarea {
      resize: none;
      min-width: 300px;
      height: 150px;
      padding: 1rem;
      font-size: 1.1rem;
    }

    button {
      width: max-content;
      margin: 0 auto;
      padding: 0.3rem 1.5rem;
    }
  }
`;
