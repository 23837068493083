import {
  Button,
  Dialog,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  checkApplication,
  saveComment,
  updatePhaseData,
} from "../../../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import { BigDispatch } from "../../../../../../../providers/scheme/big/BigProvider";
import { PrayasDispatch } from "../../../../../../../providers/scheme/prayas/PrayasProvider";
import { updatePrayasApplication } from "../../../../../../../reducers/schemes/prayas/applications";
import {
  ApplicaitonStatusColor,
  ApplicationStatus,
  ApplicationStatusTextColor,
} from "../../../../../../../utils/constants";
import { Icons } from "../../../../../../../utils/mediaExports";
import CustomButton from "../../../../../../customComponents/CustomButton";
import {
  applicanteeSideRole,
  applicationSideRole,
} from "../../../../../customComponents/CustomTabView";
import { CommentActionContainer } from "../../../../big/phases/applicant/agreement/Agreement";
import ActionDialog from "../../../../big/phases/applicant/applicationAction/ActionModal";
import { SchemePhaseTabContainer } from "../../../../big/phases/ikp/Applications";
import { Container } from "../onlineReview/OnlineReviewApplicant";

const PHASE = "phase4";

export const documents = [
  {
    id: "teamAadhar",
    title: "Team Aadhar",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "hardwareDevelopment",
    title: "Hardware Development",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "workingFulltime",
    title: "Working Fulltime",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "underSevenYears",
    title: "Under 7 Years",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "addressProof",
    title: "Address Proof",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "itReturn",
    title: "IT Return",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "noc",
    title: "NOC",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "cin",
    title: "CIN",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "moa",
    title: "MOA",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "aoa",
    title: "AOA",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "letterOfCommitment",
    title: "Letter of Commitment",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "selfCertificate1",
    title: "Self Certificate 1",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
  {
    id: "selfCertificate2",
    title: "Self Certificate 2",
    label: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
];

const DueDiligence = ({ call, from, setActive }) => {
  const { user } = globalSelector((state) => state.auth);
  const controller = new AbortController();
  const dispatch = globalDispatch();
  const prayasDispatch = PrayasDispatch();
  const [comment, setComment] = useState("");
  const params = useParams();

  const [application, setApplication] = useState(null);
  const [applicationData, setApplicationData] = useState(null);

  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const isProgramAssociate = applicanteeSideRole.includes(user.role.roleId);
  const isFounder = applicationSideRole.includes(user.role.roleId);

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState({});
  const [docVersion, setDocVersion] = useState(null);

  //function to get application details and application phase data
  const getApplication = async () => {
    let bodyData = {};
    if (isProgramAssociate) {
      bodyData = {
        fromIkp: true,
        applicationId: params.applicationId,
      };
    } else if (isFounder) {
      bodyData = { callId: call._id };
    }

    try {
      const response = await checkApplication(bodyData, dispatch, controller);
      if (response && response.status == 200) {
        setApplication(response.msg?.application);
        setApplicationData(response.msg?.applicationData);
        prayasDispatch(updatePrayasApplication({
          id: "application",
          value: response?.msg?.application,
        }));
        setComment(response.msg?.applicationData?.[PHASE]?.comment);
        setData(response.msg?.applicationData?.[PHASE]?.data);
        let progress = {};
        for (let i = 1; i <= 6; i++) {
          progress["phase" + i] =
            response?.msg?.applicationData?.["phase" + i]?.status;
        }
        progress.milestone = response?.msg?.applicationData?.milestone?.status;
        prayasDispatch(
          updatePrayasApplication({
            id: "progress",
            value: progress,
          })
        );
        setDocVersion({applicationVersion:response?.msg?.application?.__v, applicationDataVersion: response?.msg?.applicationData?.__v});
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getApplication();
  }, []);

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  const submitComment = async () => {
    const response = await saveComment(
      { applicationId: application._id, phase: PHASE, values: comment },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(
        `Comment submitted!`,
        "success"
      );
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
    if (comment) {
      submitComment();
    }
  };

  const submitData = async () => {
    let obj = { ...data };
    const response = await updatePhaseData(
      { applicationId: application._id, phase: "phase4", data: obj, docVersion },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(response?.msg || "Saved..", "success");
      submitComment();
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  }

  //function to move to next phase automatically when moved to next round
  const [movingNextRound, setMovingNextRound] = useState(false);
  useEffect(() => {
    if (movingNextRound) {
      setActionDialogOpen(false);
      setActive(4);
      setMovingNextRound(false);
    }
  }, [movingNextRound]);

  const handleChange = (id, value) => {
    setData({...data, [id]: value});
  }

  return (
    <SchemePhaseTabContainer>
      <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
        <div
          className={`statusTextApplication `}
          style={{
            background:
              ApplicaitonStatusColor[applicationData?.[PHASE]?.status],
            color: ApplicationStatusTextColor[applicationData?.[PHASE]?.status],
          }}
        >
          {ApplicationStatus[applicationData?.[PHASE]?.status] || "Pending"}
        </div>

        {from == "associate" ? (
          <div className="assignedText" style={{ fontSize: "1.2rem" }}>
            Assigned to:{" "}
            {application?.reviewer?.map((person, i) => {
              return (
                <span className="rName">
                  {person.name}{" "}
                  {application?.reviewer?.length != i + 1 ? ", " : ""}
                </span>
              );
            })}
            {application?.reviewer?.length == 0 ? "-" : ""}
          </div>
        ) : (
          <></>
        )}
      </div>

      <Container>
        <div className="title">Documents Verification</div>

        <div className="content" style={{ flexWrap: "unset", gap: "0rem" }}>
          <div className="column">
            <div className="multipleHeading">
              <div className="text">Product Title&nbsp;:&nbsp;-</div>
              <div className="text">Sector&nbsp;:&nbsp;-</div>
            </div>
            <div className="row">
              {documents?.map((document, i) => {
                return (
                  <div className="checkField" key={i}>
                    <FormLabel>{document?.title}</FormLabel>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid item>{document?.label[0]?.label}</Grid>
                      <>{data?.[document.id] || false}</>
                      <Grid item>
                        <Switch
                          disabled={from == "associate" ? false : true}
                          checked={!!data?.[document?.id]}
                          onChange={(e, value) => handleChange(document?.id, value)}
                        />
                      </Grid>
                      <Grid item>{document?.label[1]?.label}</Grid>
                    </Grid>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="column col">
            <div className="heading">
              <div className="text">Eligibility</div>
              <div className="icon">{Icons.eligibility}</div>
            </div>
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item>{"No"}</Grid>
              <Grid item>
                <Switch
                  disabled={from == "associate" ? false : true}
                  checked={!!data?.eligible}
                  onChange={(e, value) => handleChange("eligible", value)}
                />
              </Grid>
              <Grid item>{"Yes"}</Grid>
            </Grid>

            <textarea
              placeholder={
                from == "associate" ? "Add your comments" : "Comments"
              }
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              readOnly={from == "associate" ? false : true}
            ></textarea>

            {
              from == "associate" ? <CustomButton title={"Save"} onClickHandler={submitData} /> : <></>
            }
          </div>
        </div>
      </Container>

      <div className="actionSection">
        {from == "associate" && application?.status == "dueDiligence" ? (
          <div className="actions">
            <div className="title">Action: </div>
            <div className="input">
              <Select
                size="small"
                value={selectedAction}
                onChange={handleActionManager}
              >
                <MenuItem value={-1}>
                  <FormLabel>Select</FormLabel>
                </MenuItem>
                <MenuItem value={"nextRound"}>
                  <FormLabel>Move to next round</FormLabel>
                </MenuItem>
                <MenuItem value={"reject"}>
                  <FormLabel style={{ color: "red" }}>Reject</FormLabel>
                </MenuItem>
              </Select>
            </div>
          </div>
        ) : (
          <></>
        )}
        <Dialog open={actionDialogOpen} onClose={handleActionDialog}>
          <ActionDialog
            handleActionDialog={handleActionDialog}
            activeAction={selectedAction}
            application={application}
            getApplicationDataHandlerForManager={getApplication}
            phase={PHASE}
            setMovingNextRound={setMovingNextRound}
            applicationDataVersion={applicationData?.__v}
          />
        </Dialog>
      </div>
    </SchemePhaseTabContainer>
  );
};

export default DueDiligence;
