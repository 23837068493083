import { createSlice } from '@reduxjs/toolkit'

export const ApplicationDataSlice = createSlice({
  name: 'navigation',
  initialState: {
    application: null,
    isLateral: false,
  },
  reducers: {
    updateApplication: (state, actions) => {
      state['application'] = actions.payload['application']
      state['isLateral'] = actions.payload['isLateral']
    },
  },
})

export const { updateNavigation } = ApplicationDataSlice.actions

export default ApplicationDataSlice.reducer
