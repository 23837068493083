import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  application: null,
  applicationData: null,
  progress: {},
  isLateral: false,
}

const TitanApplicationSlice = createSlice({
  name: 'titan_application',
  initialState: { ...INITIAL_STATE },
  reducers: {
    updateTitanApplication: (state, actions) => {
      state[actions.payload.id] = actions.payload.value
    },
  },
})

export const { updateTitanApplication } = TitanApplicationSlice.actions

export default TitanApplicationSlice.reducer
