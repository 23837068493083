import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveUser } from "../../../data/network/api/user";
import { globalDispatch, globalSelector } from "../../../providers/GlobalProvider";
import { updateData } from "../../../reducers/startupform/formReducer";
import { DialogBody } from "../../../utils/customStyledComponents";
import { Icons } from "../../../utils/mediaExports";
import { domainAndSectors } from "../../../utils/optionValues";

const InvestorAddModal = ({ handleDialogClose }) => {
  const [data, setData] = useState({
    name: "",
    investedSectors: [],
    investedCompanies: [],
    proficiency: "",
    ticketSize: "",
    type: "investor",
  });
  const [loading, setLoading] = useState(false);
  const fields = [
    "_id",
    "name",
    "investedSectors",
    "investedCompanies",
    "proficiency",
    "ticketSize",
  ];
  let investorData = globalSelector(
    (state) => state.startup.association.investors
  );
  const dispatch = globalDispatch();
  const controller = new AbortController();

  useEffect(() => {
    setData({ ...data, name: investorData.name.value });
  }, []);

  useEffect(() => {}, []);

  const handleChange = (id, value) => {
    setData({ ...data, [id]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await addUser();
    setLoading(false);
  };

  const addUser = async () => {
    const response = await saveUser(data, dispatch, controller);
    if (response && response?.status == 200) {
      const data = response?.msg;
      console.log(data);
      fields.map((field, i) => {
        dispatch(
          updateData({
            category: "association",
            subCategory: "investors",
            id: field,
            type: "value",
            value: data?.[field] || "",
          })
        );
      });
      handleDialogClose();
    }
  };

  return (
    <DialogBody>
      <div className="closeButton" onClick={handleDialogClose}>
        {Icons.close}
      </div>
      <DialogTitle className="dialogTitle">Add Investor</DialogTitle>
      <DialogContent className="content">
        <TextField
          className="firstField"
          label={"Investor Name"}
          size={"small"}
          value={data.name}
          onChange={(e) => handleChange("name", e.target.value)}
        />
        <Autocomplete
          multiple
          id="tags-outlined"
          options={domainAndSectors}
          getOptionLabel={(option) => option}
          defaultValue={[]}
          filterSelectedOptions
          onChange={(event, newValue) => {
            let values = newValue.map((object) => {
              return object;
            });
            handleChange("investedSectors", values);
          }}
          renderInput={(params) => (
            <TextField {...params} size={"small"} label="Invested Sectors" />
          )}
        />
        <MuiChipsInput
          value={data.investedCompanies}
          onChange={(value) => handleChange("investedCompanies", value)}
          size={"small"}
          label="Invested Companies"
        />
        <TextField
          label={"Proficiency"}
          size={"small"}
          value={data.proficiency}
          onChange={(e) => handleChange("proficiency", e.target.value)}
        />

        <TextField
          label={"Ticket Size"}
          size={"small"}
          value={data.ticketSize}
          onChange={(e) => handleChange("ticketSize", e.target.value)}
        />

        <div className="actionField ">
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              className="button"
              onClick={handleSubmit}
              variant="contained"
            >
              Add
            </Button>
          )}
        </div>
      </DialogContent>
    </DialogBody>
  );
};

export default InvestorAddModal;
