import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  application: null,
  applicationData: null,
  progress: {},
  isLateral: false,
}

const PrayasApplicationSlice = createSlice({
  name: 'prayas_application',
  initialState: { ...INITIAL_STATE },
  reducers: {
    updatePrayasApplication: (state, actions) => {
      state[actions.payload.id] = actions.payload.value
    },
  },
})

export const { updatePrayasApplication } = PrayasApplicationSlice.actions

export default PrayasApplicationSlice.reducer
