import { createSlice } from '@reduxjs/toolkit'

export const AuthDataSlice = createSlice({
  name: 'navigation',
  initialState: {
    isAuthenticated: false,
    user: null,
  },
  reducers: {
    updateAuth: (state, actions) => {
      state['isAuthenticated'] = actions.payload['isAuthenticated']
      state['user'] = actions.payload.user
    },
  },
})

export const { updateAuth } = AuthDataSlice.actions

export default AuthDataSlice.reducer
