import {
  ListTab,
  OutletContainer,
} from "../../../utils/customStyledComponents";
import styled from "styled-components";
import CustomButton from "../../customComponents/CustomButton";
import CustomTab from "../../customComponents/CustomTab";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import paths from "../../../utils/links";
import {
  handleTopBarTitle,
  setSidebarActive,
} from "../../../utils/helperHandler";
import StartupsList from "./StartupsList";
import { updateNavigation } from "../../../reducers/navigationReducer";
import {
  globalDispatch,
  globalSelector,
} from "../../../providers/GlobalProvider";
import BulkUploadDialog from "./BulkUploadDialog";
import { Dialog } from "@mui/material";
import BIG from "../../schemes/ikp/big/Big";
import OwlLoader from "../../customComponents/OwlLoader";
import { useSnackbar } from "notistack";

const Startups = () => {
  const user = globalSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = globalDispatch();
  const [uploadModalOpen, setUploadModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };
  const handleUploadModal = (e, reason) => {
    if (reason == "backdropClick") {
      return;
    }
    setUploadModal(!uploadModalOpen);
  };

  const startupForm = () => {
    navigate("/startups/add");
    dispatch(
      updateNavigation({
        title: paths.addStartup.title,
        path: paths.addStartup.link,
      })
    );
  };

  const customReport = () => {
    navigate("/startups/custom-report");
    dispatch(
      updateNavigation({
        title: paths.customReport.title,
        path: paths.customReport.link,
      })
    );
  };

  useEffect(() => {
    if (
      (user.role.roleId == "external_reviewer" ||
        user.role.roleId == "founder") &&
      user?.role?.tempPassword
    ) {
      snackbarHandler("Please, change the password", "success");
      navigate("/profile/:id");
      return;
    }
    if (user.role.roleId == "founder") {
      navigate("/");
      return;
    }
    setLoading(false);
    handleTopBarTitle(dispatch, paths.startups);
    setSidebarActive(paths.startups.id);
    document.getElementById("pageNavStackContainer").style.display = "block";
  }, []);

  if (loading) {
    return <OwlLoader />;
  }

  return (
    <OutletContainer>
      <ListTab>
        {user.role.roleId == "super_admin" ? (
          <>
            <CustomButton
              title="Custom Report Generation"
              onClickHandler={customReport}
            />
            <CustomButton title="Add Startup" onClickHandler={startupForm} />
            <CustomButton
              title="Bulk Upload"
              onClickHandler={() => setUploadModal(true)}
            />
          </>
        ) : (
          <div style={{ marginBottom: "1rem" }}></div>
        )}
      </ListTab>

      <TabContainer>
        <StartupsList />
      </TabContainer>

      {uploadModalOpen ? (
        <Dialog open={uploadModalOpen} onClose={handleUploadModal}>
          <BulkUploadDialog handleDialogClose={handleUploadModal} />
        </Dialog>
      ) : (
        <></>
      )}
    </OutletContainer>
  );
};

export default Startups;

const TabContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`;
