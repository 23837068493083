import styled from "styled-components";
import { Icons } from "../../../../../utils/mediaExports";

const MilestoneTab = ({ milestones, activeTab, changeActiveTab }) => {
  return (
    <Container>
      <div className="tabContainer">
        {milestones?.map((milestone, index) => {
          return (
            <div
              className="item"
              key={index}
              onClick={() => changeActiveTab(index, milestone._id)}
            >
              <span className={`tabItem ${activeTab == index ? "active" : ""}`}>
                <div
                  className="text"
                  style={index == 0 ? null : { marginLeft: "1rem" }}
                >
                  Milestone {index + 1}
                </div>

                <span className="arrowIcon">
                  {index + 1 < milestones.length ? Icons.arrowFormward : null}
                </span>
              </span>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default MilestoneTab;

const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .tabContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--text1);
    width: max-content;

    .item {
      display: flex;
      align-items: center;

      cursor: pointer;

      .tabItem {
        display: flex;
        align-items: center;
        gap: 1rem;
        border-bottom: 2px solid var(--borderBottomColor);

        .text {
          font-size: 1.2rem;
          padding: 0.7rem 0;
        }

        .badge {
          background: #fff;
          font-size: 0.7rem;
          border-radius: 50%;
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .activeBadge {
          background: var(--primary);
        }
      }

      .active {
        border-bottom: 2px solid var(--primary);
      }
    }

    .block {
      pointer-events: none;
      background: #ecf0f1;

      .tabItem {
        .text {
          color: var(--text3);
        }
      }
    }
  }
`;
