import { createSlice } from '@reduxjs/toolkit'

export const DocumentDataSlice = createSlice({
  name: 'document',
  initialState: {
    open: false,
    url: '',
  },
  reducers: {
    updateDocument: (state, actions) => {
      state['open'] = actions.payload['open']
      state['url'] = actions.payload['url']
      console.log(state['url'])
    },
  },
})

export const { updateDocument } = DocumentDataSlice.actions

export default DocumentDataSlice.reducer
