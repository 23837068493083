import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { globalDispatch, globalSelector } from "../../providers/GlobalProvider";
import { OutletContainer } from "../../utils/customStyledComponents";
import { handleTopBarTitle, setSidebarActive } from "../../utils/helperHandler";
import paths, { SidebarLinks } from "../../utils/links";
import styled from "styled-components";
import CustomButton from "../customComponents/CustomButton";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = globalDispatch();
  const navigate = useNavigate();
  const { user } = globalSelector((state) => state.auth);

  useEffect(() => {
    if (user.role.roleId != "founder") {
      navigate("/startups");
      return;
    }

    handleTopBarTitle(dispatch, {
      title: paths.home.title,
      path: paths.home.path,
    });
    setSidebarActive(paths.home.id);
    document.getElementById("pageNavStackContainer").style.display = "none";
  }, []);

  return (
    <OutletContainer>
      <Container>
        <div className="text" style={{ textAlign: "center" }}>
          <strong style={{ fontSize: "larger" }}> Welcome to Athena! </strong>
          <br /> <br />
          Click on "Schemes" in the side menu to apply
        </div>

        <div className="button">
          {/* <CustomButton
            title={"TITAN Application"}
            onClickHandler={() => {
              navigate("/schemes/titan/653fa874c81e834e47074a2f");
            }}
          /> */}
        </div>
      </Container>
    </OutletContainer>
  );
};

export default Home;

const Container = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
  border: 1px solid #d0d3d4;
  padding: 2rem;
  border-radius: 8px;
  margin: auto auto;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;

  .text {
    font-size: 24px;
    color: var(--text1);
  }
`;
