import axios from 'axios'
import { getFileName } from '../../../utils/helperHandler'

//api request to download file
export const downloadFile = async (url, filename) => {
  await axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    const fileName = filename ? filename : getFileName(url)
    link.setAttribute('download', fileName)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    link.remove()
  })
}
