import {
	Alert,
	Autocomplete,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	assignReviewersForApplications,
	checkApplication,
	getAllReviewersData,
	moveToNextRound,
	rejectApplications,
	revokeReviewersForApplications,
} from "../../../../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../../../../providers/GlobalProvider";
import { DialogBody } from "../../../../../../../utils/customStyledComponents";
import { Icons } from "../../../../../../../utils/mediaExports";
import CustomButton from "../../../../../../customComponents/CustomButton";
const TitanActionDialog = ({
	handleActionDialog,
	activeAction,
	applications,
	getApplicationDataHandlerForManager,
	phase,
	selectApplication,
	setMovingNextRound,
	selectedRow,
	scheme = null,
	reviewers,
}) => {
	const [serverResponse, setServerResponse] = useState(null);

	const [associate, setAssociate] = useState([]);
	const [associates, setAssociates] = useState([]);
	const [revokeAssociate, setRevokeAssociate] = useState([]);
	const [revokeAssociates, setRevokeAssociates] = useState(reviewers);
	const [applicationsData, setApplicationsData] = useState([]);

	const dispatch = globalDispatch();
	const controller = new AbortController();

	const { id, applicationId } = useParams();

	const [timer, setTimer] = useState(null);

	useEffect(() => {
		handleAssociateSearch();
	}, []);

	const handleAssociateSearch = async () => {
		try {
			const response = await getAllReviewersData(
				{
					callId: id,
				},
				dispatch,
				controller
			);
			if (response && response.status == 200) {
				setAssociates(response.msg);
			} else {
				setAssociates([]);
			}
			const applicationDatas = [];
			selectedRow?.map(async (id) => {
				const response = await checkApplication(
					{
						fromIkp: true,
						applicationId: id,
					},
					dispatch,
					controller
				);
				applicationDatas.push(response.msg?.applicationData);
			});
			setApplicationsData(applicationDatas);
		} catch (err) {
			console.log(err);
		}
	};

	const handleSelect = (e, value) => {
		let isStringFound = false;
		value?.map((data) => {
			if (typeof data === "string") {
				isStringFound = true;
			}
		});
		if (isStringFound) {
			setServerResponse({
				type: "error",
				body: "Incorrect reviewer data found",
			});
		} else {
			setServerResponse();
		}
		setAssociate(value);
	};

	const submitHandler = async () => {
		try {
			setServerResponse(null);
			const associatesID = [];
			associate?.map((person, i) => {
				if (associatesID.indexOf(person._id) === -1) {
					associatesID.push(person._id);
				}
			});
			const response = await assignReviewersForApplications(
				{
					callId: id,
					applications: selectedRow,
					reviewer: associatesID,
				},
				dispatch,
				controller
			);
			if (response && response.status == 200) {
				setServerResponse({ type: "success", body: response.msg });
				window.location.reload();
			} else {
				setServerResponse({
					type: "error",
					body: response?.msg || "Something went wrong!",
				});
			}
		} catch (err) {
		} finally {
		}
	};

	const changeApplicationStatusHandler = async (status) => {
		try {
			setServerResponse(null);
			const applicationDataVersion = applicationsData.map((data) => data?.__v);
			const allApplicationData = selectedRow.map((row, index) => {
				return {
					applicationId: row,
					phase: phase,
					applicationDataVersion: applicationDataVersion[index],
				};
			});
			let response = "";
			if (status === "rejected") {
				response = await rejectApplications(
					{
						callId: id,
						applications: allApplicationData,
					},
					dispatch,
					controller
				);
			}
			if (status === "approved") {
				response = await moveToNextRound(
					{
						callId: id,
						applications: allApplicationData,
					},
					dispatch,
					controller
				);
			}

			if (response && response.msg[0].status == 200) {
				setServerResponse({ type: "success", body: response.msg[0].data });
				if (status == "approved") {
					setTimer(
						setTimeout(() => {
							setMovingNextRound(true);
						}, [3000])
					);
				}
				window.location.reload();
			} else {
				setServerResponse({
					type: "error",
					body:
						response?.msg[0].data || response?.msg || "Something went wrong!",
				});
			}
		} catch (error) {
			setServerResponse({
				type: "error",
				body: response?.msg[0].data || response?.msg || "Something went wrong!",
			});
		}
	};

	const handleRevokeReveiwerSelect = (e, value) => {
		let isStringFound = false;
		value?.map((data) => {
			if (typeof data === "string") {
				isStringFound = true;
			}
		});
		if (isStringFound) {
			setServerResponse({
				type: "error",
				body: "Incorrect reviewer data found",
			});
		} else {
			setServerResponse();
		}
		setRevokeAssociate(value);
	};
	const submitRevokeReviewersHandler = async () => {
		let response = "";
		try {
			const reviewersIDs = [];
			revokeAssociate?.map((data) => {
				if (reviewersIDs.indexOf(data?._id) === -1) {
					reviewersIDs.push(data?._id);
				}
			});
			response = await revokeReviewersForApplications(
				{
					callId: id,
					applications: selectedRow,
					reviewer: reviewersIDs,
				},
				dispatch,
				controller
			);
			if (response && response?.status == 200) {
				setServerResponse({ type: "success", body: response?.msg });
				window.location.reload();
			} else {
				setServerResponse({
					type: "error",
					body:
						response?.msg[0]?.data || response?.msg || "Something went wrong!",
				});
			}
		} catch (error) {
			setServerResponse({
				type: "error",
				body:
					response?.msg[0]?.data || response?.msg || "Something went wrong!",
			});
		}
	};
	switch (activeAction) {
		case "assign":
			return (
				<DialogBody>
					<div className="closeButton" onClick={handleActionDialog}>
						{Icons.close}
					</div>

					<DialogTitle className="dialogTitle">Assign Reviewer</DialogTitle>
					<DialogContent className="content">
						<Autocomplete
							freeSolo
							disableClearable
							options={associates}
							getOptionLabel={(option) =>
								`${option?.name} - (${option?.email})`
							}
							onChange={handleSelect}
							multiple={true}
							value={associate}
							renderInput={(params) => (
								<TextField
									className="firstField"
									{...params}
									// onChange={handleAssociateSearch}
									label="Search Associates"
									InputProps={{
										...params.InputProps,
										type: "search",
									}}
								/>
							)}
						/>

						<div className="actionField">
							<CustomButton
								title={"Submit"}
								onClickHandler={submitHandler}
								disabled={
									serverResponse || associate?.length === 0 ? true : false
								}
							/>
						</div>

						{serverResponse ? (
							<Alert color={serverResponse?.type || "error"}>
								{serverResponse.body}
							</Alert>
						) : (
							<></>
						)}
					</DialogContent>
				</DialogBody>
			);
		case "revoke":
			return (
				<DialogBody>
					<div className="closeButton" onClick={handleActionDialog}>
						{Icons.close}
					</div>

					<DialogTitle className="dialogTitle">Revoke Reviewer</DialogTitle>
					<DialogContent className="content">
						<Autocomplete
							freeSolo
							disableClearable
							options={revokeAssociates}
							getOptionLabel={(option) =>
								`${option?.name} - (${option?.email})`
							}
							onChange={handleRevokeReveiwerSelect}
							multiple={true}
							value={revokeAssociate}
							renderInput={(params) => (
								<TextField
									className="firstField"
									{...params}
									// onChange={handleAssociateSearch}
									label="Search Associates"
									InputProps={{
										...params.InputProps,
										type: "search",
									}}
								/>
							)}
						/>

						<div className="actionField">
							<CustomButton
								title={"Submit"}
								onClickHandler={submitRevokeReviewersHandler}
								disabled={
									serverResponse || revokeAssociate?.length === 0 ? true : false
								}
							/>
						</div>

						{serverResponse ? (
							<Alert color={serverResponse?.type || "error"}>
								{serverResponse.body}
							</Alert>
						) : (
							<></>
						)}
					</DialogContent>
				</DialogBody>
			);

		case "nextRound":
			return (
				<DialogBody>
					<div
						className="closeButton"
						onClick={() => {
							clearTimeout(timer);
							handleActionDialog();
						}}
					>
						{Icons.close}
					</div>

					<DialogTitle className="dialogTitle">
						{selectApplication
							? "Select Application"
							: "Move Application to next Round"}
					</DialogTitle>
					<DialogContent className="content">
						<div className="actionField">
							<CustomButton
								title={"Confirm"}
								onClickHandler={() =>
									changeApplicationStatusHandler("approved")
								}
							/>
						</div>

						{serverResponse ? (
							<Alert color={serverResponse?.type || "error"}>
								{serverResponse.body}
							</Alert>
						) : (
							<></>
						)}
					</DialogContent>
				</DialogBody>
			);

		case "reject":
			return (
				<DialogBody>
					<div className="closeButton" onClick={handleActionDialog}>
						{Icons.close}
					</div>

					<DialogTitle className="dialogTitle">Reject Application</DialogTitle>
					<DialogContent className="content">
						<div className="actionField">
							<CustomButton
								title={"Confirm"}
								onClickHandler={() =>
									changeApplicationStatusHandler("rejected")
								}
							/>
						</div>
						{serverResponse ? (
							<Alert color={serverResponse?.type || "error"}>
								{serverResponse.body}
							</Alert>
						) : (
							<></>
						)}
					</DialogContent>
				</DialogBody>
			);
	}
};

export default TitanActionDialog;
